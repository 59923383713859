.sub-visual {
	height:210px;position:relative;
	background-color:#1a1b1e;
	background: {
		//image: url("../../resources/images/basic/visual.jpg");
		repeat: repeat;
		position: center top;
		//size: auto 100%;
		size: cover;
	}
	font-family: $font2;

	&__tit {
		color: #fff;
		font-size: 44px;
		padding-top: 51px;
		text-align:center;
		font-family:'GongGothicM';
	}
	&__desc {
		color: #000;
		font-size: 24px;
		padding-top: 10px;
		text-align:center;
	}
	&__move {color:#fff; max-width:1580px; width:100%; position: relative;margin:0 auto; font-size:18px; top:-10%;
		&__prev {position: absolute;left:0;padding-left:44px;  background:url("../../resources/images/cont/sub-visual-top-right.png") left center no-repeat;}
		&__next {position: absolute;right:0;padding-right:44px; background:url("../../resources/images/cont/sub-visual-top-left.png") right center no-repeat;}

	}
}

@media screen and (min-width:769px) and (max-width:$siteSize) {
	.sub-visual__tit {font-size: 40px;}
	.sub-visual {display: none;}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.sub-visual {display: none;}
}