
/*탭*/
.js-box {display:none;position:relative;min-height: 20px;}
.js-box.on {display:block;}
.js-box2 {display:none;position:relative;min-height: 100px;}
.js-box2.on {display:block;}
.js-box3 {display:none;min-height: 100px;}
.js-box3.on {display:block;}

.sub-tab { //sub-tab 디자인때문에 아래와 같이 1픽셀 밀림 수정 필요
	.js-box {margin-left:1px;}
	.js-box2 {margin-left:1px;}
	.js-box3 {margin-left:1px;}
}



/*메인탭*/

.main-tab {
	position: relative;
	&__tit {
		font-size: 40px;
		font-family: 'GongGothicM';
		margin-bottom: 30px;
		position: absolute;
		top:0;
		left:0;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			position: relative;
			margin-bottom:20px;
		}
		@media screen and (min-width:1px) and (max-width:768px) {
			font-size:28px;
		}
	}
	&__ul {
		padding-left: 230px;
		margin-bottom:30px;
		display:flex; 
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			padding-left:0;
			margin-bottom:10px;
		}
		&__li {margin-right:25px; 
			
			@media screen and (min-width:1px) and (max-width:480px) {
				margin-right:10px; 
			}
			a {padding-top:10px;
				box-sizing: border-box;
				display:block;
				@include fontSize(20);
				text-align: center;

				color: #919191;
				span {display: inline-block;padding-bottom:3px;border-bottom:3px solid #fff;}
				position: relative;
				@media screen and (min-width:1px) and (max-width:$siteSize) {

					@include fontSize(18);

				}
				// @media screen and (min-width:1px) and (max-width:480px) {
				// 	height:auto;
				// 	line-height: 1.5;
				// 	margin-bottom:5px;
				// }
				// &:before {
				// 	position: absolute;
				// 	left:50%;
				// 	transform: translateX(-50%);
				// 	top:3px;
				// 	//@include sprite($notice-off)
				// 	@media screen and (min-width:1px) and (max-width:$siteSize) {
				// 		top:7px;
				// 	}
				// }
			}
			&.on a{
				color: #111111;
				opacity: 1;
				font-weight: bold;padding-bottom:2px; 
				border-bottom:2px solid #111;
				//font-size:22px;
				// &:before {
				// 	@include sprite($notice-on)
				// }
				span {
					border-bottom:3px solid #000;
				}
				@media screen and (min-width:1px) and (max-width:540px) {
					font-size: 16px;
				}
			}
			a:hover,
			a:focus {
				
				border-bottom:2px solid #111;padding-bottom:2px; 
			}
			// @media screen and (min-width:1px) and (max-width:540px) {
			// 	float:none;
			// 	a {
			// 		padding-left:0;
			// 		padding-right:0;
			// 		border:1px solid #ddd;
			// 		&:before{
			// 			display:none;
			// 		}
			// 		font-weight: normal;
			// 	}
			// 	&.on a{
			// 		background-color: #0e813f;
			// 		color:#fff;
			// 		&:before{
			// 			display:none;
			// 		}
			// 	}
			// }
		}
	}

	&__cont {
		// min-height:327px;
		box-sizing: border-box;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
		}
		@media screen and (min-width:1px) and (max-width:540px) {
		}
		&__ul {
			&__li {
				@media screen and (min-width:1px) and (max-width:540px) {
					display:none;
					&:nth-child(1),
					&:nth-child(2),
					&:nth-child(3) {display: block;}
				}
			}
		}

	}
}


.main-tab__cont__ul__li a {
	display:block;position: relative;padding-left:20px;padding-right: 10px;
	&:before {
		content:' ';
		display: block;
		width:3px;
		height:3px;
		border-radius: 100%;
		background-color:#888;
		position: absolute;
		top:50%;margin-top:-1px;
		left:3px;
	}
}
.main-tab__cont__ul__li a:hover,
.main-tab__cont__ul__li a:focus {background-color:#eee}
.main-tab__cont__more {
	position: absolute;
	right: 0;
	top: 10px;
	text-indent: -9999px;
	@include sprite($bbs-more);

	@media screen and (min-width:1px) and (max-width:$siteSize) {
	}
	@media screen and (min-width:1px) and (max-width:540px) {
		// top:10px;
		// right: 10px;

		// width:30px;
		// height:30px;
		// background-color: transparent;
		// &:after {
		// 	top:8px;
		// 	left:8px;

		// }

	}
}
	.main-tab__cont__more:focus {border:1px solid gold;padding:0;}
.mtcul-cont {
	white-space: nowrap;overflow:hidden;display: block;text-overflow: ellipsis;
	width:70%;

	@include fontSize(17);
	padding-top:8px;padding-bottom:8px;color:#000;}
.mtcul-date {display:block;position: absolute;top:10px;right:10px;}

@media screen and (min-width:1px) and (max-width:768px) {
	.mtcul-cont {width:auto;padding-top:5px;padding-bottom:5px;

		@include fontSize(16);
	}
	.mtcul-date {display: none;}
}

//메인 bbs 추가 수정
//main new
.new-list {
	@include floatWrap;
	margin-left:-50px;
	&__li {
		float:left;
		width:50%;
		@media screen and (min-width:1081px) and (max-width:$siteSize) {
			width:100%;
			display:none;
			&:first-child {
				display: block;
			}
		}
		@media screen and (min-width: 1px) and (max-width: 560px){
			width:100%;
		}
		a {
			display: block;
			margin-left:50px;
			position: relative;
			&:after {
				position: absolute;
				right:0;
				bottom:0;
				@include sprite($main-tab-bul)
			}
			.bbs-ctg {
				color:#089296;
				font-size:18px;
				margin-bottom:15px;
				white-space: nowrap;
			}
			.bbs-sbj {
				font-size:20px;
				font-weight: bold;
				margin-bottom:20px;
				height:56px;
				overflow: hidden;
			}
			.bbs-cnt {
				font-size:16px;
				margin-bottom:54px;
				height:52px;
				overflow: hidden;
			}
			.bbs-date {
				font-size:15px;
				color:#777;
				position: relative;
				&:after {
					position: absolute;
					top:-20px;
					left:0;
					content: ' ';
					display: block;
					width:100%;
					height:1px;
					background-color: #999;
				}
			}
		}

		a:hover,
		a:focus {
			.bbs-sbj {
				text-decoration: underline;
			}

		}
	}
}

/*서브탭*/
.sub-tab {
	overflow: hidden;
	&__ul {
		@include floatWrap;
		display: flex;
		flex-wrap: wrap;
		border:1px solid #ddd;
		margin-left:-1px;
		margin-top:-1px;
		border-radius: 7px;
		margin-bottom:30px;
		overflow: hidden;
		&__li {

			flex:1;

			min-width: 140px;
			a {
				color:#333;
				display:block;
				border-top:1px solid #ddd;
				border-left:1px solid #ddd;
				text-align: center;
				@include fontSize(16);
				padding-top:12px;
				padding-bottom:12px;
				white-space: nowrap;
				&:hover {text-decoration: underline;}
				&:focus {background-color: #3963b9;color:#fff;
					border-top:1px solid #3963b9;
					border-left:1px solid #3963b9;}
			}
			&.on {
				a {
					background-color: #2550a7;
					color:#fff;
					border-top:1px solid #2550a7;
					border-left:1px solid #2550a7;
				}
			}
		}

	}
}



/*서브탭2*/
.sub-tab2 {
	overflow: hidden;

	&__ul {
		@include floatWrap;
		border-top:1px solid #ddd;
		border-left:1px solid #ddd;
		margin-bottom:30px;
		&__li {
			float:left;
			box-sizing: border-box;

			@media screen and (min-width:1px) and (max-width:900px) {
				width:25% !important;
			}
			@media screen and (min-width:1px) and (max-width:768px) {
				width:33.33% !important;
			}
			@media screen and (min-width:1px) and (max-width:540px) {
				width:50% !important;
			}
			a {
				color:#333;
				display:block;

				border:1px solid #ddd;
				margin-top:-1px;
				margin-left:-1px;
				text-align: center;
				@include fontSize(14);
				padding-top:10px;
				padding-bottom:10px;
				white-space: nowrap;
				box-sizing: border-box;

				&:hover {text-decoration: underline;}
				&:focus {}
			}
			&.on {
				a {
					color:#2550a7;
					position: relative;
					z-index: 99999;
					font-weight: bold;
					padding-top:9px;
					padding-bottom:9px;
					border:2px solid #2550a7;
				}
			}
		}

	}

	&.menu-num-2 > ul > li {width:50%;}
	&.menu-num-3	> ul > li {width:33.33%;}
		*+ html &.menu-num-3	> ul > li {width:33%;}
	&.menu-num-4	> ul > li {width:25%;}
	&.menu-num-5	> ul > li {width:20%;}
	&.menu-num-6	> ul > li {width:16.66%;}
		*+ html &.menu-num-6	> ul > li {width:16.66%;}
	&.menu-num-7	> ul > li {width:16.66%;}
	&.menu-num-8	> ul > li {width:16.66%;}
	&.menu-num-9	> ul > li {width:16.66%;}
		*+ html .menu-num-9	> ul > li {width:16.66%;}
	&.menu-num-10 > ul > li {width:16.66%;}
	&.menu-num-11 > ul > li {width:16.66%;}
		*+ html &.menu-num-11 > ul > li {width:16.66%;}
	&.menu-num-12 > ul > li {width:16.66%;}
		*+ html &.menu-num-12 > ul > li {width:16.66%;}

	&.menu-num-13 > ul > li {width:16.66%;}

	&.menu-num-14 > ul > li {width:16.66%;}
	&.menu-num-15 > ul > li {width:16.66%;}
	&.menu-num-16 > ul > li {width:16.66%;}
	&.menu-num-17 > ul > li {width:16.66%;}
	&.menu-num-18 > ul > li {width:16.66%;}
	&.menu-num-19 > ul > li {width:16.66%;}
	&.menu-num-20 > ul > li {width:16.66%;}
	&.menu-num-21 > ul > li {width:16.66%;}
	&.menu-num-22 > ul > li {width:16.66%;}
	&.menu-num-23 > ul > li {width:16.66%;}
	&.menu-num-24 > ul > li {width:16.66%;}
	&.menu-num-25 > ul > li {width:16.66%;}

}