/*영문페이지*/


.header.eng {
    .gnb .gnb-menu > a { font-size:20px;}
    .header__wrap__h1 h1 {top:38px;}
    @media screen and (min-width: 769px) and (max-width: 1500px) {

        .header__wrap__h1 h1 {top:38px;}
    }
}


.subpage .down-all.eng .header__wrap__h1 h1 a {

    @include sprite($title3);
    @media screen and (min-width:1201px) and (max-width:1300px) {
        @include sprite($title3,1.3);
    }
}
.subpage.eng {
    .depth3__ul > li > a {font-size:15px;}
    

}
.mainpage .down-all.eng .header__wrap__h1 h1 a {
    @include sprite($title3);
    @media screen and (min-width:1201px) and (max-width:1300px) {
        @include sprite($title3,1.3);
    }
}


.main-section.eng {
    .main-lay-1 {max-width:1580px;}
    .popupzone-slide-big h4 {font-family: 'GongGothicL';text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
        span:first-child {font-size:18px;display:block;margin-bottom:10px;}
        span:last-child {font-size:18px;display:block; font-style: italic;margin-top:20px;}
    }
    .popupzone-slide-big p {font-size:16px; line-height:1.4; margin-top:25px;text-shadow: 1px 1px 1px rgba(0,0,0,0.4);}
    .popupzone-slide-first .popupzone2-list__item a h4 {font-family: 'GongGothicL';text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
        span {display:none;}
    }
    .popupzone-slide-big .popupzone2-list__item__btn1,.popupzone-slide-big .popupzone2-list__item__btn2 {-webkit-box-shadow: 10px 10px 11px -10px rgba(0,0,0,0.5);
        -moz-box-shadow: 10px 10px 11px -10px rgba(0,0,0,0.5);
        box-shadow: 10px 10px 11px -10px rgba(0,0,0,0.5);}
    .shortcut {margin-top:0;}
    .shortcut__btn {padding-top:50px;}
    .shortcut__ul__li {align-items: start;box-sizing:border-box; padding-top:70px; min-height:520px;}
    .shortcut__ul__li h4 {font-size:30px; margin-top:0; }
    .shortcut__ul__li h4 span {font-size:16px;
        font-family: 'GongGothicL';}
    .shortcut__ul__li:first-child {background: url(../../resources/images/temp/eng-baro-go-img1.jpg) center center no-repeat;background-size: cover;}
    .shortcut__ul__li:nth-child(2) {background: url(../../resources/images/temp/eng-baro-go-img2.jpg) center center no-repeat;background-size: cover;}
    .shortcut__ul__li:nth-child(3) {background: url(../../resources/images/temp/eng-baro-go-img3.jpg) center center no-repeat;background-size: cover;}
    
    @media screen and (min-width:1px) and (max-width:700px) {
        .popupzone-slide-big p {display:none;}
        .shortcut__ul__li {min-height:500px;}
    }
    
}


/*Greeting*/

.eng_greeting {padding-left:600px; background:url('../../resources/images/cont/eng-greeting-img1.png') left top no-repeat;
    h4{font-size:70px; color:#143553;font-family: 'GongGothicM'; line-height: 1.4;
        span {font-size:30px;display:block;}
    }
    p {font-size:18px;margin-bottom:20px;
        &.type1 {color:#0050a0; font-weight:bold; font-size:22px; margin-top:30px; margin-bottom:90px;}
    }
    @media screen and (min-width:1px) and (max-width:1000px) {
        padding-left:0; padding-top:630px;
        h4{font-size:40px;
            span {font-size:20px;}
        }
        p {font-size:16px;margin-bottom:15px;
            &.type1 { font-size:18px; margin-top:20px; margin-bottom:50px;}
        }
    }

    
}

/*오시는길*/
.way-to__jido.eng {
    .way-to__jido__text {padding-right:50px;}
    .way-to__jido__text__tit {font-size:30px; line-height:1.2; text-align: center;margin-right:50px; padding-right:50px;}

    @media screen and (min-width:1px) and (max-width:768px) {
        .way-to__jido__text__tit {text-align: left; padding-right:0; margin-right:0;;}
    }
}


/*Support Facilities*/
.subpage.eng {
    .jiwon-sisal__box {font-size:16px; min-height:420px;    padding: 40px 45px;}
    .jiwon-sisal__box .h3Type1 {margin-bottom:10px;}

}
