$siteSize:1580px;

$red: #ff5054;
$font1: 'Nanum Gothic';
$font2: 'Pretendard-Regular',
'Nanum Gothic',
sans-serif;
$font3: 'NanumSquare',
'Noto Sans KR',
'Nanum Gothic',
sans-serif;
$font4: 'NanumBarunGothic',
'Nanum Gothic',
'NanumSquare',
'Noto Sans KR',
sans-serif;
$font5: 'Jalnan';
$font6: 'rokg';
$mobileTitle : 34px;
$color1:#0e5390;
$color2:#faff76;
$color3:#333333;

//---------------------------------------------

@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

@function str-split($string, $separator) {
	$split-arr: ();
	$index: str-index($string, $separator);

	@while $index !=null {
		$item: str-slice($string, 1, $index - 1);
		$split-arr: append($split-arr, $item);
		$string: str-slice($string, $index + 1);
		$index: str-index($string, $separator);
	}

	$split-arr: append($split-arr, $string);

	@return $split-arr;
}

@mixin sprite($sprite, $size: 1) {
	$sprite-offset-x: nth($sprite, 3) / $size;
	$sprite-offset-y: nth($sprite, 4) / $size;
	$sprite-total-width: nth($sprite, 7) / $size;
	$sprite-width: nth($sprite, 5) / $size;
	$sprite-height: nth($sprite, 6) / $size;
	$sprite-image: nth($sprite, 9);
	$sprite-name: nth($sprite, 10);

	content: '';
	vertical-align: middle;
	display: inline-block;

	background: {
		repeat: no-repeat;
		position: $sprite-offset-x $sprite-offset-y;
		image: url('#{$sprite-image}');
	}

	;

	@if ($size > 1) {
		background-size: $sprite-total-width auto;
	}

	width: $sprite-width;
	min-width: $sprite-width;
	height: $sprite-height;
}


@mixin sprites($sprites) {
	@each $sprite in $sprites {
		$sprite-name: nth($sprite, 10);

		@if str-index($sprite-name, '-active') {

			//*:focus > .icon-#{str-replace($sprite-name, '-active', '')},
			*:active>.icon-#{str-replace($sprite-name, '-active', '')},
			*:hover>.icon-#{str-replace($sprite-name, '-active', '')} {
				@include sprite($sprite);
			}
		}

		@else {
			.icon-#{$sprite-name} {
				@include sprite($sprite);
			}
		}
	}
}

@function size($pixels) {
	@return $pixels;
}

@mixin hiddenWord {
	text-indent: -9999px;
	position: absolute;
	top: 0;
	left: -9999px;
	font-size: 0;
	height: 0;
	overflow: hidden;
}

@mixin floatWrap {
	&:after {
		content: ' ';
		clear: both;
		display: block;
		visibility: hidden;
	}

	*+html & {
		display: inline-block;
	}
}

@mixin btn1 {
	& {
		display: block;
		color: #fff;
		background-color: #0e5390;
		font-size: 16px;
		padding: 10px 0;
		font-weight: 500;
		transition: all 0.3s ease 0s;
		text-align: center;
	}

	&:hover,
	&:focus {
		background-color: #3174b0;
	}
}

@mixin btn2 {
	& {
		color: #fff;
		background-color: #000;
		height: 50px;
		line-height: 50px;
		display: inline-block;
		padding-left: 30px;
		padding-right: 30px;
		vertical-align: middle;
		font-size: 15px;
		font-weight: 500;
		text-align: center;
		&.st2 {background-color: #0050a0;}
	}

	&:focus {
		background-color: #333;
		color: gold;
	}
	
	&.st2:focus {
		background-color: #00264c;
		color: gold;
	}
}

@mixin btn3 {
	& {
		display: inline-block;
		color: #fff;
		background-color: #da4453;
		font-size: 19px;
		padding: 20px 60px;
		font-weight: 600;
		transition: all 0.3s ease 0s;
		min-width: 100px;
		text-align: center;
	}

	&:hover,
	&:focus {
		background-color: #da5b67;
		color: gold;
	}
}

@mixin btn4 {
	& { position:relative;
		display: inline-block;
		color: #000;
		background-color: #fff;
		font-size: 16px;
		padding:  0px 50px 0px 18px;
		border: 1px solid #000;
		font-weight: 500;
		line-height:54px;
		font-weight: 600;
		height:54px;
		transition: all 0.3s ease 0s;
		min-width: 70px;
		box-sizing: border-box;
		
	}
	&:before {width:18px; height:11px; content:''; position:absolute; background:url(../../resources/images/icons/btn-type4.png) center center no-repeat; 
		right: 20px; top: 50%; margin-top: -5.5px;}
	&:hover,
	&:focus {
		background-color: #eee;
		color: #000;
	}
}

@mixin btn5 {
	& {
		display: inline-block;
		color: #000;
		background-color: #fff;
		font-size: 17px;
		padding: 13px 30px;
		border: 1px solid #000;
		font-weight: 600;
		transition: all 0.3s ease 0s;
		min-width: 50px;
		text-align: center;
	}

	&:hover,
	&:focus {
		background-color: #eee;
		color: #000;
	}
}

@mixin btn6 {
	& {
		display: inline-block;
		color: #000;
		background-color: #fff;
		font-size: 16px;
		padding: 13px 60px 13px 20px;
		border: 1px solid #000;
		transition: all 0.3s ease 0s;
		min-width: 50px;
		text-align: center;
		position: relative;

		&:after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 15px;
			@include sprite($go-icon2);
		}
	}

	&:hover,
	&:focus {
		background-color: #eee;
		color: #000;
	}
}

@mixin btn7 {
	
	& {
		display: inline-block;
		color: #000;
		background-color: #fff;
		font-size: 16px;
		padding:0px 18px 0px 50px;
		height:54px;
		line-height: 54px;
		font-weight: 600;
		transition: all 0.3s ease 0s;
		min-width: 70px;
		text-align: center;
		border: 1px solid #000;
		box-sizing: border-box;
		position: relative;
		vertical-align: middle;

		&:after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 20px;
			@include sprite($list-icon);
		}
	}

	&:hover,
	&:focus {
		
		background-color: #eee;
	}
}

@mixin btn8 {
	& {
		color: #fff;
		background-color: #5bb7d6;
		height: 40px;
		line-height: 40px;
		border-radius: 40px;
		display: inline-block;
		padding-left: 20px;
		padding-right: 20px;
		vertical-align: middle;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
		transition: all 0.3s ease 0s;
	}

	&:hover {
		opacity: 0.8;
	}

	&:focus {
		background-color: #369cbe;
		color: gold;
	}
}


@mixin btn9 {
	& {
		color: #fff;
		background-color: #000;
		height: 30px;
		line-height: 30px;
		display: inline-block;
		padding-left: 20px;
		padding-right: 20px;
		vertical-align: middle;
		font-size: 15px;
		font-weight: 600;
		text-align: center;
		transition: all 0.3s ease 0s;
	}

	&:hover {
		opacity: 0.8;
	}

	&:focus {
		background-color: #333;
		color: gold;
	}
}


@mixin downBtn1 {
	& {
		display: inline-block;
		color: #000;
		background-color: #fff;
		font-size: 16px;
		padding:0px 50px 0px 18px;
		height:54px;
		line-height: 54px;
		font-weight: 600;
		transition: all 0.3s ease 0s;
		min-width: 70px;
		text-align: center;
		border: 1px solid #000;
		box-sizing: border-box;
		position: relative;
		vertical-align: middle;

		&:after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 20px;
			@include sprite($down-icon3);
		}
	}

	&:hover,
	&:focus {
		background-color: #ddd;

	}
}

@mixin prvBtn {
	& {
		display: inline-block;
		color: #000;
		background-color: #fff;
		font-size: 14px;
		padding:0px 7px 0px 20px;
		height:26px;
		line-height: 26px;
		transition: all 0.3s ease 0s;
		min-width: 68px;
		text-align: center;
		border: 1px solid #000;
		position: relative;
		vertical-align: middle;

		&:after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 10px;
			@include sprite($prvbtn);
		}
	}

	&:hover,
	&:focus {
		background-color: #fafafa;

	}
}

@mixin downBtn2 {
	& {
		display: inline-block;
		color: #0c5493;
		background-color: #fff;
		font-size: 15px;
		padding: 10px 40px 10px 30px;
		font-weight: 600;
		transition: all 0.3s ease 0s;
		min-width: 80px;
		text-align: center;
		border: 1px solid #0c5493;
		position: relative;

		&:after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 15px;
			@include sprite($down-icon2);
		}
	}

	&:hover,
	&:focus {
		background-color: #1e79c8;
		color: gold;
	}
}

@mixin goBtn {
	& {
		display: inline-block;
		color: #000;
		background-color: #fff;
		font-size: 15px;
		padding: 10px 25px 10px 40px;
		font-weight: 600;
		transition: all 0.3s ease 0s;
		min-width: 80px;
		text-align: center;
		border: 1px solid #000;
		position: relative;

		&:after {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 15px;
			@include sprite($go-icon);
		}
	}

	&:hover,
	&:focus {
		background-color: #fafafa;

	}
}

@mixin btnSmall-1 {
	& {
		display: inline-block;
		color: #000;
		background-color: #fff;
		border: 1px solid #000;
		box-sizing: border-box;
		vertical-align: middle;
		font-size: 15px;
		padding: 0 10px;
		height: 40px;
		line-height: 40px;
		font-weight: 600;
		transition: all 0.3s ease 0s;
		min-width: 50px;
		text-align: center;
	}

	&:hover,
	&:focus {
		background-color: #eee;
	}
}

@mixin btnSmall-2 {
	& {
		display: inline-block;
		color: #fff;
		background-color: #0a6568;
		border: 1px solid #0a6568;
		box-sizing: border-box;
		vertical-align: middle;
		font-size: 15px;
		padding: 0 5px;
		height: 30px;
		line-height: 30px;
		font-weight: 600;
		transition: all 0.3s ease 0s;
		min-width: 50px;
		text-align: center;
	}

	&:hover,
	&:focus {
		background-color: #1d8185;
	}
}

@mixin btnMore {
	& {
		display: inline-block;
		color: #0e5390;
		background-color: #fff;
		border: 1px solid #0e5390;
		box-sizing: border-box;
		vertical-align: middle;
		font-size: 15px;
		padding: 0 20px 0 20px;
		height: 40px;
		line-height: 40px;
		font-weight: 600;
		transition: all 0.3s ease 0s;
		min-width: 50px;
		text-align: center;
	}

	&:hover,
	&:focus {
		background-color: #eee;
	}
}

@mixin searchBtn {
	& {
		font-size: 0;
		text-indent: -9999px;
		@include sprite($search-btn);
		position: absolute;
		top: 50%;
		right: 2px;
		transform: translateY(-50%);

		&:focus {
			border: 2px solid gold;
		}
	}
}


@mixin basicList {
	& {
		padding-left: 15px;

		>li {
			text-align: left;
			position: relative;
			padding-left: 20px;
			line-height: 1.6;
			margin-bottom: 5px;

			&:before {
				position: absolute;
				top: 12px;
				left: 5px;
				content: ' ';
				@include sprite($list-bul);
			}
		}
	}
}

@mixin basicList2 {
	& {
		>li {
			text-align: left;
			position: relative;
			padding-left: 15px;
			line-height: 1.6;
			margin-bottom: 14px;

			&:before {
				position: absolute;
				top: 12px;
				left: 5px;
				content: ' ';
				@include sprite($list-bul2);
			}
		}
	}
}

@mixin basicList3 {
	& {
		>li {
			text-align: left;
			position: relative;
			padding-left: 12px;
			line-height: 1.6;

			&:before {
				position: absolute;
				top: 12px;
				left: 0;
				content: ' ';
				@include sprite($list-bul);
			}
		}
	}
}
@mixin basicList4 {
	& {
		>li {
			text-align: left;
			position: relative;
			padding-left: 15px;
			line-height: 1.6;
			margin-bottom: 5px;

			&:before {
				position: absolute;
				top: 10px;
				left: 5px;
				content: ' ';
				background-color:#000000; width:4px; height:4px;
			}
		}
	}
}


@mixin basicList5 {
	& {
		>li {
			color:#999999;
			font-size:15px;
			text-align: left;
			position: relative;
			padding-left: 20px;
			line-height: 1.6;
			margin-bottom: 2px;

			&:before {
				position: absolute;
				top: 7px;
				left: 1px;
				content: ' ';
				@include sprite($list-bul5);
			}
		}
	}
}


@mixin inText($inTextSize) {
	& {
		border: 1px solid #000;
		height: 34px;
		width: $inTextSize;
		line-height: 34px;
		font-size: 16px;
		padding: 0;
		padding-left: 10px;
		box-sizing: border-box;
	}
}

@mixin inText2 {
	& {
		border: 1px solid #000;
		height: 34px;
		line-height: 34px;
		font-size: 16px;
		min-width: 30%;
		padding: 0;
		padding-left: 10px;
		box-sizing: border-box;
	}
}

@mixin inText3 {
	& {
		border: 1px solid #e0e0e0;
		height: 37px;
		line-height: 37px;
		font-size: 16px;
		min-width: 30%;
		padding: 0;
		padding-left: 10px;
		box-sizing: border-box;
	}
}

@mixin inText4 {

	//종량제봉투 로그인
	& {
		border: 1px solid #e0e0e0;
		height: 45px;
		line-height: 45px;
		font-size: 16px;
		min-width: 30%;
		padding: 0;
		padding-left: 10px;
		box-sizing: border-box;
	}
}

@mixin inText5 {
	& {
		border: 1px solid #e0e0e0;
		height: 30px;
		line-height: 30px;
		font-size: 16px;
		min-width: 30%;
		padding: 0;
		padding-left: 10px;
		box-sizing: border-box;
	}
}

@mixin inTextareaN1 {
	& {
		border: 1px solid #f6f6f6;
		height: 120px;
		width: 100%;
		line-height: 1.6;
		font-size: 16px;
		padding: 10px;
		box-sizing: border-box;
		background-color: #f6f6f6;
	}
}

@mixin inTextarea($inTextSize) {
	& {
		border: 1px solid #000;
		height: 130px;
		width: $inTextSize;
		line-height: 1.6;
		font-size: 16px;
		padding: 0;
		padding-left: 10px;
		box-sizing: border-box;
	}
}

@mixin inSearchText($searchWidth) {
	& {
		display: inline-block;
		vertical-align: middle;
		position: relative;

		input {
			width: $searchWidth;
			border: 1px solid #ddd;
			height: 40px;
			line-height: 40px;
			font-size: 16px;
			padding: 0;
			padding-left: 15px;
		}

		a { width: 40px; text-indent: -9999px; font-size:0;
			height: 40px;
			border: none;
			font-size:0;
			display: inline-block;
			position: absolute;
			top: 50%;
			right: 0;
			bottom: auto;
			box-sizing: border-box;
			left: auto;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			background: url(/template/resources/images/icons/bbs-search-img.png) center center no-repeat #f8f8f8;
			border-left: 1px solid #ddd;
			border-right: 1px solid #ddd;
			//@include searchBtn;
			&:focus {
				text-decoration: underline;
			}
		}
	}
}

@mixin fontFamily {}

@mixin selectboxN1 {
	& {
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		background-color: #fff;

		&:after {
			position: absolute;
			top: 50%;
			right: 0;
			margin-right: 10px;
			transform: translateY(-50%);
			content: ' ';
			@include sprite($select-bul);
		}

		.lte-ie9 &:after {
			display: none
		}

		text-align:left;
		border:1px solid #ddd;

		label {
			@include hiddenWord
		}

		select {
			width: 100%;
			box-sizing: border-box;
			background-color: transparent;
			appearance: none;
			min-width: 100px;
			vertical-align: middle;
			color: #111;
			font-size: 16px;
			height: 40px;
			margin-top: 0;
			padding: 2px 2px 2px 15px;
			padding-right: 110px;
			position: relative;
			border: 0px;


			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			-o-appearance: none;

			&::-ms-expand {
				display: none;
			}

			/*ie10~11 에서 셀렉트 기본디자인 무력화*/

			.lte-ie9 & {
				width: 100%;
				vertical-align: top;
				padding-right: 0px;
			}

			option {
				font-family: $font1;
			}
		}

	}

	@media screen and (min-width:1px) and (max-width:768px) {
		& {

			select {}
		}
	}
}

@mixin selectboxN2 {
	& {
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		background-color: #fff;

		&:after {
			position: absolute;
			top: 50%;
			right: 0;
			margin-right: 10px;
			transform: translateY(-50%);
			content: ' ';
			@include sprite($select-bul);


		}

		.lte-ie9 &:after {
			display: none
		}

		text-align:left;
		border:1px solid #ddd;

		select {
			width: 100%;
			box-sizing: border-box;
			background-color: transparent;
			appearance: none;
			min-width: 100px;
			vertical-align: middle;
			color: #000;
			font-size: 16px;
			height: 34px;
			margin-top: 0;
			padding: 2px 2px 2px 10px;
			padding-right: 40px;
			position: relative;
			border: 0px;

			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			-o-appearance: none;

			&::-ms-expand {
				display: none;
			}

			/*ie10~11 에서 셀렉트 기본디자인 무력화*/

			.lte-ie9 & {
				width: 100%;
				vertical-align: top;
				padding-right: 0px;

			}

			option {
				font-family: $font1;
			}
		}

	}

	@media screen and (min-width:1px) and (max-width:768px) {
		& {
			select {}
		}
	}
}

@mixin selectBox($mWidth) {
	& {
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		background-color: #fff;

		&:after {
			position: absolute;
			top: 50%;
			right: 0;
			margin-right: 10px;
			transform: translateY(-50%);
			content: ' ';
			@include sprite($select-bul);
		}

		.lte-ie9 &:after {
			display: none
		}

		text-align:left;
		border:1px solid #ddd;

		select {
			width: 100%;
			background-color: transparent;
			appearance: none;
			min-width: $mWidth+30;
			vertical-align: middle;
			color: #000;
			font-size: 16px;
			height: 58px;
			margin-top: 0;
			padding: 2px 2px 2px 10px;
			position: relative;
			border: 0px;
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			-o-appearance: none;

			&::-ms-expand {
				display: none;
			}

			/*ie10~11 에서 셀렉트 기본디자인 무력화*/

			.lte-ie9 & {
				width: 100%;
				vertical-align: top;
				padding-right: 0px;

			}

			option {
				font-family: $font1;
			}
		}

	}

	@media screen and (min-width:1px) and (max-width:768px) {
		& {
			select {

				min-width: $mWidth;
			}
		}
	}
}

@mixin selectBox2($mWidth) {
	& {
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		background-color: transparent;

		&:after {
			position: absolute;
			top: 50%;
			right: 0;
			margin-right: 10px;
			transform: translateY(-50%);
			content: ' ';
			@include sprite($select-bul);
		}

		.lte-ie9 &:after {
			display: none
		}

		text-align:left;
		border-bottom:2px solid #000;

		select {
			width: 100%;
			background-color: transparent;
			appearance: none;
			min-width: $mWidth+30;
			vertical-align: middle;
			color: #000;
			font-size: 14px;
			height: 38px;
			margin-top: 0;
			padding: 2px 2px 2px 10px;
			position: relative;
			border: 0px;
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			-o-appearance: none;

			&::-ms-expand {
				display: none;
			}

			/*ie10~11 에서 셀렉트 기본디자인 무력화*/

			.lte-ie9 & {
				width: 100%;
				vertical-align: top;
				padding-right: 0px;
			}

			option {
				font-family: $font1;
			}
		}

	}

	@media screen and (min-width:1px) and (max-width:768px) {
		& {
			select {

				min-width: $mWidth;
			}
		}
	}
}

// footer쪽 select디자인
@mixin selectBox3($mWidth) {
	& {
		overflow: hidden;
		display: inline-block;
		vertical-align: top;
		position: relative;
		background-color: transparent;

		&:after {
			position: absolute;
			top: 50%;
			right: 0;
			margin-right: 10px;
			transform: translateY(-50%);
			content: ' ';
			@include sprite($select_arrow3);
		}

		text-align:left;
		border:0px solid #717171;

		select {
			width: 100%;
			background-color: transparent;
			appearance: none;
			min-width: $mWidth+30;
			vertical-align: top;
			color: #ddd;
			font-size: 14px;
			height: 38px;
			margin-top: 0;
			padding: 2px 2px 2px 10px;
			position: relative;
			border: 0px;
			font-family: $font2;

			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			-o-appearance: none;

			&::-ms-expand {
				display: none;
			}

			/*ie10~11 에서 셀렉트 기본디자인 무력화*/

			.lte-ie9 & {
				width: 125%;
				vertical-align: top;
			}

			option {
				color: #000;
				font-family: $font1;
			}

			&:focus {
				border: 1px solid gold;
				border-radius: 4px;
			}
		}

	}

	@media screen and (min-width:1px) and (max-width:768px) {
		& {
			select {

				min-width: $mWidth;
			}
		}
	}
}

@mixin selectBox4($mWidth) {
	& {
		overflow: hidden;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		background-color: #fff;

		&:after {
			position: absolute;
			top: 50%;
			right: 0;
			margin-right: 10px;
			transform: translateY(-50%);
			content: ' ';
			@include sprite($select-bul);
		}

		.lte-ie9 &:after {
			display: none
		}

		text-align:left;
		border:1px solid #ddd;

		select {
			width: 100%;
			background-color: transparent;
			appearance: none;
			min-width: $mWidth+30;
			vertical-align: middle;
			color: #000;
			font-size: 16px;
			height: 34px;
			margin-top: 0;
			padding: 2px 2px 2px 10px;
			position: relative;
			border: 0px;
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			-o-appearance: none;

			&::-ms-expand {
				display: none;
			}

			/*ie10~11 에서 셀렉트 기본디자인 무력화*/

			.lte-ie9 & {
				width: 100%;
				vertical-align: top;
				padding-right: 0px;

			}

			option {
				font-family: $font1;
			}
		}

	}

	@media screen and (min-width:1px) and (max-width:768px) {
		& {
			select {

				min-width: $mWidth;
			}
		}
	}
}

@mixin dl {
	& {

		@include floatWrap;

		dt {
			display: block;
			min-width: 80px;
			font-weight: bold;
			float: left;
			margin-bottom: 10px;
		}

		dd {
			display: block;
			margin-bottom: 10px;

		}
	}
}


@mixin tabType1 {
	& {
		display: flex;
		margin-bottom: 40px;
		flex-wrap: wrap;

		&>li {
			flex: 1;padding-left:7px;
			text-align: center;

			>a {
				display: grid;
				align-items: center;
				height: 80px;
				white-space: nowrap;
				border: 1px solid #dcdcdc;
				margin-left: -1px;
				margin-top: -1px;
				font-size: 16px;
				font-weight: bold;
				color: #666;
			}

			&:first-child>a {
				border-left: 1px solid #dcdcdc;
			}

			&.on>a {
				background-color: #0d9a9e;
				color: #fff;
				border: 1px solid #0d9a9e;
				border-left: 0;
			}

			&>a:hover,
			&>a:focus {
				background-color: #0d9a9e;
				color: #fff;
				border: 1px solid #0d9a9e;
				border-left: 0;
			}
		}
	}

	@media screen and (min-width:1px) and (max-width:768px) {
		& {
			display: block;
			@include floatWrap;
			margin-left: -10px;

			&>li {
				float: left;
				width: 50%;    padding-left: 0;

				>a {
					margin-bottom: 10px;
					margin-left: 10px;
					border-left: 1px solid #dcdcdc;
				}
			}
		}
	}

}

@mixin basicTable1 {

	//가로형
	& {
		margin-bottom: 30px;
		border-top: 2px solid #111111;
		border-bottom: 0px solid #000;

		table {
			border-collapse: collapse;
			width: 100%;
			min-width: 540px;
			th:first-child,
			td:first-child {
				border-left: 0;
			}
			th:last-child,
			td:last-child {
				border-right: 0;
			}
		}
		thead tr:first-child th {border-top:0;}
		thead th {
			border-bottom: 0px;
			text-align: center;
		}

		th {
			border: 1px solid #dddddd;
			padding: 12px 0;
			color: #111111; text-align: center;
			background-color: #f8f8f8
		}

		td {
			border: 1px solid #dddddd;color:#444444; font-size:16px;
			padding: 12px 5px; font-size:16px;
			text-align: center;
			vertical-align: middle;
		}

		&.left-td td {
			padding-left: 10px;
			padding-right: 10px;
			text-align: left;
		}

		td.left-td {
			padding-left: 10px;
			padding-right: 10px;
			text-align: left;
		}

	}
	@media (max-width:768px) {
		overflow: auto;

		&.res {
			padding-top: 10px;
			table th:first-child, table td:first-child {border-left:1px solid #ddd !important; }
			table th:last-child, table td:last-child { border-right:1px solid #ddd !important;}
			table {
				min-width: auto;
				
			}

			colgroup {
				display: none;
			}

			thead {
				display: none;
			}

			tr {
				display: block;
				margin-bottom: 20px;
				border-top: 1px solid #dcdcdc;
			}

			th {
				display: none;
			}

			td {
				display: flex;
				margin-bottom: 0px;
				text-align: left;
				padding-left: 10px;
				border-top: 0px;
				border-right: 1px solid #dcdcdc;
			}

			td:before {
				color: #000;
				font-weight: bold;
				content: attr(data-th) " - "
			}
		}
	}

}

@mixin basicTable2 {

	//세로형
	& {
		margin-bottom: 30px;
		border-top: 2px solid #3e997a;
		border-bottom: 0px solid #000;

		table {
			border-collapse: collapse;
			width: 100%;
			min-width: 540px;
		}

		th {
			border: 1px solid #dcdcdc;
			color: #227b5d;
			border-left: 0;
			padding: 13px 0;
			background-color: #edf9f5;
			text-align: left;
			padding-left: 30px;
		}

		td {
			border: 1px solid #dcdcdc;
			border-right: 0;
			padding: 13px 5px;
			text-align: left;
			padding-left: 30px;
		}

		@media print {}


	}

	@media (max-width:768px) {
		overflow: auto;

		&.res {
			padding-top: 10px;

			table {
				min-width: auto;
			}

			colgroup {
				display: none;
			}

			thead {
				display: none;
			}

			tr {
				display: block;
				margin-bottom: 20px;
				border-top: 1px solid #dcdcdc;
			}

			th {
				display: none;
			}

			td {
				display: block;
				margin-bottom: 0px;
				text-align: left;
				padding-left: 10px;
				border-top: 0px;
				border-right: 1px solid #dcdcdc;
			}

			td:before {
				color: #000;
				font-weight: bold;
				content: attr(data-th) " - "
			}
		}
	}
}

@mixin basicTable3 {

	//세로형
	& {
		text-align: center;
		margin-bottom: 30px;
		border-bottom: 1px solid #000;

		table {
			border-collapse: collapse;
			width: 100%;
			min-width: 540px;
		}

		thead th {
			background-color: #333;
			color: #fff;
			border: 1px solid #333;
		}

		th {
			border: 1px solid #dcdcdc;
			border-left: 0;
			padding: 18px 0;
			background-color: #f4f4f4;
			padding-left: 30px;
		}

		td {
			border: 1px solid #dcdcdc;
			border-right: 0;
			padding: 18px 5px;
			padding-left: 30px;
		}


	}

	@media (max-width:768px) {
		overflow: auto;

		&.res {
			padding-top: 10px;
			border-top: 0px;
			border-bottom: 0px;

			table {
				min-width: auto;
			}

			colgroup {
				display: none;
			}

			thead {
				display: none;
			}

			tr {
				display: block;
				margin-bottom: 20px;
				border-top: 1px solid #dcdcdc;
			}

			th {
				display: none;
			}

			td {
				display: block;
				margin-bottom: 0px;
				text-align: left;
				padding-left: 10px;
				border-top: 0px;
				border-right: 1px solid #dcdcdc;
			}

			td:before {
				color: #000;
				font-weight: bold;
				content: attr(data-th) " - "
			}
		}
	}

}

@mixin basicTable4 {

	//가로형 좌우 보더 삭제
	& {
		margin-bottom: 30px;
		border-top: 2px solid #203c92;
		border-bottom: 1px solid #000;

		table {
			border-collapse: collapse;
			width: 100%;
			min-width: 540px;
			margin-left: -1px;
		}

		thead th {
			border-bottom: 1px solid #203c92;
		}

		th {
			border-left: 1px solid #dcdcdc;
			padding: 13px 0;
			background-color: #fbfcff;
			text-align: center;
			color: #000;
			//&:first-child {border-left:0px;}
		}

		tbody th {
			border: 1px solid #dcdcdc;
			padding: 13px 5px;
			text-align: center;
			border-right: 0px;
			border-bottom: 0px;
			background-color: #fbfbfb;
			//&:first-child {border-left:0px;}
		}

		td {
			border: 1px solid #dcdcdc;
			padding: 13px 5px;
			text-align: center;
			border-right: 0px;
			border-bottom: 0px;
			//&:first-child {border-left:0px;}

			&.sbj-line {
				padding-left: 15px;
				text-align: left;

				>a {

					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}
			}

			&>a {

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}

		td.align-left {
			text-align: left;
			padding-left: 15px;
		}

	}

	@media (max-width:768px) {
		overflow: auto;

		&.res {
			padding-top: 0px;
			border-top: 0px;
			border-bottom: 0px;

			table {
				min-width: auto;
				margin-left: 0px;
			}

			colgroup {
				display: none;
			}

			thead {
				display: none;
			}

			tr {
				display: block;
				margin-bottom: 20px;
				border-bottom: 1px solid #dcdcdc;
			}

			th {
				display: none;
			}

			td {
				display: block;
				margin-bottom: 0px;
				text-align: left;
				padding-left: 10px;
				border-right: 1px solid #dcdcdc;
				border-top: 1px solid #dcdcdc;
				border-bottom: 0px;

				&:first-child {
					border-left: 1px solid #dcdcdc;
				}
			}

			td:before {
				color: #000;
				font-weight: bold;
				content: attr(data-th) " : "
			}

			td.align-left {
				padding-left: 10px;
			}
		}
	}

}



@mixin basicTable5 {

	//세로형
	& {
		text-align: center;
		margin-bottom: 30px;
		max-width: 100%;

		table {
			border-collapse: collapse;
			width: 100%;
			min-width: 540px;
		}

		thead th {
			background-color: #333;
			color: #fff;
			border: 1px solid #333;
		}

		th {
			border: 1px solid #3774a9;
			padding: 8px 0;
			background-color: #0e5390;
			padding-left: 3px;
			padding-right: 3px;
			text-align: center;
			color: #fff;
		}

		td {
			border: 1px solid #dcdcdc;
			padding: 8px 2px;
			text-align: center;
		}


	}

	@media (max-width:768px) {
		overflow: auto;

		&.res {
			padding-top: 10px;
			border-top: 0px;
			border-bottom: 0px;

			table {
				min-width: auto;
			}

			colgroup {
				display: none;
			}

			thead {
				display: none;
			}

			tr {
				display: block;
				margin-bottom: 20px;
				border-top: 1px solid #dcdcdc;
			}

			th {
				display: none;
			}

			td {
				display: block;
				margin-bottom: 0px;
				text-align: left;
				padding-left: 10px;
				border-top: 0px;
				border-right: 1px solid #dcdcdc;
			}

			td:before {
				color: #000;
				font-weight: bold;
				content: attr(data-th) " - "
			}
		}
	}

}


//컨텐츠 소제목

@mixin h3Type1 {
	& {
		color: #111111;
		font-weight: bold;
		font-size: 24px;
		margin-bottom: 15px;
		padding-top: 0px;
		position: relative;
		@media (max-width:768px) {
			font-size: 20px;
		}
	}
}

@mixin h3Type2 {
	& {
		color: #000;
		font-weight: bold;
		font-size: 26px;
		margin-bottom: 25px;

		@media (max-width:768px) {
			font-size: 20px;
		}
	}
}

@mixin h3Type3 {
	& {
		border-left: 5px solid #3a8dcb;
		font-weight: bold;
		font-size: 28px;
		height: 23px;
		line-height: 19px;
		padding-left: 10px;
		margin-bottom: 25px;

		@media (max-width:768px) {
			font-size: 22px;
		}
	}
}

@mixin h4Type1 {
	& {
		position:relative;
		padding-left:30px;
		color: #333;
		font-weight: bold;
		font-size: 16px;
		line-height:1.8;
		margin-bottom: 15px;

		@media (max-width:768px) {
			font-size: 15px;
		}

		&:before {
			content: ' ';
			position: absolute;
			left: 0;
			top:4px
		}
		&__1 {
			&:before {
				@include sprite($h4-type1)
			}
		}
		&__2 {
			&:before {
				@include sprite($h4-type2)
			}
		}
		&__3 {
			&:before {
				@include sprite($h4-type3)
			}
		}
		&__4 {
			&:before {
				@include sprite($h4-type4)
			}
		}
		&__5 {
			&:before {
				@include sprite($h4-type5)
			}
		}
		&__6 {
			&:before {
				@include sprite($h4-type6)
			}
		}
		&__7 {
			&:before {
				@include sprite($h4-type7)
			}
		}
		&__8 {
			&:before {
				@include sprite($h4-type8)
			}
		}
		&__9 {
			&:before {
				@include sprite($h4-type9)
			}
		}
		&__10 {
			&:before {
				@include sprite($h4-type10)
			}
		}
	}

}

@mixin h4Type2 {
	& {
		color: #000;
		font-weight: bold;
		font-size: 18px;
		margin-bottom: 10px;

		@media (max-width:768px) {
			font-size: 14px;
		}

		position:relative;
		padding-left:10px;

		&:before {
			content: ' ';
			position: absolute;
			top: 12px;
			left: 0;
			@include sprite($h4_bul)
		}
	}

}

@mixin h4Type3 {
	& {
		color: #0e5390;
		font-weight: bold;
		font-size: 16px;
		margin-bottom: 5px;

		@media (max-width:768px) {
			font-size: 15px;
		}
	}

}


//캘린더
@mixin typeCal {
	& {
		width: 100%;
		border-collapse: collapse;
		box-sizing: border-box;
		text-align: center;

		th {
			color: #bbb;

			&.sat {
				color: $red
			}

			height:60px;
			vertical-align: middle;
		}

		td {
			font-weight: bold;
			font-size: 20px;
			height: 60px;
			vertical-align: middle;
			padding: 10px 0;
			color: #bbb;

			&.sun {
				color: #bbb;
			}

			a {
				display: block;
				height: 60px;
				line-height: 60px;
				margin: 0 auto;
				box-sizing: border-box;
				border: 1px solid #fff;
				margin-left: 1px;

				&.today.posb {
					color: #000;
					background-color: #fff;
					border: 1px solid #ec6159;
				}

				&.posb {
					border: 1px solid #fbe2de;
					background-color: #fbe2de;
					color: #000;
				}

				&.selected {
					background-color: gold !important;
				}

				&.on {
					color: #000;
					background-color: #fff;
					border: 1px solid #ec6159;
				}

				.ver-show &.on.posb {
					background-color: #f0afb5;
					border: 1px solid #f0afb5;
				}

				&:hover,
				&:focus {
					color: #fff;
					background-color: #f0afb5;
					border: 1px solid #f0afb5;
				}
			}
		}
	}
}

/*게시판*/

@mixin bbsList {
	& {
		margin-bottom: 30px;
		border-top: 1px solid #000;

		table {
			border-collapse: collapse;
			width: 100%;
			background-color: #fff;
		}

		thead th {
			border-bottom: 0px;
		}

		th {
			border: 1px solid #3172ac;
			padding: 13px 0;
			background-color: #0e5390;
			color: #fff;
			font-size: 15px;
		}

		td {
			border: 1px solid #dcdcdc;
			padding: 13px 5px;
			text-align: center;
			vertical-align: middle;
			font-size: 15px;

			&.sbj-line {
				text-align: left;
				padding-left: 15px;
			}
		}

		.paging {
			text-align: center;
			padding-top: 20px;
			margin-bottom: 20px;

			a {
				display: inline-block;
				min-width: 25px;
				height: 15px;
				line-height: 10px;

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}

			strong {
				text-decoration: underline;
			}

			&__prev {
				margin-right: 10px;
			}

			&__next {
				margin-left: 10px;
			}
		}
	}

	@media (max-width:768px) {
		& {
			padding-top: 10px;

			colgroup {
				display: none;
			}

			thead {
				display: none;
			}

			tr {
				display: block;
				margin-bottom: 20px;
				border-top: 1px solid #dcdcdc;
			}

			th {
				display: none;
			}

			td {
				display: block;
				margin-bottom: 0px;
				text-align: left;
				padding-top: 5px;
				padding-bottom: 5px;
				padding-left: 10px;
				border-top: 0px;
				border-right: 1px solid #dcdcdc;

				&.num {
					display: none;
				}

				&.sbj-line {
					text-align: left;
					padding-left: 10px;
				}
			}

			td:before {
				color: #000;
				font-weight: bold;
				content: attr(data-th) " - "
			}

			td.sbj-line:before {
				display: none;
			}
		}
	}

}

@mixin bbsView {
	& {
		margin-bottom: 30px;
		border-top: 1px solid #000;

		table {
			border-collapse: collapse;
			width: 100%;
			background-color: #fff;
		}

		th {
			border: 1px solid #ddd;
			padding: 13px 0;
			background-color: #f3f6f7;
			color: #000;
			font-size: 15px;
		}

		td {
			border: 1px solid #dcdcdc;
			padding: 13px 5px;
			text-align: center;
			vertical-align: middle;
			font-size: 15px;

			&.sbj-line {
				text-align: left;
				padding-left: 15px;
				font-size: 18px;
				font-weight: bold;
			}
		}

		.bbs-cont {
			background-color: #fff;
			border: 1px solid #ddd;
			border-top: 0px;
			padding: 20px;
			min-height: 150px;
			;
		}
	}

	@media (max-width:768px) {
		& {
			padding-top: 10px;

			table {
				border-top: 1px solid #dcdcdc;
			}

			colgroup {
				display: none;
			}

			thead {
				display: none;
			}

			tr {
				display: block;
			}

			th {
				display: none;
			}

			td {
				display: block;
				margin-bottom: 0px;
				text-align: left;
				padding-top: 5px;
				padding-bottom: 5px;
				padding-left: 10px;
				border-top: 0px;
				border-right: 1px solid #dcdcdc;

				&.num {
					display: none;
				}

				&.sbj-line {
					text-align: left;
					padding-left: 10px;
				}
			}

			td:before {
				color: #000;
				font-weight: bold;
				content: attr(data-th) " - "
			}

			td.sbj-line:before {
				display: none;
			}
		}
	}

}

// 슬라이드 네비
@mixin slickDots {
	& {
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 0px;
		box-sizing: border-box;
		z-index: 3;
		width: 100%;
		display: block;

		&.off {
			display: none;
		}

		&>div {
			background-color:rgba(0,0,0,0.8);
			display: inline-block;
			text-align: center;
			margin-top: 0;
			z-index: 999;
			//background-image:url("../../resources/images/basic/opa.png");

			margin-top: -44px;

			&>div {padding-left:15px;
				display: inline-block;

				*+html & {
					display: inline;
				}
			}

			&>ul {
				display: inline-block;
				font-size: 0;
				vertical-align: middle;

				*+html & {
					display: inline;
				}

				li {
					display: inline-block;
					vertical-align: middle;

					*+html & {
						display: inline;
					}

					a {
						color: gold;
						width: 50px;
						height: 50px;
					}

					.slide-pause-item {border-right:1px solid #fff;
						background:url(../../resources/images/icons/popup-pause.png) center center no-repeat;
						display: block;
						font-size: 0;
						text-indent: -9999px;
						position: relative;
						margin-top: 0px;
						margin-left:1px;
						vertical-align: top;

						&:focus {
							border:1px solid #fff;
							border-radius: 2px;

						}
					}

					.slide-play-item {
						display: block;
						font-size: 0;
						text-indent: -9999px;
						&:focus {
							border:1px solid #fff;
							border-radius: 2px;
						}
					}

					.slide-more-item {
						// 더보기버튼
						display: block;
						font-size: 0;
						text-indent: -9999px;
						margin-top: 0px;
						vertical-align: top;

						&:focus {
							background-color: gold;
							border-radius: 100%;
						}
					}

					.slide-next-item {
						background:url(../../resources/images/icons/popup-next.png) center center no-repeat ;
						display: block;
						font-size: 0;border-right:1px solid #fff;
						margin-left:1px;
						text-indent: -9999px;
						position: relative;
						margin-top: 0px;
						vertical-align: top;

						&:focus {
							border:1px solid #fff;
							border-radius: 2px;
						}
					}

					.slide-prev-item {border-right:1px solid #fff;
						background:url(../../resources/images/icons/popup-prev.png) center center no-repeat ;
						display: block;
						font-size: 0;
						margin-left:1px;
						text-indent: -9999px;
						position: relative;
						margin-top: 0px;
						vertical-align: top;

						&:focus {
							border:1px solid #fff;
							border-radius: 2px;
						}
					}
				}
			}
		}

		.slick-dots-list {
			display: inline-block !important;

			*+html & {
				display: inline !important;
			}

			vertical-align: middle;

			li {
				display: inline-block;
				margin-right: 12px;

				button {
					display: inline-block;
					color: #e19026;
					vertical-align: middle;
					background-color: #d3d3d3;
					overflow: visible;
					width: 17px;
					height: 17px;
					line-height: 15px;
					border-radius: 15px;
					font-size: 0;
				}

			}

			li.slick-active button {
				background-color: transparent;
				border: 2px solid #fff;
				width: 17px;
				height: 17px;
			}

			li button:focus {
				background-color: #e19026;
			}


		}

		*+html .slick-dots-list li {
			display: inline;
		}

		*+html .slick-dots-list li button {
			display: inline;
		}

		//.slick-dots-on-off img {vertical-align:middle;}

	}

	//total ver
	.popup-total {
		display: none;
	}

	.ver-total & {
		.slick-dots-list {
			font-size: 0;
			margin-top: 3px;

			li {
				margin-right: 0;
			}

			& button {
				font-size: 15px;
				text-indent: 0;
				background-image: none;
				display: none;
			}

			& .slick-active button {
				background-image: none;
				display: block;
				width: auto;
				height: 30px;
				text-align: center;
				color: #fff;
				background-color: transparent;
				font-weight: bold;
				border-color: transparent;
				margin-bottom: 1px;

				&:focus {
					border: 2px solid #000;
				}
			}
		}

		.popup-total {
			color: #fff;
			display: inline-block;
			vertical-align: middle;
			font-size: 15px;
			margin-top: 1px;
		}

		*+html .popup-total {
			display: inline;
		}

		.popup-total-num {
			padding-left: 4px;
			padding-right:18px;
		}

	}

	//arrow ver
	.nav-arrow {
		display: none !important;
	}

	.ver-arrow & {
		.nav-arrow {
			display: block !important;
		}

		.slide-play-item {
			display: none;
		}

		.popupzone-nav__prev {}

		.popupzone-nav__next {}
	}


	*+html &.ver-total .popup-total {
		display: none !important;
	}

	*+html &.ver-total .slick-dots-list {
		display: none !important;
	}
}
@mixin slickDots2 {
	& {
		position: absolute;
		top:260px; 
		left:0;
		box-sizing: border-box;
		z-index: 3;
		width: 100%;
		display: block;

		&.off {
			display: none;
		}

		&>div {
			display: inline-block;
			text-align: center;
			margin-top: 0;
			z-index: 999;
			//background-image:url("../../resources/images/basic/opa.png");

			&>div {
				display: none;

				*+html & {
					display: inline;
				}
			}

			&>ul {
				display: inline-block;
				font-size: 0;
				vertical-align: middle;

				*+html & {
					display: inline;
				}

				li {
					display: inline-block;
					vertical-align: middle;

					*+html & {
						display: inline;
					}

					a {
						width:54px; height:54px; border:1px solid #fff;
					}

					.slide-pause-item {
						display: block;
						font-size: 0;
						text-indent: -9999px;
						position: relative;
						margin-top: 0px;
						vertical-align: top;

						&:focus {
							background-color: gold;
						}
					}

					.slide-play-item {
						display: block;
						font-size: 0;
						text-indent: -9999px;
						background:url(../../resources/images/icons/popup-play.png) center center no-repeat ;
						margin-top: 0px;
						vertical-align: top;

						&:focus {
							background-color: gold;
						}
					}

					.slide-more-item {
						// 더보기버튼
						display: block;
						font-size: 0;
						text-indent: -9999px;
						margin-top: 0px;
						background:url(../../resources/images/icons/popup-more.png) center center no-repeat ;
						vertical-align: top;

						&:focus {
							background-color: gold;
						}
					}

					.slide-next-item {
						display: block;
						font-size: 0;
						margin-right:5px;
						text-indent: -9999px;
						position: relative;
						background:url(../../resources/images/icons/popup-next.png) center center no-repeat ;
						margin-top: 0px;
						vertical-align: top;

						&:focus {
							background-color: gold;
						}
					}

					.slide-prev-item {
						display: block;
						font-size: 0;
						margin-right:5px;
						text-indent: -9999px;
						background:url(../../resources/images/icons/popup-prev.png) center center no-repeat ;
						position: relative;
						margin-top: 0px;
						vertical-align: top;

						&:focus {
							background-color: gold;
						}
					}
				}
			}
		}

		.slick-dots-list {
			display: inline-block !important;

			*+html & {
				display: inline !important;
			}

			vertical-align: middle;

			li {
				display: inline-block;
				margin-right: 12px;

				button {
					display: inline-block;
					color: #e19026;
					vertical-align: middle;
					background-color: #d3d3d3;
					overflow: visible;
					width: 17px;
					height: 17px;
					line-height: 15px;
					border-radius: 15px;
					font-size: 0;
				}

			}

			li.slick-active button {
				background-color: transparent;
				border: 2px solid #fff;
				width: 17px;
				height: 17px;
			}

			li button:focus {
				background-color: #e19026;
			}


		}

		*+html .slick-dots-list li {
			display: inline;
		}

		*+html .slick-dots-list li button {
			display: inline;
		}

		//.slick-dots-on-off img {vertical-align:middle;}

	}

	//total ver
	.popup-total {
		display: none;
	}

	.ver-total & {
		.slick-dots-list {
			font-size: 0;
			margin-top: 3px;

			li {
				margin-right: 0;
			}

			& button {
				font-size: 15px;
				text-indent: 0;
				background-image: none;
				display: none;
			}

			& .slick-active button {
				background-image: none;
				display: block;
				width: auto;
				height: 30px;
				text-align: center;
				color: #fff;
				background-color: transparent;
				font-weight: bold;
				border-color: transparent;
				margin-bottom: 1px;

				&:focus {
					border: 2px solid #000;
				}
			}
		}

		.popup-total {
			color: #fff;
			display: inline-block;
			vertical-align: middle;
			font-size: 15px;
			margin-top: 1px;
		}

		*+html .popup-total {
			display: inline;
		}

		.popup-total-num {
			padding-left: 4px;
		}

	}

	//arrow ver
	.nav-arrow {
		display: none !important;
	}

	.ver-arrow & {
		.nav-arrow {
			display: block !important;
		}

		.slide-play-item {
			display: none;
		}

		.popupzone-nav__prev {}

		.popupzone-nav__next {}
	}


	*+html &.ver-total .popup-total {
		display: none !important;
	}

	*+html &.ver-total .slick-dots-list {
		display: none !important;
	}
}

/* 박스 타입 */

@mixin boxType1 {
	& {
		padding: 8px;
		background-image: url('../../resources/images/cont/box-dotted.png');
		margin-bottom: 30px;
		>div {
			background-color: #fff;
			padding: 20px;

			>h2 {
				font-size: 18px;
				font-weight: 700;
				color: #333;
				margin-bottom: 10px;
			}

			>p {
				font-size: 15px;
				color: #666;
			}
		}
	}
}

// font-size

@function size($pixels) {
	@return $pixels /10+rem;
}

// font-size px,rem

@mixin fontSize($pixels) {
	font-size: $pixels + px;
	font-size: $pixels /10 + rem;
}


// //고정비율 박스
// @mixin fixSize {


// 	& {
// 		position: relative;
// 		width: 100%;
// 		height: 0;
// 		overflow: hidden;
// 		padding-bottom: 56.25%;
// 		/*16:9*/
// 		//padding-bottom: 59.2%; /*16:9*/
// 		//background-color:#00235d;
// 		color: #fff;

// 		&__in {
// 			position: absolute;
// 			top: 0;
// 			left: 0;
// 			width: 100%;
// 			height: 100%;

// 		}

// 		video {
// 			width: 100%;
// 			height: 100%
// 		}

// 		iframe {
// 			width: 100%;
// 			height: 100%
// 		}

// 		img {
// 			width: 100%;
// 			height: 100% !important;
// 			/* 비율 안깨지고 상하 가운데 정렬시
// 		 object-fit: cover;
// 		 position: absolute;
// 		 top: 50%;
// 		 left: 0;
// 		 transform: translateY(-50%);
// 		*/
// 		}

// 	}
// }