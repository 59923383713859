@charset "UTF-8";
/*게시판*/
/* 박스 타입 */
.icon-a-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -623px -916px;
  background-image: url("../images/sprite.png");
  width: 33px;
  min-width: 33px;
  height: 26px;
}

.icon-accordian-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1013px -1092px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 2px;
}

.icon-accordian-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -706px -916px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 26px;
}

.icon-all-menu-btn-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -340px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-all-menu-btn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -492px -406px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 20px;
}

.icon-all-menu-btn2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -544px -193px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 26px;
}

.icon-all-menu-btnn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -340px -1092px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 19px;
}

.icon-all_menu_close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -420px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-arrow_icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -140px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 18px;
}

.icon-bbs-excel-img {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -805px -1006px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 22px;
}

.icon-bbs-first {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -372px -1242px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 13px;
}

.icon-bbs-last {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -436px -1242px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 13px;
}

.icon-bbs-map-img {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -732px -1006px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 23px;
}

.icon-bbs-more {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px 0px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-bbs-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -1070px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 13px;
}

.icon-bbs-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -1133px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 13px;
}

.icon-bbs-search-img {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -70px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-book {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -332px -406px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 21px;
}

.icon-btn-type4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -688px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 11px;
}

.icon-calender-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -88px;
  background-image: url("../images/sprite.png");
  width: 32px;
  min-width: 32px;
  height: 32px;
}

.icon-calender-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -170px;
  background-image: url("../images/sprite.png");
  width: 32px;
  min-width: 32px;
  height: 32px;
}

.icon-camera {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -412px -406px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 20px;
}

.icon-card-left {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -292px -200px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 34px;
}

.icon-card-right {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -514px -1006px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 34px;
}

.icon-check-type-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -1071px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 13px;
}

.icon-check-type {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -1134px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 13px;
}

.icon-check-type2-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -507px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-check-type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -569px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-check-type3-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -817px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 11px;
}

.icon-close-btn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -140px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-confirm {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px -123px;
  background-image: url("../images/sprite.png");
  width: 49px;
  min-width: 49px;
  height: 71px;
}

.icon-copy_li_bg {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -1370px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 13px;
}

.icon-copy_li_bg2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -703px -576px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 10px;
}

.icon-depth4-img {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -933px -916px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.icon-djh-item1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -176px 0px;
  background-image: url("../images/sprite.png");
  width: 140px;
  min-width: 140px;
  height: 128px;
}

.icon-djh-item2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-image: url("../images/sprite.png");
  width: 126px;
  min-width: 126px;
  height: 150px;
}

.icon-djh-item3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -366px -115px;
  background-image: url("../images/sprite.png");
  width: 128px;
  min-width: 128px;
  height: 112px;
}

.icon-down-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -208px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 18px;
}

.icon-down-icon1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -944px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 13px;
}

.icon-down-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -1007px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 13px;
}

.icon-down-icon3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -1007px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 14px;
}

.icon-down {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -744px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.icon-eng {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px 0px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-eng2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -67px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-fav-1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -810px;
  background-image: url("../images/sprite.png");
  width: 54px;
  min-width: 54px;
  height: 56px;
}

.icon-fav-2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -263px -576px;
  background-image: url("../images/sprite.png");
  width: 72px;
  min-width: 72px;
  height: 56px;
}

.icon-fav-3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -322px -698px;
  background-image: url("../images/sprite.png");
  width: 55px;
  min-width: 55px;
  height: 54px;
}

.icon-fav-4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -739px -698px;
  background-image: url("../images/sprite.png");
  width: 58px;
  min-width: 58px;
  height: 47px;
}

.icon-fav-5 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -355px -478px;
  background-image: url("../images/sprite.png");
  width: 58px;
  min-width: 58px;
  height: 45px;
}

.icon-fav-6 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -463px -478px;
  background-image: url("../images/sprite.png");
  width: 64px;
  min-width: 64px;
  height: 36px;
}

.icon-foot-icon1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -376px;
  background-image: url("../images/sprite.png");
  width: 42px;
  min-width: 42px;
  height: 42px;
}

.icon-foot-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -468px;
  background-image: url("../images/sprite.png");
  width: 42px;
  min-width: 42px;
  height: 42px;
}

.icon-foot-icon3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -560px;
  background-image: url("../images/sprite.png");
  width: 42px;
  min-width: 42px;
  height: 42px;
}

.icon-foot-sns-b {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -758px -344px;
  background-image: url("../images/sprite.png");
  width: 62px;
  min-width: 62px;
  height: 62px;
}

.icon-foot-sns-f {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -758px -456px;
  background-image: url("../images/sprite.png");
  width: 62px;
  min-width: 62px;
  height: 62px;
}

.icon-foot-sns-insta {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -758px -568px;
  background-image: url("../images/sprite.png");
  width: 62px;
  min-width: 62px;
  height: 62px;
}

.icon-foot-sns-y {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -698px;
  background-image: url("../images/sprite.png");
  width: 62px;
  min-width: 62px;
  height: 62px;
}

.icon-foot_img {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -200px;
  background-image: url("../images/sprite.png");
  width: 242px;
  min-width: 242px;
  height: 62px;
}

.icon-footer-select-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -862px -1092px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 8px;
}

.icon-footlink-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1026px -1172px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 10px;
}

.icon-footlink {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1093px -1172px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 10px;
}

.icon-g-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -210px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-go-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -942px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 15px;
}

.icon-go-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -1196px;
  background-image: url("../images/sprite.png");
  width: 10px;
  min-width: 10px;
  height: 10px;
}

.icon-h3-type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -622px -511px;
  background-image: url("../images/sprite.png");
  width: 50px;
  min-width: 50px;
  height: 4px;
}

.icon-h4-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -340px -1310px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.icon-h4-type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -280px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-h4-type10 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -350px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-h4-type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -420px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-h4-type3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -490px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-h4-type4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -560px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-h4-type5 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -630px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-h4-type6 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -700px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-h4-type7 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -770px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-h4-type8 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -840px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-h4-type9 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -910px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-header-eng {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px 0px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.icon-header-kor {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -88px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.icon-header-logout {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -879px -1006px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 22px;
}

.icon-header-mypage {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -176px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.icon-header-search-in-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -383px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 12px;
}

.icon-header-search-in {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -652px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.icon-header-search {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -264px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.icon-heart-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -367px -916px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 30px;
}

.icon-heart {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -453px -916px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 30px;
}

.icon-home {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -445px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 12px;
}

.icon-home02 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px 0px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 15px;
}

.icon-ico-roll-left {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -744px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -822px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-plus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -900px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-right {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -978px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-ico-roll-start {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -782px -916px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 25px;
}

.icon-icon-print {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -742px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.icon-icon-url {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -916px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.icon-icon1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -584px -1006px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.icon-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -658px -1006px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.icon-info-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -536px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 21px;
}

.icon-item-dur {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -112px -698px;
  background-image: url("../images/sprite.png");
  width: 55px;
  min-width: 55px;
  height: 55px;
}

.icon-item-name {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -217px -698px;
  background-image: url("../images/sprite.png");
  width: 55px;
  min-width: 55px;
  height: 55px;
}

.icon-join-icon-1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px 0px;
  background-image: url("../images/sprite.png");
  width: 51px;
  min-width: 51px;
  height: 73px;
}

.icon-join-icon-2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -576px;
  background-image: url("../images/sprite.png");
  width: 88px;
  min-width: 88px;
  height: 72px;
}

.icon-join-step-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -1120px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 1px;
}

.icon-join-step-now {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -65px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-latest-news-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -90px -916px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 36px;
}

.icon-leaf {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -352px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 38px;
}

.icon-links1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -312px;
  background-image: url("../images/sprite.png");
  width: 116px;
  min-width: 116px;
  height: 116px;
}

.icon-links1n {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -622px 0px;
  background-image: url("../images/sprite.png");
  width: 86px;
  min-width: 86px;
  height: 86px;
}

.icon-links2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -166px -312px;
  background-image: url("../images/sprite.png");
  width: 116px;
  min-width: 116px;
  height: 116px;
}

.icon-links2n {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -622px -136px;
  background-image: url("../images/sprite.png");
  width: 86px;
  min-width: 86px;
  height: 85px;
}

.icon-list-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -678px -1242px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 3px;
}

.icon-list-bul2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -393px -1310px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.icon-list-bul5 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1007px -916px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 11px;
}

.icon-list-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -620px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 14px;
}

.icon-location-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -156px -1370px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 7px;
}

.icon-location-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1097px -1006px;
  background-image: url("../images/sprite.png");
  width: 6px;
  min-width: 6px;
  height: 11px;
}

.icon-login-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1437px 0px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.icon-mail-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -412px -1172px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 16px;
}

.icon-main-icon1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px -728px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 32px;
}

.icon-main-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -252px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 38px;
}

.icon-main-icon3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px 0px;
  background-image: url("../images/sprite.png");
  width: 32px;
  min-width: 32px;
  height: 38px;
}

.icon-main-icon4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -184px -916px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 36px;
}

.icon-main-icon5 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -440px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-main-more {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -128px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-main-sns-b {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px -540px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-main-sns-btn-img1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px -634px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-main-sns-btn-img2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -104px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-main-sns-btn-img3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -198px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-main-sns-btn-img4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -292px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-main-sns-f {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -386px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-main-sns-insta {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -480px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-main-sns-youtube {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -574px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-main-tab-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -858px -916px;
  background-image: url("../images/sprite.png");
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.icon-main-visual-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -1092px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 30px;
}

.icon-main-visual-pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -878px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 16px;
}

.icon-main-visual-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -136px -1092px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 28px;
}

.icon-main-way-go-img1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -756px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 11px;
}

.icon-main-way-go-img2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -824px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 11px;
}

.icon-main-way-go-img3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -631px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-map-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -272px -1092px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 26px;
}

.icon-menu_open {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -385px -576px;
  background-image: url("../images/sprite.png");
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.icon-menu_search {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -491px -576px;
  background-image: url("../images/sprite.png");
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.icon-mobile-bul-minus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -935px -1092px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 2px;
}

.icon-mobile-bul-plus {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -544px -115px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.icon-mobile-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -597px -576px;
  background-image: url("../images/sprite.png");
  width: 56px;
  min-width: 56px;
  height: 56px;
}

.icon-nav-3rd-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -1256px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 4px;
}

.icon-nav-left {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -892px -1172px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 11px;
}

.icon-nav-pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -565px -406px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 10px;
}

.icon-nav-right {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -959px -1172px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 11px;
}

.icon-newsns-b-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -526px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-b {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -612px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-f-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -698px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-f {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -784px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-ks-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -870px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-ks {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -1006px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-kt-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -86px -1006px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-kt {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -172px -1006px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-tw-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -258px -1006px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-newsns-tw {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -344px -1006px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.icon-notice-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -285px -1310px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 5px;
}

.icon-notice-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -110px -1310px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 7px;
}

.icon-o-icon1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -718px -1092px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 12px;
}

.icon-o-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -482px -1172px;
  background-image: url("../images/sprite.png");
  width: 19px;
  min-width: 19px;
  height: 16px;
}

.icon-o-icon3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -551px -1172px;
  background-image: url("../images/sprite.png");
  width: 19px;
  min-width: 19px;
  height: 16px;
}

.icon-open-close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -500px -1242px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.icon-open-done {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1089px -1092px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
}

.icon-open-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1160px -1172px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 7px;
}

.icon-open-icon2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1222px -1172px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 7px;
}

.icon-open-num-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -414px -1092px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 14px;
}

.icon-open-num {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -490px -1092px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 14px;
}

.icon-open-tit {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px -440px;
  background-image: url("../images/sprite.png");
  width: 46px;
  min-width: 46px;
  height: 50px;
}

.icon-phone-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -607px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 21px;
}

.icon-popup-more {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -980px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-popup-more2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -1050px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-popup-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -1242px;
  background-image: url("../images/sprite.png");
  width: 11px;
  min-width: 11px;
  height: 18px;
}

.icon-popup-next2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -61px -1242px;
  background-image: url("../images/sprite.png");
  width: 11px;
  min-width: 11px;
  height: 18px;
}

.icon-popup-pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -317px;
  background-image: url("../images/sprite.png");
  width: 10px;
  min-width: 10px;
  height: 16px;
}

.icon-popup-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -122px -1242px;
  background-image: url("../images/sprite.png");
  width: 11px;
  min-width: 11px;
  height: 18px;
}

.icon-popup-prev2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -183px -1242px;
  background-image: url("../images/sprite.png");
  width: 11px;
  min-width: 11px;
  height: 18px;
}

.icon-pre-view {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -668px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-prvbtn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -244px -1242px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 14px;
}

.icon-q-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -539px -916px;
  background-image: url("../images/sprite.png");
  width: 34px;
  min-width: 34px;
  height: 27px;
}

.icon-radio-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -693px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-radio-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -755px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.icon-radio-type-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -191px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-radio-type-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -254px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

.icon-re-icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -276px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 18px;
}

.icon-replay {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -678px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 16px;
}

.icon-s-true1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -810px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.icon-s-true2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -790px -1092px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 12px;
}

.icon-s-true3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -51px -1370px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 12px;
}

.icon-search-btn {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -430px -1006px;
  background-image: url("../images/sprite.png");
  width: 34px;
  min-width: 34px;
  height: 34px;
}

.icon-search-btn2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -1172px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-search-btn2n {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -70px -1172px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.icon-search-btn3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -500px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.icon-search_icon {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -344px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 18px;
}

.icon-second-menu {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -446px -1310px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.icon-select-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -167px -1310px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.icon-select_arrow {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -616px -1242px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 4px;
}

.icon-select_arrow3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -226px -1310px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.icon-side-bul-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -1310px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 10px;
}

.icon-side-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -55px -1310px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 10px;
}

.icon-side-list-inbox {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -790px -1242px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 2px;
}

.icon-side-list-inbox_on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -844px -1242px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 2px;
}

.icon-side-list-li-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -558px -1242px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.icon-side-list-li-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1147px -1092px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
}

.icon-sidebarbul2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -102px -1370px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 8px;
}

.icon-slide-left {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -758px 0px;
  background-image: url("../images/sprite.png");
  width: 64px;
  min-width: 64px;
  height: 64px;
}

.icon-slide-left2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -204px -1092px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 28px;
}

.icon-slide-left3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -427px -698px;
  background-image: url("../images/sprite.png");
  width: 54px;
  min-width: 54px;
  height: 54px;
}

.icon-slide-right {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -758px -114px;
  background-image: url("../images/sprite.png");
  width: 64px;
  min-width: 64px;
  height: 64px;
}

.icon-slide-right2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -68px -1092px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 30px;
}

.icon-slide-right3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -531px -698px;
  background-image: url("../images/sprite.png");
  width: 54px;
  min-width: 54px;
  height: 54px;
}

.icon-sns-off {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -580px;
  background-image: url("../images/sprite.png");
  width: 27px;
  min-width: 27px;
  height: 32px;
}

.icon-sns-on {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -662px;
  background-image: url("../images/sprite.png");
  width: 27px;
  min-width: 27px;
  height: 32px;
}

.icon-special-img1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -762px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-special-img2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -856px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-special-img3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px 0px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-special-img4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -94px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-special-img5 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -188px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-special-img6 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -282px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.icon-step-final {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -242px -478px;
  background-image: url("../images/sprite.png");
  width: 63px;
  min-width: 63px;
  height: 46px;
}

.icon-step-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -832px;
  background-image: url("../images/sprite.png");
  width: 43px;
  min-width: 43px;
  height: 33px;
}

.icon-step-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -274px -916px;
  background-image: url("../images/sprite.png");
  width: 43px;
  min-width: 43px;
  height: 33px;
}

.icon-sub-menu-select {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -308px -1242px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 14px;
}

.icon-sub-nav_3rd_bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -898px -1242px;
  background-image: url("../images/sprite.png");
  width: 2px;
  min-width: 2px;
  height: 2px;
}

.icon-thrd-menu {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -735px -1242px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

.icon-title {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -478px;
  background-image: url("../images/sprite.png");
  width: 192px;
  min-width: 192px;
  height: 48px;
}

.icon-title2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -366px 0px;
  background-image: url("../images/sprite.png");
  width: 206px;
  min-width: 206px;
  height: 65px;
}

.icon-title3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -332px -312px;
  background-image: url("../images/sprite.png");
  width: 203px;
  min-width: 203px;
  height: 44px;
}

.icon-top-banner-next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px -244px;
  background-image: url("../images/sprite.png");
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.icon-top-banner-prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px -342px;
  background-image: url("../images/sprite.png");
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.icon-top-move {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -635px -698px;
  background-image: url("../images/sprite.png");
  width: 54px;
  min-width: 54px;
  height: 54px;
}

.icon-total-count-bul {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1437px -60px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.icon-twolinks_1a {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -138px -576px;
  background-image: url("../images/sprite.png");
  width: 75px;
  min-width: 75px;
  height: 63px;
}

.icon-twolinks_1b {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -622px -271px;
  background-image: url("../images/sprite.png");
  width: 70px;
  min-width: 70px;
  height: 70px;
}

.icon-twolinks_2a {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -758px -228px;
  background-image: url("../images/sprite.png");
  width: 60px;
  min-width: 60px;
  height: 66px;
}

.icon-twolinks_2b {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -622px -391px;
  background-image: url("../images/sprite.png");
  width: 70px;
  min-width: 70px;
  height: 70px;
}

.icon-up {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -876px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.icon-v-left {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -566px -1092px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 12px;
}

.icon-v-right {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -642px -1092px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 12px;
}

.icon-visual_next {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -134px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_next2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -201px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_pause {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -953px -1006px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
}

.icon-visual_pause2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -268px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_play {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1025px -1006px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
}

.icon-visual_play2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -335px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_prev {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -402px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

.icon-visual_prev2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -469px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 17px;
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  word-break: keep-all;
}

button,
input,
select,
textarea {
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

a:focus {
  outline: 0;
}

a:hover,
a:active {
  outline: 0;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ins {
  text-decoration: underline;
}

del {
  text-decoration: line-through;
}

mark {
  background: #ff0;
  font-style: italic;
}

pre,
code,
kbd,
samp {
  font-family: monospace;
  _font-family: monospace;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

p {
  margin: 0;
}

q {
  quotes: none;
}

q:before,
q:after {
  content: "";
  content: none;
}

small {
  font-size: 85%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ul,
ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

dl,
dt,
dd {
  margin: 0;
}

img {
  max-width: 100%;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: top;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  display: none;
  border: 0;
  *margin-left: -7px;
  padding: 0;
}

label {
  cursor: pointer;
}

button {
  padding: 0;
  outline: 0;
  border: none;
  background: transparent;
}

button,
input,
select,
textarea {
  font-size: 100%;
  font-family: inherit;
  margin: 0;
  /*vertical-align: baseline;*/
  vertical-align: middle;
  border-radius: 0;
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

button,
input {
  line-height: normal;
  *overflow: visible;
}

table button,
table input {
  *overflow: auto;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input:invalid,
textarea:invalid {
  border-color: #C82E2D;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input::-ms-clear {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

address,
em,
i {
  font-style: normal;
}

caption {
  position: absolute;
  top: 0;
  left: -9999px;
  height: 0 !important;
  width: 0 !important;
  overflow: hidden;
  font-size: 0;
}

/*
-----------*/
.in_length30 {
  width: 30px;
}

.in_length40 {
  width: 40px;
}

.in_length50 {
  width: 50px;
}

.in_length60 {
  width: 60px;
}

.in_length70 {
  width: 70px;
}

.in_length80 {
  width: 80px;
}

.in_length90 {
  width: 90px;
}

.in_length100 {
  width: 100px;
}

.in_length110 {
  width: 110px;
}

.in_length120 {
  width: 120px;
}

.in_length130 {
  width: 130px;
}

.in_length140 {
  width: 140px;
}

.in_length150 {
  width: 150px;
}

.in_length160 {
  width: 160px;
}

.in_length170 {
  width: 170px;
}

.in_length180 {
  width: 180px;
}

.in_length190 {
  width: 190px;
}

.in_length200 {
  width: 200px;
}

.in_length250 {
  width: 250px;
}

.in_length300 {
  width: 300px;
}

.in_length350 {
  width: 350px;
}

.in_length400 {
  width: 400px;
}

.in_length450 {
  width: 450px;
}

.in_length500 {
  width: 500px;
}

.in_length600 {
  width: 600px;
}

.in_length98pro {
  width: 98%;
}

.float_wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .float_wrap {
  display: inline-block;
}

* html .float_wrap {
  height: 1%;
}

.float-wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float-wrap {
  display: inline-block;
}

.align_right {
  text-align: right;
}

.align_left {
  text-align: left;
}

.align_center {
  text-align: center;
}

.hidden_word {
  position: absolute;
  top: 0;
  left: -9999px;
  text-indent: -9999px;
  display: block;
}

.mgn_b0 {
  margin-bottom: 0 !important;
}

.mgn_b5 {
  margin-bottom: 5px;
}

.mgn_b8 {
  margin-bottom: 8px;
}

.mgn_b10 {
  margin-bottom: 10px;
}

.mgn_b15 {
  margin-bottom: 15px;
}

.mgn_b18 {
  margin-bottom: 18px;
}

.mgn_b20 {
  margin-bottom: 20px;
}

.mgn_b25 {
  margin-bottom: 25px;
}

.mgn_b30 {
  margin-bottom: 30px;
}

.mgn_b40 {
  margin-bottom: 40px;
}

.mgn_b50 {
  margin-bottom: 50px;
}

.mgn_b70 {
  margin-bottom: 70px;
}

.mgn_b80 {
  margin-bottom: 80px;
}

.mgn_b90 {
  margin-bottom: 90px;
}

.mgn_t0 {
  margin-top: 0 !important;
}

.mgn_t5 {
  margin-top: 5px;
}

.mgn_t8 {
  margin-top: 8px;
}

.mgn_t10 {
  margin-top: 10px;
}

.mgn_t15 {
  margin-top: 15px;
}

.mgn_t18 {
  margin-top: 18px;
}

.mgn_t20 {
  margin-top: 20px;
}

.mgn_t25 {
  margin-top: 25px;
}

.mgn_t30 {
  margin-top: 30px;
}

.mgn_t40 {
  margin-top: 40px;
}

.mgn_t50 {
  margin-top: 50px;
}

.mgn_t60 {
  margin-top: 60px;
}

.mgn_t70 {
  margin-top: 70px;
}

.mgn_t80 {
  margin-top: 80px;
}

.mgn_t90 {
  margin-top: 90px;
}

.mgn_t105 {
  margin-top: 105px;
}

.mgn_l10 {
  margin-left: 10px;
}

.mgn_l15 {
  margin-left: 15px;
}

.mgn_l20 {
  margin-left: 20px;
}

.mgn_l30 {
  margin-left: 30px;
}

.mgn_l40 {
  margin-left: 40px;
}

.mgn_r10 {
  margin-right: 10px;
}

.mgn_r20 {
  margin-right: 20px;
}

.mgn_r30 {
  margin-right: 30px;
}

.mgn_r40 {
  margin-right: 40px;
}

.left_10 {
  padding-left: 10px;
}

.left_15 {
  padding-left: 15px;
}

.left_20 {
  padding-left: 20px;
}

.left_30 {
  padding-left: 30px;
}

.left_40 {
  padding-left: 40px;
}

.left_50 {
  padding-left: 50px;
}

.left_100 {
  padding-left: 100px;
}

.left_150 {
  padding-left: 150px;
}

.right_10 {
  padding-right: 10px;
}

.right_20 {
  padding-right: 20px;
}

.right_30 {
  padding-right: 30px;
}

.right_50 {
  padding-right: 50px;
}

.right_100 {
  padding-right: 100px;
}

.right_150 {
  padding-right: 150px;
}

.col_brown {
  color: #996630;
}

.line_14 {
  line-height: 1.4;
}

.line_16 {
  line-height: 1.6;
}

.line_18 {
  line-height: 1.8;
}

.col_white {
  color: #fff;
}

.col_red {
  color: #ff5054;
}

.col_gold {
  color: #CD883D;
}

.col_black {
  color: #000000;
}

.col_org {
  color: #f05914;
}

.col_gray {
  color: #818283;
}

.col_black {
  color: #000;
}

.col_pul {
  color: #3c5e80;
}

.col_blue {
  color: #21539b;
}

.col_gold {
  color: #999900;
}

.col_yellow {
  color: #fff585;
}

.font_bold {
  font-weight: bold;
}

.font_11 {
  font-size: 11px;
  font-size: 1.1rem;
}

.font_12 {
  font-size: 12px;
  font-size: 1.2rem;
}

.font_13 {
  font-size: 13px;
  font-size: 1.3rem;
}

.font_14 {
  font-size: 14px;
  font-size: 1.4rem;
}

.font_15 {
  font-size: 15px;
  font-size: 1.5rem;
}

.font_16 {
  font-size: 16px;
  font-size: 1.6rem;
}

.font_17 {
  font-size: 17px;
  font-size: 1.7rem;
}

.font_18 {
  font-size: 18px;
  font-size: 1.8rem;
}

.font_20 {
  font-size: 20px;
  font-size: 2.0rem;
}

.font_22 {
  font-size: 22px;
  font-size: 2.2rem;
}

.chk_box {
  height: 13px;
  margin: 0;
}

.chk_box, label {
  vertical-align: middle;
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.eot);
  src: local("NanumGothic"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.eot?#iefix) format("embedded-opentype"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.woff2) format("x-woff2"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.woff) format("woff"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.eot);
  src: local("NanumGothicBold"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.eot?#iefix) format("embedded-opentype"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.woff2) format("x-woff2"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.woff) format("woff"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-Bold.ttf) format("truetype");
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 800;
  src: url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.eot);
  src: local("NanumGothicExtraBold"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.eot?#iefix) format("embedded-opentype"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.woff2) format("x-woff2"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.woff) format("woff"), url(//themes.googleusercontent.com/static/fonts/earlyaccess/nanumgothic/v3/NanumGothic-ExtraBold.ttf) format("truetype");
}

/* Noto Sans KR (korean) http://www.google.com/fonts/earlyaccess
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 100; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 300; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 400; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 500; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 700; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format("opentype"); }
@font-face { font-family: 'Noto Sans KR'; font-style: normal; font-weight: 900; src: url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format("woff2"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format("woff"), url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format("opentype"); }
*/
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(../../resources/fonts/NotoSansKR-Thin.woff2) format("woff2"), url(../../resources/fonts/NotoSansKR-Thin.woff) format("woff"), url(../../resources/fonts/NotoSansKR-Thin.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(../../resources/fonts/NotoSansKR-Light.woff2) format("woff2"), url(../../resources/fonts/NotoSansKR-Light.woff) format("woff"), url(../../resources/fonts/NotoSansKR-Light.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(../../resources/fonts/NotoSansKR-Regular.woff2) format("woff2"), url(../../resources/fonts/NotoSansKR-Regular.woff) format("woff"), url(../../resources/fonts/NotoSansKR-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/NotoSansKR-Medium.woff2) format("woff2"), url(../../resources/fonts/NotoSansKR-Medium.woff) format("woff"), url(../../resources/fonts/NotoSansKR-Medium.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(../../resources/fonts/NotoSansKR-Bold.woff2) format("woff2"), url(../../resources/fonts/NotoSansKR-Bold.woff) format("woff"), url(../../resources/fonts/NotoSansKR-Bold.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url(../../resources/fonts/NotoSansKR-Black.woff2) format("woff2"), url(../../resources/fonts/NotoSansKR-Black.woff) format("woff"), url(../../resources/fonts/NotoSansKR-Black.otf) format("opentype");
}

@font-face {
  font-family: 'yes24';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/yes24.woff) format("woff");
}

@font-face {
  font-family: 'rokg';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/rokg.woff) format("woff");
}

@font-face {
  font-family: 'scdream';
  font-style: normal;
  font-weight: 400;
  src: url(../../resources/fonts/SCDream4.woff) format("woff");
}

@font-face {
  font-family: 'scdream';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/SCDream5.woff) format("woff");
}

@font-face {
  font-family: 'scdream';
  font-style: normal;
  font-weight: 600;
  src: url(../../resources/fonts/SCDream6.woff) format("woff");
}

@font-face {
  font-family: 'Jalnan';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/Jalnan.woff) format("woff");
}

@font-face {
  font-family: 'NanumSquare';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/NanumSquare_acL.woff) format("woff");
}

@font-face {
  font-family: 'NanumSquare';
  font-style: normal;
  font-weight: 400;
  src: url(../../resources/fonts/NanumSquare_acR.woff) format("woff");
}

@font-face {
  font-family: 'NanumSquare';
  font-style: normal;
  font-weight: 500;
  src: url(../../resources/fonts/NanumSquare_acB.woff) format("woff");
}

@font-face {
  font-family: 'NanumSquare';
  font-style: normal;
  font-weight: 600;
  src: url(../../resources/fonts/NanumSquare_acEB.woff) format("woff");
}

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 400;
  src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot");
  src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot?#iefix") format("embedded-opentype"), url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.woff") format("woff"), url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.ttf") format("truetype");
}

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 700;
  src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.eot");
  src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.eot?#iefix") format("embedded-opentype"), url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.woff") format("woff"), url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.ttf") format("truetype");
}

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 300;
  src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.eot");
  src: url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.eot?#iefix") format("embedded-opentype"), url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.woff") format("woff"), url("//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.ttf") format("truetype");
}

html {
  font-size: 62.5%;
}

html.websize-1 {
  font-size: 62.5%;
}

html.websize-2 {
  font-size: 68.5%;
}

html.websize-3 {
  font-size: 72.5%;
}

html.websize-4 {
  font-size: 78.5%;
}

html.websize-5 {
  font-size: 82.5%;
}

html.websize-6 {
  font-size: 88.5%;
}

html.websize-7 {
  font-size: 93.5%;
}

.float-wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float-wrap {
  display: inline-block;
}

.float_wrap:after {
  content: ' ';
  clear: both;
  visibility: hidden;
  display: block;
}

* + html .float_wrap {
  display: inline-block;
}

/**/
.size-realtime {
  display: none;
  font-size: 12px;
  padding-left: 0 !important;
}

html.websize-1 .size-realtime.s1 {
  display: inline-block;
}

html.websize-2 .size-realtime.s2 {
  display: inline-block;
}

html.websize-3 .size-realtime.s3 {
  display: inline-block;
}

html.websize-4 .size-realtime.s4 {
  display: inline-block;
}

html.websize-5 .size-realtime.s5 {
  display: inline-block;
}

html.websize-6 .size-realtime.s6 {
  display: inline-block;
}

html.websize-7 .size-realtime.s7 {
  display: inline-block;
}

/* br 태그 모바일 or pc */
br.pc_none {
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  br.mb_none {
    display: none;
  }
  br.pc_none {
    display: block;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**/
html body {
  font-size: 14px;
  font-size: 1.4rem;
}

/*사이즈 확인*/
.resol {
  position: fixed;
  bottom: 0;
  right: 0;
  border: 3px solid red;
  background-color: #fff;
  padding: 5px;
}

/*숫자클래스 삽입*/
.menu-num-2 > ul > li {
  width: 50%;
}

.menu-num-3 > ul > li {
  width: 33.33%;
}

* + html .menu-num-3 > ul > li {
  width: 33%;
}

.menu-num-4 > ul > li {
  width: 25%;
}

.menu-num-5 > ul > li {
  width: 18%;
}

.menu-num-5 > ul > li:first-child {
  width: 28%;
}

.menu-num-6 > ul > li {
  width: 16.66%;
}

* + html .menu-num-6 > ul > li {
  width: 16%;
}

.menu-num-7 > ul > li {
  width: 14%;
}

.menu-num-8 > ul > li {
  width: 12.5%;
}

.menu-num-9 > ul > li {
  width: 11.20%;
}

* + html .menu-num-9 > ul > li {
  width: 11%;
}

.menu-num-10 > ul > li {
  width: 10%;
}

.menu-num-11 > ul > li {
  width: 9.09%;
}

* + html .menu-num-11 > ul > li {
  width: 9%;
}

.menu-num-12 > ul > li {
  width: 8.3%;
}

* + html .menu-num-12 > ul > li {
  width: 8%;
}

.menu-num-13 > ul > li {
  width: 7.5%;
}

* + html .menu-num-13 > ul > li {
  width: 7%;
}

/*유동적 가로세로 비율 박스*/
#movie-player-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  /*16:9*/
  background-color: #00235d;
  color: #fff;
}

#movie-player-wrapper .mov-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#movie-player-wrapper iframe {
  width: 100%;
  height: 100%;
}

/*표 반응형*/
.test-table {
  margin-bottom: 30px;
}

.test-table table {
  border-collapse: collapse;
  width: 100%;
}

.test-table th {
  border: 1px solid #777;
  padding: 5px;
}

.test-table td {
  border: 1px solid #777;
  padding: 5px;
}

@media (max-width: 800px) {
  .test-table th {
    display: none;
  }
  .test-table td {
    display: block;
    margin-bottom: 10px;
  }
  .test-table td:before {
    color: blue;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
}

.show-list__top {
  position: relative;
  min-height: 120px;
  min-height: 20px;
  margin-bottom: 5px;
  background-color: #fff;
}

.show-list__top:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .show-list__top {
  display: inline-block;
}

.show-list__top__info {
  color: #666;
}

.show-list__top__info strong {
  color: #111111;
}

.show-list__top__search__selectbox {
  margin-right: 4px;
}

.show-list__top__search__selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #ddd;
}

.show-list__top__search__selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -167px -1310px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .show-list__top__search__selectbox:after {
  display: none;
}

.show-list__top__search__selectbox label {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  font-size: 0;
  height: 0;
  overflow: hidden;
}

.show-list__top__search__selectbox select {
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  appearance: none;
  min-width: 100px;
  vertical-align: middle;
  color: #111;
  font-size: 16px;
  height: 40px;
  margin-top: 0;
  padding: 2px 2px 2px 15px;
  padding-right: 110px;
  position: relative;
  border: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.show-list__top__search__selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .show-list__top__search__selectbox select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.show-list__top__search__selectbox select option {
  font-family: "Nanum Gothic";
}

.show-list__top__search__selectbox label {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  font-size: 0;
  height: 0;
  overflow: hidden;
}

.show-list__top__search__inputbox {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.show-list__top__search__inputbox input {
  width: 250px;
  border: 1px solid #ddd;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding: 0;
  padding-left: 15px;
}

.show-list__top__search__inputbox a {
  width: 40px;
  text-indent: -9999px;
  font-size: 0;
  height: 40px;
  border: none;
  font-size: 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: auto;
  box-sizing: border-box;
  left: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(/template/resources/images/icons/bbs-search-img.png) center center no-repeat #f8f8f8;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.show-list__top__search__inputbox a:focus {
  text-decoration: underline;
}

.show-list__top.ver2 {
  background-color: #fff;
}

.ver2 .show-list__top__info {
  left: 0;
}

.ver2 .show-list__top__search {
  text-align: right;
  margin-bottom: 15px;
}

.ver2 .show-list__top__search .show-list__top__search__selectbox {
  border: 1px solid #ddd;
}

.ver2 .show-list__top__search input {
  border: 1px solid #ddd;
}

/* show list top */
@media screen and (min-width: 1px) and (max-width: 1580px) {
  .show-list__top__search__selectbox {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #fff;
    text-align: left;
    border: 1px solid #ddd;
  }
  .show-list__top__search__selectbox:after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
    content: ' ';
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -167px -1310px;
    background-image: url("../images/sprite.png");
    width: 9px;
    min-width: 9px;
    height: 5px;
  }
  .lte-ie9 .show-list__top__search__selectbox:after {
    display: none;
  }
  .show-list__top__search__selectbox label {
    text-indent: -9999px;
    position: absolute;
    top: 0;
    left: -9999px;
    font-size: 0;
    height: 0;
    overflow: hidden;
  }
  .show-list__top__search__selectbox select {
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    appearance: none;
    min-width: 100px;
    vertical-align: middle;
    color: #111;
    font-size: 16px;
    height: 40px;
    margin-top: 0;
    padding: 2px 2px 2px 15px;
    padding-right: 110px;
    position: relative;
    border: 0px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /*ie10~11 에서 셀렉트 기본디자인 무력화*/
  }
  .show-list__top__search__selectbox select::-ms-expand {
    display: none;
  }
  .lte-ie9 .show-list__top__search__selectbox select {
    width: 100%;
    vertical-align: top;
    padding-right: 0px;
  }
  .show-list__top__search__selectbox select option {
    font-family: "Nanum Gothic";
  }
  .show-list__top__search__inputbox {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .show-list__top__search__inputbox input {
    width: 200px;
    border: 1px solid #ddd;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    padding: 0;
    padding-left: 15px;
  }
  .show-list__top__search__inputbox a {
    width: 40px;
    text-indent: -9999px;
    font-size: 0;
    height: 40px;
    border: none;
    font-size: 0;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: auto;
    box-sizing: border-box;
    left: auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url(/template/resources/images/icons/bbs-search-img.png) center center no-repeat #f8f8f8;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  .show-list__top__search__inputbox a:focus {
    text-decoration: underline;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .show-list__top {
    padding: 20px;
  }
  .show-list__top__info {
    transform: translateY(0);
    top: 0;
    left: 0;
    position: relative;
    margin-bottom: 10px;
  }
  .show-list__top__search {
    transform: translateY(0);
    top: 0;
    right: 0;
    position: relative;
  }
  .show-list__top__search__selectbox {
    margin-bottom: 10px;
  }
  .show-list__top__search__selectbox {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: #fff;
    text-align: left;
    border: 1px solid #ddd;
  }
  .show-list__top__search__selectbox:after {
    position: absolute;
    top: 50%;
    right: 0;
    margin-right: 10px;
    transform: translateY(-50%);
    content: ' ';
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -167px -1310px;
    background-image: url("../images/sprite.png");
    width: 9px;
    min-width: 9px;
    height: 5px;
  }
  .lte-ie9 .show-list__top__search__selectbox:after {
    display: none;
  }
  .show-list__top__search__selectbox label {
    text-indent: -9999px;
    position: absolute;
    top: 0;
    left: -9999px;
    font-size: 0;
    height: 0;
    overflow: hidden;
  }
  .show-list__top__search__selectbox select {
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    appearance: none;
    min-width: 100px;
    vertical-align: middle;
    color: #111;
    font-size: 16px;
    height: 40px;
    margin-top: 0;
    padding: 2px 2px 2px 15px;
    padding-right: 110px;
    position: relative;
    border: 0px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /*ie10~11 에서 셀렉트 기본디자인 무력화*/
  }
  .show-list__top__search__selectbox select::-ms-expand {
    display: none;
  }
  .lte-ie9 .show-list__top__search__selectbox select {
    width: 100%;
    vertical-align: top;
    padding-right: 0px;
  }
  .show-list__top__search__selectbox select option {
    font-family: "Nanum Gothic";
  }
  .show-list__top__search__inputbox {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .show-list__top__search__inputbox input {
    width: 250px;
    border: 1px solid #ddd;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    padding: 0;
    padding-left: 15px;
  }
  .show-list__top__search__inputbox a {
    width: 40px;
    text-indent: -9999px;
    font-size: 0;
    height: 40px;
    border: none;
    font-size: 0;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: auto;
    box-sizing: border-box;
    left: auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url(/template/resources/images/icons/bbs-search-img.png) center center no-repeat #f8f8f8;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  .show-list__top__search__inputbox a:focus {
    text-decoration: underline;
  }
  .show-list__top.ver2 {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .show-list__top__search__inputbox {
    margin-top: 8px;
  }
}

.board_view .editor_view {
  min-height: 200px;
}

.common_layer_box {
  display: none;
}

.test {
  font-size: 19px;
  font-size: 1.9rem;
}

/*skip navigation*/
.skiptoContent {
  position: fixed;
  left: 50%;
  top: -30px;
  height: 30px;
  line-height: 30px;
  background-color: #00235d;
  width: 540px;
  margin-left: -270px;
  text-align: center;
  transition: all 0.3s ease 0s;
  z-index: 9999;
}

.skiptoContent.on {
  top: 0;
}

.skiptoContent strong {
  font-size: 0;
}

.skiptoContent a {
  color: #fff;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
}

.skiptoContent a:focus {
  color: gold;
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .skiptoContent {
    display: none;
  }
}

/*ul 사이즈 설정필수*/
/*컬러설정*/
/*header*/
.header {
  height: 120px;
  z-index: 99;
  width: 100%;
  font-family: "Pretendard-Regular", "Nanum Gothic", sans-serif;
  /*헤더 위 링크들*/
  /*header search*/
  /*
	&__search {position:absolute;top:0px;left:50%;margin-left:-70px;}
	&__search__in {border:1px solid #333;background-color:#fff;}
	&__search-in_box {border:0px;padding:0;height:32px;width:150px;line-height: 32px; vertical-align: top;}
    */
}

.subpage .header {
  position: relative;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .subpage .header {
    position: fixed;
  }
}

.header__links {
  position: relative;
  width: 1500px;
  margin: 0 auto;
  z-index: 66999;
}

.header__links__1 {
  position: absolute;
  top: 31px;
  right: 40px;
  z-index: 10;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
}

.header__links__1 > a {
  position: relative;
  color: #000;
  margin-left: 17px;
  height: 30px;
}

.header__links__1__icon-alert {
  top: -11px;
}

.header__links__1__icon-alert a {
  display: block;
}

.header__links__1__icon-alert img {
  width: 92px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__links__1__icon-alert {
    display: none;
  }
}

.header__links__1 > a:hover {
  opacity: 0.8;
}

.header__links__1 > a:focus {
  text-decoration: underline;
}

.header__links__2 {
  position: absolute;
  top: 25px;
  right: 10px;
  z-index: 10;
}

@media screen and (min-width: 769px) and (max-width: 1580px) {
  .header__links__1 {
    top: 3px;
    right: 40px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .header__links__1 {
    top: 30px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__links__1 > a {
    font-size: 13px;
    width: auto !important;
    margin-left: 10px !important;
    color: #000;
    padding-left: 0;
  }
  .header__links__1 > a:before {
    display: none !important;
  }
  .header__links__1 > a.header__links__1__icon3 {
    color: #000;
  }
  .header__links__1 > a.header__links__1__icon3 span {
    display: inline;
  }
}

.header__wrap {
  position: relative;
}

.header__wrap__h1 {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  position: relative;
  z-index: 10000;
}

.header__wrap__h1 h1 {
  position: absolute;
  left: 25px;
  top: 35px;
}

.header__wrap__h1 h1 a {
  display: block;
  text-align: center;
  font-size: 0;
}

@media screen and (min-width: 1px) and (max-width: 1500px) {
  .header__links {
    width: auto;
  }
  .header__wrap__h1 {
    width: auto;
  }
  .header h1 a img {
    max-height: 40px;
  }
}

.header__search {
  position: relative;
  z-index: 999999;
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  background-color: transparent;
}

.header__search__open {
  position: absolute;
  top: 44px;
  right: 175px;
  border-radius: 100px 100px;
  color: #0f9d4c;
  display: block;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;
  text-indent: -9999px;
  overflow: hidden;
  width: 40px;
  background-position: 10px center;
  background-repeat: no-repeat;
}

.header__search__open:focus {
  border: 1px solid #0e813f;
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .header__search__open {
    right: 112px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__search__open {
    display: none;
  }
}

.header__search__in {
  display: none;
  position: absolute;
  top: 30px;
  right: 102px;
  border: 1px solid #0f9d4c;
  background-color: #fff;
  border-radius: 34px;
  padding-left: 12px;
  padding-right: 6px;
  box-shadow: 0px 7px 11px -13px rgba(0, 0, 0, 0.75);
  top: 120px;
  right: 0;
  border: 0;
  background-color: #fff;
  border-radius: 0;
  padding-left: 12px;
  padding-right: 6px;
  text-align: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.header__search__in__inner {
  display: inline-block;
  border-bottom: 2px solid #000;
  width: 42%;
  position: relative;
  text-align: left;
  padding-bottom: 5px;
}

.header__search__in.on {
  display: block;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .header__search__in {
    top: 141px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__search__in {
    display: block;
    right: 0;
  }
  .header__search__in__inner {
    width: 80%;
  }
}

.header__search-in_box {
  border: 0px;
  padding: 0;
  padding-left: 10px;
  height: 40px;
  width: 85%;
  line-height: 30px;
  vertical-align: top;
  font-size: 17px;
  background-color: transparent;
  padding-top: 2px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__search-in_box {
    width: 77%;
  }
}

.header__search .top-s-btn {
  padding: 6px;
  position: absolute;
  top: 0;
  right: 0;
}

.header__search .top-search-close-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__search .top-search-close-btn {
    right: 10px;
    top: 20px;
    display: none;
  }
}

.header__search .top-search-close-btn:focus {
  border: 1px solid #0e813f;
}

.add-search-btn .header__search__in {
  margin-right: 0px;
  top: 120px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .add-search-btn .header__search__in {
    top: 50px;
  }
}

.add-search-btn .header__search {
  display: none;
}

.add-search-btn .header__search.on {
  display: block;
}

* + html .add-search-btn .header-search-btn {
  display: inline;
}

@media screen and (min-width: 769px) and (max-width: 1500px) {
  .header__search {
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__search {
    display: none;
  }
  .header__search__in {
    top: 52px;
  }
  .add-search-btn .header__search__in {
    right: 0;
    margin-right: 0;
  }
  .header__search.on {
    display: block;
    width: auto;
  }
  .header-search-btn {
    display: none !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 1500px) {
  .header {
    background-color: #26272b;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .header {
    border-bottom: 1px solid #3c3d41;
  }
}

/*gnb*/
.gnb {
  width: 100%;
  position: absolute;
  left: 0;
  top: 80px;
  background-repeat: repeat-x;
  background-position: center top;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 7px 11px -13px rgba(0, 0, 0, 0.75);
  /*한개씩 떨어짐*/
}

.selected .gnb {
  box-shadow: 0px 7px 11px -13px rgba(0, 0, 0, 0.75);
}

.gnb .gnb-ul {
  display: block;
  width: 1580px;
  padding-left: 0px;
  margin: 0 auto;
}

.gnb .gnb-menu {
  float: left;
}

.gnb .gnb-menu > a {
  display: block;
  text-align: center;
  color: #fff;
  height: 60px;
  line-height: 60px;
  text-decoration: none;
  font-size: 24px;
  font-weight: 500;
}

.gnb .gnb-menu > a span {
  display: inline-block;
  vertical-align: top;
  font-family: "Pretendard-Regular", "Nanum Gothic", sans-serif;
}

.gnb .gnb-menu.over > a,
.gnb .gnb-menu.focus > a,
.gnb .gnb-menu > a:hover,
.gnb .gnb-menu > a:focus {
  color: #fff;
}

.gnb .gnb-menu.over > a span,
.gnb .gnb-menu.focus > a span,
.gnb .gnb-menu > a:hover span,
.gnb .gnb-menu > a:focus span {
  background-image: url("../../resources/images/basic/gnb-bul.png");
  background-position: center bottom;
  background-repeat: repeat-x;
  position: relative;
  z-index: 9999;
}

.gnb .gnb-menu.over > a,
.gnb .gnb-menu.focus > a,
.gnb .gnb-menu > a:hover,
.gnb .gnb-menu > a:focus {
  color: #fff;
}

.gnb .gnb-menu {
  position: relative;
}

.gnb .sub-nav {
  visibility: hidden;
  height: 0;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  z-index: 98;
  background-color: #26272b;
  padding-top: 0;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.gnb .gnb-menu.over .sub-nav,
.selected .gnb .gnb-menu.focus .sub-nav {
  visibility: visible;
  height: auto;
  opacity: 1;
}

.gnb .sub-nav__box {
  border: 2px solid #00235d;
  border-top: 0px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s ease 0s;
}

.gnb .sub-nav__box__list > a {
  display: block;
  padding: 8px 0 8px 10px;
}

.gnb .sub-nav__box__list > a:hover,
.gnb .sub-nav__box__list > a:focus {
  text-decoration: underline;
  color: #000;
}

.gnb .gnb-menu.over .sub-nav__box,
.selected .gnb .gnb-menu.focus .sub-nav__box {
  padding-bottom: 10px;
  padding-top: 15px;
}

* + html .gnb {
  position: absolute;
  top: 0;
  padding-top: 0;
}

/* all 2nd menu down
===============================*/
.down-all .gnb {
  background-image: url("../../resources/images/basic/header-bg-ver1.png");
}

.down-all .gnb .sub-nav {
  display: block;
  text-align: center;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  position: relative;
  top: 0;
  left: 0;
  opacity: 1;
  background: transparent;
}

.down-all .gnb .sub-nav__box {
  border: 0px;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.down-all .gnb .sub-nav__box__list a {
  display: block;
  width: 100%;
  padding: 8px 0;
  color: #fff;
  font-family: "Nanum Gothic";
  font-weight: 400;
  font-size: 16px;
  /*
                &:hover,
				&:focus {}
				*/
}

.down-all .gnb .gnb-menu.over .sub-nav {
  background-color: transparent;
}

.down-all .gnb .gnb-menu.over .sub-nav__box__list a {
  color: #fff;
}

.down-all .gnb .gnb-menu.over .sub-nav__box__list a:hover,
.down-all .gnb .gnb-menu.over .sub-nav__box__list a:focus {
  color: #fff;
}

.down-all .gnb .gnb-menu.over > a,
.down-all .gnb .gnb-menu.focus > a {
  background-position: left bottom;
  background-repeat: repeat-x;
}

.mainpage .gnb .gnb-menu > a {
  color: #fff;
}

.down-all .header__wrap__h1 h1 a img {
  display: none;
}

.down-all .header__wrap__h1 h1 a:focus {
  border: 1px solid gold;
}

.mainpage .down-all .header__wrap__h1 h1 a {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -366px 0px;
  background-image: url("../images/sprite.png");
  width: 206px;
  min-width: 206px;
  height: 65px;
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .mainpage .down-all .header__wrap__h1 h1 a {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 0px -367.69231px;
    background-image: url("../images/sprite.png");
    background-size: 1106.15385px auto;
    width: 147.69231px;
    min-width: 147.69231px;
    height: 36.92308px;
  }
}

.subpage .down-all .header__wrap__h1 h1 a {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -366px 0px;
  background-image: url("../images/sprite.png");
  width: 206px;
  min-width: 206px;
  height: 65px;
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .subpage .down-all .header__wrap__h1 h1 a {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -281.53846px 0px;
    background-image: url("../images/sprite.png");
    background-size: 1106.15385px auto;
    width: 158.46154px;
    min-width: 158.46154px;
    height: 50px;
  }
}

.down-all.selected {
  background-color: #26272b;
  position: relative;
}

.down-all.selected .header__wrap__h1 h1 a {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -366px 0px;
  background-image: url("../images/sprite.png");
  width: 206px;
  min-width: 206px;
  height: 65px;
}

.down-all.selected .header__wrap__h1 h1 a img {
  display: none;
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .down-all.selected .header__wrap__h1 h1 a {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -281.53846px 0px;
    background-image: url("../images/sprite.png");
    background-size: 1106.15385px auto;
    width: 158.46154px;
    min-width: 158.46154px;
    height: 50px;
  }
}

.down-all.selected .gnb {
  background-color: #26272b;
}

.down-all.selected .gnb .gnb-ul .sub-nav {
  height: auto;
  visibility: visible;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0s ease 0s;
}

.down-all.selected .gnb .gnb-menu > a {
  background-image: url("../../resources/images/basic/header-bg-ver-main2.png");
  background-repeat: repeat-x;
  background-position: left bottom;
  color: #fff;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .down-all.selected .gnb .gnb-menu > a {
    background-image: none;
  }
}

/* 2차메뉴 균일 박스 디자인 적용시 */
.down-all .gnb .gnb-ul {
  display: table;
}

.down-all .gnb .gnb-menu {
  display: table-cell;
  float: none;
}

.down-all .gnb .gnb-menu.over {
  background-color: transparent;
}

/* 2차메뉴 보더적용시 주석 해제 */
.down-all .gnb .gnb-menu:first-child {
  border-left: 0px solid #eee;
}

.down-all .gnb .gnb-menu:last-child {
  border-right: 0px solid #eee !important;
}

.down-all .gnb .gnb-menu {
  padding-right: 1px;
}

.down-all.selected .gnb .gnb-menu {
  padding-right: 0;
}

.down-all .gnb .gnb-menu > a {
  display: block;
  margin-left: -1px;
  position: relative;
  text-align: center;
  width: 102%;
}

/*모드별 디자인
	ver2
	down-all
	add-all-menu
	add-search-btn
--------------*/
/*ver2*/
.ver2 .header__wrap__h1 h1 {
  top: 27px;
  z-index: 9999;
}

@media screen and (min-width: 769px) and (max-width: 1500px) {
  .ver2 .header__wrap__h1 h1 {
    top: 27px;
  }
}

.ver2 .gnb {
  top: 0;
  z-index: 9;
}

.ver2 .gnb .gnb-ul {
  width: 950px;
}

.ver2 .gnb .gnb-menu > a {
  height: 120px;
  line-height: 120px;
}

.ver2 .gnb .sub-nav {
  top: 120px;
}

.ver2.down-all .gnb {
  background-image: url("../../resources/images/basic/header-bg-ver-main2hover.png");
}

.ver2.down-all .gnb .sub-nav {
  top: 0;
}

.ver2.down-all.selected .gnb {
  background-image: url("../../resources/images/basic/header-bg-ver-main2hover.png");
}

/*down-all*/
/* add-all-menu */
.add-all-menu .all_menu_wrap__outwrap {
  display: block;
}

.add-all-menu .gnb .gnb-ul {
  width: 1250px;
}

/*ver2 + add-all-menu*/
.ver2.add-all-menu .gnb .gnb-ul {
  width: 940px;
  padding-right: 153px;
  padding-left: 140px;
}

.ver2.add-all-menu .gnb .gnb-ul {
  width: 1030px;
  padding-right: 0;
  padding-left: 30px;
  margin: 0 auto;
}

@media screen and (min-width: 1401px) and (max-width: 1700px) {
  .ver2.add-all-menu .gnb .gnb-ul {
    width: 870px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .ver2.add-all-menu .gnb .gnb-ul {
    width: 780px;
    padding-left: 120px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .header {
    height: 140px;
  }
  .header__wrap__h1 h1 {
    top: 20px !important;
  }
  .gnb {
    top: 80px !important;
    background-image: none !important;
  }
  .gnb .gnb-ul {
    width: 99% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .gnb .gnb-menu > a {
    height: 60px !important;
    line-height: 60px !important;
  }
}

/* 펼쳤을때 좌측 타이틀*/
@media screen and (min-width: 1px) and (max-width: 1200px) {
  .down-all .gnb .sub-nav {
    background-image: none;
  }
}

.down-all .gnb .sub-nav__box {
  position: relative;
}

.gnb-tit {
  display: none;
}

.gnb .gnb-tit {
  display: none;
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 999;
  width: 240px;
  text-align: left;
  color: #000;
  letter-spacing: -1px;
  font-size: 27px;
  font-weight: bold;
}

.gnb .gnb-tit:before {
  content: ' ';
  display: block;
  width: 40px;
  height: 1px;
  background-color: #000;
  position: absolute;
  top: 55px;
  left: 0;
}

.gnb .gnb-tit:after {
  position: absolute;
  top: 75px;
  left: 0px;
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  font-weight: normal;
  white-space: pre;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .gnb .gnb-tit {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1500px) {
  .header__links__2 {
    top: 15px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__links__2 {
    top: 10px;
    right: 10px;
    z-index: 0;
  }
  .mobile_menu_open .header__links__2 {
    display: none !important;
  }
}

.gnb .sub-nav__3rd,
.gnb .gnb-thrd-menu {
  display: none;
}

.header__etc-wrap {
  max-width: 1900px;
  margin: 0 auto;
  position: relative;
}

.header__etc {
  position: absolute;
  top: 42px;
  right: 95px;
  z-index: 9999;
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .header__etc {
    top: 35px;
    right: 10px;
  }
  .header__etc > a {
    margin-left: 8px !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__etc {
    display: none;
  }
}

.header__etc > a {
  float: left;
  margin-left: 15px;
  box-sizing: border-box;
}

.header__etc > a:hover, .header__etc > a:focus {
  opacity: 0.6;
}

.header__etc > a:first-child {
  margin-left: 0;
}

.header__logout {
  z-index: 9999;
  height: 38px;
  line-height: 38px;
  color: #fff;
  font-size: 12px;
}

.header__logout:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -879px -1006px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 22px;
  margin-right: 9px;
}

.header__MyPage {
  text-indent: -9999px;
  z-index: 9999;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -176px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.header__Search {
  text-indent: -9999px;
  z-index: 9999;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -264px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.header__Search__tap {
  display: none;
  left: 0;
  top: 120px;
  position: fixed;
  width: 100%;
  height: 100%;
  background: url("../../resources/images/cont/header-search-in-bg.jpg") center top no-repeat rgba(0, 0, 0, 0.6);
}

.header__Search__tap.open {
  display: block;
}

.header__Search__tap .search-input {
  width: calc(100% - 60px);
  position: relative;
}

.header__Search__tap__in {
  border-bottom: 2px solid #fff;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  margin-top: 70px;
  display: flex;
}

.header__Search__tap__in label {
  font-size: 0;
  width: 60px;
  float: left;
}

.header__Search__tap__in label:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -652px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-top: 15px;
  margin-left: 10px;
}

.header__Search__tap__in input {
  height: 70px;
  background-color: transparent;
  border: none;
  padding-left: 10px;
  color: #7c7c7d;
  font-size: 30px;
  width: 100%;
  box-sizing: border-box;
}

.header__Search__tap__close {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -383px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 12px;
  position: absolute;
  right: 40px;
  top: 190px;
  font-size: 0;
}

.header__Search__tap .header__Search__tap__list {
  display: none;
}

.header__Search__tap.focus .header__Search__tap__list {
  display: block;
  position: absolute;
  width: 100%;
  background-color: #fff;
}

.header__Search__tap.focus .header__Search__tap__list li a {
  position: relative;
  display: block;
  width: 100%;
  padding: 8px 20px 8px 40px;
  box-sizing: border-box;
  font-size: 16px;
}

.header__Search__tap.focus .header__Search__tap__list li a:hover, .header__Search__tap.focus .header__Search__tap__list li a:focus {
  background-color: #fafafa;
}

.header__Search__tap.focus .header__Search__tap__list li a:before {
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -9px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -344px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 18px;
}

.header__Search__tap.focus .header__Search__tap__list li a:after {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -9px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -140px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 18px;
}

.ver-left .header .header__Search__tap.open {
  top: 0 !important;
}

.header__eng {
  text-indent: -9999px;
  z-index: 9999;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px 0px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.header__eng:hover, .header__eng:focus {
  text-decoration: underline;
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .header__eng {
    right: 48px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__eng {
    padding: 0 6px;
    color: #000;
    background-image: none !important;
    right: 8px;
    top: 8px;
    border-radius: 10px 10px;
    height: 35px;
    line-height: 35px;
    z-index: 999999;
  }
  .header__eng span {
    display: none;
  }
  .mobile_menu_open .header__eng {
    display: none;
  }
}

.header__kor {
  text-indent: -9999px;
  z-index: 9999;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -88px;
  background-image: url("../images/sprite.png");
  width: 38px;
  min-width: 38px;
  height: 38px;
}

.header__kor:hover, .header__kor:focus {
  text-decoration: underline;
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .header__kor {
    right: 48px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header__kor {
    padding: 0 6px;
    color: #000;
    background-image: none !important;
    right: 8px;
    top: 8px;
    border-radius: 10px 10px;
    height: 35px;
    line-height: 35px;
    z-index: 999999;
  }
  .header__kor span {
    display: none;
  }
  .mobile_menu_open .header__kor {
    display: none;
  }
}

.header__search__open {
  background-image: url("../../resources/images/icons/search-btn2.png");
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .subpage .down-all .header__wrap__h1 h1 a {
    height: auto !important;
  }
}

/*상단배너*/
.top-banner {
  max-height: 140px;
}

.top-banner__in {
  min-height: 30px;
  position: relative;
  text-align: center;
}

.top-banner__in > div:nth-child(1) {
  min-height: 30px;
}

.top-banner__in__link {
  display: block;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  font-size: 17px;
}

.top-banner__in__link img {
  margin: 0 auto;
}

.top-banner__in__link__tit {
  color: #f2cb8e;
  font-weight: normal;
  padding-right: 10px;
}

.top-banner__in img {
  max-width: 100%;
  margin: 0 auto;
}

.top-banner__in p {
  position: absolute;
  top: 0;
  left: -9999px;
  font-size: 0;
  overflow: hidden;
  width: 0;
  height: 0;
}

.top-banner__in .top-banner-close {
  background-color: #2b3353;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 0;
  position: absolute;
  top: 0;
  right: 10px;
  text-align: right;
}

.top-banner__in .top-banner-close a,
.top-banner__in .top-banner-close label {
  vertical-align: middle;
  color: #fff;
  font-size: 14px;
  margin-lefT: 5px;
}

.top-banner__in__slick-pause {
  position: absolute;
  top: 0;
  right: 200px;
  display: block;
  width: 22px;
  height: 22px;
  font-size: 0;
  text-indent: -9999px;
  border-radius: 20px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -953px -1006px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
  vertical-align: top;
}

.top-banner__in__slick-pause:focus {
  background-color: gold;
}

.top-banner .slick-arrow {
  display: none !important;
}

.top-banner .top-banner-nav {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
}

.top-banner .top-banner-nav__in {
  width: 1580px;
  margin: 0 auto;
  position: relative;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .top-banner .top-banner-nav__in {
    width: auto;
  }
}

.top-banner .top-banner-nav__in .slick-dots {
  position: absolute;
  top: 0;
  right: 225px;
}

.top-banner .top-banner-nav__in .slick-dots:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .top-banner .top-banner-nav__in .slick-dots {
  display: inline-block;
}

.top-banner .top-banner-nav__in .slick-dots li {
  float: left;
  margin-right: 5px;
}

.top-banner .top-banner-nav__in .slick-dots li button {
  background-color: transparent;
  display: inline-block;
  color: gold;
  vertical-align: middle;
  overflow: visible;
  border: 3px solid #fff;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 22px;
  font-size: 0;
}

.top-banner .top-banner-nav__in .slick-dots li.slick-active button {
  background-color: #fff;
  border: 3px solid #fff;
}

.top-banner .top-banner-nav__in .slick-dots li button:focus {
  background-color: gold;
}

.top-banner .top-banner-nav__in__prev {
  position: absolute;
  left: 0;
  top: 40px;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px -342px;
  background-image: url("../images/sprite.png");
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.top-banner .top-banner-nav__in__prev:focus {
  opacity: 0.7;
}

.top-banner .top-banner-nav__in__next {
  position: absolute;
  right: 0;
  top: 40px;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px -244px;
  background-image: url("../images/sprite.png");
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.top-banner .top-banner-nav__in__next:focus {
  opacity: 0.7;
}

body .top-banner {
  overflow: hidden;
  height: 0px;
  max-height: 140px;
  position: relative;
  z-index: 9;
}

body.pop-closed .top-banner {
  height: 0 !important;
  min-height: 0;
  overflow: hidden;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  body .top-banner {
    height: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  body .top-banner {
    display: none;
  }
}

/*모바일메뉴*/
.mobile_top_nav {
  display: none;
}

#gnb_nav_mobile {
  height: auto;
  display: none;
}

/*모바일 메뉴 닫기*/
.mobile-close {
  position: fixed;
  left: -30px;
  top: 10px;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.mobile_on_off a {
  display: none;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .mobile_on_off a {
    display: block;
  }
}

.mobile_top_nav.selected .mobile-close {
  opacity: 1;
  left: 285px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .header {
    overflow: visible;
    position: fixed;
    border-bottom: 1px solid #333;
    width: 100%;
    padding-bottom: 0px;
    height: 50px !important;
    background-image: none;
  }
  .header.selected {
    height: 100%;
  }
  .header__wrap__h1 h1 {
    margin-left: 0;
    left: 0;
    position: relative;
    top: 9px;
    width: 100%;
    text-align: center;
    padding-top: 0px;
  }
  .header__wrap__h1 h1 a {
    width: auto;
    height: auto;
    display: inline-block;
    min-width: 0;
    vertical-align: top;
    /*
						@include sprite($title,1.8);

						margin: 0 auto;

						img {
							display: none;
						}*/
  }
  .header__wrap__h1 h1 a img {
    max-height: 28px;
  }
  .ver2 .header__wrap__h1 h1 {
    top: 12px !important;
  }
  .header__wrap .gnb {
    display: none;
  }
  [class*="header__wrap"] {
    height: 50px;
  }
  .header__wrap__h1 h1 a {
    width: auto;
    height: auto;
    background-image: none !important;
    min-width: 100px;
  }
  .header__wrap__h1 h1 a img {
    display: block !important;
    margin: 0 auto;
  }
  /*모바일 메뉴*/
  .mobile_top_nav {
    display: block;
    z-index: 10000;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: 0px;
    width: 100%;
    background-image: url("../../resources/images/basic/opa.png");
    background-repeat: repeat;
    background-position: 0 0;
  }
  .mobile_top_nav .m_s_btn {
    position: absolute;
    top: 8px;
    right: 60px;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 0;
    text-indent: -9999px;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -288.82353px -338.82353px;
    background-image: url("../images/sprite.png");
    background-size: 845.88235px auto;
    width: 32.94118px;
    min-width: 32.94118px;
    height: 32.94118px;
  }
  .mobile_top_nav .m_open_btn {
    position: absolute;
    top: 12px;
    left: 15px;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 0;
    text-indent: -9999px;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -192.5px -288px;
    background-image: url("../images/sprite.png");
    background-size: 719px auto;
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
  .mobile_top_nav__in {
    left: -320px;
    width: 320px;
    position: fixed;
    top: 0;
    height: 100%;
    background-color: #fff;
    transition: all 0.3s ease 0s;
    overflow: auto;
  }
  .mobile_top_nav.selected {
    height: 100%;
    overflow: auto;
    width: 100%;
    left: 0px;
  }
  .mobile_top_nav.selected .m_s_btn {
    display: none;
  }
  .mobile_top_nav.selected .mobile_top_nav__in {
    left: 0;
    box-shadow: 3px 4px 18px -7px rgba(0, 0, 0, 0.75);
  }
  #gnb_nav_mobile {
    opacity: 0;
    position: relative;
    padding-top: 44px;
    z-index: 9999;
    background-color: #fff;
    border-top: 1px solid #d1d1d6;
    background-color: #fff;
  }
  #gnb_nav_mobile li.gnb-menu {
    background-color: #fff;
  }
  #gnb_nav_mobile li.gnb-menu.over {
    background-color: #fff;
  }
  #gnb_nav_mobile li.gnb-menu:first-child {
    border-left: 0px;
  }
  #gnb_nav_mobile li.gnb-menu > a {
    width: auto;
    display: block;
    text-shadow: none;
    padding: 0px 10px 0px 20px;
    height: 38px;
    line-height: 38px;
    font-size: 17px;
    font-weight: 500;
    color: #222;
    text-align: left;
    border-top: 1px solid #d1d1d6;
  }
  #gnb_nav_mobile li.gnb-menu > a > span {
    background-image: none;
  }
  #gnb_nav_mobile .sub-nav__box__ul {
    background-color: #eee;
  }
  #gnb_nav_mobile .sub-nav__box__list a {
    border-top: 1px solid #d1d1d6;
    padding: 0px 10px 0px 25px;
    width: auto;
    height: 38px;
    line-height: 38px;
    font-weight: normal;
    display: block;
    color: #000;
    font-size: 16px;
    text-shadow: 0px 0px 0px;
  }
  #gnb_nav_mobile .sub-nav__box__list a:hover,
  #gnb_nav_mobile .sub-nav__box__list a:focus {
    background-color: #fff;
  }
  /*불켜짐*/
  #gnb_nav_mobile .gnb-menu {
    position: relative;
  }
  #gnb_nav_mobile .gnb-menu:after {
    content: '';
    display: inline-block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -272px -57.5px;
    background-image: url("../images/sprite.png");
    background-size: 719px auto;
    width: 14px;
    min-width: 14px;
    height: 14px;
    position: absolute;
    top: 12px;
    right: 20px;
  }
  #gnb_nav_mobile .gnb-menu.selected:after,
  #gnb_nav_mobile .gnb-menu.on:after {
    content: '';
    display: inline-block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -467.5px -546px;
    background-image: url("../images/sprite.png");
    background-size: 719px auto;
    width: 14px;
    min-width: 14px;
    height: 1px;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  #gnb_nav_mobile .gnb-menu.on > a {
    color: #000;
  }
  #gnb_nav_mobile .gnb-menu.no-has-menu:after {
    display: none;
  }
  #gnb_nav_mobile .sub-nav__box__list.on > a {
    color: #000;
    text-decoration: underline;
  }
  #gnb_nav_mobile .sub-nav__box__list.on > a span {
    color: #000;
    text-decoration: underline;
  }
  /*3rd*/
  #gnb_nav_mobile .sub-nav__3rd {
    position: relative;
  }
  #gnb_nav_mobile .sub-nav__3rd a {
    color: #666;
    border-bottom: 0px;
    font-size: 13px;
    font-weight: normal;
    width: auto;
    display: block;
    text-decoration: none;
    text-align: left;
    padding-left: 35px;
    position: relative;
  }
  #gnb_nav_mobile .sub-nav__3rd a:after {
    content: '';
    display: inline-block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -898px -1242px;
    background-image: url("../images/sprite.png");
    width: 2px;
    min-width: 2px;
    height: 2px;
    position: absolute;
    top: 20px;
    left: 25px;
  }
  #gnb_nav_mobile .sub-nav__3rd li:first-child {
    border-top: 0px;
  }
  /*#gnb_nav_mobile .gnb-menu.nav-item.on .sub-nav{height:auto;		visibility: visible;}*/
  #gnb_nav_mobile .gnb-menu.nav-item.on .sub-nav {
    height: auto;
    visibility: visible;
    display: block;
  }
  /*sldieup*/
  #gnb_nav_mobile .gnb-menu.nav-item .sub-nav {
    display: none;
    height: auto;
    visibility: visible;
  }
  /*sldieup*/
  #gnb_nav_mobile .gnb-menu.nav-item.on > a {
    color: #087f97;
  }
  #gnb_nav_mobile .sub-nav__box__list.on > a span {
    color: #087f97;
  }
  #gnb_nav_mobile .sub-nav__3rd .on a {
    color: #000;
    font-weight: bold;
  }
  #gnb_nav_mobile .sub-nav__3rd a.on {
    color: #000;
    font-weight: bold;
  }
  /*모바일 메뉴 닫기*/
  .mobile-close {
    position: fixed;
    left: -30px;
    top: 9px;
    opacity: 0;
    transition: all 0.3s ease 0s;
  }
  .mobile_menu_open .mobile-close {
    left: 275px;
    z-index: 10000;
    opacity: 1;
  }
  .mobile-close a {
    font-size: 0;
    width: 25px;
    height: 25px;
    text-indent: -9999px;
    display: block;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -298.5px -288px;
    background-image: url("../images/sprite.png");
    background-size: 719px auto;
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
  .mobile_top_nav.selected .mobile-close {
    opacity: 1;
    left: 285px;
  }
  /*gnb 방식변경*/
  .gnb-ul {
    width: 100%;
    display: block;
    padding-right: 0;
  }
  .gnb-menu {
    width: 100%;
    display: block;
    border-right: 0px;
  }
  .gnb-menu > a {
    text-align: left;
    line-height: 30px;
    height: 30px;
    padding-left: 15px;
  }
  .sub-nav {
    width: auto;
    border: 0;
    background-color: #f7f7f7;
    text-align: left;
  }
  .sub-nav__box__list a span {
    color: #666666;
    background-image: none !important;
    font-size: 17px;
  }
  #gnb_nav_mobile li.gnb-menu.selected .sub-nav {
    height: auto;
    visibility: visible;
  }
  #gnb_nav_mobile.selected {
    display: block;
    opacity: 1;
  }
  .gnb.selected {
    display: block;
    opacity: 1;
  }
  .header__links {
    z-index: 99999;
  }
  .header__links__1 {
    width: 250px;
    right: unset;
    left: -250px;
    top: 15px;
    transition: all 0.3s ease 0s;
  }
  .header__links.selected .header__links__1 {
    left: 0;
  }
}

/*메인 */
@media screen and (min-width: 1px) and (max-width: 768px) {
  .mainpage .header__links__1 {
    top: 15px;
  }
  .mainpage .header__wrap__h1 h1 {
    width: auto;
    height: auto;
  }
  .mainpage .header__wrap__h1 h1 a {
    width: auto !important;
    height: auto !important;
    min-width: 10px;
  }
}

/*메인 fullpage 관련*/
@media screen and (min-width: 1px) and (max-width: 768px) {
  .mainpage.fp-viewing-firstPage .header__wrap__h1 h1 a {
    display: block;
    width: auto;
    height: auto;
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 0px -478px;
    background-image: url("../images/sprite.png");
    width: 192px;
    min-width: 192px;
    height: 48px;
  }
  .mainpage.fp-viewing-firstPage .header__wrap__h1 h1 a img {
    display: block;
  }
  .mainpage.fp-viewing-firstPage .selected .header__wrap__h1 h1 a {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 0px -478px;
    background-image: url("../images/sprite.png");
    width: 192px;
    min-width: 192px;
    height: 48px;
  }
  .mainpage.fp-viewing-firstPage .header__links__1__icon1 {
    color: #000;
  }
  .mainpage.fp-viewing-firstPage .header__links__1__icon2 {
    color: #000;
  }
  .mainpage.fp-viewing-firstPage .header__links__1__icon3 {
    color: #000;
  }
  .mainpage.fp-viewing-firstPage .header__links__1__icon3 span {
    display: inline;
  }
}

/*전체메뉴보기*/
.all_menu_wrap__outwrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1900px;
}

.all_menu_wrap__outwrap.selected {
  z-index: 10001;
  display: block !important;
}

.btn_all-wrap {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 999999;
}

.btn_all-wrap .btn_all {
  width: 81px;
  height: 86px;
  display: block;
  display: none;
  text-align: center;
  border-left: 1px solid #ddd;
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .btn_all-wrap .btn_all {
    width: 47px;
  }
}

.btn_all-wrap .btn_all:focus {
  border: 1px solid #000;
  height: 83px;
}

.btn_all-wrap .btn_all span {
  display: block;
  font-size: 0;
  margin: 0 auto;
  margin-top: 32px;
}

.btn_all-wrap .btn_all span.type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -492px -406px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 20px;
}

.btn_all-wrap .btn_all span.type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -340px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: none !important;
}

.btn_all-wrap .btn_all.selected span.type1 {
  display: none !important;
}

.btn_all-wrap .btn_all.selected span.type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -420px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: inline-block !important;
  margin-top: 29px;
}

.subpage .down-all .btn_all-wrap .btn_all span.type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -340px -1092px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 19px;
}

.subpage .down-all .btn_all-wrap .btn_all span.type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -340px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.mainpage .down-all.selected .btn_all-wrap .btn_all span.type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -340px -1092px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 19px;
}

.mainpage .down-all.selected .btn_all-wrap .btn_all span.type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -340px;
  background-image: url("../images/sprite.png");
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.btn_all-wrap .btn_all:hover,
.btn_all-wrap .btn_all:focus {
  opacity: 0.8;
}

.all_menu_wrap {
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  z-index: 9999;
  position: absolute;
  top: 120px;
  left: 0px;
  box-shadow: 4px 3px 6px -3px rgba(0, 0, 0, 0.75);
  /*
	height:0;
	overflow:hidden;
	visibility: hidden;*/
  opacity: 0;
  transition: all 0.7s ease 0s;
  display: none;
}

.all_menu_wrap__h2 {
  height: 110px;
  background-color: #0068b7;
  display: none;
}

.all_menu_wrap__outwrap.selected .all_menu_wrap {
  display: block;
  height: auto;
  opacity: 1;
  visibility: visible;
  display: block;
}

.all_menu_in {
  position: relative;
  padding: 20px;
  padding-top: 40px;
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
}

.all_menu_wrap__outwrap.selected .all_menu_in .gnb-menu {
  border-right: 0;
}

.all_menu_in .gnb-ul {
  padding-right: 0;
  margin-left: -20px;
}

.all_menu_in .gnb-menu {
  float: left;
}

.all_menu_in .gnb-menu > a {
  display: block;
  color: #2595ac;
  font-size: 20px;
  height: 48px;
  line-height: 48px;
  border-bottom: 2px solid #48b0c5;
  margin-left: 20px;
  text-align: left;
  padding-left: 20px;
  font-weight: normal;
  background-color: #effbfa;
  background-position: right bottom;
  background-repeat: no-repeat;
  font-family: "rokg";
}

.all_menu_in .all_menu_close {
  color: #333;
  display: block;
  font-size: 0;
  position: absolute;
  right: 20px;
  top: -85px;
  z-index: 9999;
  width: 30px;
  height: 30px;
}

.all_menu_in .sub-nav {
  height: auto;
  visibility: visible;
}

.all_menu_in .sub-nav__box__ul {
  margin-left: 20px;
  padding-top: 20px;
}

.all_menu_in .sub-nav__box__list > a {
  display: block;
  min-height: 30px;
  line-height: 30px;
  padding: 0 0 6px 20px;
  color: #000;
  font-size: 15px;
  position: relative;
  font-family: "Nanum Gothic";
}

.all_menu_in .sub-nav__box__list > a:before {
  position: absolute;
  top: 14px;
  left: 8px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -446px -1310px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.all_menu_in .sub-nav__box__list > a:hover,
.all_menu_in .sub-nav__box__list > a:focus {
  opacity: 0.8;
  text-decoration: underline;
}

.all_menu_in .gnb-ul {
  display: table;
  width: 100%;
}

.all_menu_in .gnb-menu {
  min-width: 12%;
  margin-bottom: 20px;
}

.all_menu_in .gnb-menu:first-child {
  border-left: 0px;
}

.all_menu_in .sub-nav__3rd {
  text-align: left;
  padding-left: 20px;
  padding-top: 0px;
  padding-bottom: 10px;
}

.all_menu_in .sub-nav__3rd__ul__li a,
.all_menu_in .gnb-thrd-menu__item {
  display: block;
  margin-bottom: 12px;
  padding-left: 13px;
  color: #666;
  position: relative;
}

.all_menu_in .sub-nav__3rd__ul__li a:hover, .all_menu_in .sub-nav__3rd__ul__li a:focus,
.all_menu_in .gnb-thrd-menu__item:hover,
.all_menu_in .gnb-thrd-menu__item:focus {
  text-decoration: underline;
}

.all_menu_in .sub-nav__3rd__ul__li a:before,
.all_menu_in .gnb-thrd-menu__item:before {
  position: absolute;
  top: 8px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -735px -1242px;
  background-image: url("../images/sprite.png");
  width: 5px;
  min-width: 5px;
  height: 2px;
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .all_menu_wrap__outwrap {
    width: 100%;
    top: -0;
  }
  .all_menu_in {
    width: auto;
  }
  .all_menu_in .gnb-menu > a {
    background-image: none !important;
  }
  .btn_all-wrap .btn_all {
    border-left: 0px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .all_menu_wrap__outwrap {
    display: none !important;
  }
  .all_menu_wrap__outwrap.selected .all_menu_wrap {
    display: none !important;
  }
}

/*	메인페이지
===========================*/
@font-face {
  font-family: 'GongGothicL';
  src: url("GongGothic_Light.eot");
  src: url("../../resources/fonts/GongGothic_Light.eot?#iefix") format("embedded-opentype"), url("../../resources/fonts/GongGothic_Light.woff") format("woff"), url("../../resources/fonts/GongGothic_Light.woff2") format("woff2"), url("../../resources/fonts/GongGothic_Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GongGothicM';
  src: url("GongGothic_Medium.eot");
  src: url("../../resources/fonts/GongGothic_Medium.eot?#iefix") format("embedded-opentype"), url("../../resources/fonts/GongGothic_Medium.woff2") format("woff"), url("../../resources/fonts/GongGothic_Medium.woff2") format("woff2"), url("../../resources/fonts/GongGothic_Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GongGothicB';
  src: url("GongGothic_Bold.eot");
  src: url("../../resources/fonts/GongGothic_Bold.eot?#iefix") format("embedded-opentype"), url("../../resources/fonts/GongGothic_Bold.woff") format("woff"), url("../../resources/fonts/GongGothic_Bold.woff2") format("woff2"), url("../../resources/fonts/GongGothic_Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url("../../resources/fonts/Pretendard-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

/*메인첫화면 로딩아이콘*/
div.loading-box {
  height: 80px;
  width: 80px;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translateX(-50%);
}

div.loading-box p {
  color: #fff;
  text-align: center;
}

body.loading .loading-box {
  display: block;
}

.resol {
  display: none;
}

/*메인 레이아웃*/
.main-section {
  position: relative;
  background-color: #ffffff;
  font-family: 'Pretendard-Regular';
  margin-top: -120px;
  z-index: 0;
}

@media screen and (min-width: 769px) and (max-width: 1580px) {
  .main-section {
    margin-top: -141px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-section {
    margin-top: 0;
    padding-top: 51px;
  }
}

.popupzone2 {
  position: relative;
  margin-top: 0px;
  width: 100%;
  height: auto;
  padding-left: 700px;
  box-sizing: border-box;
}

.popupzone2 .slick-arrow {
  border: 0px;
  top: 220px;
}

.popupzone2 .slick-prev {
  position: absolute;
  left: 10px;
  z-index: 1;
}

.popupzone2 .slick-next {
  position: absolute;
  right: 10px;
  z-index: 1;
}

.popupzone2 .popupzone-slide-first a {
  display: block;
}

.popupzone2 .popupzone-slide-first a:focus {
  opacity: 1;
}

.popupzone2 .popupzone2-nav {
  bottom: -100px;
  left: 0;
  text-align: center;
  top: unset;
}

.popupzone-slide-big {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 760px;
}

.popupzone-slide-big .slick-next {
  display: none !important;
}

.popupzone-slide-big .popupzone2-list__item {
  /* Shine */
}

.popupzone-slide-big .popupzone2-list__item > a {
  position: relative;
  box-sizing: border-box;
  width: 760px;
  background-color: gold;
  height: 520px;
  display: block;
  padding: 50px;
  font-size: 30px;
  box-sizing: border-box;
}

.popupzone-slide-big .popupzone2-list__item__btn1 {
  position: absolute;
  bottom: 50px;
  color: #000;
  height: 54px;
  line-height: 54px;
  font-size: 16px;
  display: inline-block;
  padding-left: 20px;
  padding-right: 60px;
  background: url(../../resources/images/icons/main-way-go-img1.png) 86% center no-repeat #fff;
}

.popupzone-slide-big .popupzone2-list__item__btn2 {
  position: absolute;
  bottom: 50px;
  color: #000;
  height: 54px;
  line-height: 54px;
  font-size: 16px;
  display: inline-block;
  padding-left: 20px;
  padding-right: 60px;
  background: url(../../resources/images/icons/main-way-go-img3.png) 86% center no-repeat #fff;
}

.popupzone-slide-big .popupzone2-list__item > a {
  position: relative;
}

.popupzone-slide-big .popupzone2-list__item > a::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
  opacity: 0;
}

.popupzone-slide-big .popupzone2-list__item > a:hover::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
  width: 50%;
  height: 100%;
  opacity: 1;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.popupzone-slide-big h4 {
  font-size: 40px;
  color: #fff;
  font-family: 'GongGothicM';
}

.popupzone-slide-big p {
  font-size: 20px;
  color: #fff;
  margin-top: 15px;
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .popupzone-slide-big p {
    font-size: 16px;
  }
  .popupzone-slide-big h4 {
    font-size: 24px;
  }
  .popupzone-slide-big .popupzone2-list__item__btn1, .popupzone-slide-big .popupzone2-list__item__btn2 {
    font-size: 14px;
    line-height: 40px;
    height: 40px;
  }
  .main-visual .slick-next, .main-visual .slick-prev {
    background-size: 16px;
  }
  .popupzone2-nav > div > ul {
    display: inline-block !important;
  }
}

.popupzone-slide-first {
  margin-left: -320px;
}

.popupzone-slide-first .popupzone2-list__item {
  transition: all 0.3s ease 0s;
  margin-left: 30px;
}

.popupzone-slide-first .popupzone2-list__item a {
  padding-top: 50px;
  padding-left: 40px;
  width: 380px;
  pointer-events: none;
  cursor: default;
  display: block;
  height: 520px;
  background-position: right;
  background-color: gold;
  transition: all 0.3s ease 0s;
  box-sizing: border-box;
  font-size: 30px;
}

.popupzone-slide-first .popupzone2-list__item a h4 {
  font-size: 30px;
  color: #fff;
  font-family: 'GongGothicM';
}

.popupzone-slide-first .popupzone2-list__item p, .popupzone-slide-first .popupzone2-list__item__btn1, .popupzone-slide-first .popupzone2-list__item__btn2 {
  display: none;
}

.popupzone-slide-first .popupzone2-list__item img {
  object-fit: cover;
  border-radius: 0px;
  overflow: hidden;
  margin: 0 auto;
}

.popupzone-slide-first .popupzone2-list__item.slick-current a {
  opacity: 1;
}

.popupzone2-nav {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0px;
  box-sizing: border-box;
  z-index: 3;
  width: 100%;
  display: block;
}

.popupzone2-nav.off {
  display: none;
}

.popupzone2-nav > div {
  background-color: rgba(0, 0, 0, 0.8);
  display: inline-block;
  text-align: center;
  margin-top: 0;
  z-index: 999;
  margin-top: -44px;
}

.popupzone2-nav > div > div {
  padding-left: 15px;
  display: inline-block;
}

* + html .popupzone2-nav > div > div {
  display: inline;
}

.popupzone2-nav > div > ul {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

* + html .popupzone2-nav > div > ul {
  display: inline;
}

.popupzone2-nav > div > ul li {
  display: inline-block;
  vertical-align: middle;
}

* + html .popupzone2-nav > div > ul li {
  display: inline;
}

.popupzone2-nav > div > ul li a {
  color: gold;
  width: 50px;
  height: 50px;
}

.popupzone2-nav > div > ul li .slide-pause-item {
  border-right: 1px solid #fff;
  background: url(../../resources/images/icons/popup-pause.png) center center no-repeat;
  display: block;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  margin-left: 1px;
  vertical-align: top;
}

.popupzone2-nav > div > ul li .slide-pause-item:focus {
  border: 1px solid #fff;
  border-radius: 2px;
}

.popupzone2-nav > div > ul li .slide-play-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
}

.popupzone2-nav > div > ul li .slide-play-item:focus {
  border: 1px solid #fff;
  border-radius: 2px;
}

.popupzone2-nav > div > ul li .slide-more-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone2-nav > div > ul li .slide-more-item:focus {
  background-color: gold;
  border-radius: 100%;
}

.popupzone2-nav > div > ul li .slide-next-item {
  background: url(../../resources/images/icons/popup-next.png) center center no-repeat;
  display: block;
  font-size: 0;
  border-right: 1px solid #fff;
  margin-left: 1px;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone2-nav > div > ul li .slide-next-item:focus {
  border: 1px solid #fff;
  border-radius: 2px;
}

.popupzone2-nav > div > ul li .slide-prev-item {
  border-right: 1px solid #fff;
  background: url(../../resources/images/icons/popup-prev.png) center center no-repeat;
  display: block;
  font-size: 0;
  margin-left: 1px;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone2-nav > div > ul li .slide-prev-item:focus {
  border: 1px solid #fff;
  border-radius: 2px;
}

.popupzone2-nav .slick-dots-list {
  display: inline-block !important;
  vertical-align: middle;
}

* + html .popupzone2-nav .slick-dots-list {
  display: inline !important;
}

.popupzone2-nav .slick-dots-list li {
  display: inline-block;
  margin-right: 12px;
}

.popupzone2-nav .slick-dots-list li button {
  display: inline-block;
  color: #e19026;
  vertical-align: middle;
  background-color: #d3d3d3;
  overflow: visible;
  width: 17px;
  height: 17px;
  line-height: 15px;
  border-radius: 15px;
  font-size: 0;
}

.popupzone2-nav .slick-dots-list li.slick-active button {
  background-color: transparent;
  border: 2px solid #fff;
  width: 17px;
  height: 17px;
}

.popupzone2-nav .slick-dots-list li button:focus {
  background-color: #e19026;
}

.popupzone2-nav * + html .slick-dots-list li {
  display: inline;
}

.popupzone2-nav * + html .slick-dots-list li button {
  display: inline;
}

.popupzone2-nav .popup-total {
  display: none;
}

.ver-total .popupzone2-nav .slick-dots-list {
  font-size: 0;
  margin-top: 3px;
}

.ver-total .popupzone2-nav .slick-dots-list li {
  margin-right: 0;
}

.ver-total .popupzone2-nav .slick-dots-list button {
  font-size: 15px;
  text-indent: 0;
  background-image: none;
  display: none;
}

.ver-total .popupzone2-nav .slick-dots-list .slick-active button {
  background-image: none;
  display: block;
  width: auto;
  height: 30px;
  text-align: center;
  color: #fff;
  background-color: transparent;
  font-weight: bold;
  border-color: transparent;
  margin-bottom: 1px;
}

.ver-total .popupzone2-nav .slick-dots-list .slick-active button:focus {
  border: 2px solid #000;
}

.ver-total .popupzone2-nav .popup-total {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  margin-top: 1px;
}

.ver-total .popupzone2-nav * + html .popup-total {
  display: inline;
}

.ver-total .popupzone2-nav .popup-total-num {
  padding-left: 4px;
  padding-right: 18px;
}

.popupzone2-nav .nav-arrow {
  display: none !important;
}

.ver-arrow .popupzone2-nav .nav-arrow {
  display: block !important;
}

.ver-arrow .popupzone2-nav .slide-play-item {
  display: none;
}

* + html .popupzone2-nav.ver-total .popup-total {
  display: none !important;
}

* + html .popupzone2-nav.ver-total .slick-dots-list {
  display: none !important;
}

.popupzone2-nav > div {
  background: none;
}

.popupzone2-nav > div > ul li .slide-pause-item {
  background: url(../../resources/images/icons/main-visual-pause.png) center center no-repeat;
  border-right: 0;
  transition: all 0.3s ease 0s;
}

.popupzone2-nav .slick-dots-list li button {
  width: 10px;
  height: 10px;
  background-color: #fff;
}

.popupzone2-nav .slick-dots-list li.slick-active button {
  position: relative;
  width: 24px;
  height: 24px;
  border: 3px solid #fff;
  transition: all 0.3s ease 0s;
}

.popupzone2-nav .slick-dots-list li.slick-active button:before {
  background-color: #fff;
  border-radius: 100px;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  left: 50%;
  content: '';
  margin-left: -3px;
  display: inline-block;
}

.popupzone2-nav > div > ul li a {
  width: 20px;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .popupzone2 .popupzone-list__item img {
    height: auto;
    object-fit: unset;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone2 {
    max-width: 768px;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .popupzone2 .popupzone-list__item img {
    height: auto !important;
  }
  .popupzone2-nav {
    top: 9px;
    right: 0px;
    padding-right: 0;
  }
}

@media screen and (min-height: 1px) and (max-height: 930px) {
  .popupzone2-nav > div > ul {
    display: none;
  }
  .popupzone2-nav {
    top: 15px;
    right: 10px;
  }
  .popupzone2__tit {
    display: none;
  }
}

.lte-ie9 .popupzone2 .popupzone-list__item {
  height: 340px;
  overflow: hidden;
}

.lte-ie9 .popupzone2 .popupzone-list__item img {
  height: auto;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .lte-ie9 .popupzone2 .popupzone-list__item {
    height: 300px;
    overflow: hidden;
  }
  .lte-ie9 .popupzone2 .popupzone-list__item img {
    height: 300px;
    object-fit: cover;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .lte-ie9 .popupzone2 .popupzone-list__item {
    height: auto;
    overflow: hidden;
  }
  .lte-ie9 .popupzone2 .popupzone-list__item img {
    height: auto;
  }
}

/*판교*/
.main-section {
  position: relative;
  font-family: "Pretendard-Regular", "Nanum Gothic", sans-serif;
}

.main-section__in {
  position: relative;
  min-height: 500px;
  background-color: #fff;
}

.main-section__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .main-section__in {
  display: inline-block;
}

.main-lay-1 {
  max-width: 1200px;
  width: 100%;
  padding-top: 160px;
  padding-bottom: 150px;
  margin: 0 auto;
}

.main-lay-1__in {
  display: flex;
  justify-content: space-between;
}

.main-lay-2 {
  background: url(../../resources/images/temp/main-project-bg.jpg);
  display: inline-block;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 110px;
  min-height: 290px;
}

.main-lay-3 {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-top: 150px;
  padding-bottom: 180px;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .main-section__in {
    width: auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-section {
    padding-top: 51px;
  }
  .main-lay-1, .main-lay-2, .main-lay-3 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

/*팝업존*/
.popupzone {
  position: relative;
  margin-top: 0px;
  width: calc(39.2% - 20px);
  height: auto;
  box-sizing: border-box;
}

.popupzone .slick-arrow {
  border: 0px;
  top: 220px;
}

.popupzone .slick-prev {
  position: absolute;
  left: 10px;
  z-index: 1;
  display: none !important;
}

.popupzone .slick-next {
  position: absolute;
  right: 10px;
  z-index: 1;
  display: none !important;
}

.popupzone .slick-slide a:hover,
.popupzone .slick-slide a:focus {
  opacity: 0.8;
}

.popupzone__tit {
  font-size: 40px;
  font-family: 'GongGothicM';
  margin-bottom: 30px;
}

.popupzone__item > img {
  width: 100%;
}

.popupzone .popupzone-nav {
  bottom: 4px;
}

.popupzone-nav {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0px;
  box-sizing: border-box;
  z-index: 3;
  width: 100%;
  display: block;
}

.popupzone-nav.off {
  display: none;
}

.popupzone-nav > div {
  background-color: rgba(0, 0, 0, 0.8);
  display: inline-block;
  text-align: center;
  margin-top: 0;
  z-index: 999;
  margin-top: -44px;
}

.popupzone-nav > div > div {
  padding-left: 15px;
  display: inline-block;
}

* + html .popupzone-nav > div > div {
  display: inline;
}

.popupzone-nav > div > ul {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

* + html .popupzone-nav > div > ul {
  display: inline;
}

.popupzone-nav > div > ul li {
  display: inline-block;
  vertical-align: middle;
}

* + html .popupzone-nav > div > ul li {
  display: inline;
}

.popupzone-nav > div > ul li a {
  color: gold;
  width: 50px;
  height: 50px;
}

.popupzone-nav > div > ul li .slide-pause-item {
  border-right: 1px solid #fff;
  background: url(../../resources/images/icons/popup-pause.png) center center no-repeat;
  display: block;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  margin-left: 1px;
  vertical-align: top;
}

.popupzone-nav > div > ul li .slide-pause-item:focus {
  border: 1px solid #fff;
  border-radius: 2px;
}

.popupzone-nav > div > ul li .slide-play-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
}

.popupzone-nav > div > ul li .slide-play-item:focus {
  border: 1px solid #fff;
  border-radius: 2px;
}

.popupzone-nav > div > ul li .slide-more-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone-nav > div > ul li .slide-more-item:focus {
  background-color: gold;
  border-radius: 100%;
}

.popupzone-nav > div > ul li .slide-next-item {
  background: url(../../resources/images/icons/popup-next.png) center center no-repeat;
  display: block;
  font-size: 0;
  border-right: 1px solid #fff;
  margin-left: 1px;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone-nav > div > ul li .slide-next-item:focus {
  border: 1px solid #fff;
  border-radius: 2px;
}

.popupzone-nav > div > ul li .slide-prev-item {
  border-right: 1px solid #fff;
  background: url(../../resources/images/icons/popup-prev.png) center center no-repeat;
  display: block;
  font-size: 0;
  margin-left: 1px;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone-nav > div > ul li .slide-prev-item:focus {
  border: 1px solid #fff;
  border-radius: 2px;
}

.popupzone-nav .slick-dots-list {
  display: inline-block !important;
  vertical-align: middle;
}

* + html .popupzone-nav .slick-dots-list {
  display: inline !important;
}

.popupzone-nav .slick-dots-list li {
  display: inline-block;
  margin-right: 12px;
}

.popupzone-nav .slick-dots-list li button {
  display: inline-block;
  color: #e19026;
  vertical-align: middle;
  background-color: #d3d3d3;
  overflow: visible;
  width: 17px;
  height: 17px;
  line-height: 15px;
  border-radius: 15px;
  font-size: 0;
}

.popupzone-nav .slick-dots-list li.slick-active button {
  background-color: transparent;
  border: 2px solid #fff;
  width: 17px;
  height: 17px;
}

.popupzone-nav .slick-dots-list li button:focus {
  background-color: #e19026;
}

.popupzone-nav * + html .slick-dots-list li {
  display: inline;
}

.popupzone-nav * + html .slick-dots-list li button {
  display: inline;
}

.popupzone-nav .popup-total {
  display: none;
}

.ver-total .popupzone-nav .slick-dots-list {
  font-size: 0;
  margin-top: 3px;
}

.ver-total .popupzone-nav .slick-dots-list li {
  margin-right: 0;
}

.ver-total .popupzone-nav .slick-dots-list button {
  font-size: 15px;
  text-indent: 0;
  background-image: none;
  display: none;
}

.ver-total .popupzone-nav .slick-dots-list .slick-active button {
  background-image: none;
  display: block;
  width: auto;
  height: 30px;
  text-align: center;
  color: #fff;
  background-color: transparent;
  font-weight: bold;
  border-color: transparent;
  margin-bottom: 1px;
}

.ver-total .popupzone-nav .slick-dots-list .slick-active button:focus {
  border: 2px solid #000;
}

.ver-total .popupzone-nav .popup-total {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  margin-top: 1px;
}

.ver-total .popupzone-nav * + html .popup-total {
  display: inline;
}

.ver-total .popupzone-nav .popup-total-num {
  padding-left: 4px;
  padding-right: 18px;
}

.popupzone-nav .nav-arrow {
  display: none !important;
}

.ver-arrow .popupzone-nav .nav-arrow {
  display: block !important;
}

.ver-arrow .popupzone-nav .slide-play-item {
  display: none;
}

* + html .popupzone-nav.ver-total .popup-total {
  display: none !important;
}

* + html .popupzone-nav.ver-total .slick-dots-list {
  display: none !important;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .popupzone .popupzone-list__item img {
    object-fit: cover;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .popupzone {
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 0;
    margin-top: 30px;
  }
  .popupzone .popupzone-list__item img {
    height: auto;
    width: 100%;
  }
}

.lte-ie9 .popupzone .popupzone-list__item {
  height: 340px;
  overflow: hidden;
}

.lte-ie9 .popupzone .popupzone-list__item img {
  height: auto;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .lte-ie9 .popupzone .popupzone-list__item {
    height: 300px;
    overflow: hidden;
  }
  .lte-ie9 .popupzone .popupzone-list__item img {
    height: 300px;
    object-fit: cover;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .lte-ie9 .popupzone .popupzone-list__item {
    height: auto;
    overflow: hidden;
  }
  .lte-ie9 .popupzone .popupzone-list__item img {
    height: auto;
  }
}

/*팝업존2*/
.popupzone-nav2 {
  position: absolute;
  top: 260px;
  left: 0;
  box-sizing: border-box;
  z-index: 3;
  width: 100%;
  display: block;
}

.popupzone-nav2.off {
  display: none;
}

.popupzone-nav2 > div {
  display: inline-block;
  text-align: center;
  margin-top: 0;
  z-index: 999;
}

.popupzone-nav2 > div > div {
  display: none;
}

* + html .popupzone-nav2 > div > div {
  display: inline;
}

.popupzone-nav2 > div > ul {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

* + html .popupzone-nav2 > div > ul {
  display: inline;
}

.popupzone-nav2 > div > ul li {
  display: inline-block;
  vertical-align: middle;
}

* + html .popupzone-nav2 > div > ul li {
  display: inline;
}

.popupzone-nav2 > div > ul li a {
  width: 54px;
  height: 54px;
  border: 1px solid #fff;
}

.popupzone-nav2 > div > ul li .slide-pause-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone-nav2 > div > ul li .slide-pause-item:focus {
  background-color: gold;
}

.popupzone-nav2 > div > ul li .slide-play-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  background: url(../../resources/images/icons/popup-play.png) center center no-repeat;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone-nav2 > div > ul li .slide-play-item:focus {
  background-color: gold;
}

.popupzone-nav2 > div > ul li .slide-more-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  margin-top: 0px;
  background: url(../../resources/images/icons/popup-more.png) center center no-repeat;
  vertical-align: top;
}

.popupzone-nav2 > div > ul li .slide-more-item:focus {
  background-color: gold;
}

.popupzone-nav2 > div > ul li .slide-next-item {
  display: block;
  font-size: 0;
  margin-right: 5px;
  text-indent: -9999px;
  position: relative;
  background: url(../../resources/images/icons/popup-next.png) center center no-repeat;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone-nav2 > div > ul li .slide-next-item:focus {
  background-color: gold;
}

.popupzone-nav2 > div > ul li .slide-prev-item {
  display: block;
  font-size: 0;
  margin-right: 5px;
  text-indent: -9999px;
  background: url(../../resources/images/icons/popup-prev.png) center center no-repeat;
  position: relative;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone-nav2 > div > ul li .slide-prev-item:focus {
  background-color: gold;
}

.popupzone-nav2 .slick-dots-list {
  display: inline-block !important;
  vertical-align: middle;
}

* + html .popupzone-nav2 .slick-dots-list {
  display: inline !important;
}

.popupzone-nav2 .slick-dots-list li {
  display: inline-block;
  margin-right: 12px;
}

.popupzone-nav2 .slick-dots-list li button {
  display: inline-block;
  color: #e19026;
  vertical-align: middle;
  background-color: #d3d3d3;
  overflow: visible;
  width: 17px;
  height: 17px;
  line-height: 15px;
  border-radius: 15px;
  font-size: 0;
}

.popupzone-nav2 .slick-dots-list li.slick-active button {
  background-color: transparent;
  border: 2px solid #fff;
  width: 17px;
  height: 17px;
}

.popupzone-nav2 .slick-dots-list li button:focus {
  background-color: #e19026;
}

.popupzone-nav2 * + html .slick-dots-list li {
  display: inline;
}

.popupzone-nav2 * + html .slick-dots-list li button {
  display: inline;
}

.popupzone-nav2 .popup-total {
  display: none;
}

.ver-total .popupzone-nav2 .slick-dots-list {
  font-size: 0;
  margin-top: 3px;
}

.ver-total .popupzone-nav2 .slick-dots-list li {
  margin-right: 0;
}

.ver-total .popupzone-nav2 .slick-dots-list button {
  font-size: 15px;
  text-indent: 0;
  background-image: none;
  display: none;
}

.ver-total .popupzone-nav2 .slick-dots-list .slick-active button {
  background-image: none;
  display: block;
  width: auto;
  height: 30px;
  text-align: center;
  color: #fff;
  background-color: transparent;
  font-weight: bold;
  border-color: transparent;
  margin-bottom: 1px;
}

.ver-total .popupzone-nav2 .slick-dots-list .slick-active button:focus {
  border: 2px solid #000;
}

.ver-total .popupzone-nav2 .popup-total {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  margin-top: 1px;
}

.ver-total .popupzone-nav2 * + html .popup-total {
  display: inline;
}

.ver-total .popupzone-nav2 .popup-total-num {
  padding-left: 4px;
}

.popupzone-nav2 .nav-arrow {
  display: none !important;
}

.ver-arrow .popupzone-nav2 .nav-arrow {
  display: block !important;
}

.ver-arrow .popupzone-nav2 .slide-play-item {
  display: none;
}

* + html .popupzone-nav2.ver-total .popup-total {
  display: none !important;
}

* + html .popupzone-nav2.ver-total .slick-dots-list {
  display: none !important;
}

.popupzone-nav3 {
  position: absolute;
  top: 260px;
  left: 0;
  box-sizing: border-box;
  z-index: 3;
  width: 100%;
  display: block;
}

.popupzone-nav3.off {
  display: none;
}

.popupzone-nav3 > div {
  display: inline-block;
  text-align: center;
  margin-top: 0;
  z-index: 999;
}

.popupzone-nav3 > div > div {
  display: none;
}

* + html .popupzone-nav3 > div > div {
  display: inline;
}

.popupzone-nav3 > div > ul {
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
}

* + html .popupzone-nav3 > div > ul {
  display: inline;
}

.popupzone-nav3 > div > ul li {
  display: inline-block;
  vertical-align: middle;
}

* + html .popupzone-nav3 > div > ul li {
  display: inline;
}

.popupzone-nav3 > div > ul li a {
  width: 54px;
  height: 54px;
  border: 1px solid #fff;
}

.popupzone-nav3 > div > ul li .slide-pause-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  position: relative;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone-nav3 > div > ul li .slide-pause-item:focus {
  background-color: gold;
}

.popupzone-nav3 > div > ul li .slide-play-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  background: url(../../resources/images/icons/popup-play.png) center center no-repeat;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone-nav3 > div > ul li .slide-play-item:focus {
  background-color: gold;
}

.popupzone-nav3 > div > ul li .slide-more-item {
  display: block;
  font-size: 0;
  text-indent: -9999px;
  margin-top: 0px;
  background: url(../../resources/images/icons/popup-more.png) center center no-repeat;
  vertical-align: top;
}

.popupzone-nav3 > div > ul li .slide-more-item:focus {
  background-color: gold;
}

.popupzone-nav3 > div > ul li .slide-next-item {
  display: block;
  font-size: 0;
  margin-right: 5px;
  text-indent: -9999px;
  position: relative;
  background: url(../../resources/images/icons/popup-next.png) center center no-repeat;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone-nav3 > div > ul li .slide-next-item:focus {
  background-color: gold;
}

.popupzone-nav3 > div > ul li .slide-prev-item {
  display: block;
  font-size: 0;
  margin-right: 5px;
  text-indent: -9999px;
  background: url(../../resources/images/icons/popup-prev.png) center center no-repeat;
  position: relative;
  margin-top: 0px;
  vertical-align: top;
}

.popupzone-nav3 > div > ul li .slide-prev-item:focus {
  background-color: gold;
}

.popupzone-nav3 .slick-dots-list {
  display: inline-block !important;
  vertical-align: middle;
}

* + html .popupzone-nav3 .slick-dots-list {
  display: inline !important;
}

.popupzone-nav3 .slick-dots-list li {
  display: inline-block;
  margin-right: 12px;
}

.popupzone-nav3 .slick-dots-list li button {
  display: inline-block;
  color: #e19026;
  vertical-align: middle;
  background-color: #d3d3d3;
  overflow: visible;
  width: 17px;
  height: 17px;
  line-height: 15px;
  border-radius: 15px;
  font-size: 0;
}

.popupzone-nav3 .slick-dots-list li.slick-active button {
  background-color: transparent;
  border: 2px solid #fff;
  width: 17px;
  height: 17px;
}

.popupzone-nav3 .slick-dots-list li button:focus {
  background-color: #e19026;
}

.popupzone-nav3 * + html .slick-dots-list li {
  display: inline;
}

.popupzone-nav3 * + html .slick-dots-list li button {
  display: inline;
}

.popupzone-nav3 .popup-total {
  display: none;
}

.ver-total .popupzone-nav3 .slick-dots-list {
  font-size: 0;
  margin-top: 3px;
}

.ver-total .popupzone-nav3 .slick-dots-list li {
  margin-right: 0;
}

.ver-total .popupzone-nav3 .slick-dots-list button {
  font-size: 15px;
  text-indent: 0;
  background-image: none;
  display: none;
}

.ver-total .popupzone-nav3 .slick-dots-list .slick-active button {
  background-image: none;
  display: block;
  width: auto;
  height: 30px;
  text-align: center;
  color: #fff;
  background-color: transparent;
  font-weight: bold;
  border-color: transparent;
  margin-bottom: 1px;
}

.ver-total .popupzone-nav3 .slick-dots-list .slick-active button:focus {
  border: 2px solid #000;
}

.ver-total .popupzone-nav3 .popup-total {
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  margin-top: 1px;
}

.ver-total .popupzone-nav3 * + html .popup-total {
  display: inline;
}

.ver-total .popupzone-nav3 .popup-total-num {
  padding-left: 4px;
}

.popupzone-nav3 .nav-arrow {
  display: none !important;
}

.ver-arrow .popupzone-nav3 .nav-arrow {
  display: block !important;
}

.ver-arrow .popupzone-nav3 .slide-play-item {
  display: none;
}

* + html .popupzone-nav3.ver-total .popup-total {
  display: none !important;
}

* + html .popupzone-nav3.ver-total .slick-dots-list {
  display: none !important;
}

.popupzone-nav3 > div > ul li a {
  border: 1px solid #000;
}

.popupzone-nav3 > div > ul li .slide-prev-item {
  background: url("../../resources/images/icons/popup-prev2.png") center center no-repeat;
}

.popupzone-nav3 > div > ul li .slide-next-item {
  background: url("../../resources/images/icons/popup-next2.png") center center no-repeat;
}

.popupzone-nav3 > div > ul li .slide-more-item {
  background: url("../../resources/images/icons/popup-more2.png") center center no-repeat;
}

/*NEWS*/
.news {
  width: calc(60.8% - 20px);
  box-sizing: border-box;
  position: relative;
  margin-right: 20px;
  box-sizing: border-box;
}

.news__tit {
  font-size: 40px;
  font-family: 'GongGothicM';
  margin-bottom: 30px;
}

.news__ul {
  border-top: 2px solid #000;
}

.news__ul__li {
  border-bottom: 1px solid #cccccc;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 19px;
  padding-bottom: 19px;
}

.news__ul__li .cont {
  width: 100%;
  font-size: 20px;
  color: #111;
  font-weight: 500;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.news__ul__li .date {
  font-size: 16px;
  color: #777777;
  margin-top: 10px;
}

.news .popupzone-slide4 .slick-arrow {
  display: none !important;
}

.news .popupzone-nav {
  bottom: 4px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .main-lay-1__in {
    padding-left: 1%;
    padding-right: 1%;
  }
  .news__ul__li {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .news__ul__li .cont {
    font-size: 16px;
  }
  .news__tit {
    font-size: 34px;
  }
  .popupzone__tit {
    font-size: 34px;
    margin-top: 0;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-lay-1__in {
    display: block;
  }
  .news {
    width: 100%;
  }
  .popupzone {
    width: 100%;
  }
  .popupzone__tit {
    margin-top: 50px;
  }
}

/*popupzone*/
/*시설안내 아카이브*/
.shortcut {
  margin-top: 140px;
}

.shortcut__ul {
  display: flex;
  gap: 40px;
  text-align: center;
  color: #fff;
  align-items: center;
}

.shortcut__ul__li {
  flex: 1;
  min-height: 580px;
  display: grid;
  align-items: center;
  transition: all 0.3s ease 0s;
  transform: scale(0.97);
}

.shortcut__ul__li:first-child {
  background: url(../../resources/images/temp/main-baro-go-img1.jpg) center center no-repeat;
  background-size: cover;
}

.shortcut__ul__li:last-child {
  background: url(../../resources/images/temp/main-baro-go-img2.jpg) center center no-repeat;
  background-size: cover;
}

.shortcut__ul__li h4 {
  font-size: 44px;
  font-weight: 300;
  font-family: 'GongGothicM';
  margin-top: -20px;
}

.shortcut__ul__li h4 span {
  font-size: 16px;
  font-weight: 100;
}

.shortcut__ul__li p {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 15px;
  margin-bottom: 35px;
}

.shortcut__ul__li:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  box-shadow: 6px 10px 17px -7px rgba(0, 0, 0, 0.6);
}

.shortcut__btn {
  margin: 0 auto;
  text-align: center;
}

.shortcut__btn a {
  text-align: left;
  padding-left: 18px;
  font-size: 16px;
  background: url(../../resources/images/icons/main-way-go-img2.png) 90% center no-repeat;
  transition: all 0.3s ease 0s;
  padding-right: 18px;
  display: block;
  border: 1px solid #fff;
  height: 54px;
  max-width: 200px;
  width: 100%;
  display: grid;
  align-items: center;
  margin: 0 auto;
  margin-top: 10px;
}

.shortcut__btn a:hover {
  color: #000;
  background: url(../../resources/images/icons/main-way-go-img1.png) 90% center no-repeat #fff;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .shortcut__ul {
    gap: 10px;
  }
  .shortcut__ul__li p {
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .shortcut__ul__li h4 {
    font-size: 36px;
  }
  .shortcut__btn a {
    font-size: 15px;
  }
}

@media screen and (min-width: 1px) and (max-width: 680px) {
  .shortcut__ul {
    display: block;
  }
  .shortcut__ul__li {
    min-height: 500px;
  }
  .shortcut {
    margin-top: 50px;
  }
}

/*support project*/
.main-project {
  max-width: 1580px;
  width: 100%;
  color: #fff;
  position: relative;
  margin: 0 auto;
}

.main-project__tit__wrap {
  float: left;
  width: 380px;
}

.main-project__tit {
  font-size: 44px;
  font-family: 'GongGothicM';
}

.main-project p {
  font-size: 18px;
  margin-top: 15px;
  font-weight: 300;
}

.main-project .popupzone-slide2 {
  width: calc(100% - 380px);
  float: left;
}

.main-project .popupzone-slide2 .slick-track {
  margin-left: unset;
  margin-right: unset;
}

.main-project .popupzone-slide2 button {
  display: none !important;
}

.main-project .popupzone-slide2__text {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  margin-top: 12px;
  margin-bottom: 32px;
  word-wrap: break-word;
  overflow: hidden;
  padding-right: 1%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  width: 96%;
  -webkit-box-orient: vertical;
  height: 55px;
}

.main-project .popupzone-slide2 .popupzone-list__item__in {
  padding-left: 2%;
  padding-right: 2%;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .main-project {
    padding-left: 1%;
    padding-right: 1%;
    box-sizing: border-box;
  }
  .main-project__tit__wrap {
    width: 100%;
    float: none;
  }
  .main-project .popupzone-slide2 {
    width: 100%;
    float: none;
  }
  .popupzone-nav2 {
    top: 5px;
    right: 1%;
    text-align: right;
    left: auto;
  }
  .popupzone-nav2 > div > ul li a {
    width: 30px;
    height: 30px;
  }
  .main-project__tit {
    font-size: 36px;
  }
  .main-project__tit br {
    display: none;
  }
  .main-project p {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 15px;
  }
  .main-project p br {
    display: none;
  }
  .main-project .popupzone-slide2__text {
    font-size: 16px;
    height: 40px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-project__tit {
    font-size: 28px;
  }
}

/* SNS */
.SNS {
  position: relative;
}

.SNS__tit {
  font-size: 44px;
  font-family: 'GongGothicM';
  margin-bottom: 25px;
}

.SNS__ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px 20px;
}

.SNS__ul__li {
  flex-basis: 285px;
}

.SNS__ul__li a {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.SNS__ul__li__in {
  padding: 40px 30px;
  font-size: 22px;
  font-weight: 500;
  box-sizing: border-box;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: -500px;
  transition: 0.5s ease;
}

.SNS__ul__li__in__text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.SNS__ul__li:hover .SNS__ul__li__in {
  bottom: 0;
}

.SNS__btn {
  position: absolute;
  right: 0;
  top: 0;
}

.SNS__btn a {
  text-indent: -9999px;
  font-size: 0;
  margin-left: 2px;
}

.SNS__btn__facebook {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px -634px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.SNS__btn__instagram {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -104px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.SNS__btn__youtube {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -198px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.SNS__btn__more {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -292px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

@media screen and (min-width: 1px) and (max-width: 1220px) {
  .SNS__tit {
    padding-left: 1%;
    padding-right: 1%;
    font-size: 34px;
  }
  .SNS__ul {
    display: inline-block;
  }
  .SNS__btn {
    right: 1%;
  }
  .SNS__ul__li {
    width: 33.333%;
    float: left;
    padding: 1%;
    box-sizing: border-box;
    text-align: center;
  }
}

@media screen and (min-width: 1px) and (max-width: 600px) {
  .SNS__ul {
    display: inline-block;
  }
  .SNS__ul__li {
    width: 50%;
    float: left;
    padding: 1%;
    box-sizing: border-box;
    text-align: center;
  }
}

/*SNS2 221205 추가*/
.SNS2 {
  display: flex;
  gap: 20px;
  position: relative;
}

.SNS2 h3 {
  font-size: 28px;
  font-family: 'GongGothicM';
  margin-bottom: 30px;
}

.SNS2 h3:before {
  margin-right: 10px;
}

.SNS2 .youtube {
  width: 100%;
  position: relative;
}

.SNS2 .youtube__in {
  display: flex;
  gap: 30px;
}

.SNS2 .youtube__in a {
  flex: 1;
}

.SNS2 .youtube__in__tit {
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 54px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.SNS2 .youtube__in__date {
  font-size: 16px;
  color: #777777;
}

.SNS2 .youtube h3:before {
  content: '';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -574px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.SNS2 .Instagram {
  width: 26.7%;
}

.SNS2 .Instagram h3:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -480px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.SNS2 .facebook {
  width: 26.7%;
}

.SNS2 .facebook h3:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -386px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.SNS2 .fix-size {
  display: block;
  border: 1px solid #e0e0e0;
  height: 330px;
  box-sizing: border-box;
  overflow: hidden;
}

.SNS2 .fix-size iframe {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  min-width: 100%;
  object-fit: cover;
  width: 100%;
  min-height: 100%;
}

.SNS2 .fix-size img {
  position: relative;
  object-fit: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}

.SNS2 .fix-size .fb_iframe_widget {
  display: inline;
}

.SNS2 .fix-size .fb_iframe_widget span {
  display: inline;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .SNS2 .youtube {
    padding-left: 1%;
    padding-right: 1%;
  }
  .SNS2 .main-tab__cont__more {
    right: 1%;
  }
}

@media screen and (min-width: 1px) and (max-width: 860px) {
  .SNS2 h3:before {
    display: none !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 600px) {
  .SNS2 {
    display: inline-block;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }
  .SNS2 .fix-size {
    height: 220px;
  }
  .SNS2 h3:before {
    display: inline-block !important;
  }
  .SNS2 .youtube {
    width: 100%;
  }
  .SNS2 .Instagram {
    width: 49%;
    float: left;
    margin-top: 20px;
    margin-right: 1%;
  }
  .SNS2 .facebook {
    width: 49%;
    float: left;
    margin-top: 20px;
    margin-left: 1%;
  }
  .SNS2 .youtube__in {
    gap: 10px;
  }
  .SNS2 .youtube__in__tit {
    font-size: 19px;
    min-height: 48px;
  }
}

@media screen and (min-width: 1px) and (max-width: 450px) {
  .SNS2 .Instagram, .SNS2 .facebook {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .SNS2 .fix-size {
    height: auto;
  }
  .SNS2 .fix-size img, .SNS2 .fix-size iframe {
    top: auto;
    left: auto;
    transform: none;
  }
}

/*partner*/
.partner {
  margin-top: 150px;
  position: relative;
  min-height: 320px;
}

.partner__tit__wrap {
  float: left;
  width: 400px;
}

.partner__tit {
  font-size: 44px;
  font-family: 'GongGothicM';
}

.partner p {
  font-size: 18px;
  margin-top: 15px;
  font-weight: 300;
}

.partner .popupzone-slide3 {
  width: calc(100% - 400px);
  float: left;
}

.partner .popupzone-slide3 button {
  display: none !important;
}

.partner .popupzone-slide3__text {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  margin-top: 12px;
  margin-bottom: 32px;
  word-wrap: break-word;
  overflow: hidden;
  padding-right: 1%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  width: 96%;
  font-family: 'GongGothicM';
  -webkit-box-orient: vertical;
  height: 55px;
}

.partner .popupzone-slide3 .popupzone-list__item__in {
  margin: 5px;
  height: 100px;
  background-color: #f2f6f7;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.partner .popupzone-nav3 {
  top: 155px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .partner {
    padding-left: 1%;
    padding-right: 1%;
    box-sizing: border-box;
    margin-top: 50px;
  }
  .partner__tit {
    font-size: 34px;
  }
  .partner__tit__wrap {
    float: none;
    width: 100%;
  }
  .partner .popupzone-slide3 {
    float: none;
    width: 100%;
  }
  .partner .popupzone-nav3 {
    top: 5px;
    right: 1%;
    text-align: right;
    left: auto;
  }
  .popupzone-nav3 > div > ul li a {
    width: 30px;
    height: 30px;
  }
  .partner p {
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .partner .popupzone-slide3 .popupzone-list__item__in {
    height: 80px;
    box-sizing: border-box;
    padding: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .news__tit, .popupzone__tit, .SNS__tit, .partner__tit {
    font-size: 28px;
  }
}

/*Archive 탭*/
.archive {
  margin-top: 150px;
  position: relative;
}

.archive .slick-prev {
  position: absolute;
  top: -68px;
  right: 70px;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
  background: url("../../resources/images/icons/popup-prev2.png") center center no-repeat;
}

.archive .slick-prev:focus {
  background-color: gold;
}

.archive .slick-next {
  position: absolute;
  top: -68px;
  right: 30px;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
  background: url("../../resources/images/icons/popup-next2.png") center center no-repeat;
}

.archive .slick-next:focus {
  background-color: gold;
}

.archive .popupzone-nav {
  display: none;
}

.archive .slick-list {
  margin: 0 -15px;
}

.archive .slick-slide {
  margin: 0 15px;
}

.archive .macin-tab__cont__tit {
  padding-top: 20px;
  margin-bottom: 30px;
  min-height: 60px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.4;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.archive .macin-tab__cont__data {
  font-size: 16px;
  color: #777777;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .archive .slick-prev, .archive .slick-next {
    top: -112px;
  }
  .archive {
    padding-left: 1%;
    padding-right: 1%;
    box-sizing: border-box;
  }
  .archive .macin-tab__cont__tit {
    font-size: 18px;
    min-height: 50px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .archive {
    margin-top: 70px;
  }
  .archive .slick-prev, .archive .slick-next {
    top: -98px;
  }
}

@media screen and (min-width: 1px) and (max-width: 600px) {
  .archive .slick-prev, .archive .slick-next {
    top: -100px;
  }
  .archive .slick-list {
    margin: 0 -5px;
  }
  .archive .slick-slide {
    margin: 0 5px;
  }
  .archive .macin-tab__cont__tit {
    font-size: 18px;
  }
  .archive .main-tab__cont__more {
    top: 5px;
  }
}

@media screen and (min-width: 1px) and (max-width: 420px) {
  .archive .slick-prev, .archive .slick-next {
    top: -102px;
  }
}

/*메인 이미지 사이즈 고정*/
/*이미지 고정 사이즈*/
.main-project .fix-size {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 58%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.main-project .fix-size__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-project .fix-size iframe {
  width: 100%;
  height: 100%;
}

.main-project .fix-size img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  position: absolute;
}

.SNS .fix-size {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.SNS .fix-size__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-lay-1 .news .fix-size {
  border: 1px solid #e0e0e0;
  position: relative;
  width: 100%;
  height: 0;
  text-align: center;
  overflow: hidden;
  padding-bottom: 45.7%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.main-lay-1 .news .fix-size img {
  display: inline-block;
}

.main-lay-1 .news .fix-size__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-lay-1 .popupzone .fix-size {
  border: 1px solid #e0e0e0;
  position: relative;
  width: 100%;
  height: 0;
  text-align: center;
  overflow: hidden;
  padding-bottom: 72.1%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.main-lay-1 .popupzone .fix-size img {
  display: inline-block;
}

.main-lay-1 .popupzone .fix-size__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-tab .fix-size {
  position: relative;
  width: 100%;
  height: 0;
  text-align: center;
  overflow: hidden;
  padding-bottom: 75.6%;
  /*16:9*/
  background-color: #eee;
  color: #fff;
}

.main-tab .fix-size img {
  display: inline-block;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.main-tab .fix-size__in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*메인슬라이드*/
.background-cover, .main-slide__item {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-slide__item {
  position: relative;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .main-slide__item > img {
    height: 900px;
  }
}

.main-slide__item__text {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 1300px;
  text-align: right;
}

.main-slide__item__text img {
  display: inline;
}

@media screen and (min-width: 1001px) and (max-width: 1300px) {
  .main-slide__item__text {
    width: 1000px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1150px) {
  .popupzone-slide-big {
    position: relative;
    width: 100%;
  }
  .popupzone2 {
    padding-left: 0;
  }
  .popupzone-slide-first {
    display: none;
  }
  .popupzone-slide-big .popupzone2-list__item > a {
    width: 100%;
    background-position: center;
  }
  .popupzone-slide-big .slick-next {
    display: block !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .main-slide__item__text {
    width: 700px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-slide__item__text {
    width: 500px;
  }
  .popupzone2 .popupzone2-nav {
    bottom: -60px;
    top: auto;
  }
  .popupzone-slide-big .popupzone2-list__item > a {
    height: 400px;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .main-slide__item__text {
    width: 360px;
    top: 24%;
  }
  .popupzone-slide-big .popupzone2-list__item > a {
    height: 300px;
  }
}

.main-visual {
  position: relative;
  width: 100%;
  /*이미지의 상하사이즈로 설정*/
}

.main-visual .slick-arrow {
  border: 0px;
  top: 50%;
  margin-top: -9px;
}

.main-visual .slick-prev {
  position: absolute;
  left: 10px;
  z-index: 1;
  width: 18px;
  height: 30px;
  text-indent: -9999px;
  background-image: url("../../resources/images/icons/main-visual-prev.png");
  background-repeat: no-repeat;
  background-position: 0 0;
}

.main-visual .slick-next {
  position: absolute;
  right: 10px;
  z-index: 10;
  width: 18px;
  height: 30px;
  text-indent: -9999px;
  background-image: url("../../resources/images/icons/main-visual-next.png");
  background-repeat: no-repeat;
  background-position: 0 0;
}

.main-visual .go-vr-btn {
  display: block;
  position: absolute;
  top: 164px;
  left: 0;
  z-index: 1;
}

@media screen and (min-width: 1701px) and (max-width: 1900px) {
  .main-visual .go-vr-btn {
    width: 100px;
    height: 100px;
    top: 130px;
    right: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1700px) {
  .main-visual .go-vr-btn {
    display: none;
  }
}

.main-visual .slick-dots-wrap-outwrap {
  display: none !important;
  text-align: left;
  position: absolute;
  z-index: 3;
  bottom: 10px;
  right: 2%;
}

.main-visual .slick-dots-wrap-outwrap .slick-dots-wrap {
  display: inline-block;
  text-align: center;
  margin-top: 0;
  z-index: 999;
  padding: 5px 10px;
  border-radius: 20px;
}

.main-visual .slick-dots-wrap-outwrap .myslickcarousel {
  display: inline-block;
}

.main-visual .slick-dots-wrap-outwrap .slick-dots-on-off {
  display: inline-block;
}

.main-visual .slick-dots-wrap-outwrap .slick-dots-on-off li {
  display: inline-block;
  vertical-align: middle;
}

.main-visual .slick-dots-wrap-outwrap .slick-dots-on-off li a {
  color: gold;
}

.main-visual .slick-dots-wrap-outwrap * + html .myslickcarousel {
  display: inline;
}

.main-visual .slick-dots-wrap-outwrap * + html .slick-dots-on-off {
  display: inline;
}

.main-visual .slick-dots-wrap-outwrap * + html .slick-dots-on-off li {
  display: inline;
}

.main-visual .slick-dots-wrap-outwrap .slick-dots-list {
  display: inline-block !important;
  vertical-align: middle;
}

.main-visual .slick-dots-wrap-outwrap .slick-dots-list li {
  display: inline-block;
  margin-right: 3px;
}

.main-visual .slick-dots-wrap-outwrap .slick-dots-list li button {
  display: inline-block;
  color: #fff;
  vertical-align: middle;
  overflow: visible;
  font-weight: bold;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 22px;
  font-size: 13px;
  background-color: #ddd;
  border: 0px solid #ddd;
  width: 20px;
  height: 3px;
  font-size: 0;
}

.main-visual .slick-dots-wrap-outwrap .slick-dots-list li.slick-active button {
  background-color: #000;
  border: 0px solid #fff;
  width: 20px;
  height: 5px;
  font-size: 0;
}

.main-visual .slick-dots-wrap-outwrap .slick-dots-list li button:focus {
  background-color: #faff76;
  color: #000;
}

.main-visual .slick-dots-wrap-outwrap * + html .slick-dots-list li {
  display: inline;
}

.main-visual .slick-dots-wrap-outwrap * + html .slick-dots-list li button {
  display: inline;
}

.main-visual .slick-dots-wrap-outwrap .slick-dots-on-off img {
  vertical-align: middle;
}

.main-visual .slick-dots-wrap-outwrap .slick-dots-on-off li {
  margin-left: 10px;
}

.main-visual .slick-dots-wrap-outwrap .main-slide-pause {
  display: block;
  width: 22px;
  height: 22px;
  font-size: 0;
  text-indent: -9999px;
  vertical-align: top;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -953px -1006px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 40px;
}

.main-visual .slick-dots-wrap-outwrap .main-slide-pause:focus {
  background-color: #0e5390;
}

.main-visual .slick-dots-wrap-outwrap .main-slide-play {
  display: block;
  width: 22px;
  height: 22px;
  font-size: 0;
  text-indent: -9999px;
  vertical-align: top;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1025px -1006px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 40px;
}

.main-visual .slick-dots-wrap-outwrap .main-slide-play:focus {
  background-color: #0e5390;
}

.main-visual .slick-dots-wrap-outwrap .main-slider-prev {
  display: block;
  width: 64px;
  height: 64px;
  font-size: 0;
  text-indent: -9999px;
  vertical-align: top;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -541.42857px 0px;
  background-image: url("../images/sprite.png");
  background-size: 1027.14286px auto;
  width: 45.71429px;
  min-width: 45.71429px;
  height: 45.71429px;
  border-radius: 40px;
  border: 1px solid #fff;
}

.main-visual .slick-dots-wrap-outwrap .main-slider-prev:focus {
  background-color: #0e5390;
}

.main-visual .slick-dots-wrap-outwrap .main-slider-next {
  display: block;
  width: 64px;
  height: 64px;
  font-size: 0;
  text-indent: -9999px;
  vertical-align: top;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -541.42857px -81.42857px;
  background-image: url("../images/sprite.png");
  background-size: 1027.14286px auto;
  width: 45.71429px;
  min-width: 45.71429px;
  height: 45.71429px;
  border-radius: 40px;
  border: 1px solid #fff;
}

.main-visual .slick-dots-wrap-outwrap .main-slider-next:focus {
  background-color: #0e5390;
}

.main-visual .slick-dots-wrap-outwrap {
  display: none;
}

.main-visual .slick-dots-wrap-outwrap.on {
  display: block;
}

@media screen and (min-width: 1px) and (max-width: 820px) {
  .main-visual .slick-dots-wrap-outwrap {
    display: none !important;
  }
  .main-visual .main-slider-prev,
  .main-visual .main-slider-next {
    display: none;
  }
}

@media screen and (min-width: 1px) and (max-height: 400px) {
  .main-visual .slick-dots-wrap-outwrap {
    bottom: 20px;
  }
}

/*
@media screen and (min-width:1px) and (max-width:1500px) {
	.main-visual {width:100%;padding-top:0;height: auto;margin-top:-140px;}
	.main-visual {}
	.main-visual .slick-arrow {top:150px;}
	.main-slide__item > img {width:1500px}
}
@media screen and (min-width:901px) and (max-width:1200px) {
	.main-slide__item > img {width:1500px}
}
@media screen and (min-width:769px) and (max-width:900px) {
	.main-slide__item > img {width:1250px}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.main-visual {margin-top:0;}
	.main-visual .slick-arrow {top:70px;}
	.main-slide__item > img {width:100%}
}

*/
/*video*/
.main-visual {
  overflow: hidden;
}

.video-box {
  position: relative;
}

.video-box__over {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../resources/images/basic/video-over-img.png");
  background-repeat: repeat;
  background-position: 0 0;
}

.video-box video {
  width: 1900px;
}

.video-box__pause {
  position: absolute;
  top: 825px;
  right: 50%;
  margin-right: -740px;
  z-index: 99999;
  background-color: #fff;
  font-weight: bold;
  display: block;
  padding: 3px;
  border-radius: 10px;
  border: 2px solid #000;
}

.video-box__pause:focus {
  background-color: gold;
}

@media screen and (min-width: 1px) and (max-width: 1500px) {
  .video-box video {
    width: 1500px;
  }
  .video-box__pause {
    top: 800px;
    margin-right: -42%;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .video-box video {
    width: 1200px;
  }
  .video-box__pause {
    top: 620px;
    margin-right: -40%;
  }
}

@media screen and (min-width: 769px) and (max-width: 900px) {
  .video-box video {
    width: 900px;
  }
  .video-box__pause {
    top: 460px;
    margin-right: -40%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .video-box video {
    width: 100%;
  }
  .video-box__over {
    display: none;
  }
  .video-box__pause {
    display: none;
    top: unset;
    bottom: 10%;
    margin-right: -44%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-slide {
    display: none !important;
  }
}

.main-first-cont-box {
  position: relative;
  max-width: 1580px;
  width: 100%;
  margin: 0 auto;
  z-index: 999999;
}

@media screen and (min-width: 1px) and (max-width: 1400px) {
  .main-first-cont-box {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-first-cont-box {
    padding-bottom: 60px;
    padding-top: 40px;
  }
}

.main-first-cont-box__in {
  position: absolute;
  bottom: 160px;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-first-cont-box__in {
    position: relative;
    bottom: 5px;
    left: 0;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 5px;
  }
}

.latest-news {
  width: 84%;
  margin: auto 8%;
  position: relative;
  overflow: hidden;
}

.latest-news:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .latest-news {
  display: inline-block;
}

.latest-news__tit {
  width: 210px;
  height: 70px;
  line-height: 68px;
  float: left;
  font-size: 18px;
  box-sizing: border-box;
  font-weight: 500;
  padding-left: 78px;
  color: #fff;
  position: relative;
  background-color: #f7941c;
}

.latest-news__tit:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -90px -916px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 36px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .latest-news__tit:before {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -60px -610.66667px;
    background-image: url("../images/sprite.png");
    background-size: 958.66667px auto;
    width: 29.33333px;
    min-width: 29.33333px;
    height: 24px;
  }
}

.latest-news__text {
  height: 40px;
  width: 65%;
  float: left;
  margin-top: 13px;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 1%;
  border-radius: 5px;
  overflow: hidden;
  /*background-image: url("../../commons/images/global/latest-news__text-icon.png");
		background-position:10px center;background-repeat: no-repeat;*/
}

.latest-news__text__a {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: normal;
  color: #333;
  display: block;
  width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 45px;
  line-height: 45px;
  box-sizing: border-box;
}

.latest-news__text__a strong {
  color: #0e813f;
  font-size: 18px;
  font-size: 1.8rem;
  padding-right: 10px;
  padding-left: 10px;
}

.latest-news__text__a:focus {
  background-color: #eee;
  z-index: 90;
}

.latest-news__btn {
  display: block;
  border: 0px;
  background: transparent;
  font-size: 0;
  width: 28px;
  height: 28px;
  text-indent: -9999px;
  z-index: 1;
}

.latest-news__btn:focus {
  border: 1px solid gold;
  z-index: 90;
}

.latest-news__prev {
  position: absolute;
  top: 20px !important;
  right: 85px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -744px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.latest-news__stop {
  position: absolute;
  top: 20px !important;
  right: 45px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -822px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.latest-news__next {
  position: absolute;
  top: 20px !important;
  right: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -978px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.latest-news__start {
  position: absolute;
  top: 20px !important;
  right: 20px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -782px -916px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 25px;
}

.latest-news__plus {
  position: absolute;
  top: 20px !important;
  right: 127px;
  height: 30px;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 30px;
  border: 1px solid #0e813f;
  color: #0e813f;
  font-size: 15px;
  text-indent: 0;
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.latest-news .slick-vertical .slick-slide {
  border: 0px;
}

@media screen and (min-width: 1px) and (max-width: 1500px) {
  .latest-news {
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .latest-news {
    border-radius: 0;
  }
  .latest-news__tit {
    width: 180px;
    height: 40px;
    line-height: 40px;
    padding-left: 60px;
  }
  .latest-news__text {
    width: 60%;
    background-image: none;
    background: transparent;
    padding-left: 10px;
    margin-top: 0px;
  }
  .latest-news__text__a {
    height: 40px;
    line-height: 40px;
  }
  .latest-news__prev,
  .latest-news__next,
  .latest-news__stop,
  .latest-news__start,
  .latest-news__plus {
    top: 5px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 960px) {
  .latest-news__text {
    width: 50%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-first-cont-box__in2 {
    position: relative;
    background-color: #f6f6f6;
  }
  .latest-news {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .latest-news__tit {
    background-color: transparent;
    color: #000;
    padding-left: 20px;
  }
  .latest-news__tit:before {
    display: none;
  }
  .latest-news__text {
    height: 35px;
    width: 95% !important;
    margin-top: 3px;
    background-color: transparent;
  }
  .latest-news__text__a {
    height: 35px;
    line-height: 35px;
  }
  .latest-news__prev,
  .latest-news__next,
  .latest-news__stop,
  .latest-news__start,
  .latest-news__plus {
    top: 15px !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .latest-news__text {
    width: 65%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-visual {
    background-image: url("../../resources/images/cont/slide00.jpg");
    background-size: cover;
    height: auto !important;
  }
  .main-visual .main-slide__item {
    height: auto !important;
  }
  .main-visual .main-slide__item > img {
    height: auto;
  }
  .main-visual .main-slide .slick-list {
    height: auto !important;
  }
  .slick-dots-wrap-outwrap {
    bottom: 10px;
  }
}

/*배너존*/
.banner_zone_outwrap {
  font-family: 'Noto Sans KR';
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 20px;
  border-top: 0px solid #e0e0e0;
}

.banner_zone_wrap {
  position: relative;
  overflow: hidden;
  width: 1300px;
  margin: 0 auto;
  height: 60px;
}

.banner_zone {
  width: 1185px;
  overflow: hidden;
  z-index: 0;
  position: relative;
  text-align: center;
  margin-left: 115px;
}

.banner_zone a {
  padding: 0 0;
}

.selectbox_title_focus {
  border: 1px solid #ffc169 !important;
}

.listwrap {
  position: relative;
  left: 0;
  overflow: hidden;
}

* + html .listwrap {
  overflow: hidden;
  height: 60px;
  white-space: nowrap;
}

.listwrap li {
  float: left;
  width: 198px;
  height: 60px;
}

.listwrap li a {
  display: block;
}

.listwrap li a:focus img {
  border: 1px solid gold;
}

.listwrap li a img {
  width: 188px;
  height: 58px;
}

.navi {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 18px;
}

.navi .on {
  background-color: red;
}

.banner_zone_wrap h3 {
  margin-left: 0px;
  margin-bottom: 0px;
  font-size: 18px;
  color: #333333;
  position: absolute;
  top: 15px;
  left: 2px;
  display: none;
}

.foot_roll_nav2 {
  position: relative;
  z-index: 1;
}

.foot_roll_nav2 a:focus {
  border: 1px solid gold;
}

.foot_roll_nav2 .r_prev {
  left: 0px;
  top: 15px;
}

.foot_roll_nav2 .r_pause {
  left: 35px;
  top: 15px;
}

.foot_roll_nav2 .r_next {
  left: 70px;
  top: 15px;
}

.foot_roll_nav2 .r_plus {
  left: 105px;
  top: 13px;
}

.foot_roll_nav2 .r_start {
  left: 103px;
  top: 29px;
}

.foot_roll_nav2 span {
  float: left;
  position: absolute;
}

.foot_roll_nav2 span.go_all_banner {
  padding-left: 3px;
}

.banner_zone_wrap .pos_banner {
  position: absolute;
  top: 15px;
  left: 133px;
}

.r_plus a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -900px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.r_pause a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -822px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.r_start a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -782px -916px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 25px;
}

.r_prev a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -744px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
}

.r_next a {
  width: 28px;
  height: 28px;
  font-size: 0;
  display: block;
  background-color: #fff;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -978px;
  background-image: url("../images/sprite.png");
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin-right: -1px;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .banner_zone_outwrap {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .banner_zone_wrap {
    width: auto;
  }
  .banner_zone_wrap h3 {
    left: 9px;
  }
}

@media screen and (min-width: 851px) and (max-width: 1000px) {
  .banner_zone_wrap h3 {
    left: 9px;
  }
  /*배너존*/
  .banner_zone {
    width: 595px;
  }
}

@media screen and (min-width: 769px) and (max-width: 850px) {
  /*배너존*/
  .banner_zone {
    width: 395px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .banner_zone_wrap {
    height: auto;
    padding-left: 0px;
  }
  .banner_zone_wrap h3 {
    top: 0px;
    left: 10px;
  }
  .foot_roll_nav2 .r_prev {
    left: 0px;
    top: 0px;
  }
  .foot_roll_nav2 .r_pause {
    left: 35px;
    top: 0px;
  }
  .foot_roll_nav2 .r_next {
    left: 70px;
    top: 0px;
  }
  .foot_roll_nav2 .r_plus {
    left: 164px;
    top: 0px;
  }
  .foot_roll_nav2 .r_start {
    left: 34px;
    top: 0px;
  }
  .banner_zone {
    width: 395px;
    margin: 0 auto;
    padding-top: 40px;
  }
}

@media screen and (min-width: 1px) and (max-width: 470px) {
  .listwrap li {
    width: 175px;
  }
  .listwrap li a img {
    width: 175px;
    height: 54px;
  }
  .banner_zone {
    width: 350px;
  }
}

.sub-visual {
  height: 210px;
  position: relative;
  background-color: #1a1b1e;
  background-repeat: repeat;
  background-position: center top;
  background-size: cover;
  font-family: "Pretendard-Regular", "Nanum Gothic", sans-serif;
}

.sub-visual__tit {
  color: #fff;
  font-size: 44px;
  padding-top: 51px;
  text-align: center;
  font-family: 'GongGothicM';
}

.sub-visual__desc {
  color: #000;
  font-size: 24px;
  padding-top: 10px;
  text-align: center;
}

.sub-visual__move {
  color: #fff;
  max-width: 1580px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  font-size: 18px;
  top: -10%;
}

.sub-visual__move__prev {
  position: absolute;
  left: 0;
  padding-left: 44px;
  background: url("../../resources/images/cont/sub-visual-top-right.png") left center no-repeat;
}

.sub-visual__move__next {
  position: absolute;
  right: 0;
  padding-right: 44px;
  background: url("../../resources/images/cont/sub-visual-top-left.png") right center no-repeat;
}

@media screen and (min-width: 769px) and (max-width: 1580px) {
  .sub-visual__tit {
    font-size: 40px;
  }
  .sub-visual {
    display: none;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-visual {
    display: none;
  }
}

/*sub-page-container*/
.sub-page-container {
  padding-bottom: 60px;
  font-family: "Pretendard-Regular", "Nanum Gothic", sans-serif;
  background-image: url("../../resources/images/cont/sub-container-bg.jpg");
  background-repeat: repeat-x;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .sub-page-container {
    padding-top: 0;
    padding-bottom: 0;
    background-image: none;
  }
}

.sub-page-container__in {
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  background-position: 0 0;
  background-repeat: repeat-y;
}

.sub-page-container__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .sub-page-container__in {
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .sub-page-container__in {
    background-image: none;
  }
}

.sub-contents {
  border-top: 1px solid #ddd;
}

.cont-in {
  max-width: 1580px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 140px;
  min-height: 500px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.6;
  color: #000;
}

.cont-in__wrap {
  border-top: 1px solid #ddd;
  padding-top: 60px;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .cont-in {
    width: auto;
  }
}

.cont-in.ver2 {
  width: 100%;
  max-width: 100% !important;
  padding-bottom: 0px;
}

.width__1200 {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.cont-in img {
  max-width: 100%;
  height: auto !important;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .sub-page-container__in {
    width: auto;
  }
  .sub-contents {
    width: auto;
    float: none;
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  .cont-in img {
    height: auto !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-page-container {
    padding-top: 51px;
  }
}

.sub-contents__top {
  position: relative;
  font-family: 'Noto Sans KR';
  /*background-image:url("../../commons/images/global/sub-visual-bg1.jpg");*/
  background-position: right top;
  background-repeat: no-repeat;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .sub-contents__top {
    margin-bottom: 40px;
  }
}

.sub-contents__top h2.sub-tit {
  font-size: 40px;
  color: #222;
  font-family: 'GongGothicM';
  padding-top: 70px;
  padding-bottom: 50px;
  text-align: center;
  height: 40px;
  font-weight: normal;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .sub-contents__top h2.sub-tit {
    text-indent: 0;
    border-bottom: 0;
  }
}

/* location */
.location-box {
  position: relative;
  display: none;
}

.location-box__cont {
  position: absolute;
  right: 30px;
  text-align: right;
  top: 10px;
  width: 100%;
}

.location-box__cont span {
  display: inline-block;
  font-size: 14px;
  font-size: 1.4rem;
  height: 28px;
  line-height: 28px;
  color: #000;
}

.location-box__cont span:before {
  content: '';
  display: inline-block;
  font-size: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -156px -1370px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 7px;
  margin: 0 17px;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .location-box__cont span:before {
    margin: 0 10px;
  }
}

.location-box__cont span:first-child::before {
  display: none;
}

.location-box__cont .loc-home {
  display: block;
  padding-left: 30px;
  font-size: 14px;
  font-size: 1.4rem;
  height: 28px;
  line-height: 27px;
  background-image: url("../../resources/images/icons/home.png");
  background-position: 0 center;
  background-repeat: no-repeat;
  color: #666;
}

.location-box__cont .loc-home:hover, .location-box__cont .loc-home:focus {
  text-decoration: underline;
}

/* 프린트, url 버튼 등*/
/*메인 퀵박스 공유, 프린트 버튼 등*/
.sub-page-container .quick-box .quick-box__share__wrap.on .quick-box__share {
  border: none;
}

.quick-box {
  z-index: 9;
  position: absolute;
  top: 126px;
  right: 50%;
  transform: translateX(50%);
  font-size: 0;
  text-indent: -9999px;
}

.quick-box a {
  float: left;
}

.quick-box__share {
  vertical-align: top;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -856px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.quick-box__share__wrap {
  position: relative;
  float: left;
  transition: all 0.3s ease 0s;
}

.quick-box__share__wrap .quick-box__in {
  display: none;
}

.quick-box__share__wrap.on .quick-box__in {
  opacity: 1;
  border: 1px solid #cccccc;
  display: block;
  background-color: #fff;
}

.quick-box__share__wrap.on .quick-box__share:before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -762px -810px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.quick-box__print {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px 0px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
  display: inline-block;
  margin-left: 10px;
  position: relative;
  vertical-align: top;
}

.quick-box__in {
  opacity: 0;
  position: absolute;
  padding: 10px 17px;
  left: 50%;
  transform: translateX(-50%);
  top: 50px;
}

.quick-box__in a {
  position: relative;
  margin-bottom: 5px;
}

.quick-box__in a:last-child {
  position: relative;
  margin-bottom: 0;
}

.quick-box__sns1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -94px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.quick-box__sns2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -188px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.quick-box__sns3 {
  width: 22px;
  position: relative;
  vertical-align: top;
}

.quick-box__sns3:before {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -221px -539px;
  background-image: url("../images/sprite.png");
  width: 22px;
  min-width: 22px;
  height: 20px;
}

.quick-box__url {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -282px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
}

.quick-box a:hover .quick-box__sns-go {
  display: block !important;
  padding: 0 10px;
  font-size: 12px;
  position: absolute;
  top: -31px;
  height: 22px;
  line-height: 22px;
  text-indent: 0;
  left: 58%;
  margin-left: 0;
  transform: translateX(-50%);
  border-radius: 20px;
  color: #333333;
}

.quick-box a:hover .quick-box__sns-go::before {
  content: '';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -187px -683px;
  background-image: url("../images/sprite.png");
  width: 10px;
  min-width: 10px;
  height: 8px;
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
}

.quick-box a:hover .quick-box__print-go {
  display: block !important;
  padding: 0 10px;
  font-size: 12px;
  position: absolute;
  top: -31px;
  height: 22px;
  line-height: 22px;
  text-indent: 0;
  background-color: rgba(244, 148, 28, 0.8);
  left: 50%;
  margin-left: 0;
  transform: translateX(-50%);
  border-radius: 20px;
  color: #fff;
}

.quick-box a:hover .quick-box__print-go::before {
  content: '';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px 0px;
  background-image: url("../images/sprite.png");
  width: 44px;
  min-width: 44px;
  height: 44px;
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
}

.sub-page-container .quick-box a:hover .quick-box__sns-go {
  background-color: #fff !important;
  border: 1px solid #cccccc;
}

.sub-page-container .quick-box a:hover .quick-box__sns-go::before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -702px -68px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 9px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .quick-box {
    top: 112px;
    right: 270px;
  }
  .sub-page-container .quick-box {
    top: 40px;
    right: 0;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .quick-box {
    top: 161px;
    right: 140px;
  }
  .quick-box__print {
    display: none;
  }
  .sub-page-container .quick-box {
    top: 30px;
    right: 0;
  }
}

@media screen and (min-width: 1px) and (max-width: 620px) {
  .quick-box {
    display: none;
  }
}

.special-box {
  position: absolute;
  top: 50px;
  right: 5px;
  z-index: 9;
}

.special-box a {
  border: 1px solid #fff;
}

.special-box a:focus {
  border: 2px solid gold;
}

.special-box__print {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -973px -742px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 4px;
  text-indent: -9999px;
}

.special-box__url {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -916px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 40px;
  text-indent: -9999px;
  display: none !important;
}

.special-box__sns {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -916px;
  background-image: url("../images/sprite.png");
  width: 40px;
  min-width: 40px;
  height: 40px;
  text-indent: -9999px;
  display: none !important;
}

.special-box .special-openbox__cont {
  display: none;
  border: 1px solid #ddd;
  white-space: nowrap;
  padding: 10px;
  background-color: #fff;
}

.special-box .special-openbox__cont a {
  vertical-align: middle;
  display: inline-block;
}

.special-box .special-openbox__cont a span {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  font-size: 0;
  height: 0;
  overflow: hidden;
}

.special-box .special-openbox__cont a.type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -784px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a.type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -344px -1006px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a.type3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -1006px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a.type4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -172px -1006px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a.type5 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -612px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a:hover.type1, .special-box .special-openbox__cont a:focus.type1 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -698px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a:hover.type2, .special-box .special-openbox__cont a:focus.type2 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -258px -1006px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a:hover.type3, .special-box .special-openbox__cont a:focus.type3 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -870px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a:hover.type4, .special-box .special-openbox__cont a:focus.type4 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -86px -1006px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox__cont a:hover.type5, .special-box .special-openbox__cont a:focus.type5 {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1067px -526px;
  background-image: url("../images/sprite.png");
  width: 36px;
  min-width: 36px;
  height: 36px;
}

.special-box .special-openbox.on .special-openbox__btn {
  border: 1px solid #000;
}

.special-box .special-openbox.on .special-openbox__btn:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -662px;
  background-image: url("../images/sprite.png");
  width: 27px;
  min-width: 27px;
  height: 32px;
}

.special-box .special-openbox.on .special-openbox__cont {
  display: block;
  position: absolute;
  top: 65px;
  right: 0;
}

@media screen and (min-width: 769px) and (max-width: 1580px) {
  .location-box__cont {
    position: relative;
    top: 3px;
    width: auto;
    text-align: right;
  }
  .sub-contents__top h2.sub-tit {
    padding-top: 40px;
  }
  .special-box {
    top: 38px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-contents__top {
    background-image: none !important;
    margin-bottom: 10px;
    display: block;
  }
  .sub-contents__top h2.sub-tit {
    font-size: 21px;
    margin-bottom: 8px;
    padding-top: 40px;
    height: auto;
  }
  .location-box {
    position: relative;
    top: 0;
    right: 0;
    padding-bottom: 0px;
  }
  .location-box__cont {
    position: relative;
    white-space: normal;
    width: auto;
    text-align: left;
    overflow: visible;
    top: 0;
    left: 0;
    padding-top: 0px;
  }
  .special-box {
    display: none;
  }
}

/* checkbox */
input[class="check-type"] + label {
  display: inline-block;
  color: #999999;
  height: 44px;
  line-height: 44px;
  cursor: pointer;
  position: relative;
  padding-right: 20px;
  font-size: 16px;
  padding-left: 45px;
}

input[class="check-type"] + label span {
  z-index: 10;
}

input[class="check-type"] + label:after {
  display: inline-block;
  content: ' ';
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0px;
  border: 1px solid #cccccc;
  border-radius: 30px;
}

input[class="check-type"] + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -1071px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 13px;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 20px;
  z-index: 9;
}

input[class="check-type"]:checked + label {
  color: #0050a0;
}

input[class="check-type"]:checked + label:after {
  z-index: 0;
  border: 1px solid #0050a0;
}

input[class="check-type"]:checked + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -1134px;
  background-image: url("../images/sprite.png");
  width: 16px;
  min-width: 16px;
  height: 13px;
}

input[class="check-type"] {
  position: absolute;
  left: -9999px;
}

/*

input[class="check-type"] + label {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 2px solid #cccccc;
	background-color:#dddddd;
	cursor: pointer;
	border-radius: 30px;
	position: relative;
	font-size: 0;
	text-indent: -9999px;
	&:before {
		content:' ';
		@include sprite($check-type);
		position: absolute;
		top:10px;left:10px;
	}
}

input[class="check-type"]:checked + label {
	background-color: #666666;
}

input[class="check-type"]:focus + label {
	border: 2px solid gold;
}
input[class="check-type"] {
	position: absolute;
	left: -9999px;
}
*/
/* 2 */
input[class="check-type2"] + label {
  display: inline-block;
  height: 18px;
  line-height: 18px;
  position: relative;
  padding-left: 27px;
}

input[class="check-type2"] + label:after {
  display: inline-block;
  content: ' ';
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 1px solid #ddd;
  position: absolute;
  top: 0px;
  left: 0px;
}

input[class="check-type2"]:checked + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -507px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 4px;
}

input[class="check-type2"]:focus + label:after {
  border: 1px solid #bbbbbb;
}

input[class="check-type2"] {
  position: absolute;
  left: -9999px;
}

/* 3 */
input[class="check-type3"] + label {
  display: inline-block;
  height: 18px;
  line-height: 18px;
  cursor: pointer;
  position: relative;
  padding-left: 27px;
}

input[class="check-type3"] + label:after {
  display: inline-block;
  content: ' ';
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 1px solid #ddd;
  position: absolute;
  top: 0px;
  left: 0px;
}

input[class="check-type3"]:checked + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -817px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 11px;
  position: absolute;
  top: 4px;
  left: 4px;
}

input[class="check-type3"]:focus + label:after {
  border: 1px solid #ec6159;
}

input[class="check-type3"] {
  position: absolute;
  left: -9999px;
}

/*라디오버튼*/
input[class="radio-type"] + label {
  display: inline-block;
  height: 17px;
  line-height: 17px;
  border: 0;
  cursor: pointer;
  position: relative;
  padding-left: 27px;
}

input[class="radio-type"] + label:before {
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -191px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
  position: absolute;
  top: 6px;
  left: 5px;
}

input[class="radio-type"]:checked + label:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -254px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

input[class="radio-type"] {
  position: absolute;
  left: -9999px;
}

.choose-label-text {
  margin-left: 0;
  color: #7d7d7d;
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
}

.choose-textarea {
  display: block;
  box-sizing: border-box;
  border: 1px solid #d5d9dd;
  background-color: #e9ecf0;
  padding: 20px;
  height: 100px;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
  color: #768393;
}

.choose-textarea.long {
  height: 150px;
}

.editor_view.on .editor_view__cont {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  /*16:9*/
  background-color: #00235d;
  color: #fff;
}

.editor_view.on .editor_view__cont > p {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editor_view.on .editor_view__cont > p iframe {
  width: 100%;
  height: 100%;
}

.sub-slide {
  position: relative;
  margin-bottom: 10px;
}

.sub-slide__box {
  max-width: 100%;
  margin: 0 auto;
}

.sub-slide__box img {
  width: 100%;
}

.sub-slide__nav {
  position: absolute;
  width: 50px;
  display: block;
  top: 50%;
  margin-top: -35px;
  height: 70px;
  background-color: #000;
  opacity: 0.8;
  text-indent: -9999px;
}

.sub-slide__nav:after {
  width: 20px;
  height: 27px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  color: #fff;
  text-indent: 0;
  font-size: 22px;
  font-family: "Nanum Gothic";
}

.sub-slide__nav:hover:after, .sub-slide__nav:focus:after {
  color: gold;
}

.sub-slide__prev {
  left: 0;
}

.sub-slide__prev:after {
  content: '<';
}

.sub-slide__next {
  right: 0;
}

.sub-slide__next:after {
  content: '>';
}

.sub-slide-cro {
  margin-bottom: 40px;
  margin-left: -10px;
  overflow: hidden;
}

.sub-slide-cro img {
  height: 100px;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
  opacity: 0.4;
  transition: all 0.3s ease 0s;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .sub-slide-cro img {
    height: 100px !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-slide-cro img {
    height: 55px !important;
  }
}

.sub-slide-cro .slick-current img {
  opacity: 1;
}

.sub-slide-cro a:focus img {
  opacity: 1;
}

.layer-box {
  height: 0;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
}

.layer-box.on {
  height: 100%;
  visibility: visible;
  opacity: 1;
}

.layer-box {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100%;
  background-image: url("../../resources/images/basic/opa2.png");
  background-repeat: repeat;
  color: #fff;
}

.photo-slide {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
}

.photo-slide .slick-prev {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  z-index: 999;
  font-size: 0;
}

.photo-slide .slick-prev.slick-disabled {
  display: none !important;
}

.photo-slide .slick-prev:before {
  font-size: 40px;
  content: '<';
  display: block;
  padding: 20px;
}

.photo-slide .slick-prev:hover:before, .photo-slide .slick-prev:focus:before {
  color: gold;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .photo-slide .slick-prev {
    left: 2%;
  }
}

.photo-slide .slick-next {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
  z-index: 999;
  font-size: 0;
}

.photo-slide .slick-next.slick-disabled {
  display: none !important;
}

.photo-slide .slick-next:before {
  font-size: 40px;
  content: '>';
  display: block;
  padding: 20px;
}

.photo-slide .slick-next:hover:before, .photo-slide .slick-next:focus:before {
  color: gold;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .photo-slide .slick-next {
    right: 2%;
  }
}

.photo-slide__item {
  width: 100%;
  text-align: center;
}

.photo-slide__item img {
  margin: 0 auto;
}

.layer-box__close {
  font-size: 30px;
  font-weight: bold;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #ddd;
  border-radius: 40px;
  color: #000;
  position: absolute;
  top: 30px;
  right: 30px;
}

.root_daum_roughmap {
  width: 100% !important;
}

/* 다음맵 접근성 추가 .warp_map으로 감쌈 */
.wrap_map a:focus,
.wrap_map button:focus,
.roughmap_maker_label a:focus .roughmap_lebel_text,
.root_daum_roughmap .wrap_btn_zoom button:focus {
  border: 2px solid gold;
}

.wrap_controllers.hide {
  display: none;
}

.slider-for {
  width: 1200px !important;
  margin: 0 auto;
}

.slider-for a {
  display: inline-block;
}

.slider-for a:focus {
  border: 1px solid black;
}

.slider-for__item {
  background-color: cadetblue;
  min-height: 200px;
}

.slider-nav-outwrap {
  width: 1278px;
  overflow: hidden;
  margin: 0 auto;
}

.slider-nav {
  width: 1200px !important;
  margin: 0 auto;
}

.slider-nav a {
  display: inline-block;
  text-align: center;
}

.slider-nav a:focus {
  background-color: #444;
}

.slider-nav .slick-current {
  background-color: black;
  color: #fff;
}

/*3뎁스*/
.depth3__ul {
  display: flex;
  margin: 0 auto;
}

.depth3__ul.ver2 {
  margin-bottom: 160px;
}

.depth3__ul > li {
  position: relative;
  flex: 1;
  text-align: center;
  box-sizing: border-box;
}

.depth3__ul > li:last-child {
  border-right: none;
  margin-bottom: 90px;
}

.depth3__ul > li.on > a {
  background-color: #1a1b1e;
  color: #fff;
}

.depth3__ul > li > a {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  height: 58px;
  justify-content: space-evenly;
  border: 1px solid #dddddd;
  background-color: #fff;
}

.depth3-wrap.menu-num-1 > ul {
  max-width: 400px;
  width: 100%;
}

.depth3-wrap.menu-num-2 > ul {
  max-width: 500px;
  width: 100%;
}

.depth3-wrap.menu-num-3 > ul {
  max-width: 600px;
  width: 100%;
}

.depth3-wrap.menu-num-4 > ul {
  max-width: 700px;
  width: 100%;
}

.depth3-wrap.menu-num-5 > ul {
  max-width: 800px;
  width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .depth3-wrap.menu-num-4 > ul {
    display: flex;
    flex-wrap: wrap;
  }
  .depth3-wrap.menu-num-4 > ul > li {
    flex: auto;
    border-right: 0;
    border-left: 0;
    width: 50%;
    position: relative;
  }
  .depth3-wrap.menu-num-4 > ul > li:first-child, .depth3-wrap.menu-num-4.menu-num-4 > ul > li:nth-child(2) {
    border-top: 0;
  }
}

@media screen and (min-width: 1px) and (max-width: 500px) {
  .depth3-wrap > ul {
    flex-direction: column;
  }
  .depth3-wrap > ul > li {
    width: 100%;
  }
  .depth3-wrap > ul > li:last-child {
    margin-bottom: 30px;
  }
  .depth3-wrap > ul > li > a {
    font-size: 16px;
    height: 48px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .depth3__ul.ver2 {
    margin-bottom: 20px;
  }
}

/*4뎁스*/
.depth4__wrap {
  margin: 0 auto;
  max-width: 700px;
  margin: 0 auto;
  position: relative;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .depth4__wrap {
    text-align: center;
  }
}

.depth4__ul {
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: 50%;
  margin-top: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.depth4__ul:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -933px -916px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 24px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: -24px;
}

.depth4__ul > li {
  font-size: 18px;
  float: left;
  padding: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.depth4__ul > li a {
  color: #777777;
  display: inline;
}

.depth4__ul > li.on a {
  color: #0050a0;
  border-bottom: 1px solid #0050a0;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .depth4__ul {
    display: inline-block;
    text-align: center;
    position: relative;
    margin-left: 50%;
  }
  .depth4__ul:before {
    -webkit-transform: none;
    transform: none;
    margin-left: -12px;
  }
  .depth4__ul > li {
    width: 100%;
    padding: 0;
  }
  .depth4__ul > li a {
    font-size: 15px;
  }
}

/*게시판 스타일1*/
.bbs-st1__top {
  position: relative;
  border-top: 3px solid #111;
  padding-top: 30px;
  padding-bottom: 10px;
}

.bbs-st1__top__ul li {
  padding-left: 50px;
  position: relative;
  margin-bottom: 30px;
  clear: both;
  display: flex;
  align-items: center;
}

.bbs-st1__top__ul li > span, .bbs-st1__top__ul li > label {
  flex-basis: 100px;
  float: left;
  font-size: 18px;
  font-weight: bold;
}

.bbs-st1__top__type {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: calc(100% - 100px);
}

.bbs-st1__top__type > a {
  width: 150px;
  min-height: 50px;
  float: left;
  text-align: center;
  color: #555;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-around;
}

.bbs-st1__top__type > a.on {
  background-color: #111;
  color: #fff;
  border: 1px solid #111;
}

.bbs-st1__top__map {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 25px;
  padding-left: 25px;
  box-sizing: border-box;
  min-width: 130px;
  height: 50px;
  line-height: 50px;
  border-radius: 100px;
  border: 1px solid #111;
  text-align: center;
}

.bbs-st1__top__map a {
  width: 100%;
  height: 100%;
  display: inline-block;
}

.bbs-st1__top__map a:before {
  padding-right: 10px;
  margin-top: -2px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -732px -1006px;
  background-image: url("../images/sprite.png");
  width: 23px;
  min-width: 23px;
  height: 23px;
}

.bbs-st1__top__search__wrap {
  display: flex;
  width: 100%;
}

.bbs-st1__top__search {
  border: 1px solid #e0e0e0;
  height: 50px;
  padding-left: 20px;
  color: #999;
  box-sizing: border-box;
  max-width: 450px;
  width: 100%;
}

.bbs-st1__top__search::placeholder {
  color: #999;
}

.bbs-st1__top__search__btn {
  background-color: #f8f8f8;
  font-size: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #e0e0e0;
  float: right;
  box-sizing: border-box;
  border-left: none;
  text-align: center;
}

.bbs-st1__top__search__btn:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -70px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.bbs-st1__bottom {
  padding-top: 50px;
  border-top: 1px solid #e0e0e0;
}

.bbs-st1__bottom > p {
  font-size: 16px;
  color: #555555;
}

.bbs-st1__bottom__ul {
  overflow: hidden;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.bbs-st1__bottom__ul > li {
  float: left;
  width: 23.6%;
  margin-right: 1.647%;
  margin-bottom: 35px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  padding-bottom: 20px;
}

.bbs-st1__bottom__ul > li .tit h5 {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bbs-st1__bottom__ul > li .tit h5.blue {
  color: #1179c7;
}

.bbs-st1__bottom__ul > li .tit h5.red {
  color: #db4453;
}

.bbs-st1__bottom__ul > li .tit h4 {
  font-size: 24px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bbs-st1__bottom__ul > li > a {
  display: block;
}

.bbs-st1__bottom__ul > li .img-box {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 69.9%;
  background-color: #f7f7f7;
  text-align: center;
}

.bbs-st1__bottom__ul > li .img-box .inner_box {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.bbs-st1__bottom__ul > li .img-box .mark {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 25px;
  line-height: 25px;
  padding: 0 10px;
  background-color: #f4511e;
  color: #fff;
  font-size: 14px;
}

.bbs-st1__bottom__ul > li .img-box img {
  width: 100%;
  height: 100% !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.bbs-st1__bottom__ul > li > a:hover img, .bbs-st1__bottom__ul > li > a:focus img {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  transform: scale(1.3);
}

.bbs-st1__bottom__ul > li .video-bg {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2) url("/template/cms/user/base/image/basic/video_bg.png") center center no-repeat;
}

.bbs-st1__bottom__ul > li .txt-box {
  padding: 0 25px;
}

.bbs-st1__bottom__ul > li .txt-box .sort {
  margin-bottom: 3px;
  color: #2196f3;
  font-weight: 500;
}

.bbs-st1__bottom__ul > li .new_mark, .bbs-st1__bottom__ul > li .vi_board_list .new_mark {
  position: static;
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin-top: 0;
  margin-right: 3px;
  vertical-align: top;
}

.bbs-st1__bottom__ul > li:nth-child(4n) {
  margin-right: 0;
}

.bbs-st1__bottom__ul > li .basicList3 {
  font-size: 15px;
  color: #555;
  margin-top: 10px;
}

.bbs-st1__bottom__ul > li .basicList3 > li:before {
  top: 11px;
}

@media screen and (min-width: 1px) and (max-width: 1300px) {
  .bbs-st1__top__ul li {
    padding-left: 20px;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .bbs-st1__bottom__ul > li {
    width: 32%;
    margin-right: 2%;
  }
  .bbs-st1__bottom__ul > li:nth-child(4n) {
    margin-right: 2%;
  }
  .bbs-st1__bottom__ul > li:nth-child(3n) {
    margin-right: 0;
  }
  .bbs-st1__bottom__ul > li .tit h4 {
    font-size: 20px;
  }
  .bbs-st1__bottom__ul > li .tit h5 {
    font-size: 15px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .bbs-st1__top__type {
    gap: 0;
  }
  .bbs-st1__top__type a {
    width: 49%;
    font-size: 16px;
    margin-left: 2%;
    margin-bottom: 2%;
  }
  .bbs-st1__top__type a:nth-child(2n+1) {
    margin-left: 0;
  }
  .bbs-st1__top__search {
    width: calc(100% - 50px);
  }
  .bbs-st1__top__search::placeholder {
    font-size: 16px;
  }
  .bbs-st1__bottom__ul > li .txt-box {
    padding: 0 15px;
  }
  .bbs-st1__bottom__ul > li {
    width: 49%;
    margin-right: 1%;
  }
  .bbs-st1__bottom__ul > li:nth-child(4n) {
    margin-right: 1%;
  }
  .bbs-st1__bottom__ul > li:nth-child(3n) {
    margin-right: 1%;
  }
  .bbs-st1__bottom__ul > li:nth-child(2n) {
    margin-right: 0;
  }
  .bbs-st1__bottom__ul > li .tit h4 {
    font-size: 20px;
  }
  .bbs-st1__bottom__ul > li .tit h5 {
    font-size: 15px;
  }
}

@media screen and (min-width: 1px) and (max-width: 700px) {
  .bbs-st1__top__search__wrap {
    width: calc(100% - 100px);
  }
  .bbs-st1__top__ul li {
    padding-left: 10px;
  }
  .bbs-st1__top__ul li > form {
    width: calc(100% - 100px);
  }
  .bbs-st1__top__ul li > form .bbs-st1__top__search__wrap {
    width: 100%;
  }
  .bbs-st1__top__map {
    position: relative;
    display: block;
    flex: auto;
    margin-left: 0;
    width: 100%;
    margin-top: 10px;
  }
}

/*게시판 스타일2*/
.bbs-st2__top {
  position: relative;
  border-top: 3px solid #111;
  padding-top: 50px;
  padding-bottom: 10px;
}

.bbs-st2__top__ul__li {
  position: relative;
  margin-bottom: 40px;
  clear: both;
  display: inline-block;
  padding-left: 50px;
  padding-right: 50px;
}

.bbs-st2__top__ul__li select {
  height: 50px;
  border: 1px solid #e0e0e0;
  float: left;
  padding-left: 15px;
  color: #999999;
  font-size: 16px;
  background: url("../../resources/images/icons/select-bul.png") no-repeat 89% center;
  -webkit-appearance: none;
  /* for chrom */
  -moz-appearance: none;
  /* for firefox */
  appearance: none;
}

.bbs-st2__top__ul__li select::-ms-expand {
  display: none;
  /*for IE10,11*/
}

.bbs-st2__top__ul__li select.category {
  width: 110px;
}

.bbs-st2__top__ul__li select.consortium {
  width: 150px;
}

.bbs-st2__top__ul__li select.corporation {
  width: 110px;
  margin-right: 5px;
}

.bbs-st2__top__ul__li__in {
  float: left;
  display: flex;
  align-items: center;
}

.bbs-st2__top__ul__li__in label {
  padding-right: 15px;
  float: left;
  font-size: 18px;
  font-weight: bold;
  padding-left: 50px;
}

.bbs-st2__top__ul__li__in:first-of-type label {
  padding-left: 0;
}

.bbs-st2__top__search__wrap {
  float: left;
}

.bbs-st2__top__search {
  border: 1px solid #e0e0e0;
  height: 50px;
  padding-left: 20px;
  color: #999;
  box-sizing: border-box;
  width: 396px;
  font-size: 16px;
}

.bbs-st2__top__search::placeholder {
  color: #999;
}

.bbs-st2__top__search__btn {
  background-color: #f8f8f8;
  font-size: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #e0e0e0;
  float: right;
  box-sizing: border-box;
  border-left: none;
  text-align: center;
}

.bbs-st2__top__search__btn:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -70px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.bbs-st2__bottom {
  padding-top: 50px;
  border-top: 1px solid #e0e0e0;
}

@media screen and (min-width: 1px) and (max-width: 1240px) {
  .bbs-st2__top__ul__li {
    display: flex;
    flex-wrap: wrap;
  }
  .bbs-st2__top__ul__li__in {
    width: 50%;
  }
  .bbs-st2__top__ul__li__in:last-child {
    width: 100%;
    margin-top: 10px;
  }
  .bbs-st2__top__ul__li__in:last-child label {
    padding-left: 0;
  }
  .bbs-st2__top__ul__li__in:last-child .bbs-st2__top__search__wrap {
    width: 100%;
  }
  .bbs-st2__top__ul__li__in:last-child .bbs-st2__top__search {
    width: calc(100% - 50px);
  }
  .bbs-st2__top__ul__li select.category, .bbs-st2__top__ul__li select.consortium {
    width: 100%;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .bbs-st2__top__ul__li {
    padding-left: 10px;
    padding-right: 10px;
  }
  .bbs-st2__top__ul__li__in label {
    padding-left: 10px;
    font-size: 16px;
    padding-right: 10px;
  }
  .bbs-st2__top__ul__li select {
    padding-left: 10px;
    background: url("../../resources/images/icons/select-bul.png") no-repeat 91% center;
  }
  .bbs-st2__top__ul__li__in:last-child .bbs-st2__top__search {
    padding-left: 10px;
  }
}

.excel-btn {
  width: 140px;
  height: 44px;
  line-height: 44px;
  border: 1px solid #000000;
  font-size: 16px;
  display: inline-block;
  padding-left: 15px;
  text-align: left;
}

.excel-btn::before {
  margin-right: 6px;
  box-sizing: border-box;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -805px -1006px;
  background-image: url("../images/sprite.png");
  width: 24px;
  min-width: 24px;
  height: 22px;
}

.bbs-excel {
  font-size: 0;
}

.bbs-excel::before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -1007px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 14px;
}

.pagination {
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
}

.pagination a {
  height: 38px;
  width: 38px;
  line-height: 38px;
  display: inline-block;
}

.pagination a.on {
  color: #fff;
  background-color: #1a1b1e;
}

.pagination .first, .pagination .prev, .pagination .next, .pagination .last {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.pagination .first:before, .pagination .prev:before, .pagination .next:before, .pagination .last:before {
  margin-top: -3px;
}

.pagination .first {
  margin-right: -3px;
  margin-right: -5px;
}

.pagination .first:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -372px -1242px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 13px;
}

.pagination .prev {
  margin-right: 10px;
}

.pagination .prev:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -1133px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 13px;
}

.pagination .next {
  margin-left: 10px;
}

.pagination .next:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -1070px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 13px;
}

.pagination .last {
  margin-left: -5px;
}

.pagination .last:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -436px -1242px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 13px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .pagination a {
    width: 22px;
    height: 26px;
    line-height: 26px;
    font-size: 15px;
  }
  .pagination .prev {
    margin-right: 0;
  }
  .pagination .next {
    margin-left: 0;
  }
}

/*		sidebar
=========================*/
/* sidebar */
.sidebar {
  font-family: "Pretendard-Regular", "Nanum Gothic", sans-serif;
  position: relative;
  background-color: #f4f5f9;
}

.sidebar__wrap {
  background-color: #f4f5f9;
  max-width: 1700px;
  min-height: 79px;
  margin: 0 auto;
}

.sidebar__h2 {
  text-align: center;
  padding-top: 35px;
  height: 79px;
  font-size: 30px;
  font-size: 3.0rem;
  position: relative;
  padding-bottom: 20px;
  color: #fff;
  background-color: #00235d;
  display: none;
}

.sidebar__h2 span {
  display: block;
  font-size: 12px;
  color: #fff;
  text-align: center;
}

.sidebar__h2 a {
  display: none;
}

/*메뉴 이름 길경우*/
.sidebar__h2.long-type {
  font-size: 20px;
  padding-top: 45px;
  height: 55px;
}

.side-list {
  display: flex;
  justify-content: center;
}

.side-list__li > span > a {
  display: block;
  padding-left: 0px;
  height: 79px;
  line-height: 79px;
  border: 0px solid #f7f7f7;
  text-align: center;
  color: #444;
  font-size: 20px;
  font-weight: normal;
  transition: all 0.3s ease 0s;
  background-position: 213px center;
  white-space: nowrap;
}

.side-list__li.open-type > span > a {
  position: relative;
}

.side-list__li.open-type > span > a:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -558px -1242px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 20px;
}

.side-list__li.open-type > span > a:hover:after,
.side-list__li.open-type > span > a:focus:after,
.side-list__li.open-type > span.on > a:after,
.side-list__li.open-type > span > a.on:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1147px -1092px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 20px;
}

.side-list__li.open-type.on > span > a:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1147px -1092px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 20px;
}

.side-list__li.on > span {
  display: block;
}

.side-list__li > span > a:hover,
.side-list__li > span > a:focus,
.side-list__li > span.on > a,
.side-list__li > span > a.on,
.side-list__li.selected > span > a {
  font-weight: normal;
  color: #000;
}

.side-list__li > span > a:focus em {
  text-decoration: underline;
}

.side-list__li.on > span > a {
  font-weight: normal;
}

.side-list__li__inbox {
  display: none;
}

.on > .side-list__li__inbox {
  display: block;
  background-color: #f7f8ff;
  padding: 10px;
}

.spp__in > a {
  display: block;
  font-size: 15px;
  color: #565656;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 13px;
}

.spp__in > a:before {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -790px -1242px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 2px;
  padding-right: 8px;
}

.spp__in > a:hover,
.spp__in > a:focus,
.spp__in.on > a,
.spp__in > .on,
.spp__in.selected > a,
.spp__in > .selected {
  color: #000;
}

.spp__in > a:hover:before,
.spp__in > a:focus:before,
.spp__in.on > a:before,
.spp__in > .on:before,
.spp__in.selected > a:before,
.spp__in > .selected:before {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -844px -1242px;
  background-image: url("../images/sprite.png");
  width: 4px;
  min-width: 4px;
  height: 2px;
}

.spp__in__small {
  display: none;
}

.on > .spp__in__small {
  display: block;
  padding: 3px 10px 10px 15px;
}

.spp__in__small a {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  font-size: 13px;
  font-size: 1.3rem;
}

.spp__in__small .on,
.spp__in__small a:hover,
.spp__in__small a:focus {
  text-decoration: underline;
}

.sp-open-type {
  position: relative;
}

.sp-open-type:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -500px -1242px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 8px;
  position: absolute;
  top: 13px;
  right: 15px;
}

.sp-open-type.on:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1089px -1092px;
  background-image: url("../images/sprite.png");
  width: 8px;
  min-width: 8px;
  height: 2px;
  position: absolute;
  top: 15px;
  right: 15px;
}

/*4차*/
.spp__in__small__4th {
  display: none;
}

.on > .spp__in__small__4th {
  display: block;
  padding: 10px 5px 10px 10px;
  background-color: #eff3f7;
}

.spp__in__small__4th a {
  display: block;
  padding: 3px 0;
  min-height: 17px;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: normal;
  padding-left: 15px;
}

.spp__in__small__4th > li.on > a,
.spp__in__small__4th > li > .on,
.spp__in__small__4th a:hover,
.spp__in__small__4th a:focus {
  text-decoration: underline;
}

/*2019 사이드 메뉴 수정 추가 */
.mobile-submenu-btn {
  display: none;
  background-color: #26272b;
  padding: 4px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 4px;
  right: 7px;
  z-index: 98;
}

.mobile-submenu-btn .type1 {
  display: block;
}

.mobile-submenu-btn .type2 {
  display: none;
}

.selected.mobile-submenu-btn .type1 {
  display: none;
}

.selected.mobile-submenu-btn .type2 {
  display: block;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .sidebar {
    position: relative;
    z-index: 1;
    margin-top: 0px;
    float: none;
    width: 100%;
  }
  .sidebar {
    padding-bottom: 5px;
  }
  .side_in_wrap {
    display: none;
    border-bottom: 2px solid #4c4c4e;
    opacity: 0;
  }
  .sidebar h2 {
    text-align: left;
    background-image: none;
    background-color: #4c4c4e;
    padding-left: 10px;
    font-size: 1.5rem;
    height: 32px;
    padding-top: 5px;
    line-height: 1.6;
  }
  .sidebar h2 {
    color: #fff;
    min-height: 10px;
    height: 30px;
    border-radius: 0px;
  }
  .sidebar h2 a {
    display: none;
  }
  .sidebar__h2 {
    padding-bottom: 0px;
    display: block;
  }
  .sidebar__h2.long-type {
    padding-bottom: 0px;
    font-size: 16px;
    padding-top: 5px;
    height: 32px;
  }
  .sidebar h2 {
    text-align: left;
    padding-left: 20px;
    font-size: 16px;
  }
  .side-list__li > span > a {
    height: 30px;
    line-height: 30px;
  }
  .side-list__li.open-type > span > a {
    background-position: 96% center;
  }
  .side-list__li.open-type.on > span > a {
    background-position: 96% center;
  }
  .sp-open-type {
    background-position: 97% center;
  }
  .sp-open-type.on {
    background-position: 97% center;
  }
  /**/
  .sidebar__wrap {
    opacity: 1.0 !important;
    margin-left: 0px !important;
  }
  .side_in_wrap {
    display: block;
    border-bottom: 2px solid #000;
    opacity: 1;
  }
  .side_in_wrap li.selected span a {
    background-color: #fff !important;
    color: #f6804e !important;
  }
  .mobile-submenu-btn {
    display: block;
  }
  .on > .side-list__li__inbox {
    padding: 0 10px;
  }
  .sidebar .side-list > li {
    overflow: hidden;
    height: 0;
  }
  /*.sidebar .side-list > li.on {display:block;}*/
  .sidebar .side-list > li.on {
    height: auto;
  }
  .sidebar.selected .side-list > li {
    height: auto;
  }
  .side-list__li > span > a {
    padding: 0px 0 0px 20px;
    transition: all 0.3s ease 0s;
  }
  .sidebar.selected .side-list__li > span > a {
    padding: 5px 0 5px 20px;
  }
  .sidebar.selected .side-list > li.open-type {
    height: auto;
  }
}

/**/
.side-list {
  height: 79px;
}

.sidebar__wrap {
  padding: 0 15%;
}

.sidebar__wrap.menu-num-1 {
  padding: 0 35%;
}

.sidebar__wrap.menu-num-2 {
  padding: 0 30%;
}

.sidebar__wrap.menu-num-3 {
  padding: 0 20%;
}

.sidebar__wrap.menu-num-6,
.sidebar__wrap.menu-num-7,
.sidebar__wrap.menu-num-8 {
  padding: 0 0;
}

.side-list:after:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .side-list:after {
  display: inline-block;
}

.side-list__li {
  float: left;
  width: 100%;
}

/*따라다니는 모션*/
.side-list__li em {
  position: relative;
  z-index: 1;
  display: inline-block;
}

.selected-bar {
  position: absolute;
  left: 50%;
  bottom: 0;
  display: block;
  width: 150px;
  height: 4px;
  border-top: 0px solid #000;
  float: left;
}

.selected-bar:before {
  content: ' ';
  width: 80%;
  height: 4px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .subpage.eng .sidebar__wrap {
    padding: 10px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .sidebar {
    padding: 0;
  }
  .sidebar__wrap {
    padding: 0 0 !important;
    min-height: auto;
  }
  .side-list {
    height: auto;
    display: block;
  }
  .side-list__li {
    float: none;
    width: 100% !important;
  }
  .selected-bar {
    display: none !important;
  }
  .side-list__li > span > a {
    text-align: left;
    font-size: 16px;
  }
}

.sidebar.selected .side-list {
  background-color: #f8f8f8;
}

.sidebar.selected .side-list li {
  border-bottom: 1px solid #ddd;
}

/* 간격 맞춰달라 해서 display:table로 변경  20220210 */
@media screen and (min-width: 1580px) and (max-width: 22200px) {
  .side-list {
    display: table;
    margin: 0 auto;
  }
  .side-list__li {
    float: none;
    display: table-cell;
    width: auto !important;
  }
  .side-list__li {
    padding-left: 40px;
    padding-right: 40px;
    vertical-align: middle;
  }
}

/*탭*/
.js-box {
  display: none;
  position: relative;
  min-height: 20px;
}

.js-box.on {
  display: block;
}

.js-box2 {
  display: none;
  position: relative;
  min-height: 100px;
}

.js-box2.on {
  display: block;
}

.js-box3 {
  display: none;
  min-height: 100px;
}

.js-box3.on {
  display: block;
}

.sub-tab .js-box {
  margin-left: 1px;
}

.sub-tab .js-box2 {
  margin-left: 1px;
}

.sub-tab .js-box3 {
  margin-left: 1px;
}

/*메인탭*/
.main-tab {
  position: relative;
}

.main-tab__tit {
  font-size: 40px;
  font-family: 'GongGothicM';
  margin-bottom: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .main-tab__tit {
    position: relative;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .main-tab__tit {
    font-size: 28px;
  }
}

.main-tab__ul {
  padding-left: 230px;
  margin-bottom: 30px;
  display: flex;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .main-tab__ul {
    padding-left: 0;
    margin-bottom: 10px;
  }
}

.main-tab__ul__li {
  margin-right: 25px;
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .main-tab__ul__li {
    margin-right: 10px;
  }
}

.main-tab__ul__li a {
  padding-top: 10px;
  box-sizing: border-box;
  display: block;
  font-size: 20px;
  font-size: 2rem;
  text-align: center;
  color: #919191;
  position: relative;
}

.main-tab__ul__li a span {
  display: inline-block;
  padding-bottom: 3px;
  border-bottom: 3px solid #fff;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .main-tab__ul__li a {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.main-tab__ul__li.on a {
  color: #111111;
  opacity: 1;
  font-weight: bold;
  padding-bottom: 2px;
  border-bottom: 2px solid #111;
}

.main-tab__ul__li.on a span {
  border-bottom: 3px solid #000;
}

@media screen and (min-width: 1px) and (max-width: 540px) {
  .main-tab__ul__li.on a {
    font-size: 16px;
  }
}

.main-tab__ul__li a:hover,
.main-tab__ul__li a:focus {
  border-bottom: 2px solid #111;
  padding-bottom: 2px;
}

.main-tab__cont {
  box-sizing: border-box;
}

@media screen and (min-width: 1px) and (max-width: 540px) {
  .main-tab__cont__ul__li {
    display: none;
  }
  .main-tab__cont__ul__li:nth-child(1), .main-tab__cont__ul__li:nth-child(2), .main-tab__cont__ul__li:nth-child(3) {
    display: block;
  }
}

.main-tab__cont__ul__li a {
  display: block;
  position: relative;
  padding-left: 20px;
  padding-right: 10px;
}

.main-tab__cont__ul__li a:before {
  content: ' ';
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background-color: #888;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 3px;
}

.main-tab__cont__ul__li a:hover,
.main-tab__cont__ul__li a:focus {
  background-color: #eee;
}

.main-tab__cont__more {
  position: absolute;
  right: 0;
  top: 10px;
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px 0px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.main-tab__cont__more:focus {
  border: 1px solid gold;
  padding: 0;
}

.mtcul-cont {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  width: 70%;
  font-size: 17px;
  font-size: 1.7rem;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #000;
}

.mtcul-date {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .mtcul-cont {
    width: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    font-size: 1.6rem;
  }
  .mtcul-date {
    display: none;
  }
}

.new-list {
  margin-left: -50px;
}

.new-list:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .new-list {
  display: inline-block;
}

.new-list__li {
  float: left;
  width: 50%;
}

@media screen and (min-width: 1081px) and (max-width: 1580px) {
  .new-list__li {
    width: 100%;
    display: none;
  }
  .new-list__li:first-child {
    display: block;
  }
}

@media screen and (min-width: 1px) and (max-width: 560px) {
  .new-list__li {
    width: 100%;
  }
}

.new-list__li a {
  display: block;
  margin-left: 50px;
  position: relative;
}

.new-list__li a:after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -858px -916px;
  background-image: url("../images/sprite.png");
  width: 25px;
  min-width: 25px;
  height: 25px;
}

.new-list__li a .bbs-ctg {
  color: #089296;
  font-size: 18px;
  margin-bottom: 15px;
  white-space: nowrap;
}

.new-list__li a .bbs-sbj {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  height: 56px;
  overflow: hidden;
}

.new-list__li a .bbs-cnt {
  font-size: 16px;
  margin-bottom: 54px;
  height: 52px;
  overflow: hidden;
}

.new-list__li a .bbs-date {
  font-size: 15px;
  color: #777;
  position: relative;
}

.new-list__li a .bbs-date:after {
  position: absolute;
  top: -20px;
  left: 0;
  content: ' ';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #999;
}

.new-list__li a:hover .bbs-sbj,
.new-list__li a:focus .bbs-sbj {
  text-decoration: underline;
}

/*서브탭*/
.sub-tab {
  overflow: hidden;
}

.sub-tab__ul {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  margin-left: -1px;
  margin-top: -1px;
  border-radius: 7px;
  margin-bottom: 30px;
  overflow: hidden;
}

.sub-tab__ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .sub-tab__ul {
  display: inline-block;
}

.sub-tab__ul__li {
  flex: 1;
  min-width: 140px;
}

.sub-tab__ul__li a {
  color: #333;
  display: block;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  padding-top: 12px;
  padding-bottom: 12px;
  white-space: nowrap;
}

.sub-tab__ul__li a:hover {
  text-decoration: underline;
}

.sub-tab__ul__li a:focus {
  background-color: #3963b9;
  color: #fff;
  border-top: 1px solid #3963b9;
  border-left: 1px solid #3963b9;
}

.sub-tab__ul__li.on a {
  background-color: #2550a7;
  color: #fff;
  border-top: 1px solid #2550a7;
  border-left: 1px solid #2550a7;
}

/*서브탭2*/
.sub-tab2 {
  overflow: hidden;
}

.sub-tab2__ul {
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  margin-bottom: 30px;
}

.sub-tab2__ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .sub-tab2__ul {
  display: inline-block;
}

.sub-tab2__ul__li {
  float: left;
  box-sizing: border-box;
}

@media screen and (min-width: 1px) and (max-width: 900px) {
  .sub-tab2__ul__li {
    width: 25% !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .sub-tab2__ul__li {
    width: 33.33% !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 540px) {
  .sub-tab2__ul__li {
    width: 50% !important;
  }
}

.sub-tab2__ul__li a {
  color: #333;
  display: block;
  border: 1px solid #ddd;
  margin-top: -1px;
  margin-left: -1px;
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: nowrap;
  box-sizing: border-box;
}

.sub-tab2__ul__li a:hover {
  text-decoration: underline;
}

.sub-tab2__ul__li.on a {
  color: #2550a7;
  position: relative;
  z-index: 99999;
  font-weight: bold;
  padding-top: 9px;
  padding-bottom: 9px;
  border: 2px solid #2550a7;
}

.sub-tab2.menu-num-2 > ul > li {
  width: 50%;
}

.sub-tab2.menu-num-3 > ul > li {
  width: 33.33%;
}

* + html .sub-tab2.menu-num-3 > ul > li {
  width: 33%;
}

.sub-tab2.menu-num-4 > ul > li {
  width: 25%;
}

.sub-tab2.menu-num-5 > ul > li {
  width: 20%;
}

.sub-tab2.menu-num-6 > ul > li {
  width: 16.66%;
}

* + html .sub-tab2.menu-num-6 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-7 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-8 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-9 > ul > li {
  width: 16.66%;
}

.sub-tab2 * + html .menu-num-9 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-10 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-11 > ul > li {
  width: 16.66%;
}

* + html .sub-tab2.menu-num-11 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-12 > ul > li {
  width: 16.66%;
}

* + html .sub-tab2.menu-num-12 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-13 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-14 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-15 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-16 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-17 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-18 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-19 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-20 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-21 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-22 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-23 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-24 > ul > li {
  width: 16.66%;
}

.sub-tab2.menu-num-25 > ul > li {
  width: 16.66%;
}

/* 회원가입 인트로 */
.join-wrap {
  margin-left: -20px;
}

.join-wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-wrap {
  display: inline-block;
}

.join-wrap__box {
  float: left;
}

.join-wrap__box__in {
  margin-left: 20px;
  padding-top: 60px;
  padding-bottom: 10px;
  min-height: 300px;
  border: 1px solid #000;
  border-radius: 5px;
  text-align: center;
}

.join-wrap__box__in__tit {
  display: block;
  font-size: 28px;
  margin-bottom: 35px;
  position: relative;
  padding-top: 80px;
}

.join-wrap__box__in__desc {
  margin-bottom: 30px;
}

.join-wrap__box__in__go {
  display: inline-block;
  color: #fff;
  background-color: #da4453;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.join-wrap__box__in__go:hover, .join-wrap__box__in__go:focus {
  background-color: #da5b67;
  color: gold;
}

.join-wrap__box--1 {
  width: 50%;
}

.join-wrap__box--1 strong:before {
  display: block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px 0px;
  background-image: url("../images/sprite.png");
  width: 51px;
  min-width: 51px;
  height: 73px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.join-wrap__box--2 {
  width: 50%;
}

.join-wrap__box--2 strong:before {
  display: block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -576px;
  background-image: url("../images/sprite.png");
  width: 88px;
  min-width: 88px;
  height: 72px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-wrap {
    margin-left: 0;
  }
  .join-wrap__box {
    float: none;
    margin: 0 20px;
    margin-bottom: 10px;
    width: auto;
  }
  .join-wrap__box__in {
    margin-left: 0;
  }
}

/* 회원가입 스탭
===========================*/
/* step top */
.join-step__top {
  margin-bottom: 50px;
}

.join-step__top__ul {
  margin-left: -7px;
}

.join-step__top__ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-step__top__ul {
  display: inline-block;
}

.join-step__top__ul__li {
  float: left;
  width: 33%;
}

.join-step__top__ul__li:first-child {
  width: 34%;
}

.join-step__top__ul__li p {
  white-space: nowrap;
  background-color: #f7f8fc;
  border: 1px solid #d6dde7;
  padding: 10px 20px;
  margin-left: 7px;
  position: relative;
}

.join-step__top__ul__li p:after {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -1120px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 1px;
}

.join-step__top__ul__li.on p {
  color: #fff;
  background-color: #444444;
  border: 1px solid #444444;
}

.join-step__top__ul__li.on p:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -65px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 13px;
}

@media screen and (min-width: 769px) and (max-width: 900px) {
  .join-step__top__ul__li p {
    font-size: 13px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-step__top__ul__li {
    display: none;
  }
  .join-step__top__ul__li.on {
    display: block;
    width: 100%;
  }
}

/* step1 */
.join-step--1__tit {
  margin-bottom: 20px;
}

.join-step--1__tit .label-text {
  font-size: 16px;
  margin-left: 5px;
}

.join-step--1__tit.allcheck {
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 60px;
}

.join-step--1__textbox {
  height: 140px;
  overflow: auto;
  margin-bottom: 30px;
  border: 1px solid #eee;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 1px solid #000;
  color: #666;
}

.join-step__confirm {
  background-color: #eeeeee;
  border-top: 1px solid #000;
  padding: 50px 30px;
  text-align: center;
  margin-bottom: 40px;
  width: 48%;
  margin-left: 2%;
  float: left;
  box-sizing: border-box;
}

.join-step__confirm__tit {
  display: block;
  font-size: 40px;
  margin-bottom: 20px;
}

.join-step__confirm__desc {
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  min-height: 52px;
}

.join-step__confirm__btn {
  margin-left: 24% !important;
  margin-right: 24% !important;
  margin-top: 100px !important;
  position: relative;
}

.join-step__confirm__btn {
  display: block;
  color: #fff;
  background-color: #0e5390;
  font-size: 16px;
  padding: 10px 0;
  font-weight: 500;
  transition: all 0.3s ease 0s;
  text-align: center;
}

.join-step__confirm__btn:hover, .join-step__confirm__btn:focus {
  background-color: #3174b0;
}

.join-step__confirm__btn:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -872px -123px;
  background-image: url("../images/sprite.png");
  width: 49px;
  min-width: 49px;
  height: 71px;
  position: absolute;
  top: -83px;
  left: 50%;
  transform: translateX(-50%);
}

.join-step__info {
  margin-bottom: 20px;
}

.join-step__info__tit {
  position: relative;
  padding-left: 22px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.join-step__info__tit:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -536px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 21px;
  position: absolute;
  left: 0;
  top: -2px;
}

.join-step__info__ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-step__info__ul {
  display: inline-block;
}

.join-step__info__ul > li {
  text-align: left;
  position: relative;
  padding-left: 12px;
  line-height: 1.6;
}

.join-step__info__ul > li:before {
  position: absolute;
  top: 12px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -678px -1242px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 3px;
}

.join-step__confirm-wrap {
  margin-left: -2%;
}

.join-step__confirm-wrap:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .join-step__confirm-wrap {
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-step__confirm__tit {
    font-size: 28px;
  }
  .join-step__confirm-wrap {
    margin-left: 0;
  }
  .join-step__confirm {
    float: none;
    margin-left: 0;
    width: auto;
    margin-bottom: 10px;
  }
}

/* step2 */
.serius {
  color: #ff5054;
}

.join-form {
  margin: 0 5%;
  margin-bottom: 30px;
}

.join-form__line {
  border-bottom: 1px solid #d1d1d1;
  padding: 20px 0;
  position: relative;
}

.join-form__line__tit {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 16px;
  transform: translateY(-50%);
}

.join-form__line__in {
  padding-left: 220px;
}

.join-form__line__in__input {
  border: 1px solid #000;
  height: 34px;
  width: 50%;
  line-height: 34px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__input2 {
  border: 1px solid #000;
  height: 34px;
  width: 15%;
  line-height: 34px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__input3 {
  border: 1px solid #000;
  height: 34px;
  width: 25%;
  line-height: 34px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__input4 {
  border: 1px solid #000;
  height: 34px;
  width: 35%;
  line-height: 34px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.join-form__line__in__selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #ddd;
}

.join-form__line__in__selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -167px -1310px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .join-form__line__in__selectbox:after {
  display: none;
}

.join-form__line__in__selectbox select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 130px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.join-form__line__in__selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .join-form__line__in__selectbox select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.join-form__line__in__selectbox select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-form__line__in__selectbox select {
    min-width: 100px;
  }
}

.join-form__line__in__btn {
  color: #fff;
  background-color: #000;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.join-form__line__in__btn.st2 {
  background-color: #0050a0;
}

.join-form__line__in__btn:focus {
  background-color: #333;
  color: gold;
}

.join-form__line__in__btn.st2:focus {
  background-color: #00264c;
  color: gold;
}

.join-subs {
  margin-bottom: 30px;
}

.join-subs__box {
  background-color: #f6f6f6;
  margin-bottom: 7px;
  font-size: 15px;
  height: 58px;
  line-height: 58px;
  position: relative;
  padding-left: 20px;
}

.join-subs__box__radiobox {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

.join-subs__box__radiobox .in_check {
  margin-left: 20px;
}

.ok-btn {
  display: inline-block;
  color: #fff;
  background-color: #da4453;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.ok-btn:hover, .ok-btn:focus {
  background-color: #da5b67;
  color: gold;
}

.cancel-btn {
  position: relative;
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 16px;
  padding: 0px 50px 0px 18px;
  border: 1px solid #000;
  font-weight: 500;
  line-height: 54px;
  font-weight: 600;
  height: 54px;
  transition: all 0.3s ease 0s;
  min-width: 70px;
  box-sizing: border-box;
}

.cancel-btn:before {
  width: 18px;
  height: 11px;
  content: '';
  position: absolute;
  background: url(../../resources/images/icons/btn-type4.png) center center no-repeat;
  right: 20px;
  top: 50%;
  margin-top: -5.5px;
}

.cancel-btn:hover, .cancel-btn:focus {
  background-color: #eee;
  color: #000;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .join-form__line__tit {
    position: relative;
    top: 0;
    left: 0;
    font-size: 16px;
    transform: translateY(0);
    margin-bottom: 5px;
  }
  .join-form__line__in {
    padding-left: 0;
  }
  .join-form__line__in__input {
    width: 100%;
    box-sizing: border-box;
  }
  .join-form__line__in__input3 {
    width: 50%;
    box-sizing: border-box;
  }
  .join-form__line__in__input2 {
    width: 30%;
    box-sizing: border-box;
  }
  .join-form__line__in__input4 {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .join-form__line__in__btn {
    margin-top: 10px;
    display: block;
    width: auto;
    text-align: center;
  }
  .join-subs__box {
    height: auto;
    line-height: 1.6;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .join-subs__box__radiobox {
    position: relative;
    top: 0;
    right: 0;
    transform: translateY(0);
  }
}

/* step 3 */
.join-step__final {
  text-align: center;
  padding-top: 30px;
}

.join-step__final__text1 {
  position: relative;
  padding-top: 60px;
  font-size: 22px;
  margin-bottom: 20px;
}

.join-step__final__text1 span {
  color: #ff5054;
}

.join-step__final__text1:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -242px -478px;
  background-image: url("../images/sprite.png");
  width: 63px;
  min-width: 63px;
  height: 46px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.join-step__final__text2 {
  font-size: 15px;
  color: #666;
  margin-bottom: 80px;
}

.join-step__final__text2 strong {
  color: #000;
}

.idpass-wrap {
  border: 1px solid #333;
  width: 760px;
  box-sizing: border-box;
  margin: 0 auto;
}

.idpass-wrap__top {
  background-color: #f7f7f7;
  border-bottom: 1px solid #333;
  text-align: center;
  padding: 10px;
}

.idpass-wrap__top__list {
  margin-right: 10px;
  padding-left: 20px;
  position: relative;
  display: inline-block;
}

.idpass-wrap__top__list:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -693px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.idpass-wrap__top__list.on:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -755px;
  background-image: url("../images/sprite.png");
  width: 12px;
  min-width: 12px;
  height: 12px;
}

.idpass-wrap__form {
  padding: 60px;
  text-align: center;
}

.idpass-wrap__form .line-box {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.idpass-wrap__form__label {
  width: 100px;
  display: inline-block;
  font-size: 15px;
  text-align: left;
  font-weight: bold;
}

.idpass-wrap__form__input {
  margin: 0 auto;
  border: 1px solid #000;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.idpass-wrap__form__input--1 {
  width: 476px;
}

.idpass-wrap__form__input--2 {
  width: 150px;
}

.idpass-wrap__form__select {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #ddd;
}

.idpass-wrap__form__select:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -167px -1310px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .idpass-wrap__form__select:after {
  display: none;
}

.idpass-wrap__form__select select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 150px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.idpass-wrap__form__select select::-ms-expand {
  display: none;
}

.lte-ie9 .idpass-wrap__form__select select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.idpass-wrap__form__select select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .idpass-wrap__form__select select {
    min-width: 120px;
  }
}

.idpass-wrap__form__submit {
  margin-top: 30px;
}

.idpass-wrap__form__submit {
  color: #fff;
  background-color: #000;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.idpass-wrap__form__submit.st2 {
  background-color: #0050a0;
}

.idpass-wrap__form__submit:focus {
  background-color: #333;
  color: gold;
}

.idpass-wrap__form__submit.st2:focus {
  background-color: #00264c;
  color: gold;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .idpass-wrap {
    width: 100%;
    text-align: left;
  }
  .idpass-wrap__form {
    padding: 30px 14px;
    text-align: left;
  }
  .idpass-wrap__form__label {
    width: 100%;
  }
  .idpass-wrap__form__input--1 {
    width: 100%;
  }
  .idpass-wrap__form__input--2 {
    width: 50%;
    margin-bottom: 5px;
  }
  .idpass-wrap__form__select {
    margin-bottom: 5px;
  }
}

.login-wrap {
  padding-top: 100px;
  text-align: center;
}

.login-wrap__desc {
  font-size: 15px;
  color: #666;
  margin-bottom: 30px;
}

.login-wrap__form {
  margin-bottom: 20px;
}

.login-wrap__form__input {
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
  width: 500px;
  border: 1px solid #000;
  height: 58px;
  line-height: 58px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.login-wrap__form__submit {
  color: #fff;
  background-color: #000;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  width: 500px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 600;
}

.login-wrap__form__submit:focus {
  background-color: #333;
  color: gold;
}

.login-wrap__form--bottom {
  width: 500px;
  margin: 0 auto;
  display: flex;
}

.login-wrap__form--bottom a {
  flex: 1;
  position: relative;
}

.login-wrap__form--bottom a:before {
  position: absolute;
  top: 5px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1437px 0px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.login-wrap__form--bottom a:first-child:before {
  display: none !important;
}

@media screen and (min-width: 1px) and (max-width: 600px) {
  .login-wrap {
    padding-top: 50px;
  }
  .login-wrap__desc {
    font-size: 14px;
  }
  .login-wrap__form__input {
    width: 100%;
  }
  .login-wrap__form__submit {
    width: 100%;
  }
  .login-wrap__form--bottom {
    width: 100%;
  }
}

/*회원가입 - 로그인*/
.login__wrap {
  max-width: 1200px;
  width: 100%;
  border-top: 3px solid #333333;
  margin: 0 auto;
  padding: 0 140px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  background: url(../../resources/images/cont/login-bg.jpg) right top no-repeat, url(../../resources/images/cont/login-bg2.jpg) left bottom no-repeat;
}

.login__btn1 {
  color: #fff;
  background-color: #0050a0;
  min-width: 140px;
  height: 54px;
  display: inline-flex;
  font-size: 16px;
  justify-content: space-around;
  align-items: center;
}

.login__btn2 {
  color: #fff;
  background-color: #26272b;
  min-width: 140px;
  height: 54px;
  display: inline-flex;
  font-size: 16px;
  justify-content: space-around;
  align-items: center;
}

.login__tit span {
  font-size: 50px;
  color: #143553;
  padding-top: 75px;
  display: inline-block;
  font-weight: 500;
}

.login__tit p {
  font-size: 18px;
  color: #333333;
}

.login__in {
  margin: 0 auto;
  display: inline-block;
  padding: 35px 0;
}

.login__in__left {
  float: left;
}

.login__in__left p {
  color: #111111;
  margin-bottom: 10px;
}

.login__in__left label.login_id, .login__in__left label.login_pw {
  width: 80px;
  display: inline-block;
  text-align: left;
  font-size: 18px;
}

.login__in__left input {
  box-sizing: border-box;
  border: 1px solid #eeeeee;
  height: 58px;
  padding-left: 20px;
}

.login__in__left input::placeholder {
  color: #999999;
  font-size: 16px;
}

.login .login__in__right {
  float: left;
  margin-left: 5px;
}

.login__in__right {
  float: left;
}

.login__in__right a {
  height: 126px;
  line-height: 126px;
  margin-left: 10px;
  color: #fff;
  background: #1a1b1e;
  padding: 0 36px;
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
}

.login__middle {
  padding: 50px 0 70px 0;
  border-top: 1px solid #e0e0e0;
}

.login__middle p {
  display: inline-block;
  margin-right: 15px;
}

.login__bottom {
  background-color: #f8f8f8;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding-top: 44px;
  padding-bottom: 44px;
}

.login__bottom__in {
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  background: url("../../resources/images/cont/login-img1.png") left center no-repeat;
  padding-left: 100px;
  display: flex;
  min-height: 80px;
  align-items: center;
  box-sizing: border-box;
}

.login__bottom__in__text {
  font-size: 18px;
  color: #111111;
  font-weight: bold;
  padding-right: 30px;
}

.login__bottom__in__text span {
  font-size: 16px;
  color: #555555;
}

.login__bottom .btn4 {
  background-color: transparent;
}

.login__step {
  background-color: #e6e9ee;
  padding-top: 22px;
  padding-bottom: 20px;
}

.login__step__ul {
  max-width: 412px;
  width: 100%;
  display: flex;
  margin: 0 auto;
}

.login__step__ul li {
  color: #fff;
  background-color: #1a1b1e;
  border-radius: 100px;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  position: relative;
  margin-left: 50px;
}

.login__step__ul li.on {
  background-color: #Fff;
  color: #111111;
  border: 1px solid #111111;
  border-radius: 100px;
  width: 130px;
  height: 44px;
}

.login__step__ul li.on span {
  font-weight: bold;
  position: relative;
  width: 18px;
  text-align: left;
  display: inline-block;
  margin-right: 8px;
}

.login__step__ul li.on span:before {
  content: '';
  position: absolute;
  background-color: #cccccc;
  width: 1px;
  height: 14px;
  right: 0;
  top: 50%;
  margin-top: -8px;
}

.login__step__ul li:before {
  content: '';
  width: 30px;
  height: 1px;
  border-top: 1px dashed #26272b;
  position: absolute;
  left: -40px;
  top: 50%;
  margin-top: -0.5px;
}

.login__step__ul li:first-child {
  margin-left: 0;
}

.login__step__ul li:first-child:before {
  display: none;
}

.login__step1__ol li {
  font-size: 15px;
  line-height: 25px;
  margin-left: 30px;
  list-style-type: decimal-leading-zero;
}

.login__step1__ol li ol {
  margin-bottom: 10px;
  margin-left: 20px;
}

.login__step1__ol li ol li {
  margin: 0;
  list-style-type: lower-alpha;
}

.login__step1__ol li ol li ol {
  margin-bottom: 10px;
  margin-left: 20px;
}

.login__step1__ol li ol li ol li {
  margin: 0;
  list-style-type: lower-roman;
}

.login__step1__bottom__btn {
  float: right;
  display: flex;
  gap: 5px;
}

.login__step1__box {
  border-top: 3px solid #333333;
  background-color: #f8f8f8;
  height: 450px;
  padding: 40px 50px;
  font-size: 16px;
  overflow: auto;
  box-sizing: border-box;
}

.login__step1__box h4 {
  font-size: 24px;
  font-weight: bold;
}

.login__step1__box h5 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.login__step1__box::-webkit-scrollbar {
  width: 8px;
}

.login__step1__box::-webkit-scrollbar-thumb {
  background-color: #2f3542;
  border-radius: 10px;
  background-clip: padding-box;
}

.login__step1__box::-webkit-scrollbar-track {
  background-color: #eeeeee;
}

.login__step2 {
  margin-top: 50px;
  border-top: 3px solid #333333;
  text-align: center;
  background: url("../../resources/images/cont/login-img2.png") center 55px no-repeat;
  padding-top: 320px;
}

.login__step2 h4 {
  font-size: 24px;
  font-weight: bold;
}

.login__step2 p {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.login__step2__in {
  margin-top: 60px;
  background-color: #f8f8f8;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 16px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.login__step2__in span {
  color: #0050a0;
}

.label_section {
  display: none;
}

.privacy_wrap {
  position: relative;
  /* max-width: 1200px; */
  width: 100%;
  margin: 0 auto;
}

.privacy_wrap .personal_info {
  margin: 20px 0;
  padding: 15px;
  border: solid 2px #e3e4e5;
  word-break: keep-all;
}

.privacy_wrap .label_section {
  margin-bottom: 20px;
}

.privacy_wrap .info {
  margin: 10px 0 0 10px;
  color: #ff7817;
  font-weight: 400;
}

.privacy_wrap .tit {
  border-top: 2px solid black;
  text-align: center;
  padding: 0.6rem 0;
  background: #f3f3f3;
  color: black;
  font-weight: 500;
  font-size: 18px !important;
}

.privacy_wrap .tit:after {
  width: 0;
  height: 0;
}

.privacy_wrap .tit::first-letter {
  color: #000;
  font-weight: normal;
}

.privacy_wrap .label_wrap {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  /* display: flex;
        flex-flow: row wrap;
        justify-content: space-around; */
  position: relative;
  gap: 10px;
}

.privacy_wrap .label_box {
  /* width: 16%;
        min-width: 130px; */
  box-sizing: border-box;
  padding: 20px 10px;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  position: relative;
  border: 1px solid #1647aa;
}

.privacy_wrap .label_box div[id^='label_layer_'] {
  display: none;
}

.privacy_wrap .label_box:hover div[id^='label_layer_'],
.label_box:focus div[id^='label_layer_'] {
  display: block;
}

.privacy_wrap .label_cnt {
  font-size: 14px;
  margin-top: 20px;
  font-weight: 400;
  color: #111;
}

.privacy_wrap .label_layer {
  position: absolute;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #1647aa;
  width: 400px;
  z-index: 1;
}

.privacy_wrap .label_layer {
  top: -1px;
  left: 110%;
}

.privacy_wrap .label_box:nth-child(6n) .label_layer,
.privacy_wrap .label_box:nth-child(6n-1) .label_layer {
  top: 0;
  right: 110%;
  left: auto;
}

.privacy_wrap .label_layer .label_desc_top {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  background-color: #1647aa;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.privacy_wrap .label_layer .label_desc_top p {
  color: #fff;
}

.privacy_wrap .label_layer .label_desc_btm {
  width: 100%;
  box-sizing: border-box;
  padding: 15px 20px;
}

/* 목차 */
.privacy_wrap .privacy_list ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.privacy_wrap .privacy_list ul li {
  border-bottom: 1px solid #ededed;
  padding: 8px 5px;
}

.privacy_wrap .privacy_list ul li a img {
  width: 25px;
  display: inline-block;
  margin: 0 5px;
  image-rendering: -webkit-optimize-contrast;
}

.privacy_wrap .privacy_list ul li a {
  display: flex;
  align-items: flex-start;
}

.privacy_wrap .privacy_detail {
  margin: 30px 0;
}

/*상세내용*/
.privacy_detail {
  word-break: keep-all;
}

.privacy_detail > div {
  margin-bottom: 25px;
}

.privacy_detail .pvc_tit {
  display: flex;
  align-items: flex-start;
  margin: 0 0 10px 0;
  font-weight: 500;
  font-size: 18px;
}

.privacy_detail .pvc_tit img {
  display: inline-block;
  margin: 0 10px 0 0;
  width: 30px;
  image-rendering: -webkit-optimize-contrast;
}

.privacy_detail > div ol,
.privacy_detail > div ul {
  margin: 10px 0 10px 20px;
  text-indent: -20px;
  line-height: 120%;
}

.privacy_detail ol strong,
.privacy_detail ul strong {
  display: block;
  font-weight: 500;
}

.privacy_detail div > ol li,
.privacy_detail div > ul li {
  margin-bottom: 8px;
  line-height: 140%;
}

.privacy_detail div > ol > li > ol {
  margin-left: 15px;
}

.privacy_detail .download {
  display: inline-block;
  margin: 5px 15px 5px 0;
  border: 1px solid #0a55a5;
  color: #1647aa;
  padding: 5px 15px;
}

.privacy_detail figcaption {
  font-size: 13px;
  color: #646464;
}

/* .privacy_detail div > ol > li ol,
      .privacy_detail div > ol > li ul {
        margin: 10px 0;
      } */
@media all and (max-width: 1024px) {
  .privacy_wrap .label_wrap {
    grid-template-columns: repeat(4, 1fr);
  }
  .privacy_wrap .label_box:nth-child(6n) .label_layer,
  .privacy_wrap .label_box:nth-child(6n-1) .label_layer {
    top: 0;
    left: 110%;
  }
  .privacy_wrap .label_box:nth-child(4n) .label_layer,
  .privacy_wrap .label_box:nth-child(4n-1) .label_layer {
    top: 0;
    right: 110%;
    left: auto;
  }
  .privacy_wrap .label_layer {
    width: 300px;
  }
}

@media all and (max-width: 600px) {
  .privacy_wrap .label_wrap {
    grid-template-columns: repeat(3, 1fr);
  }
  .privacy_wrap .label_box {
    padding: 20px 15px;
  }
  .privacy_wrap .label_box:nth-child(6n) .label_layer,
  .privacy_wrap .label_box:nth-child(6n-1) .label_layer,
  .privacy_wrap .label_box:nth-child(4n) .label_layer,
  .privacy_wrap .label_box:nth-child(4n-1) .label_layer {
    top: 160px;
    left: 0%;
  }
  .privacy_wrap .label_wrap .label_box .label_layer {
    top: 160px;
    left: 0%;
  }
  .privacy_wrap .label_box:nth-child(3n) .label_layer,
  .privacy_wrap .label_box:nth-child(3n-1) .label_layer {
    top: 160px;
    left: auto;
    right: 0;
  }
  .privacy_wrap .privacy_list ul {
    grid-template-columns: repeat(1, 1fr);
  }
  .privacy_wrap .privacy_list ul li:last-child {
    display: none;
  }
}

@media all and (max-width: 360px) {
  .privacy_wrap .label_wrap {
    grid-template-columns: repeat(2, 1fr);
  }
  .privacy_wrap .label_box:nth-child(6n) .label_layer,
  .privacy_wrap .label_box:nth-child(6n-1) .label_layer,
  .privacy_wrap .label_box:nth-child(4n) .label_layer,
  .privacy_wrap .label_box:nth-child(4n-1) .label_layer,
  .privacy_wrap .label_box:nth-child(3n) .label_layer,
  .privacy_wrap .label_box:nth-child(3n-1) .label_layer {
    top: 160px;
    left: 0%;
  }
  .privacy_wrap .label_wrap .label_box .label_layer {
    top: 160px;
    left: 0%;
  }
  .privacy_wrap .label_box:nth-child(2n) .label_layer {
    top: 160px;
    left: auto;
    right: 0;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .login__wrap {
    padding: 0 20px;
  }
  .login__bottom {
    padding-left: 20px;
  }
  .login__bottom__in {
    display: block;
  }
  .login__bottom__in__text {
    line-height: 1.3;
  }
  .login__bottom__in__text span {
    margin-top: 5px;
    margin-bottom: 10px;
    display: block;
  }
  .login__step1__box {
    padding: 20px 20px;
  }
  .login__step1__box h4 {
    font-size: 20px;
  }
  .login__step1__bottom__btn {
    width: 100%;
    float: none;
    margin-top: 5px;
    flex-direction: column;
  }
  .login__step__ul {
    justify-content: center;
  }
  .login__step__ul li {
    margin-left: 10px;
  }
  .login__step__ul li:before {
    display: none;
  }
  .login__step2 h4 {
    font-size: 22px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) and (min-width: 1px) and (max-width: 550px) {
  .login__wrap .login__tit span {
    padding-top: 30px;
    font-size: 44px;
  }
  .login__wrap .login__tit p {
    font-size: 16px;
  }
  .login__wrap .login__in {
    width: 100%;
    padding: 20px 0;
  }
  .login__wrap .login__in__left {
    float: none;
    text-align: left;
    text-align: center;
  }
  .login__wrap .login__in__left input {
    width: 70%;
  }
  .login__wrap .login__in__left p:last-child {
    margin-left: 0;
  }
  .login__wrap .login__in__right {
    float: none;
    margin-left: 0;
  }
  .login__wrap .login__in__right a {
    width: 100%;
    margin-left: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 60px;
    line-height: 60px;
    margin-top: 5px;
  }
  .login__wrap .login__middle {
    padding: 20px 0 20px 0;
  }
  .login__wrap .login__middle > a {
    margin-bottom: 5px;
    display: block;
    width: 100%;
    margin-left: 0 !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

/*회원가입 첫페이지 기업회원인지 개인회원인지*/
.login__first-page {
  max-width: 880px;
  width: 100%;
  margin: 0 auto;
}

.login__first-page__ul {
  display: flex;
}

.login__first-page__ul li {
  width: 50%;
  text-align: center;
  padding-top: 320px;
}

.login__first-page__ul li h4 {
  font-size: 40px;
  font-weight: bold;
  color: #111111;
}

.login__first-page__ul li p {
  font-size: 18px;
  color: #555555;
  margin-top: 20px;
  margin-bottom: 30px;
}

.login__first-page__ul li:first-child {
  background: url(../../resources/images/cont/login0-img1.png) center 60px no-repeat;
}

.login__first-page__ul li:last-child {
  background: url(../../resources/images/cont/login0-img2.png) center 60px no-repeat;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .login__first-page__ul li h4 {
    font-size: 20px;
  }
  .login__first-page__ul li p {
    font-size: 16px;
  }
  .login__first-page__ul li {
    padding-top: 180px;
  }
  .login__first-page__ul li:first-child {
    background-size: 100px;
  }
  .login__first-page__ul li:last-child {
    background-size: 100px;
  }
}

.guide-box__table {
  margin-bottom: 30px;
  border-top: 2px solid #111111;
  border-bottom: 0px solid #000;
}

.guide-box__table table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.guide-box__table table th:first-child,
.guide-box__table table td:first-child {
  border-left: 0;
}

.guide-box__table table th:last-child,
.guide-box__table table td:last-child {
  border-right: 0;
}

.guide-box__table thead tr:first-child th {
  border-top: 0;
}

.guide-box__table thead th {
  border-bottom: 0px;
  text-align: center;
}

.guide-box__table th {
  border: 1px solid #dddddd;
  padding: 12px 0;
  color: #111111;
  text-align: center;
  background-color: #f8f8f8;
}

.guide-box__table td {
  border: 1px solid #dddddd;
  color: #444444;
  font-size: 16px;
  padding: 12px 5px;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
}

.guide-box__table.left-td td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

.guide-box__table td.left-td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

@media (max-width: 768px) {
  .guide-box__table {
    overflow: auto;
  }
  .guide-box__table.res {
    padding-top: 10px;
  }
  .guide-box__table.res table th:first-child, .guide-box__table.res table td:first-child {
    border-left: 1px solid #ddd !important;
  }
  .guide-box__table.res table th:last-child, .guide-box__table.res table td:last-child {
    border-right: 1px solid #ddd !important;
  }
  .guide-box__table.res table {
    min-width: auto;
  }
  .guide-box__table.res colgroup {
    display: none;
  }
  .guide-box__table.res thead {
    display: none;
  }
  .guide-box__table.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .guide-box__table.res th {
    display: none;
  }
  .guide-box__table.res td {
    display: flex;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .guide-box__table.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.guide-box__table table colgroup .col-1 {
  width: 40%;
}

.guide-box__table table colgroup .col-2 {
  width: 30%;
}

.guide-box__table table colgroup .col-3 {
  width: 30%;
}

.guide-box__table table td {
  text-align: left;
}

.guide-box__box {
  border: 1px solid #ddd;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 20px;
  margin-bottom: 20px;
}

.guide-box__box__item {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
}

.guide-box__box__item:last-child {
  margin-bottom: 0px;
}

.h3Type1 {
  color: #111111;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 15px;
  padding-top: 0px;
  position: relative;
}

@media (max-width: 768px) {
  .h3Type1 {
    font-size: 20px;
  }
}

.h3Type2 {
  color: #000;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .h3Type2 {
    font-size: 20px;
  }
}

.h4Type1 {
  position: relative;
  padding-left: 30px;
  color: #333;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .h4Type1 {
    font-size: 15px;
  }
}

.h4Type1:before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 4px;
}

.h4Type1__1:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -280px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.h4Type1__2:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -420px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.h4Type1__3:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -490px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.h4Type1__4:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -560px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.h4Type1__5:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -630px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.h4Type1__6:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -700px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.h4Type1__7:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -770px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.h4Type1__8:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -840px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.h4Type1__9:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -910px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.h4Type1__10:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1237px -350px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.h4Type2 {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  position: relative;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .h4Type2 {
    font-size: 14px;
  }
}

.h4Type2:before {
  content: ' ';
  position: absolute;
  top: 12px;
  left: 0;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -340px -1310px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.btn1 {
  display: block;
  color: #fff;
  background-color: #0e5390;
  font-size: 16px;
  padding: 10px 0;
  font-weight: 500;
  transition: all 0.3s ease 0s;
  text-align: center;
}

.btn1:hover, .btn1:focus {
  background-color: #3174b0;
}

.btn2 {
  color: #fff;
  background-color: #000;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.btn2.st2 {
  background-color: #0050a0;
}

.btn2:focus {
  background-color: #333;
  color: gold;
}

.btn2.st2:focus {
  background-color: #00264c;
  color: gold;
}

.btn3 {
  display: inline-block;
  color: #fff;
  background-color: #da4453;
  font-size: 19px;
  padding: 20px 60px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 100px;
  text-align: center;
}

.btn3:hover, .btn3:focus {
  background-color: #da5b67;
  color: gold;
}

.btn4 {
  position: relative;
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 16px;
  padding: 0px 50px 0px 18px;
  border: 1px solid #000;
  font-weight: 500;
  line-height: 54px;
  font-weight: 600;
  height: 54px;
  transition: all 0.3s ease 0s;
  min-width: 70px;
  box-sizing: border-box;
}

.btn4:before {
  width: 18px;
  height: 11px;
  content: '';
  position: absolute;
  background: url(../../resources/images/icons/btn-type4.png) center center no-repeat;
  right: 20px;
  top: 50%;
  margin-top: -5.5px;
}

.btn4:hover, .btn4:focus {
  background-color: #eee;
  color: #000;
}

.btn5 {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 17px;
  padding: 13px 30px;
  border: 1px solid #000;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 50px;
  text-align: center;
}

.btn5:hover, .btn5:focus {
  background-color: #eee;
  color: #000;
}

.btn6 {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 16px;
  padding: 13px 60px 13px 20px;
  border: 1px solid #000;
  transition: all 0.3s ease 0s;
  min-width: 50px;
  text-align: center;
  position: relative;
}

.btn6:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -1196px;
  background-image: url("../images/sprite.png");
  width: 10px;
  min-width: 10px;
  height: 10px;
}

.btn6:hover, .btn6:focus {
  background-color: #eee;
  color: #000;
}

.btn7 {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 16px;
  padding: 0px 18px 0px 50px;
  height: 54px;
  line-height: 54px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 70px;
  text-align: center;
  border: 1px solid #000;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
}

.btn7:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -620px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 14px;
}

.btn7:hover, .btn7:focus {
  background-color: #eee;
}

.downBtn1 {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 16px;
  padding: 0px 50px 0px 18px;
  height: 54px;
  line-height: 54px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 70px;
  text-align: center;
  border: 1px solid #000;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
}

.downBtn1:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -1007px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 14px;
}

.downBtn1:hover, .downBtn1:focus {
  background-color: #ddd;
}

.prvBtn {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 14px;
  padding: 0px 7px 0px 20px;
  height: 26px;
  line-height: 26px;
  transition: all 0.3s ease 0s;
  min-width: 68px;
  text-align: center;
  border: 1px solid #000;
  position: relative;
  vertical-align: middle;
}

.prvBtn:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -244px -1242px;
  background-image: url("../images/sprite.png");
  width: 14px;
  min-width: 14px;
  height: 14px;
}

.prvBtn:hover, .prvBtn:focus {
  background-color: #fafafa;
}

.downBtn2 {
  display: inline-block;
  color: #0c5493;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 40px 10px 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
  border: 1px solid #0c5493;
  position: relative;
}

.downBtn2:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1374px -1007px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 13px;
}

.downBtn2:hover, .downBtn2:focus {
  background-color: #1e79c8;
  color: gold;
}

.goBtn {
  display: inline-block;
  color: #000;
  background-color: #fff;
  font-size: 15px;
  padding: 10px 25px 10px 40px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 80px;
  text-align: center;
  border: 1px solid #000;
  position: relative;
}

.goBtn:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -942px;
  background-image: url("../images/sprite.png");
  width: 15px;
  min-width: 15px;
  height: 15px;
}

.goBtn:hover, .goBtn:focus {
  background-color: #fafafa;
}

.btnSmall-1 {
  display: inline-block;
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 15px;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 50px;
  text-align: center;
}

.btnSmall-1:hover, .btnSmall-1:focus {
  background-color: #eee;
}

.btnSmall-2 {
  display: inline-block;
  color: #fff;
  background-color: #0a6568;
  border: 1px solid #0a6568;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 15px;
  padding: 0 5px;
  height: 30px;
  line-height: 30px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 50px;
  text-align: center;
}

.btnSmall-2:hover, .btnSmall-2:focus {
  background-color: #1d8185;
}

.btnMore {
  display: inline-block;
  color: #0e5390;
  background-color: #fff;
  border: 1px solid #0e5390;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 15px;
  padding: 0 20px 0 20px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  min-width: 50px;
  text-align: center;
}

.btnMore:hover, .btnMore:focus {
  background-color: #eee;
}

.basicList {
  padding-left: 15px;
}

.basicList > li {
  text-align: left;
  position: relative;
  padding-left: 20px;
  line-height: 1.6;
  margin-bottom: 5px;
}

.basicList > li:before {
  position: absolute;
  top: 12px;
  left: 5px;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -678px -1242px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 3px;
}

.basicList2 > li {
  text-align: left;
  position: relative;
  padding-left: 15px;
  line-height: 1.6;
  margin-bottom: 14px;
}

.basicList2 > li:before {
  position: absolute;
  top: 12px;
  left: 5px;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -393px -1310px;
  background-image: url("../images/sprite.png");
  width: 3px;
  min-width: 3px;
  height: 3px;
}

.basicList3 > li {
  text-align: left;
  position: relative;
  padding-left: 12px;
  line-height: 1.6;
}

.basicList3 > li:before {
  position: absolute;
  top: 12px;
  left: 0;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -678px -1242px;
  background-image: url("../images/sprite.png");
  width: 7px;
  min-width: 7px;
  height: 3px;
}

.basicList4 > li {
  text-align: left;
  position: relative;
  padding-left: 15px;
  line-height: 1.6;
  margin-bottom: 5px;
}

.basicList4 > li:before {
  position: absolute;
  top: 10px;
  left: 5px;
  content: ' ';
  background-color: #000000;
  width: 4px;
  height: 4px;
}

.basicList5 > li {
  color: #999999;
  font-size: 15px;
  text-align: left;
  position: relative;
  padding-left: 20px;
  line-height: 1.6;
  margin-bottom: 2px;
}

.basicList5 > li:before {
  position: absolute;
  top: 7px;
  left: 1px;
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1007px -916px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 11px;
}

.basicTable1 {
  margin-bottom: 30px;
  border-top: 2px solid #111111;
  border-bottom: 0px solid #000;
}

.basicTable1 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.basicTable1 table th:first-child,
.basicTable1 table td:first-child {
  border-left: 0;
}

.basicTable1 table th:last-child,
.basicTable1 table td:last-child {
  border-right: 0;
}

.basicTable1 thead tr:first-child th {
  border-top: 0;
}

.basicTable1 thead th {
  border-bottom: 0px;
  text-align: center;
}

.basicTable1 th {
  border: 1px solid #dddddd;
  padding: 12px 0;
  color: #111111;
  text-align: center;
  background-color: #f8f8f8;
}

.basicTable1 td {
  border: 1px solid #dddddd;
  color: #444444;
  font-size: 16px;
  padding: 12px 5px;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
}

.basicTable1.left-td td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

.basicTable1 td.left-td {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

@media (max-width: 768px) {
  .basicTable1 {
    overflow: auto;
  }
  .basicTable1.res {
    padding-top: 10px;
  }
  .basicTable1.res table th:first-child, .basicTable1.res table td:first-child {
    border-left: 1px solid #ddd !important;
  }
  .basicTable1.res table th:last-child, .basicTable1.res table td:last-child {
    border-right: 1px solid #ddd !important;
  }
  .basicTable1.res table {
    min-width: auto;
  }
  .basicTable1.res colgroup {
    display: none;
  }
  .basicTable1.res thead {
    display: none;
  }
  .basicTable1.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable1.res th {
    display: none;
  }
  .basicTable1.res td {
    display: flex;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable1.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.basicTable2 {
  margin-bottom: 30px;
  border-top: 2px solid #3e997a;
  border-bottom: 0px solid #000;
}

.basicTable2 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.basicTable2 th {
  border: 1px solid #dcdcdc;
  color: #227b5d;
  border-left: 0;
  padding: 13px 0;
  background-color: #edf9f5;
  text-align: left;
  padding-left: 30px;
}

.basicTable2 td {
  border: 1px solid #dcdcdc;
  border-right: 0;
  padding: 13px 5px;
  text-align: left;
  padding-left: 30px;
}

@media (max-width: 768px) {
  .basicTable2 {
    overflow: auto;
  }
  .basicTable2.res {
    padding-top: 10px;
  }
  .basicTable2.res table {
    min-width: auto;
  }
  .basicTable2.res colgroup {
    display: none;
  }
  .basicTable2.res thead {
    display: none;
  }
  .basicTable2.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable2.res th {
    display: none;
  }
  .basicTable2.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable2.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.basicTable3 {
  text-align: center;
  margin-bottom: 30px;
  border-bottom: 1px solid #000;
}

.basicTable3 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.basicTable3 thead th {
  background-color: #333;
  color: #fff;
  border: 1px solid #333;
}

.basicTable3 th {
  border: 1px solid #dcdcdc;
  border-left: 0;
  padding: 18px 0;
  background-color: #f4f4f4;
  padding-left: 30px;
}

.basicTable3 td {
  border: 1px solid #dcdcdc;
  border-right: 0;
  padding: 18px 5px;
  padding-left: 30px;
}

@media (max-width: 768px) {
  .basicTable3 {
    overflow: auto;
  }
  .basicTable3.res {
    padding-top: 10px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .basicTable3.res table {
    min-width: auto;
  }
  .basicTable3.res colgroup {
    display: none;
  }
  .basicTable3.res thead {
    display: none;
  }
  .basicTable3.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable3.res th {
    display: none;
  }
  .basicTable3.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable3.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.basicTable4 {
  margin-bottom: 30px;
  border-top: 2px solid #203c92;
  border-bottom: 1px solid #000;
}

.basicTable4 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
  margin-left: -1px;
}

.basicTable4 thead th {
  border-bottom: 1px solid #203c92;
}

.basicTable4 th {
  border-left: 1px solid #dcdcdc;
  padding: 13px 0;
  background-color: #fbfcff;
  text-align: center;
  color: #000;
}

.basicTable4 tbody th {
  border: 1px solid #dcdcdc;
  padding: 13px 5px;
  text-align: center;
  border-right: 0px;
  border-bottom: 0px;
  background-color: #fbfbfb;
}

.basicTable4 td {
  border: 1px solid #dcdcdc;
  padding: 13px 5px;
  text-align: center;
  border-right: 0px;
  border-bottom: 0px;
}

.basicTable4 td.sbj-line {
  padding-left: 15px;
  text-align: left;
}

.basicTable4 td.sbj-line > a:hover, .basicTable4 td.sbj-line > a:focus {
  text-decoration: underline;
}

.basicTable4 td > a:hover, .basicTable4 td > a:focus {
  text-decoration: underline;
}

.basicTable4 td.align-left {
  text-align: left;
  padding-left: 15px;
}

@media (max-width: 768px) {
  .basicTable4 {
    overflow: auto;
  }
  .basicTable4.res {
    padding-top: 0px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .basicTable4.res table {
    min-width: auto;
    margin-left: 0px;
  }
  .basicTable4.res colgroup {
    display: none;
  }
  .basicTable4.res thead {
    display: none;
  }
  .basicTable4.res tr {
    display: block;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdcdc;
  }
  .basicTable4.res th {
    display: none;
  }
  .basicTable4.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-right: 1px solid #dcdcdc;
    border-top: 1px solid #dcdcdc;
    border-bottom: 0px;
  }
  .basicTable4.res td:first-child {
    border-left: 1px solid #dcdcdc;
  }
  .basicTable4.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " : ";
  }
  .basicTable4.res td.align-left {
    padding-left: 10px;
  }
}

.basicTable5 {
  text-align: center;
  margin-bottom: 30px;
  max-width: 100%;
}

.basicTable5 table {
  border-collapse: collapse;
  width: 100%;
  min-width: 540px;
}

.basicTable5 thead th {
  background-color: #333;
  color: #fff;
  border: 1px solid #333;
}

.basicTable5 th {
  border: 1px solid #3774a9;
  padding: 8px 0;
  background-color: #0e5390;
  padding-left: 3px;
  padding-right: 3px;
  text-align: center;
  color: #fff;
}

.basicTable5 td {
  border: 1px solid #dcdcdc;
  padding: 8px 2px;
  text-align: center;
}

@media (max-width: 768px) {
  .basicTable5 {
    overflow: auto;
  }
  .basicTable5.res {
    padding-top: 10px;
    border-top: 0px;
    border-bottom: 0px;
  }
  .basicTable5.res table {
    min-width: auto;
  }
  .basicTable5.res colgroup {
    display: none;
  }
  .basicTable5.res thead {
    display: none;
  }
  .basicTable5.res tr {
    display: block;
    margin-bottom: 20px;
    border-top: 1px solid #dcdcdc;
  }
  .basicTable5.res th {
    display: none;
  }
  .basicTable5.res td {
    display: block;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 10px;
    border-top: 0px;
    border-right: 1px solid #dcdcdc;
  }
  .basicTable5.res td:before {
    color: #000;
    font-weight: bold;
    content: attr(data-th) " - ";
  }
}

.selectboxN1 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #ddd;
}

.selectboxN1:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -167px -1310px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .selectboxN1:after {
  display: none;
}

.selectboxN1 label {
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: -9999px;
  font-size: 0;
  height: 0;
  overflow: hidden;
}

.selectboxN1 select {
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  appearance: none;
  min-width: 100px;
  vertical-align: middle;
  color: #111;
  font-size: 16px;
  height: 40px;
  margin-top: 0;
  padding: 2px 2px 2px 15px;
  padding-right: 110px;
  position: relative;
  border: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.selectboxN1 select::-ms-expand {
  display: none;
}

.lte-ie9 .selectboxN1 select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.selectboxN1 select option {
  font-family: "Nanum Gothic";
}

.selectboxN2 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #ddd;
}

.selectboxN2:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -167px -1310px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .selectboxN2:after {
  display: none;
}

.selectboxN2 select {
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  appearance: none;
  min-width: 100px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 34px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  padding-right: 40px;
  position: relative;
  border: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.selectboxN2 select::-ms-expand {
  display: none;
}

.lte-ie9 .selectboxN2 select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.selectboxN2 select option {
  font-family: "Nanum Gothic";
}

.selectbox {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #ddd;
}

.selectbox:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -167px -1310px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .selectbox:after {
  display: none;
}

.selectbox select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 230px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 58px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.selectbox select::-ms-expand {
  display: none;
}

.lte-ie9 .selectbox select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.selectbox select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .selectbox select {
    min-width: 200px;
  }
}

.selectbox2 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: transparent;
  text-align: left;
  border-bottom: 2px solid #000;
}

.selectbox2:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -167px -1310px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .selectbox2:after {
  display: none;
}

.selectbox2 select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 230px;
  vertical-align: middle;
  color: #000;
  font-size: 14px;
  height: 38px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.selectbox2 select::-ms-expand {
  display: none;
}

.lte-ie9 .selectbox2 select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.selectbox2 select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .selectbox2 select {
    min-width: 200px;
  }
}

.selectbox4 {
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  background-color: #fff;
  text-align: left;
  border: 1px solid #ddd;
}

.selectbox4:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -167px -1310px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.lte-ie9 .selectbox4:after {
  display: none;
}

.selectbox4 select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 230px;
  vertical-align: middle;
  color: #000;
  font-size: 16px;
  height: 34px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.selectbox4 select::-ms-expand {
  display: none;
}

.lte-ie9 .selectbox4 select {
  width: 100%;
  vertical-align: top;
  padding-right: 0px;
}

.selectbox4 select option {
  font-family: "Nanum Gothic";
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .selectbox4 select {
    min-width: 200px;
  }
}

.inText {
  border: 1px solid #000;
  height: 34px;
  width: 200px;
  line-height: 34px;
  font-size: 16px;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.inText2 {
  border: 1px solid #000;
  height: 34px;
  line-height: 34px;
  font-size: 16px;
  min-width: 30%;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.inText3 {
  border: 1px solid #e0e0e0;
  height: 37px;
  line-height: 37px;
  font-size: 16px;
  min-width: 30%;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.inText4 {
  border: 1px solid #e0e0e0;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  min-width: 30%;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.inText5 {
  border: 1px solid #e0e0e0;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  min-width: 30%;
  padding: 0;
  padding-left: 10px;
  box-sizing: border-box;
}

.inTextareaN1 {
  border: 1px solid #f6f6f6;
  height: 120px;
  width: 100%;
  line-height: 1.6;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #f6f6f6;
}

.cont-in__wrap2 {
  position: relative;
  display: inline-block;
  width: 100%;
}

.cont-in__wrap2:before {
  content: '';
  position: absolute;
  background: url(../../resources/images/cont/cont-type1-img.jpg) center top no-repeat;
  height: 380px;
  width: 100%;
  background-size: cover;
  z-index: -1;
  transform: translateX(-50%);
  left: 50%;
}

.cont-type1 {
  padding-top: 60px;
  max-width: 1580px;
  margin: 0 auto;
}

.cont-type1__left-box {
  float: left;
  width: calc(100% - 1000px);
  padding-right: 60px;
  box-sizing: border-box;
}

.cont-type1__left-box__btn {
  margin-top: 50px;
}

.cont-type1__left-box__btn a {
  float: left;
  margin-right: 5px;
  margin-bottom: 10px;
}

.cont-type1__left-box img {
  box-shadow: 10px 10px 15px -4px rgba(0, 0, 0, 0.13);
}

.cont-type1__right-box {
  float: left;
  max-width: 1000px;
  width: 100%;
}

.cont-type1__right-box > ul > li {
  border-bottom: 1px solid #dddddd;
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
}

.cont-type1__right-box__sm-tit {
  color: #fff;
  font-size: 16px;
}

.cont-type1__right-box h4 {
  font-size: 40px;
  font-weight: bold;
  color: #Fff;
  margin-top: 150px;
}

.cont-type1__right-box p {
  font-size: 20px;
  color: #Fff;
  margin-top: 10px;
  margin-bottom: 30px;
}

.cont-type1__right-box__tit {
  font-size: 30px;
  font-weight: bold;
  width: 200px;
  padding-right: 10px;
  box-sizing: border-box;
  float: left;
  line-height: 1.4;
}

.cont-type1__right-box__txt {
  font-size: 18px;
  width: calc(100% - 200px);
  float: left;
}

@media screen and (min-width: 1px) and (max-width: 1300px) {
  .cont-type1 {
    padding-top: 50px;
  }
  .cont-type1__left-box {
    float: none;
    width: 100%;
    padding-right: 0;
    text-align: center;
  }
  .cont-type1__left-box__btn {
    display: inline-block;
  }
  .cont-in__wrap2.ver2:after {
    display: none;
  }
  .cont-type1__right-box {
    float: none;
    width: 100%;
    max-width: 100%;
  }
  .cont-type1__right-box__sm-tit {
    color: #000;
  }
  .cont-type1__right-box h4 {
    color: #000;
    margin-top: 30px;
  }
  .cont-type1__right-box p {
    color: #000;
  }
  .cont-type1__left-box > img {
    height: 300px !important;
    display: block;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .cont-type1__right-box h4 {
    font-size: 26px;
  }
  .cont-type1__right-box p {
    font-size: 17px;
  }
  .cont-type1__right-box > ul > li {
    display: inline-block;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
  }
  .cont-type1__right-box > ul > li:first-child {
    border-top: 1px solid #ddd;
  }
  .cont-type1__right-box__tit {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .cont-type1__right-box__txt {
    font-size: 16px;
  }
  .cont-type1__right-box__tit,
  .cont-type1__right-box__txt {
    float: none;
    width: 100%;
  }
}

.cont-type2 {
  max-width: 1580px;
  width: 100%;
  margin: 0 auto;
}

.cont-type2 > li {
  display: flex;
  margin-bottom: 80px;
}

.cont-type2__left-box {
  width: 380px;
}

.cont-type2__left-box > h4 {
  font-size: 30px;
  font-weight: bold;
}

.cont-type2__right-box {
  width: calc(100% - 380px);
}

.cont-type2__right-box > p {
  font-size: 18px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .cont-type2__left-box {
    width: 180px;
    padding-right: 20px;
    box-sizing: border-box;
  }
  .cont-type2__right-box {
    width: calc(100% - 180px);
  }
  .cont-type2__left-box > h4 {
    font-size: 24px;
  }
  .cont-type2__right-box > p {
    font-size: 16px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .cont-type2 > li {
    display: inline-block;
    width: 100%;
  }
  .cont-type2__left-box,
  .cont-type2__right-box {
    width: 100%;
    margin-top: 10px;
  }
  .cont-type2__left-box > h4 {
    margin-bottom: 10px;
  }
}

/*입주정보 - 공공건물입주*/
.moving-public__ul {
  display: flex;
  gap: 23px;
  text-align: center;
  margin-top: 40px;
}

.moving-public__ul li {
  border: 1px solid #e0e0e0;
  flex: 1;
  padding-bottom: 38px;
}

.moving-public__ul h5 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
}

.moving-public__ul p {
  font-size: 18px;
  min-height: 100px;
  line-height: 1.5;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .moving-public__ul {
    gap: 5px;
  }
  .moving-public__ul li {
    margin-bottom: 10px;
    padding-bottom: 20px;
  }
  .moving-public__ul h5 {
    font-size: 20px;
    margin-top: 20px;
  }
  .moving-public__ul p {
    font-size: 16px;
    min-height: 140px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media screen and (min-width: 1px) and (max-width: 600px) {
  .moving-public__ul {
    display: block;
  }
  .moving-public__ul p {
    min-height: auto;
    margin-bottom: 10px;
  }
  .moving-public__ul img {
    width: 100%;
  }
}

/*입주정보 - 스타트업보육공간*/
.accordian__tit {
  position: relative;
  padding: 25px 56px 25px 30px;
  cursor: pointer;
  font-size: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.accordian__tit:before {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -706px -916px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 26px;
}

.accordian__tit:first-child {
  border-top: 3px solid #333333;
}

.accordian__tit.on > span {
  font-weight: bold;
  color: #006633;
}

.accordian__tit.on:before {
  position: absolute;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1013px -1092px;
  background-image: url("../images/sprite.png");
  width: 26px;
  min-width: 26px;
  height: 2px;
}

.accordian__txt {
  display: none;
  overflow: hidden;
  background-color: #f8f8f8;
  padding: 25px 30px;
  font-size: 16px;
}

.accordian__ul li {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.accordian__ul li span {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  line-height: 1.5;
}

.accordian__ul li span:after {
  display: inline-block;
  width: 1px;
  height: 16px;
  background-color: #cccccc;
  content: '';
  margin-left: 20px;
  margin-right: 20px;
}

.accordian__ul li:last-child {
  margin-bottom: 0;
}

.accordian__ul li a {
  display: contents;
  word-break: break-all;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .accordian__tit .accordian__ul li {
    display: block;
  }
  .accordian__tit .accordian__ul li span:after {
    display: none;
  }
  .accordian__tit {
    padding: 20px 56px 20px 15px;
  }
  .accordian__ul li span {
    font-size: 16px;
  }
  .accordian__txt {
    padding: 20px 15px;
  }
  .accordian__ul li {
    font-size: 14px;
  }
}

/*시설예약 - 한교홍보관 예약현황 */
.reservation__date {
  background-color: #e6e9ee;
}

.reservation__date__in {
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.reservation__date__left {
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -427px -698px;
  background-image: url("../images/sprite.png");
  width: 54px;
  min-width: 54px;
  height: 54px;
}

.reservation__date__right {
  text-indent: -9999px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -531px -698px;
  background-image: url("../images/sprite.png");
  width: 54px;
  min-width: 54px;
  height: 54px;
}

.reservation__date__txt {
  font-size: 18px;
  border: 1px solid #1a1b1e;
  border-radius: 100px;
  padding: 10px 60px;
  text-align: center;
  background-color: #fff;
  line-height: 1.8;
}

.reservation__ul {
  padding-left: 50px;
  padding-right: 50px;
}

.reservation__ul__day {
  margin-right: 14px;
  color: #fff;
  background-color: #1a1b1e;
  float: left;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.reservation__ul__day2 {
  font-size: 24px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
}

.reservation__ul li {
  position: relative;
  margin-top: 50px;
}

.reservation__ul__ul {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  clear: both;
}

.reservation__ul__ul li {
  width: 100%;
  text-align: center;
}

.reservation__ul__ul li > div {
  border: 1px solid #a7a7a7;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 100px;
  color: #a7a7a7;
  margin-bottom: 5px;
}

.reservation__ul__ul li > div.on {
  border: 1px solid #0050a0;
  color: #0050a0;
}

.reservation__ul__ul li > div.off {
  border: 1px solid #ee060b;
  color: #ee060b;
}

.reservation__ul.ver2 .reservation__ul__ul {
  justify-content: center;
}

.reservation__ul.ver2 .reservation__ul__ul li {
  width: 240px;
}

.reservation .btn2.st2 {
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .reservation__ul {
    padding-left: 0;
    padding-right: 0;
  }
  .reservation__ul__ul {
    flex-wrap: wrap;
    gap: 0;
  }
  .reservation__date__txt {
    padding: 10px;
    font-size: 14px;
    display: grid;
    align-items: center;
    width: 100%;
  }
  .reservation__date__in {
    justify-content: center;
    gap: 5px;
  }
}

/*시설예약 - 판교홍보관 개요*/
.public-relations .cont-type1 {
  padding-top: 110px;
}

.public-relations .cont-type1__right-box h4 {
  margin-top: 40px;
  line-height: 1.5;
}

.public-relations .popupzone {
  width: auto;
}

.public-relations .popupzone .popupzone-nav {
  text-align: left;
}

.public-relations .popupzone .popupzone-nav > div > div {
  display: none;
}

.public-relations .popupzone .popup-total {
  display: none !important;
}

.public-relations .popupzone .popupzone-nav > div > ul li .slide-next-item {
  border-right: 0;
}

.public-relations .popupzone .popupzone-nav > div {
  left: 0;
  right: auto;
}

@media screen and (min-width: 1px) and (max-width: 1300px) {
  .public-relations .popupzone {
    max-width: 520px;
    width: 100%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .public-relations .cont-type1 {
    padding-top: 50px;
  }
  .public-relations.cont-in__wrap2:before {
    height: 18%;
  }
}

/*판교tv소개 - 개요,비전*/
.tv_info {
  position: relative;
  background: url("../../resources/images/cont/tv-info-img1.png") center top no-repeat;
  text-align: center;
  padding-top: 60px;
}

.tv_info h4 {
  font-weight: bold;
  margin-top: 40px;
  display: inline-block;
}

.tv_info h4.visions {
  font-size: 40px;
  background: url("../../resources/images/cont/tv-info-img2.png") left center no-repeat, url("../../resources/images/cont/tv-info-img3.png") right center no-repeat;
  margin-bottom: 130px;
  padding-left: 50px;
  padding-right: 50px;
}

.tv_info h4.visions span {
  font-size: 24px;
  color: #111;
  letter-spacing: -0.5px;
  display: block;
  font-weight: normal;
}

.tv_info h4.mission {
  margin-bottom: 40px;
  font-size: 30px;
}

.tv_info img {
  display: block;
  margin: 0 auto;
}

.tv_info .strategies {
  margin-top: 130px;
  margin-bottom: 50px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .tv_info h4.visions {
    font-size: 22px;
  }
  .tv_info h4.mission {
    font-size: 20px;
  }
}

/*판교tv소개 - 개요_소개*/
.tv_intro {
  max-width: 1580px;
  width: 100%;
  margin: 0 auto;
}

.tv_intro > h4 {
  font-weight: bold;
  font-size: 40px;
  margin-top: 50px;
  margin-bottom: 20px;
}

.tv_intro > p {
  font-size: 24px;
  margin-bottom: 80px;
}

.tv_intro__box {
  display: flex;
  gap: 80px;
  justify-content: center;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .tv_intro > h4 {
    font-size: 26px;
  }
  .tv_intro > p {
    font-size: 18px;
  }
  .tv_intro__box {
    gap: 20px;
  }
}

/*판교TV소개 - BI소개*/
.bi-info__img-box {
  display: flex;
  border: 1px solid #e0e0e0;
}

.bi-info__img-box li {
  flex: 1;
}

.bi-info__btn-box {
  margin-top: 40px;
}

.bi-info__btn-box li {
  float: left;
  margin-right: 10px;
}

.bi-info h5 {
  font-size: 24px;
  color: #111111;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
}

.bi-info p {
  font-size: 18px;
}

@media screen and (min-width: 1px) and (max-width: 510px) {
  .bi-info__img-box {
    display: block;
    border: 0;
  }
  .bi-info__img-box li {
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
  }
  .bi-info__btn-box li {
    display: block;
    float: none;
    margin-bottom: 5px;
  }
}

/*판교TV소개 - 조직도*/
.organization {
  position: relative;
}

.organization__top {
  position: relative;
  min-height: 650px;
  max-width: 950px;
  text-align: center;
  margin: 0 auto;
}

.organization__top a {
  cursor: pointer;
}

.organization__top__0 {
  position: relative;
  display: inline-block;
}

.organization__top__0::before {
  content: '';
  background-color: #cccccc;
  position: absolute;
  bottom: -61px;
  height: 61px;
  width: 0.5px;
  text-align: center;
  margin: 0 auto;
  transform: translateX(-50%);
  left: 50%;
}

.organization__top__1 {
  position: absolute;
  top: 329px;
  transform: translateX(-50%);
  left: 50%;
  width: 250px;
  height: 80px;
  align-items: center;
  justify-content: space-around;
  display: flex;
  background-color: #1a1b1e;
  color: #fff;
  font-weight: bold;
  text-align: center;
  font-size: 18px;
}

.organization__top__1::before {
  content: '';
  background-color: #cccccc;
  position: absolute;
  bottom: -50px;
  height: 50px;
  width: 0.5px;
  text-align: center;
  margin: 0 auto;
  transform: translateX(-50%);
  left: 50%;
}

.organization__top__2 {
  position: absolute;
  font-size: 24px;
  text-align: center;
  border: 2px solid #1a1b1e;
  width: 300px;
  height: 110px;
  top: 520px;
  left: 0;
  align-items: center;
  justify-content: space-around;
  display: grid;
  align-content: center;
}

.organization__top__2::before {
  content: '';
  background-color: #cccccc;
  position: absolute;
  top: -63px;
  height: 61px;
  width: 0.5px;
  text-align: center;
  margin: 0 auto;
  transform: translateX(-50%);
  left: 50%;
}

.organization__top__2::after {
  content: '';
  background-color: #cccccc;
  position: absolute;
  top: -64px;
  height: 1px;
  width: 325px;
  text-align: center;
  margin: 0 auto;
  left: 50%;
}

.organization__top__2 p {
  font-size: 18px;
}

.organization__top__3 {
  position: absolute;
  font-size: 24px;
  text-align: center;
  border: 2px solid #1a1b1e;
  width: 300px;
  height: 110px;
  top: 520px;
  right: 0;
  align-items: center;
  justify-content: space-around;
  display: grid;
  align-content: center;
}

.organization__top__3::before {
  content: '';
  background-color: #cccccc;
  position: absolute;
  top: -63px;
  height: 61px;
  width: 0.5px;
  text-align: center;
  margin: 0 auto;
  transform: translateX(-50%);
  left: 50%;
}

.organization__top__3::after {
  content: '';
  background-color: #cccccc;
  position: absolute;
  top: -64px;
  height: 1px;
  width: 325px;
  text-align: center;
  margin: 0 auto;
  right: 50%;
}

.organization__top__3 p {
  font-size: 18px;
}

@media screen and (min-width: 1px) and (max-width: 680px) {
  .organization {
    height: auto;
  }
  .organization__top {
    min-height: auto;
    margin-bottom: 50px;
  }
  .organization__top__0, .organization__top__1, .organization__top__2, .organization__top__3 {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    transform: none;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 100%;
  }
  .organization__top__0::before, .organization__top__1::before, .organization__top__2::before, .organization__top__3::before {
    display: none;
  }
  .organization__top__0::after, .organization__top__1::after, .organization__top__2::after, .organization__top__3::after {
    display: none;
  }
  .organization__top__1, .organization__top__2, .organization__top__3 {
    height: 60px;
  }
  .organization__top__2, .organization__top__3 {
    font-size: 18px;
  }
  .organization__top__2 p, .organization__top__3 p {
    font-size: 16px;
  }
  .organization .basicTable1.res td:before {
    min-width: 44px;
  }
}

.jojikdo-resultbox {
  display: none;
}

.jojikdo-resultbox.on {
  display: block;
}

/*사이버 홍보관*/
.cyber-promote h5 {
  font-size: 50px;
  line-height: 1.2;
  font-weight: normal;
  color: #666666;
  font-family: 'GongGothicM';
}

.cyber-promote h5 span {
  color: #111111;
}

.cyber-promote p {
  color: #555;
  font-size: 18px;
}

.cyber-promote__ul li {
  float: left;
}

.cyber-promote__ul__img {
  width: 40%;
  padding-right: 48px;
  box-sizing: border-box;
}

.cyber-promote__ul__txt {
  width: 60%;
}

.cyber-promote .btn6 {
  max-width: 200px;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .cyber-promote__ul__img {
    width: 100%;
    float: none;
    margin: 0 auto;
    text-align: center;
    padding-right: 0;
  }
  .cyber-promote__ul__txt {
    width: 100%;
    float: none;
    margin-top: 20px;
  }
  .cyber-promote h5 {
    font-size: 28px;
  }
  .cyber-promote p {
    font-size: 16px;
  }
  .downBtn1 {
    display: inline-block;
  }
}

/*찾아오시는 길*/
.way-to {
  position: relative;
  margin-top: 100px;
}

.way-to__jido {
  position: relative;
  padding-top: 450px;
}

.way-to__jido .root_daum_roughmap {
  width: 1920px !important;
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.way-to__jido__text {
  position: relative;
  align-items: center;
  padding-top: 40px;
  padding-left: 70px;
  padding-bottom: 40px;
  box-sizing: border-box;
  max-width: 1200px;
  height: 250px;
  width: 100%;
  background-color: #1a1b1e;
  color: #fff;
  z-index: 9999;
  margin: 0 auto;
  display: flex;
}

.way-to__jido__text__tit {
  font-size: 40px;
  margin-right: 70px;
  padding-right: 70px;
  border-right: 1px solid #5f6062;
  height: 100%;
  display: grid;
  align-items: center;
}

.way-to__jido__text__box {
  font-size: 18px;
}

.way-to__jido__text__box h4 {
  font-size: 24px;
  margin-bottom: 10px;
}

.way-to__jido__text__box h4::before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -272px -1092px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 26px;
  margin-right: 10px;
}

.way-to .col_red2 {
  color: #af0734;
}

.way-to .col_yellow2 {
  color: #eba900;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .way-to__jido__text {
    display: block;
    height: auto;
    padding: 20px;
  }
  .way-to__jido__text__tit {
    font-size: 26px;
    border-right: 0;
  }
  .way-to__jido__text h4 {
    font-size: 18px;
  }
  .way-to__jido__text__box {
    font-size: 16px;
  }
}

/*지원시설*/
.jiwon-sisal .popupzone {
  width: 100%;
  max-width: 620px;
  float: left;
  position: relative;
}

.jiwon-sisal .popupzone .popupzone-nav {
  text-align: left;
}

.jiwon-sisal .popupzone .popupzone-nav > div > div {
  display: none;
}

.jiwon-sisal .popupzone .popupzone-nav .popup-total {
  display: none;
}

.jiwon-sisal .popupzone .slide-next-item {
  border-right: 0;
}

.jiwon-sisal__top {
  clear: both;
  display: inline-block;
  width: 100%;
}

.jiwon-sisal__box {
  width: calc(100% - 620px);
  float: left;
  background-color: #1a1b1e;
  color: #fff;
  min-height: 419px;
  padding: 46px 48px;
  box-sizing: border-box;
}

.jiwon-sisal__box .h3Type1 {
  color: #fff;
  padding-top: 20px;
}

.jiwon-sisal__box .h3Type1:first-child {
  padding-top: 0;
}

@media screen and (min-width: 1px) and (max-width: 1600px) {
  .jiwon-sisal .jiwon-sisal__box {
    float: none;
    width: 100%;
    min-height: auto;
  }
  .jiwon-sisal .popupzone {
    float: none;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .jiwon-sisal .popupzone img {
    max-width: 100%;
    width: 100%;
  }
  .jiwon-sisal .jiwon-sisal__box {
    padding: 15px 20px;
  }
  .jiwon-sisal .jiwon-sisal__box > p {
    font-size: 16px;
  }
  .jiwon-sisal__box .h3Type1 {
    font-size: 18px;
    padding-top: 10px;
    margin-bottom: 5px;
  }
}

/*개요-연혁*/
.v-slider {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  /* carousel specific styles */
}

.v-slider > div > div {
  padding: 0 !important;
}

.v-slider__year {
  font-size: 240px;
  color: #f3f3f3;
  width: 680px;
  font-weight: bold;
}

.v-slider__txt {
  font-size: 20px;
  width: calc(100% - 680px);
}

.v-slider__txt li {
  display: flex;
}

.v-slider__txt li .v-slider__txt__date {
  width: 115px;
  color: #999999;
}

.v-slider__txt li .v-slider__txt__in {
  width: calc(100% - 115px);
}

.v-slider .slide-wrapper {
  display: -webkit-box;
}

.v-slider .slide-image-container {
  width: 30%;
}

.v-slider .slide-text-container {
  width: 70%;
}

.v-slider .slick-prev.slick-arrow {
  cursor: pointer;
  position: absolute;
  top: 280px;
  left: 580px;
  z-index: 10;
}

.v-slider .slick-next.slick-arrow {
  cursor: pointer;
  position: absolute;
  bottom: 280px;
  left: 580px;
  z-index: 10;
}

.v-slider .slick-slide {
  outline: none;
}

.v-slider .item {
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible !important;
  margin: 4px;
  height: 220px;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 1.6s cubic-bezier(0, 1, 0.86, 1), transform 0.8s cubic-bezier(0, 1, 0.86, 1);
  outline: none;
  overflow: hidden;
  z-index: -1;
}

.v-slider .item .v-slider__txt {
  display: none;
}

.v-slider .item img {
  width: 100%;
  transform: rotate(0) translate3d(0, 0, 0);
  transition: filter 1.6s cubic-bezier(0, 1, 0.86, 1);
}

.v-slider .item.slick-animate {
  opacity: 1;
  overflow: visible;
  z-index: 99;
  transition: filter 1.6s cubic-bezier(0, 1, 0.86, 1), opacity 0.4s cubic-bezier(0, 1, 0.86, 1), transform 0.4s cubic-bezier(0, 1, 0.86, 1);
  transition: filter 1.6s cubic-bezier(0, 1, 0.86, 1), opacity 0.4s cubic-bezier(0, 1, 0.86, 1);
}

.v-slider .slick-center {
  opacity: 1;
}

.v-slider .slick-center .v-slider__txt {
  display: block;
}

.v-slider .slick-center .v-slider__year {
  color: #111111;
}

.v-slider .btn {
  border: 2px solid black;
  height: 10px;
  width: 20px;
  position: absolute;
  top: 23px;
  right: 40px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .v-slider .v-slider__year {
    font-size: 80px;
    width: 270px;
  }
  .v-slider .v-slider__txt {
    font-size: 20px;
    width: calc(100% - 270px);
  }
  .v-slider .slick-prev.slick-arrow {
    left: 210px;
    width: 40px;
    top: 140px;
  }
  .v-slider .slick-next.slick-arrow {
    left: 210px;
    width: 40px;
    bottom: 140px;
  }
  .v-slider .item {
    height: 120px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .v-slider .item {
    height: 70px;
  }
  .v-slider .v-slider__year {
    font-size: 50px;
    width: 190px;
  }
  .v-slider .v-slider__txt {
    font-size: 16px;
    width: calc(100% - 190px);
  }
  .v-slider .v-slider__txt li .v-slider__txt__date {
    width: 90px;
  }
  .v-slider .v-slider__txt li .v-slider__txt__in {
    width: calc(100% - 90px);
  }
  .v-slider .slick-prev.slick-arrow {
    left: 130px;
    top: 75px;
  }
  .v-slider .slick-next.slick-arrow {
    left: 130px;
    bottom: 75px;
  }
}

@media screen and (min-width: 1px) and (max-width: 480px) {
  .v-slider .v-slider__year {
    font-size: 30px;
    width: 130px;
  }
  .v-slider .v-slider__txt {
    font-size: 14px;
    width: calc(100% - 130px);
  }
  .v-slider .v-slider__txt li .v-slider__txt__date {
    width: 70px;
  }
  .v-slider .v-slider__txt li .v-slider__txt__in {
    width: calc(100% - 70px);
  }
  .v-slider .slick-prev.slick-arrow {
    left: 80px;
  }
  .v-slider .slick-next.slick-arrow {
    left: 80px;
  }
}

/*판교tv소개 - 입주기업정보(인포그래픽스)*/
.Infographics {
  max-width: 1580px;
  width: 100%;
  margin: 0 auto;
}

.Infographics > h4 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}

.Infographics .popupzone {
  width: 76%;
  float: left;
  font-size: 0;
  position: relative;
}

.Infographics .popupzone .slick-prev {
  display: block !important;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.Infographics .popupzone .slick-prev:before {
  content: '';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -204px -1092px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 28px;
}

.Infographics .popupzone .slick-next {
  display: block !important;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.Infographics .popupzone .slick-next:before {
  content: '';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -68px -1092px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 30px;
}

.Infographics .popupzone .popupzone-nav {
  text-align: center;
  bottom: 30px;
}

.Infographics .popupzone .popupzone-nav > div {
  border-radius: 100px;
}

.Infographics .popupzone .popupzone-nav > div > ul li .slide-pause-item {
  border-right: 0;
}

.Infographics .popupzone .popupzone-nav > div .slide-pause-item {
  background: url(../../resources/images/icons/main-visual-pause.png) center center no-repeat;
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.Infographics .popupzone .popupzone-nav > div .popup-total-num {
  padding-right: 0;
}

.Infographics .popupzone .popupzone-nav > div > div {
  padding-left: 25px;
  padding-right: 7px;
}

.Infographics__ul {
  float: left;
  width: 24%;
  padding-left: 30px;
  box-sizing: border-box;
}

.Infographics__ul > li {
  position: relative;
  margin-bottom: 40px;
}

.Infographics__ul > li h5 {
  color: #000;
  border: 4px solid #0470e4;
  max-width: 277px;
  border-radius: 100px 60px 60px 100px;
  border-left: none;
  height: 67px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.Infographics__ul > li h5 > span.num {
  position: absolute;
  left: 0;
  color: #fff;
  height: 67px;
  width: 67px;
  line-height: 67px;
  text-align: center;
  display: inline-block;
  z-index: 1;
}

.Infographics__ul > li h5 > span.num:before {
  position: absolute;
  content: '';
  left: 0;
  z-index: -1;
  top: 0;
  width: 80px;
  height: 67px;
  background: url("../../resources/images/cont/Infographics-img1.png") center center no-repeat;
}

.Infographics__ul > li h5 > span.num:after {
  z-index: -2;
  width: 30px;
  height: 4px;
  content: '';
  background-color: #fff;
  position: absolute;
  left: 30px;
  top: 0;
}

.Infographics__ul > li h5 > span.tit {
  display: inline-block;
  font-size: 20px;
  font-family: 'GongGothicM';
  font-weight: normal;
  width: calc(100% - 67px);
}

.Infographics__ul__in {
  padding-top: 20px;
  padding-left: 12px;
}

.Infographics__ul__in li {
  font-size: 18px;
  display: flex;
  color: #242424;
}

.Infographics__ul__in li span.Infographics__span1 {
  width: 120px;
}

.Infographics__ul__in li span.Infographics__span2 {
  width: calc(100% - 120px);
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .Infographics__ul > li h5 > span.tit {
    font-size: 18px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1100px) {
  .Infographics .popupzone {
    float: none;
    width: 100%;
    margin-bottom: 20px;
  }
  .Infographics__ul {
    float: none;
    width: 100%;
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .Infographics__ul__in li span.Infographics__span1 {
    width: auto;
  }
  .Infographics__ul > li h5 > span.tit {
    font-size: 18px;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .Infographics__ul {
    display: block;
  }
  .Infographics__ul > li h5 {
    max-width: 100%;
  }
  .Infographics__ul > li h5 > span.tit {
    font-size: 20px;
  }
  .Infographics > h4 {
    font-size: 24px;
  }
  .Infographics__ul__in li span.Infographics__span1 {
    width: 130px;
  }
}

/*시설예약 - 대관시설예약_상세페이지*/
.cont-in__wrap2.ver2 {
  padding-bottom: 100px;
}

.cont-in__wrap2.ver2:after {
  content: '';
  position: absolute;
  height: 380px;
  width: 100%;
  z-index: -1;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
  -webkit-box-shadow: 0px 7px 21px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 7px 21px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 21px 1px rgba(0, 0, 0, 0.1);
}

.cont-in__wrap2.ver2 .cont-type1__right-box {
  padding-top: 120px;
}

.cont-in__wrap2.ver2 .cont-type1__right-box > h4 {
  margin-top: 0;
  font-size: 48px;
}

.cont-in__wrap2.ver2 .cont-type1__right-box .accordian__ul__wrap {
  margin-top: 100px;
}

.reservation-detail {
  width: 100%;
  max-width: 1580px;
  display: flex;
  gap: 60px;
  margin: 0 auto;
  padding-top: 90px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 50px;
  padding-bottom: 100px;
}

.reservation-detail .viewOption {
  position: sticky;
  top: 0;
}

.reservation-detail h3.h3Type1 {
  font-size: 30px;
  margin-top: 50px;
}

.reservation-detail h3.h3Type1:first-child {
  margin-top: 0;
}

.reservation-detail > li:first-child {
  width: calc(100% - 640px);
}

.reservation-detail > li:last-child {
  width: 580px;
}

.reservation-detail .basicTable1 table {
  min-width: auto;
}

.reservation-detail .reservation-calendar__top {
  position: relative;
}

.reservation-detail .reservation-calendar__top .left {
  text-indent: -9999px;
  font-size: 0;
  position: absolute;
  right: 42px;
  top: 10px;
}

.reservation-detail .reservation-calendar__top .left:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -170px;
  background-image: url("../images/sprite.png");
  width: 32px;
  min-width: 32px;
  height: 32px;
  display: block;
}

.reservation-detail .reservation-calendar__top .right {
  text-indent: -9999px;
  font-size: 0;
  position: absolute;
  right: 0;
  top: 10px;
}

.reservation-detail .reservation-calendar__top .right:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1155px -88px;
  background-image: url("../images/sprite.png");
  width: 32px;
  min-width: 32px;
  height: 32px;
  display: block;
}

.reservation-detail .reservation-calendar__date {
  color: #1a1b1e;
  font-size: 36px;
  font-weight: bold;
  font-family: 'GongGothicM';
}

.reservation-detail .reservation-calendar table {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.reservation-detail .reservation-calendar table th, .reservation-detail .reservation-calendar table td {
  padding-top: 16px;
  padding-bottom: 16px;
}

.reservation-detail .reservation-calendar table th {
  color: #525659;
  font-size: 18px;
  font-family: 'GongGothicM';
  font-weight: 300;
}

.reservation-detail .reservation-calendar table td {
  font-size: 20px;
  color: #555555;
}

.reservation-detail .reservation-calendar table td.gray {
  color: #aaaaaa;
}

.reservation-detail .reservation-calendar table td.on {
  background-color: #0050a0;
  color: #fff;
}

.reservation-detail .reservation-calendar__btn {
  height: 84px;
  line-height: 84px;
  color: #fff;
  font-size: 24px;
  background-color: #0050a0;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.reservation-detail .reservation-calendar__time {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 50px;
}

.reservation-detail .reservation-calendar__time li {
  width: calc(50% - 5px);
}

.reservation-detail .reservation-calendar__time li.on a {
  background-color: #0050a0;
  color: #fff;
}

.reservation-detail .reservation-calendar__time li a.selected {
  background-color: #0050a0;
  color: #fff;
}

.reservation-detail .reservation-calendar__time li a {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  display: inline-block;
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  text-align: center;
  font-size: 16px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
  .reservation-detail {
    display: block;
  }
  .reservation-detail > li:first-child {
    width: 100%;
  }
  .reservation-detail > li:last-child {
    width: 100%;
  }
}

.reservation-detail .reservation__info {
  border: 1px solid #e0e0e0;
  padding: 25px 28px;
  font-size: 16px;
}

.reservation-detail .reservation__info h4 {
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 15px;
}

.reservation-detail .reservation__info .phone {
  position: relative;
  padding-left: 26px;
}

.reservation-detail .reservation__info .phone:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1307px -607px;
  background-image: url("../images/sprite.png");
  width: 13px;
  min-width: 13px;
  height: 21px;
  position: absolute;
  left: 3px;
  top: 2px;
}

.reservation-detail .reservation__info .email {
  position: relative;
  padding-left: 26px;
}

.reservation-detail .reservation__info .email:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -412px -1172px;
  background-image: url("../images/sprite.png");
  width: 20px;
  min-width: 20px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 8px;
}

@media screen and (min-width: 1px) and (max-width: 1300px) {
  .cont-in__wrap2.ver2 .cont-type1__right-box {
    padding-top: 50px;
  }
  .cont-in__wrap2.ver2 .cont-type1__right-box > h4 {
    font-size: 26px;
  }
  .cont-in__wrap2.ver2 .cont-type1__right-box .accordian__ul__wrap {
    margin-top: 30px;
  }
  .cont-in__wrap2.ver2 {
    padding-bottom: 30px;
  }
  .reservation-detail {
    padding-top: 30px;
  }
  .reservation-detail h3.h3Type1 {
    font-size: 20px;
  }
  .reservation-detail .reservation__info {
    padding: 20px;
  }
  .reservation-detail .reservation__info h4 {
    font-size: 18px;
  }
}

/*03_시설예약 _02_대관시설예약_예약현황*/
.reservation__top {
  margin-bottom: 30px;
}

.reservation__top__select-box {
  display: flex;
}

.reservation__top__select {
  margin-right: 10px;
}

.reservation__top__select select {
  height: 50px;
  border: 1px solid #111;
  float: left;
  padding-left: 15px;
  color: #111;
  font-size: 16px;
  background: url(../../resources/images/icons/select-bul.png) no-repeat 89% center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 200px;
  padding-right: 50px;
}

.reservation__top__p {
  font-size: 16px;
  color: #ee060b;
  text-align: right;
  margin-top: -30px;
}

@media screen and (min-width: 1px) and (max-width: 1300px) {
  .reservation__top__p {
    margin-top: 0;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .reservation__top__select-box {
    display: inline-block;
  }
  .reservation__top__select {
    display: inline-block;
    margin-bottom: 10px;
  }
  .reservation__top__p {
    display: block;
    text-align: left;
  }
}

/*대관시설 예약 - 개요*/
.gayo {
  display: flex;
  margin-top: 30px;
  padding-top: 30px;
  margin-bottom: 70px;
  background: url("../../resources/images/cont/gayo-step-img.png") top left no-repeat;
}

.gayo li {
  padding-right: 20px;
  flex: 1;
}

.gayo__step {
  color: #fff;
  background-color: #000;
  font-size: 15px;
  width: 72px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}

.gayo h4 {
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gayo p {
  font-size: 16px;
  color: #555555;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .gayo {
    flex-wrap: wrap;
  }
  .gayo li {
    flex: auto;
    margin-bottom: 20px;
  }
}

/*대관시설예약 시설현황*/
.facility-status .sub-tab2 {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}

.facility-status .sub-tab2__ul {
  width: calc(100% - 580px);
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  border: none;
  align-content: flex-start;
  margin-bottom: 0;
}

.facility-status .sub-tab2__ul__li a {
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
}

.facility-status .sub-tab2__ul__li a img {
  max-height: 136px;
}

.facility-status .sub-tab2__ul__li.on {
  border: 4px solid #0050a0;
  box-sizing: border-box;
}

.facility-status .sub-tab2__cont {
  max-width: 570px;
}

.facility-status .convenient-facilities {
  display: flex;
  gap: 30px;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .facility-status .sub-tab2__ul__li {
    width: calc(33.33% - 7px);
  }
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .facility-status .sub-tab2 {
    display: block;
  }
  .facility-status .sub-tab2__cont {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }
  .facility-status .sub-tab2__ul__li {
    width: calc(25% - 8px) !important;
  }
  .facility-status .sub-tab2__ul {
    width: 100%;
  }
}

/*위로가기 버튼*/
.top-move {
  position: fixed;
  font-size: 0;
  right: 90px;
  bottom: 100px;
  z-index: 1;
}

.top-move:before {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -635px -698px;
  background-image: url("../images/sprite.png");
  width: 54px;
  min-width: 54px;
  height: 54px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .top-move {
    right: 20px;
  }
}

/*통합검색*/
.input_box .header__Search__tap__list {
  display: none;
}

.input_box.focus .header__Search__tap__list {
  display: block;
  position: absolute;
  width: 100%;
  background-color: #fff;
}

.input_box.focus .header__Search__tap__list li {
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
}

.input_box.focus .header__Search__tap__list li a {
  position: relative;
  display: block;
  width: 100%;
  padding: 8px 20px 8px 40px;
  box-sizing: border-box;
  font-size: 16px;
}

.input_box.focus .header__Search__tap__list li a:hover, .input_box.focus .header__Search__tap__list li a:focus {
  background-color: #fafafa;
}

.input_box.focus .header__Search__tap__list li a:before {
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -9px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -344px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 18px;
}

.input_box.focus .header__Search__tap__list li a:after {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -9px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -140px -1172px;
  background-image: url("../images/sprite.png");
  width: 18px;
  min-width: 18px;
  height: 18px;
}

/*230105수정사항*/
@media screen and (min-width: 1px) and (max-width: 480px) {
  .bi-info__btn-box li > a {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .selectboxN1 {
    width: 100%;
    box-sizing: border-box;
  }
  .show-list__top__search__inputbox {
    width: 100%;
    margin-left: 0 !important;
  }
  .show-list__top__search__inputbox input {
    width: 100%;
    box-sizing: border-box;
  }
  .basic-board-type2__table-top .search-box__table-search button {
    width: 40px !important;
    height: 40px !important;
  }
  .show-list__top {
    padding: 0;
  }
  .selectboxN1 {
    margin-top: 8px;
  }
  .selectboxN1:first-child {
    margin-top: 0;
  }
  .show-list__top__search__inputbox a {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  .ver2 .show-list__top__info {
    font-size: 15px;
    margin-bottom: 0;
  }
  .pagination {
    margin-top: 5px;
  }
  .pagination a {
    width: 12px !important;
    vertical-align: middle;
  }
  .cont-type2__left-box .btn6, .cont-type2__left-box .btn4 {
    width: 100%;
    line-height: unset;
    text-align: left;
    box-sizing: border-box;
    padding: 13px 20px 13px 20px;
  }
  .cont-type2__left-box,
  .cont-type2__right-box {
    padding-right: 0;
  }
  .accordian__tit {
    padding: 20px 76px 20px 15px;
  }
  .accordian__ul li span {
    font-size: 15px;
  }
  .depth3-wrap.menu-num-4 > ul > li {
    width: 100%;
  }
  .bbs-st1__top__type a:first-child {
    width: 100%;
  }
  .bbs-st1__top__ul li > span, .bbs-st1__top__ul li > label {
    flex-basis: 70px;
  }
  .bbs-st1__top__type, .bbs-st1__top__ul li > form {
    width: calc(100% - 70px);
  }
}

/*영문페이지*/
.header.eng .gnb .gnb-menu > a {
  font-size: 20px;
}

.header.eng .header__wrap__h1 h1 {
  top: 38px;
}

@media screen and (min-width: 769px) and (max-width: 1500px) {
  .header.eng .header__wrap__h1 h1 {
    top: 38px;
  }
}

.subpage .down-all.eng .header__wrap__h1 h1 a {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -332px -312px;
  background-image: url("../images/sprite.png");
  width: 203px;
  min-width: 203px;
  height: 44px;
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .subpage .down-all.eng .header__wrap__h1 h1 a {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -255.38462px -240px;
    background-image: url("../images/sprite.png");
    background-size: 1106.15385px auto;
    width: 156.15385px;
    min-width: 156.15385px;
    height: 33.84615px;
  }
}

.subpage.eng .depth3__ul > li > a {
  font-size: 15px;
}

.mainpage .down-all.eng .header__wrap__h1 h1 a {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -332px -312px;
  background-image: url("../images/sprite.png");
  width: 203px;
  min-width: 203px;
  height: 44px;
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .mainpage .down-all.eng .header__wrap__h1 h1 a {
    content: '';
    vertical-align: middle;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -255.38462px -240px;
    background-image: url("../images/sprite.png");
    background-size: 1106.15385px auto;
    width: 156.15385px;
    min-width: 156.15385px;
    height: 33.84615px;
  }
}

.main-section.eng .main-lay-1 {
  max-width: 1580px;
}

.main-section.eng .popupzone-slide-big h4 {
  font-family: 'GongGothicL';
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

.main-section.eng .popupzone-slide-big h4 span:first-child {
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
}

.main-section.eng .popupzone-slide-big h4 span:last-child {
  font-size: 18px;
  display: block;
  font-style: italic;
  margin-top: 20px;
}

.main-section.eng .popupzone-slide-big p {
  font-size: 16px;
  line-height: 1.4;
  margin-top: 25px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

.main-section.eng .popupzone-slide-first .popupzone2-list__item a h4 {
  font-family: 'GongGothicL';
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

.main-section.eng .popupzone-slide-first .popupzone2-list__item a h4 span {
  display: none;
}

.main-section.eng .popupzone-slide-big .popupzone2-list__item__btn1, .main-section.eng .popupzone-slide-big .popupzone2-list__item__btn2 {
  -webkit-box-shadow: 10px 10px 11px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 10px 10px 11px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 10px 10px 11px -10px rgba(0, 0, 0, 0.5);
}

.main-section.eng .shortcut {
  margin-top: 0;
}

.main-section.eng .shortcut__btn {
  padding-top: 50px;
}

.main-section.eng .shortcut__ul__li {
  align-items: start;
  box-sizing: border-box;
  padding-top: 70px;
  min-height: 520px;
}

.main-section.eng .shortcut__ul__li h4 {
  font-size: 30px;
  margin-top: 0;
}

.main-section.eng .shortcut__ul__li h4 span {
  font-size: 16px;
  font-family: 'GongGothicL';
}

.main-section.eng .shortcut__ul__li:first-child {
  background: url(../../resources/images/temp/eng-baro-go-img1.jpg) center center no-repeat;
  background-size: cover;
}

.main-section.eng .shortcut__ul__li:nth-child(2) {
  background: url(../../resources/images/temp/eng-baro-go-img2.jpg) center center no-repeat;
  background-size: cover;
}

.main-section.eng .shortcut__ul__li:nth-child(3) {
  background: url(../../resources/images/temp/eng-baro-go-img3.jpg) center center no-repeat;
  background-size: cover;
}

@media screen and (min-width: 1px) and (max-width: 700px) {
  .main-section.eng .popupzone-slide-big p {
    display: none;
  }
  .main-section.eng .shortcut__ul__li {
    min-height: 500px;
  }
}

/*Greeting*/
.eng_greeting {
  padding-left: 600px;
  background: url("../../resources/images/cont/eng-greeting-img1.png") left top no-repeat;
}

.eng_greeting h4 {
  font-size: 70px;
  color: #143553;
  font-family: 'GongGothicM';
  line-height: 1.4;
}

.eng_greeting h4 span {
  font-size: 30px;
  display: block;
}

.eng_greeting p {
  font-size: 18px;
  margin-bottom: 20px;
}

.eng_greeting p.type1 {
  color: #0050a0;
  font-weight: bold;
  font-size: 22px;
  margin-top: 30px;
  margin-bottom: 90px;
}

@media screen and (min-width: 1px) and (max-width: 1000px) {
  .eng_greeting {
    padding-left: 0;
    padding-top: 630px;
  }
  .eng_greeting h4 {
    font-size: 40px;
  }
  .eng_greeting h4 span {
    font-size: 20px;
  }
  .eng_greeting p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .eng_greeting p.type1 {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}

/*오시는길*/
.way-to__jido.eng .way-to__jido__text {
  padding-right: 50px;
}

.way-to__jido.eng .way-to__jido__text__tit {
  font-size: 30px;
  line-height: 1.2;
  text-align: center;
  margin-right: 50px;
  padding-right: 50px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .way-to__jido.eng .way-to__jido__text__tit {
    text-align: left;
    padding-right: 0;
    margin-right: 0;
  }
}

/*Support Facilities*/
.subpage.eng .jiwon-sisal__box {
  font-size: 16px;
  min-height: 420px;
  padding: 40px 45px;
}

.subpage.eng .jiwon-sisal__box .h3Type1 {
  margin-bottom: 10px;
}

.icon-test {
  padding: 0px;
  background-color: #000;
  height: 28px;
  display: inline-block;
}

.icon-test:after {
  content: '';
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -362.66667px -76.66667px;
  background-image: url("../images/sprite.png");
  background-size: 958.66667px auto;
  width: 18.66667px;
  min-width: 18.66667px;
  height: 18.66667px;
}

.flex {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 500px;
  height: 200px;
  border: 1px solid red;
}

.flex-item {
  flex: 1;
  order: 0;
  width: 100px;
  height: 100px;
  border: 1px solid blue;
  background-color: #ccc;
}

.flex-item:nth-child(3) {
  position: absolute;
  right: 0;
  top: 50%;
  width: 50px;
  height: auto;
  min-height: 40px;
  min-width: 40px;
  transform: translateY(-50%);
  flex: 1;
  order: 1;
  background-color: red;
}

.sample {
  font-size: 20px;
}

/*pages 가이드*/
.pages a {
  display: block;
  margin-bottom: 15px;
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
}

.pages a span {
  position: absolute;
  left: 280px;
  top: 0px;
  border: 1px solid #666;
  display: inline-block;
  padding: 2px;
  margin-left: 10px;
  width: 400px;
  padding-left: 10px;
}

.pages a em {
  color: #ff5054;
  font-weight: bold;
}

.pages a:hover span,
.pages a:focus span {
  background-color: #eee;
}

.pages a:hover, .pages a:focus {
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .pages a span {
    width: auto;
    position: relative;
    left: 0;
    display: block;
  }
}

.box1 {
  background-color: #666;
  position: relative;
  padding: 10px;
  height: 200px;
}

.box1__in {
  background-color: #eee;
  position: absolute;
  z-index: 9999;
  top: 10%;
  left: 10%;
}

@media print {
  header, footer, .sidebar, .location-box, .special-box, .foot-go-link {
    display: none;
  }
  html,
  body,
  .sub-contents {
    width: 1050px;
    float: none;
  }
}

/*	footer
==========*/
.footer {
  background-color: #1a1b1e;
  color: #fff;
  position: relative;
  font-family: "Pretendard-Regular", "Nanum Gothic", sans-serif;
  font-size: 16px;
  padding-top: 30px;
  padding-bottom: 70px;
  text-align: center;
  /*background-image:url("../../commons/images/global/footer-bg.png");background-position:0 45px;background-repeat:repeat-x;*/
}

.footer a:hover,
.footer a:focus {
  text-decoration: underline;
}

.footer__in {
  width: 1580px;
  margin: 0 auto;
  position: relative;
  min-height: 180px;
}

.footer__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .footer__in {
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .footer__in {
    min-height: 150px;
  }
}

.foot_img {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -200px;
  background-image: url("../images/sprite.png");
  width: 242px;
  min-width: 242px;
  height: 62px;
  text-indent: -9999px;
  font-size: 0;
}

.copy_box {
  margin-top: 20px;
  z-index: 9;
}

.copy_box ul {
  display: inline-block;
  margin: 0 auto;
}

.copy_box ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .copy_box ul {
  display: inline-block;
}

.copy_box li {
  float: left;
  position: relative;
  padding-left: 30px;
}

.copy_box li a {
  color: #fff;
  vertical-align: top;
}

.copy_box li:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px -1370px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 13px;
}

.copy_box li:first-child:before {
  display: none;
}

.copy_box li:first-child {
  background-image: none;
  padding-left: 0;
}

.copy_box li:first-child a {
  background-image: none;
}

.copy_box li.persnal-rule a {
  color: #fbf36f;
  font-weight: bold;
}

.copy_box li.sns-li {
  background-image: none;
}

.copy_box2 {
  width: auto;
  padding-top: 20px;
  font-size: 16px;
}

.copy_box2 .item1 {
  margin-bottom: 15px;
  font-style: normal;
  line-height: 1.5;
  color: #fff;
}

.copy_box2 .item1 ul:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .copy_box2 .item1 ul {
  display: inline-block;
}

.copy_box2 .item1 ul li {
  padding-left: 20px;
}

.copy_box2 .item1 ul li:first-child {
  padding-left: 0;
  background-image: none;
}

.copy_box2 .item2 {
  font-style: normal;
  margin-top: 5px;
  margin-bottom: 4px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #fff;
}

.copy_box3 {
  position: absolute;
  right: 0;
  top: 10px;
}

.copy_box4 {
  position: absolute;
  right: 0;
  top: 80px;
}

/*통계*/
.total-count {
  background-color: #399e90;
  padding: 10px 30px 10px 10px;
  border-radius: 30px;
}

.total-count:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .total-count {
  display: inline-block;
}

.total-count__dt {
  display: inline-block;
  color: #fff;
  margin-right: 5px;
  padding-left: 20px;
}

.total-count__dt:before {
  content: '';
  padding-right: 10px;
  padding-left: 14px;
  display: inline-block;
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1437px -60px;
  background-image: url("../images/sprite.png");
  width: 1px;
  min-width: 1px;
  height: 10px;
}

.total-count__dt:first-child:before {
  display: none;
}

.total-count__dd {
  display: inline-block;
  color: #fff;
}

/*푸더 셀렉트*/
.footer-select {
  background-color: #434a54;
  border: 1px solid #a1a5aa;
  border-radius: 10px;
  overflow: hidden;
}

.footer-select__item {
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  position: relative;
  background-color: transparent;
  text-align: left;
  border: 0px solid #717171;
}

.footer-select__item:after {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 10px;
  transform: translateY(-50%);
  content: ' ';
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -226px -1310px;
  background-image: url("../images/sprite.png");
  width: 9px;
  min-width: 9px;
  height: 5px;
}

.footer-select__item select {
  width: 100%;
  background-color: transparent;
  appearance: none;
  min-width: 210px;
  vertical-align: top;
  color: #ddd;
  font-size: 14px;
  height: 38px;
  margin-top: 0;
  padding: 2px 2px 2px 10px;
  position: relative;
  border: 0px;
  font-family: "Pretendard-Regular", "Nanum Gothic", sans-serif;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  /*ie10~11 에서 셀렉트 기본디자인 무력화*/
}

.footer-select__item select::-ms-expand {
  display: none;
}

.lte-ie9 .footer-select__item select {
  width: 125%;
  vertical-align: top;
}

.footer-select__item select option {
  color: #000;
  font-family: "Nanum Gothic";
}

.footer-select__item select:focus {
  border: 1px solid gold;
  border-radius: 4px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .footer-select__item select {
    min-width: 180px;
  }
}

.sel_go_btn {
  display: inline-block;
  height: 38px;
  width: 43px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  background-color: #272727;
  border-left: 1px solid #717171;
}

* + html .sel_go_btn {
  display: inline;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  /*footer*/
  .footer {
    height: auto;
    min-height: 100px;
    padding: 10px;
    background-image: none;
    position: relative;
    z-index: 12;
  }
  .footer__in {
    width: 100%;
  }
  .foot_img {
    display: none;
  }
  .copy_box {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 5px;
    background-color: transparent;
    padding: 5px;
  }
  .copy_box2 {
    position: relative;
    left: 0;
    top: 0;
    padding-top: 10px;
  }
  .copy_box2 .item1 {
    font-size: 15px;
  }
  .copy_box li {
    background-image: none;
    padding: 4px 10px;
  }
  .copy_box li::before {
    display: none;
  }
  .select_bottom {
    position: relative;
    right: 0;
    top: 0;
  }
  .select_bottom p {
    float: left;
  }
  .copy_box2 .item1 li {
    padding-left: 0;
    background-image: none;
    float: none;
  }
  .copy_box3 {
    position: relative;
    top: 0;
    margin-bottom: 5px;
  }
  .copy_box4 {
    position: relative;
    top: 0;
  }
  .total-count {
    padding: 0;
    border-radius: 0;
    padding: 5px;
  }
  .total-count__dt:first-child {
    padding-left: 0;
  }
  .footer-select {
    display: inline-block;
  }
  .sel_go_btn {
    border-left: 0px;
  }
}

@media screen and (min-width: 851px) and (max-width: 1000px) {
  /*footer*/
  .copy_box4 {
    position: absolute;
    top: 39px;
    right: 10px;
  }
  .copy_box4 p {
    float: none;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .copy_box2 .item1 li strong {
    display: block;
    margin-top: 5px;
  }
}

@media screen and (min-width: 1px) and (max-width: 470px) {
  .copy_box li {
    padding: 0;
    float: left;
    width: 50%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 24px;
  }
  .copy_box2 .item1 li {
    padding-left: 0 !important;
    background-image: none;
    width: 100%;
  }
}

.foot-go-link {
  background-color: #fafbff;
  position: relative;
  z-index: 9999;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.foot-go-link__in {
  width: 1580px;
  margin: 0 auto;
  border-right: 1px solid #dddddd;
  position: relative;
}

.foot-go-link__in:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .foot-go-link__in {
  display: inline-block;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .foot-go-link__in {
    width: auto;
    border-right: 0px;
  }
}

.foot-go-link__in__item {
  width: 25%;
  float: left;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .foot-go-link__in__item {
    width: 50%;
    position: relative;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .foot-go-link__in__item {
    position: unset;
  }
}

.foot-go-link__in__item__a {
  border-left: 1px solid #dddddd;
  display: block;
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  color: #555;
  font-weight: 600;
  font-size: 16px;
  position: relative;
}

@media screen and (min-width: 1px) and (max-width: 1580px) {
  .foot-go-link__in__item__a {
    border-bottom: 1px solid #5f6570;
  }
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .foot-go-link__in__item__a {
    height: 40px;
    line-height: 40px;
    border-left: 0px;
  }
}

.foot-go-link__in__item__a:hover, .foot-go-link__in__item__a:focus {
  background-color: #fff;
}

.foot-go-link__in__item__a:focus {
  text-decoration: underline;
}

.foot-go-link__in__item__a:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1026px -1172px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 10px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.on .foot-go-link__in__item__a:after {
  content: '';
  vertical-align: middle;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: -1093px -1172px;
  background-image: url("../images/sprite.png");
  width: 17px;
  min-width: 17px;
  height: 10px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .foot-go-link__in__item__a:after {
    right: 10px;
  }
}

.on .foot-go-link__in__item__a {
  background-color: #fff;
}

.foot-go-link__in__item__box {
  display: none;
  position: absolute;
  bottom: 60px;
  background-color: #fff;
  box-shadow: 2px -4px 18px -10px rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid #e2e5f3;
  min-width: 300px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .foot-go-link__in__item__box {
    bottom: 82px;
    width: 100%;
  }
}

.on .foot-go-link__in__item__box {
  display: block;
}

.foot-go-link__in__item__box .depth:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .foot-go-link__in__item__box .depth {
  display: inline-block;
}

.foot-go-link__in__item__box .depth .sec_box {
  float: left;
}

.foot-go-link__in__item__box .depth .sec_box:after {
  content: ' ';
  clear: both;
  display: block;
  visibility: hidden;
}

* + html .foot-go-link__in__item__box .depth .sec_box {
  display: inline-block;
}

.foot-go-link__in__item__box .depth .sec_box ul {
  float: left;
  padding: 20px;
  min-width: 100px;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .foot-go-link__in__item__box .depth .sec_box ul {
    padding: 10px;
  }
}

.foot-go-link__in__item__box .depth .sec_box ul li a {
  display: block;
  padding: 7px 0;
  color: #333;
  font-size: 15px;
}

.foot-go-link__in__item__box .depth .sec_box ul li a:hover, .foot-go-link__in__item__box .depth .sec_box ul li a:focus {
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .foot-go-link__in__item__box .depth .sec_box ul li a {
    padding: 3px 0;
  }
}

.foot-go-link__in__item:nth-child(1) .foot-go-link__in__item__box {
  left: 0px;
}

.foot-go-link__in__item:nth-child(2) .foot-go-link__in__item__box {
  left: 25%;
}

@media screen and (min-width: 1px) and (max-width: 768px) {
  .foot-go-link__in__item:nth-child(2) .foot-go-link__in__item__box {
    left: 0;
  }
}

.foot-go-link__in__item:nth-child(3) .foot-go-link__in__item__box {
  right: 0px;
}

.foot-go-link__in__item:nth-child(4) .foot-go-link__in__item__box {
  right: 0px;
}
