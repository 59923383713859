
/*메인슬라이드*/
.background-cover {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}
  .main-slide__item {
	  //height: 100vh;
	  @extend .background-cover;
	  position: relative;
	  @media screen and (min-width:1px) and (max-width:1200px) {
		  > img {height:900px;}
	  }
  }
 // .main-slide__item > img {height:100%}
  .main-slide__item__text {position: absolute;top: 20%;left:50%;
	transform: translateX(-50%);
	width:1300px;
	text-align: right;
	img {display: inline;}
  }
@media screen and (min-width:1001px) and (max-width:1300px) {
	.main-slide__item__text {width:1000px;}
}

@media screen and (min-width:1px) and (max-width:1150px) {
	.popupzone-slide-big{position:relative; width:100%;}
	.popupzone2 {padding-left:0;}
	.popupzone-slide-first {display:none;}
	.popupzone-slide-big .popupzone2-list__item > a {width:100%; background-position:center}
	.popupzone-slide-big .slick-next {display:block !important}
}
@media screen and (min-width:1px) and (max-width:1150px) {
}
@media screen and (min-width:1px) and (max-width:1000px) {
	.main-slide__item__text {width:700px;}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.main-slide__item__text {width:500px;}
	
	.popupzone2 .popupzone2-nav {bottom:-60px; top:auto;}
	.popupzone-slide-big {}
	.popupzone-slide-big .popupzone2-list__item > a {height:400px;}
	
}
@media screen and (min-width:1px) and (max-width:560px) {
	.main-slide__item__text {width:360px;top:24%;}
	
	.popupzone-slide-big .popupzone2-list__item > a {height:300px;}
}

.main-visual {position: relative;width:100%;
	//height:100%;
	//height: 897px;
	.slick-arrow {border:0px;top:50%; margin-top:-9px; }
	.slick-prev {position: absolute;left:10px;z-index: 1;width:18px; height:30px;text-indent: -9999px;
		background: {
			image: url('../../resources/images/icons/main-visual-prev.png');
			repeat: no-repeat;
			position: 0 0;
		}
	}
	.slick-next {position: absolute;right:10px;z-index: 10;width:18px; height:30px;text-indent: -9999px;
		background: {
			image: url('../../resources/images/icons/main-visual-next.png');
			repeat: no-repeat;
			position: 0 0;
		}
	}
	.go-vr-btn{
		display:block;
		position:absolute;
		top:164px;
		left:0;
		z-index:1;
		@media screen and (min-width:1701px) and (max-width:1900px) {
			width:100px;
			height:100px;
			top:130px;
			right:20px;
		}
		@media screen and (min-width:1px) and (max-width:1700px) {
			display:none;
		}
	}
	
	
/*이미지의 상하사이즈로 설정*/

	.slick-dots-wrap-outwrap {display:none !important;
		text-align:left;position: absolute;z-index: 3;bottom: 10px;right: 2%;
		.slick-dots-wrap {display:inline-block;text-align:center;margin-top:0;z-index:999;
			//background-image:url("../../resources/images/basic/opa.png");
			padding:5px 10px;border-radius:20px;
		}
		.myslickcarousel {display:inline-block;}
		.slick-dots-on-off {display:inline-block;
			li {
				display: inline-block;
				vertical-align: middle;
				a {color:gold;}
			}

		}

		*+ html {
			.myslickcarousel {display:inline;}
			.slick-dots-on-off {display:inline;}
			.slick-dots-on-off li {display:inline;}
		}


		.slick-dots-list {
			display:inline-block !important;
			vertical-align: middle;
			li {
				display:inline-block;margin-right:3px;
				button { display:inline-block;color:#fff;
					vertical-align: middle;
					overflow: visible;
					font-weight: bold;
					width:22px;
					height:22px;
					line-height:22px;
					border-radius:22px;
					font-size:13px;
					background-color:#ddd;border:0px solid #ddd;
					width:20px;height:3px;
					font-size:0
				}

			}

			li.slick-active button {background-color:#000;border:0px solid #fff;
				width:20px;height:5px;
				font-size:0}
			li button:focus {background-color:$color2;color:#000;}
		}


		*+ html .slick-dots-list li {display:inline;}
		*+ html .slick-dots-list li button {display:inline;}

		.slick-dots-on-off img {vertical-align:middle;}

		.slick-dots-on-off li {margin-left:10px;}
		.main-slide-pause {
			display:block; width:22px;height:22px;font-size:0;text-indent: -9999px;
			vertical-align: top;
			@include sprite($visual_pause);
			border-radius: 40px;
			&:focus {background-color:$color1}
		}
		.main-slide-play {
			display:block; width:22px;height:22px;font-size:0;text-indent: -9999px;
			vertical-align: top;
			@include sprite($visual_play);
			border-radius: 40px;
			&:focus {background-color:$color1}
		}
		.main-slider-prev {
			display:block; width:64px;height:64px;font-size:0;text-indent: -9999px;
			vertical-align: top;
			@include sprite($slide-left,1.4);
			border-radius: 40px;
			border:1px solid #fff;
			&:focus {background-color:$color1}
		}
		.main-slider-next {
			display:block; width:64px;height:64px;font-size:0;text-indent: -9999px;
			vertical-align: top;
			@include sprite($slide-right,1.4);
			border-radius: 40px;
			border:1px solid #fff;
			&:focus {background-color:$color1}
		}
	}


	.slick-dots-wrap-outwrap {display:none;}
	.slick-dots-wrap-outwrap.on {display:block;}

	@media screen and (min-width:1px) and (max-width:820px) {
		.slick-dots-wrap-outwrap {
			display: none !important;
		}
		.main-slider-prev,
		.main-slider-next {display: none;}
	}

	@media screen and (min-width:1px) and (max-height:400px) {
		.slick-dots-wrap-outwrap {bottom:20px}

	}
}
/*
@media screen and (min-width:1px) and (max-width:1500px) {
	.main-visual {width:100%;padding-top:0;height: auto;margin-top:-140px;}
	.main-visual {}
	.main-visual .slick-arrow {top:150px;}
	.main-slide__item > img {width:1500px}
}
@media screen and (min-width:901px) and (max-width:1200px) {
	.main-slide__item > img {width:1500px}
}
@media screen and (min-width:769px) and (max-width:900px) {
	.main-slide__item > img {width:1250px}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.main-visual {margin-top:0;}
	.main-visual .slick-arrow {top:70px;}
	.main-slide__item > img {width:100%}
}

*/

/*video*/
.main-visual {overflow:hidden;}
.video-box {position: relative;}
.video-box__over {
	position: absolute;
	top:0;left:0;width:100%;
	height:100%;
	background: {
		image: url('../../resources/images/basic/video-over-img.png');
		repeat: repeat;
		position: 0 0;
	}

}
.video-box video {width:1900px;}
.video-box__pause {
	position: absolute;
	top:825px;
	right:50%;
	margin-right:-740px;
	z-index: 99999;
	background-color:#fff;
	font-weight: bold;
	display:block;
	padding:3px;
	border-radius:10px;
	border:2px solid #000;
}
.video-box__pause:focus {
	background-color:gold
}

@media screen and (min-width:1px) and (max-width:1500px) {
	.video-box video {width:1500px}
	.video-box__pause {
		top:800px;
		margin-right:-42%;
	}
}
@media screen and (min-width:901px) and (max-width:1200px) {
	.video-box video  {width:1200px}
	.video-box__pause {
		top:620px;
		margin-right:-40%;
	}
}
@media screen and (min-width:769px) and (max-width:900px) {
	.video-box video  {width:900px}
	.video-box__pause {
		top:460px;
		margin-right:-40%;
	}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.video-box video  {width:100%}
	.video-box__over {display:none;}
	.video-box__pause {
		display:none;
		top:unset;
		bottom:10%;
		margin-right:-44%;
	}
}

@media screen and (min-width:1px) and (max-width:768px) {
	.main-slide {display: none !important;}
}

//콘텐츠 박스
.main-first-cont-box {
	position: relative;
	max-width:1580px;
	width:100%;
	margin:0 auto;
	z-index: 999999;
	.popupzone2-nav {}
	.popupzone2-nav > div > ul {}
	
	@media screen and (min-width:1px) and (max-width:1400px) {
		width: auto;
		margin-left:10px;
		margin-right:10px;
	}
	@media screen and (min-width:1px) and (max-width:768px) {
		//background-color: #fff;
		padding-bottom:60px;
		padding-top:40px;
	}
	&__in {
		position: absolute;
		bottom:160px;
		left:0;
		width:100%;
		// 버튼,동영상 추가

		@media screen and (min-height:650px) and (max-height:800px) {

		}

		@media screen and (min-width:1px) and (max-width:768px) {
			position: relative;
			bottom:5px;
			left:0;
			width:100%;
			//background-color: #fef5e6;
			padding-top:0px;
			padding-bottom:5px;
		}

	}

}

//탄소용어사전
.latest-news {
	width:84%;

	margin:auto 8%;
	position:relative;
	@include floatWrap;
	overflow: hidden;
	&__tit {
		width:210px;
		height:70px;
		line-height:68px;
		float:left;
		font-size:18px;
		box-sizing: border-box;
		font-weight: 500;
		padding-left:78px;
		color:#fff;
		position: relative;
		background-color: #f7941c;
		&:before {
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			left:18px;
			@include sprite($latest-news-icon);
			@media screen and (min-width:1px) and (max-width:1200px) {
				@include sprite($latest-news-icon,1.5);
			}
		}
	}
	&__text {
		height:40px;
		width:65%;
		float:left;
		margin-top:13px;
		background-color:#fff;
		padding-left:10px;
		padding-right:1%;
		border-radius: 5px;
		overflow: hidden;
		/*background-image: url("../../commons/images/global/latest-news__text-icon.png");
		background-position:10px center;background-repeat: no-repeat;*/
		&__a {
			strong {
				color:#0e813f;
				font-size: 18px;
				font-size: 1.8rem;
				padding-right:10px;
				padding-left:10px;
			}
			font-size: 16px;
			font-size: 1.6rem;
			font-weight: normal;
			color: #333;
			display: block;
			width: 100% !important;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			height: 45px;
			line-height: 45px;
			box-sizing: border-box;
			&:focus {
				//border:1px solid gold;
				background-color:#eee;
				z-index: 90;
			}
		}
	}
	&__btn {
		display:block;border:0px;background:transparent;font-size:0;
		width:28px;height:28px;
		text-indent: -9999px;
		z-index: 1;

		&:focus {
			border:1px solid gold;
			z-index: 90;
		}
	}

	$newBtnTop:20px !important;
	&__prev {
		position:absolute;top:$newBtnTop;right:85px;

		@include sprite($ico-roll-left);
	}
	&__stop {
		position:absolute;top:$newBtnTop;right:45px;
		@include sprite($ico-roll-pause);
	}
	&__next {
		position:absolute;top:$newBtnTop;right:10px;
		@include sprite($ico-roll-right);
	}
	&__start {
		position:absolute;top:$newBtnTop;right:20px;
		@include sprite($ico-roll-start);
	}
	&__plus {
		position:absolute;top:$newBtnTop;right:127px;
		height:30px;
		border-radius: 50px;
		box-sizing: border-box;
		line-height: 30px;
		border:1px solid #0e813f;
		color:#0e813f;
		font-size:15px;
		text-indent: 0;
		width: auto;
		padding-left:15px;
		padding-right:15px;
	}


	.slick-vertical .slick-slide {border:0px;}


}



@media screen and (min-width:1px) and (max-width:1500px) {
	.latest-news {width:100%;margin:0 auto;}
}


@media screen and (min-width:1px) and (max-width:1200px) {
	.latest-news {border-radius:0;}
	.latest-news__tit {width:180px;height:40px;line-height:40px;padding-left:60px;}
	.latest-news__text {
		width:60%;background-image:none;background:transparent;padding-left:10px;margin-top:0px;
		&__a {
			height:40px;
			line-height: 40px;
		}
	}
	.latest-news__prev,
	.latest-news__next,
	.latest-news__stop,
	.latest-news__start,
	.latest-news__plus {top:5px !important;}
}
@media screen and (min-width:769px) and (max-width:960px) {
	.latest-news__text {width:50%;}
	}
@media screen and (min-width:1px) and (max-width:768px) {
	.main-first-cont-box__in2 {position: relative;background-color: #f6f6f6;}
	.latest-news {
		//background-color: #efefef;
		padding-top:10px;
		padding-bottom:10px;
	}
	.latest-news__tit {
		background-color: transparent;
		color:#000;
		padding-left:20px;
		&:before {display: none;}
	}
	.latest-news__text {height:35px;width:95% !important;margin-top:3px;
		background-color:transparent;
	}
	.latest-news__text__a {height:35px;line-height:35px;}

	.latest-news__prev,
	.latest-news__next,
	.latest-news__stop,
	.latest-news__start,
	.latest-news__plus {top:15px !important;}
}
@media screen and (min-width:1px) and (max-width:480px) {
	.latest-news__text {width:65%}


}



@media screen and (min-width: 1px) and (max-width: 768px){

	.main-visual {
		background-image:url('../../resources/images/cont/slide00.jpg');
		background-size: cover;
		height: auto !important;
		.main-slide__item {

			height: auto !important;
		}
		.main-slide__item > img {
			height:auto;
		}
		.main-slide .slick-list {
			height: auto !important;
		}

	}
	.slick-dots-wrap-outwrap {
		bottom:10px;
	}
}
