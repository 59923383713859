.cont-in__wrap2 {
	position: relative;
	display: inline-block;
	width: 100%;

	&:before {
		content: '';
		position: absolute;
		background: url(../../resources/images/cont/cont-type1-img.jpg) center top no-repeat;
		height: 380px;
		width: 100%;
		background-size:cover;
		z-index: -1;
		transform: translateX(-50%);
		left: 50%;
	}
}

.cont-type1 {
	padding-top: 60px;
	max-width:1580px; margin:0 auto; 
	&__left-box {
		float: left;
		width: calc(100% - 1000px);
		padding-right: 60px;
		box-sizing: border-box;

		&__btn {
			margin-top: 50px;

			a {
				float: left;
				margin-right: 5px;
				margin-bottom: 10px;
			}
		}

		img {
			box-shadow: 10px 10px 15px -4px rgba(0, 0, 0, 0.13);
		}
	}

	&__right-box {
		float: left;
		max-width: 1000px;
		width: 100%;

		>ul>li {
			border-bottom: 1px solid #dddddd;
			display: flex;
			padding-top: 50px;
			padding-bottom: 50px;
		}

		&__sm-tit {
			color: #fff;
			font-size: 16px;
		}

		h4 {
			font-size: 40px;
			font-weight: bold;
			color: #Fff;
			margin-top: 150px;
		}

		p {
			font-size: 20px;
			color: #Fff;
			margin-top: 10px;
			margin-bottom: 30px;
		}

		&__tit {
			font-size: 30px;
			font-weight: bold;
			width: 200px;
			padding-right: 10px;
			box-sizing: border-box;
			float: left;
			line-height: 1.4;
		}

		&__txt {
			font-size: 18px;
			width: calc(100% - 200px);
			float: left;
		}
	}
}


@media screen and (min-width:1px) and (max-width:1300px) {
	.cont-type1 {
		padding-top: 50px;
	}

	.cont-type1__left-box {
		float: none;
		width: 100%;
		padding-right: 0;
		text-align: center;
		&__btn {display:inline-block;}
	}
	.cont-in__wrap2.ver2:after {display:none;}
	.cont-type1__right-box {
		float: none;
		width: 100%;
		max-width: 100%;
	}
	.cont-type1__right-box__sm-tit {color:#000;}
	.cont-type1__right-box h4 {
		color: #000;
		margin-top: 30px;
	}

	.cont-type1__right-box p {
		color: #000;
	}

	.cont-type1__left-box>img {
		height: 300px !important;
		display:block; margin:0 auto;
	}
}

@media screen and (min-width:1px) and (max-width:768px) {
	.cont-type1__right-box h4 {
		font-size: 26px;
	}

	.cont-type1__right-box p {
		font-size: 17px;
	}

	.cont-type1__right-box>ul>li {
		display: inline-block;
		padding-top: 30px;
		padding-bottom: 30px;
		width: 100%;
	}

	.cont-type1__right-box>ul>li:first-child {
		border-top: 1px solid #ddd;
	}

	.cont-type1__right-box__tit {
		font-size: 20px;
		margin-bottom: 10px;
	}

	.cont-type1__right-box__txt {
		font-size: 16px;
	}

	.cont-type1__right-box__tit,
	.cont-type1__right-box__txt {
		float: none;
		width: 100%;
	}
}

.cont-type2 {max-width:1580px; width:100%; margin:0 auto;
	>li {
		display: flex;
		margin-bottom: 80px;
	}

	&__left-box {
		width: 380px;

		>h4 {
			font-size: 30px;
			font-weight: bold;
		}
	}

	&__right-box {
		width: calc(100% - 380px);

		>p {
			font-size: 18px;
		}

	}
}

@media screen and (min-width:1px) and (max-width:1200px) {
	.cont-type2__left-box {
		width: 180px;
		padding-right: 20px;
		box-sizing: border-box;
	}

	.cont-type2__left-box .btn4 {}

	.cont-type2__right-box {
		width: calc(100% - 180px);
	}

	.cont-type2__left-box>h4 {
		font-size: 24px;
	}

	.cont-type2__right-box>p {
		font-size: 16px;
	}

}

@media screen and (min-width:1px) and (max-width:768px) {
	.cont-type2>li {
		display: inline-block;
		width: 100%;
	}

	.cont-type2__left-box,
	.cont-type2__right-box {
		width: 100%;
		margin-top: 10px;
	}

	.cont-type2__left-box>h4 {
		margin-bottom: 10px;
	}
}


/*입주정보 - 공공건물입주*/
.moving-public {
	&__ul {
		display: flex;
		gap: 23px;
		text-align: center;
		margin-top: 40px;

		li {
			border: 1px solid #e0e0e0;
			flex: 1;
			padding-bottom: 38px;
		}

		img {}

		h5 {
			font-size: 24px;
			font-weight: bold;
			margin-top: 30px;
			margin-bottom: 20px;
		}

		p {
			font-size: 18px;
			min-height: 100px;
			line-height: 1.5
		}


	}

}

@media screen and (min-width:1px) and (max-width:1200px) {
	.moving-public__ul {
		gap: 5px;
	}

	.moving-public__ul li {
		margin-bottom: 10px;
		padding-bottom: 20px;
	}

	.moving-public__ul h5 {
		font-size: 20px;
		margin-top: 20px;
	}

	.moving-public__ul p {
		font-size: 16px;
		min-height: 140px;
		padding-left: 8px;
		padding-right: 8px;
	}
}

@media screen and (min-width:1px) and (max-width:600px) {
	.moving-public__ul {
		display: block;
	}

	.moving-public__ul p {
		min-height: auto;
		margin-bottom: 10px;
	}

	.moving-public__ul img {
		width: 100%;
	}
}

/*입주정보 - 스타트업보육공간*/
.accordian {
	&__tit {
		position: relative;
		padding: 25px 56px 25px 30px;
		cursor: pointer;
		font-size: 16px;
		border-bottom: 1px solid #e0e0e0;

		&:before {
			position: absolute;
			right: 30px;
			top: 50%;
			transform: translateY(-50%);
			@include sprite($accordian-on);
		}

		&:first-child {
			border-top: 3px solid #333333;
		}

		&.on>span {
			font-weight: bold;
			color: #006633;
		}

		&.on {
			&:before {
				position: absolute;
				@include sprite($accordian-off);
			}

		}
	}

	&__txt {
		display: none;
		overflow: hidden;
		background-color: #f8f8f8;
		padding: 25px 30px;
		font-size: 16px;
	}

	&__ul {
		li {
			margin-bottom: 10px;
			display: flex;
			align-items: flex-start;

			span {
				font-size: 18px;
				font-weight: bold;
				display: flex;
				align-items: center;
				line-height: 1.5;

				&:after {
					display: inline-block;
					width: 1px;
					height: 16px;
					background-color: #cccccc;
					content: '';
					margin-left: 20px;
					margin-right: 20px;
				}

			}

			&:last-child {
				margin-bottom: 0;
			}

			a {
				display: contents;
				word-break: break-all;
			}
		}

	}

}

@media screen and (min-width:1px) and (max-width:768px) {
	.accordian__tit .accordian__ul li {
		display: block;
	}

	.accordian__tit .accordian__ul li span:after {
		display: none;
	}

	.accordian__tit {
		padding: 20px 56px 20px 15px;
	}

	.accordian__ul li span {
		font-size: 16px;
	}

	.accordian__txt {
		padding: 20px 15px
	}

	.accordian__ul li {
		font-size: 14px;
	}
}



/*시설예약 - 한교홍보관 예약현황 */

.reservation {
	&__date {
		background-color: #e6e9ee;

		&__in {
			max-width: 550px;
			width: 100%;
			margin: 0 auto;
			display: flex;
			gap: 20px;
			padding-top: 25px;
			padding-bottom: 25px;
		}

		&__left {
			text-indent: -9999px;
			@include sprite($slide-left3)
		}

		&__right {
			text-indent: -9999px;
			@include sprite($slide-right3)
		}

		&__txt {
			font-size: 18px;
			border: 1px solid #1a1b1e;
			border-radius: 100px;
			padding: 10px 60px;
			text-align: center;
			background-color: #fff;
			line-height: 1.8;
		}
	}

	&__ul {
		padding-left: 50px;
		padding-right: 50px;
		&__day {
			margin-right: 14px;
			color: #fff;
			background-color: #1a1b1e;
			float: left;
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			font-size: 18px;
			font-weight: 600;
		}

		&__day2 {
			font-size: 24px;
			font-weight: 600;
			height: 50px;
			line-height: 50px;
		}

		li {
			position: relative;
			margin-top: 50px;
		}

		&__ul {
			display: flex;
			gap: 8px;
			justify-content: space-between;
			clear: both;

			li {
				width: 100%;
				text-align: center;

				>div {
					border: 1px solid #a7a7a7;
					text-align: center;
					padding-top: 10px;
					padding-bottom: 10px;
					border-radius: 100px;
					color: #a7a7a7;
					margin-bottom: 5px;

					&.on {
						border: 1px solid #0050a0;
						color: #0050a0;
					}
					&.off {
						border: 1px solid #ee060b;
						color: #ee060b;
					}
				}
			}
		}
		&.ver2 {
			.reservation__ul__ul {justify-content:center;}
			.reservation__ul__ul li {width:240px;}
		}
	}

	.btn2.st2 {
		position: absolute;
		top: 0;
		right: 0;
	}

	@media screen and (min-width: 1px) and (max-width: 768px) {
		&__ul {
			padding-left: 0;
			padding-right: 0;

			&__ul {
				flex-wrap: wrap;
				gap: 0;
			}

		}

		&__date__txt {
			padding: 10px;
			font-size: 14px;
			display: grid;
			align-items: center;
			width: 100%;
		}

		&__date__in {
			justify-content: center;
			gap: 5px;
		}
	}
}

/*시설예약 - 판교홍보관 개요*/
.public-relations {
	.cont-type1 {
		padding-top: 110px;
	}

	.cont-type1__right-box h4 {
		margin-top: 40px;
		line-height: 1.5;
	}

	.popupzone {
		width: auto;

		.popupzone-nav {
			text-align: left;
		}

		.popupzone-nav>div>div {
			display: none;
		}

		.popup-total {
			display: none !important;
		}

		.popupzone-nav>div>ul li .slide-next-item {
			border-right: 0;
		}

		.popupzone-nav>div {
			left: 0;
			right: auto;
		}
	}

}

@media screen and (min-width: 1px) and (max-width: 1300px) {
	.public-relations .popupzone {
		max-width: 520px;
		width: 100%;
		margin: 0 auto;
	}


}


@media screen and (min-width: 1px) and (max-width: 768px) {
	.public-relations .cont-type1 {
		padding-top: 50px;
	}

	.public-relations.cont-in__wrap2:before {
		height: 18%
	}


}


/*판교tv소개 - 개요,비전*/
.tv_info {
	position: relative;
	background: url('../../resources/images/cont/tv-info-img1.png') center top no-repeat;
	text-align: center;
	padding-top: 60px;

	h4 {
		font-weight: bold;
		margin-top: 40px;
		display: inline-block;

		&.visions {
			font-size: 40px;
			background: url('../../resources/images/cont/tv-info-img2.png') left center no-repeat,
				url('../../resources/images/cont/tv-info-img3.png') right center no-repeat;
			margin-bottom: 130px;
			padding-left: 50px;
			padding-right: 50px;
			span{
				font-size:24px;
				color:#111;
				letter-spacing:-0.5px;
				display:block;
				font-weight:normal;
			}
		}

		&.mission {
			margin-bottom: 40px;
			font-size: 30px;
		}
	}

	img {
		display: block;
		margin: 0 auto;
	}

	.strategies {
		margin-top: 130px;
		margin-bottom: 50px;
	}

}


@media screen and (min-width: 1px) and (max-width: 768px) {
	.tv_info h4.visions {
		font-size: 22px;
	}

	.tv_info h4.mission {
		font-size: 20px;
	}
}

/*판교tv소개 - 개요_소개*/
.tv_intro {max-width:1580px; width:100%; margin:0 auto; 
	>h4 {
		font-weight: bold;
		font-size: 40px;
		margin-top: 50px;
		margin-bottom: 20px;
	}

	>p {
		font-size: 24px;
		margin-bottom: 80px;
	}

	&__box {
		display: flex;
		gap: 80px;
		justify-content: center;
	}

	@media screen and (min-width: 1px) and (max-width: 768px) {
		>h4 {
			font-size: 26px;
		}

		>p {
			font-size: 18px;
		}

		&__box {
			gap: 20px;
		}
	}
}


/*판교TV소개 - BI소개*/
.bi-info {
	&__img-box {
		display: flex;
		border: 1px solid #e0e0e0;

		li {
			flex: 1;
		}
	}

	&__btn-box {
		margin-top: 40px;

		li {
			float: left;
			margin-right: 10px;
		}
	}

	h5 {
		font-size: 24px;
		color: #111111;
		font-weight: bold;
		margin-top: 30px;
		margin-bottom: 20px;
	}

	p {
		font-size: 18px;
	}

	@media screen and (min-width: 1px) and (max-width: 510px) {

		&__img-box {
			display: block;
			;
			border: 0;

			li {
				border: 1px solid #e0e0e0;
				margin-bottom: 10px;
			}
		}

		&__btn-box {
			li {
				display: block;
				float: none;
				margin-bottom: 5px;
			}
		}
	}

}

/*판교TV소개 - 조직도*/

.organization {
	position: relative;

	&__top {
		position: relative;
		min-height: 650px;
		max-width: 950px;
		text-align: center;
		margin: 0 auto;

		a {
			cursor: pointer;
		}

		&__0 {
			position: relative;
			display: inline-block;

			&::before {
				content: '';
				background-color: #cccccc;
				position: absolute;
				bottom: -61px;
				height: 61px;
				width: 0.5px;
				text-align: center;
				margin: 0 auto;
				transform: translateX(-50%);
				left: 50%;
			}
		}

		&__1 {
			position: absolute;
			top: 329px;
			transform: translateX(-50%);
			left: 50%;
			width: 250px;
			height: 80px;
			align-items: center;
			justify-content: space-around;
			display: flex;
			background-color: #1a1b1e;
			color: #fff;
			font-weight: bold;
			text-align: center;
			font-size: 18px;

			&::before {
				content: '';
				background-color: #cccccc;
				position: absolute;
				bottom: -50px;
				height: 50px;
				width: 0.5px;
				text-align: center;
				margin: 0 auto;
				transform: translateX(-50%);
				left: 50%;
			}
		}

		&__2 {
			position: absolute;
			font-size: 24px;
			text-align: center;
			border: 2px solid #1a1b1e;
			width: 300px;
			height: 110px;
			top: 520px;
			left: 0;
			align-items: center;
			justify-content: space-around;
			display: grid;
			align-content: center;

			&::before {
				content: '';
				background-color: #cccccc;
				position: absolute;
				top: -63px;
				height: 61px;
				width: 0.5px;
				text-align: center;
				margin: 0 auto;
				transform: translateX(-50%);
				left: 50%;
			}

			&::after {
				content: '';
				background-color: #cccccc;
				position: absolute;
				top: -64px;
				height: 1px;
				width: 325px;
				text-align: center;
				margin: 0 auto;
				left: 50%;
			}

			p {
				font-size: 18px;
			}
		}

		&__3 {
			position: absolute;
			font-size: 24px;
			text-align: center;
			border: 2px solid #1a1b1e;
			width: 300px;
			height: 110px;
			top: 520px;
			right: 0;
			align-items: center;
			justify-content: space-around;
			display: grid;
			align-content: center;

			&::before {
				content: '';
				background-color: #cccccc;
				position: absolute;
				top: -63px;
				height: 61px;
				width: 0.5px;
				text-align: center;
				margin: 0 auto;
				transform: translateX(-50%);
				left: 50%;
			}

			&::after {
				content: '';
				background-color: #cccccc;
				position: absolute;
				top: -64px;
				height: 1px;
				width: 325px;
				text-align: center;
				margin: 0 auto;
				right: 50%;
			}

			p {
				font-size: 18px;
			}
		}



	}

	@media screen and (min-width: 1px) and (max-width: 680px) {
		height: auto;

		&__top {
			min-height: auto;
			margin-bottom: 50px;
		}

		&__top__0,
		&__top__1,
		&__top__2,
		&__top__3 {
			position: relative;
			top: auto;
			right: auto;
			left: auto;
			transform: none;
			text-align: center;
			margin: 0 auto;
			margin-bottom: 10px;
			width: 100%;

			&::before {
				display: none;
			}

			&::after {
				display: none;
			}
		}

		&__top__1,
		&__top__2,
		&__top__3 {
			height: 60px;
		}

		&__top__2,
		&__top__3 {
			font-size: 18px;

			p {
				font-size: 16px;
			}
		}

		.basicTable1.res td:before {
			min-width: 44px;
		}
	}
}

.jojikdo-resultbox {
	display: none;
}

.jojikdo-resultbox.on {
	display: block;
}


/*사이버 홍보관*/

.cyber-promote {
	h5 {
		font-size: 50px;
		line-height: 1.2;
		font-weight: normal;
		color: #666666;
		font-family: 'GongGothicM';

		span {
			color: #111111;
		}
	}

	p {
		color: #555;
		font-size: 18px;
	}

	&__ul {
		li {
			float: left
		}

		&__img {
			width: 40%;
			padding-right: 48px;
			box-sizing: border-box;
		}

		&__txt {
			width: 60%;
		}
	}

	.btn6 {
		max-width: 200px;
		width: 100%;
		box-sizing: border-box;
		text-align: left;
	}
}

@media screen and (min-width: 1px) and (max-width: 768px) {
	.cyber-promote__ul__img {
		width: 100%;
		float: none;
		margin: 0 auto;
		text-align: center;
		padding-right: 0;
	}

	.cyber-promote__ul__txt {
		width: 100%;
		float: none;
		margin-top: 20px;
	}

	.cyber-promote h5 {
		font-size: 28px;
	}

	.cyber-promote p {
		font-size: 16px;
	}

	.downBtn1 {
		display: inline-block;
	}
}


/*찾아오시는 길*/
.way-to {
	position: relative;
	margin-top: 100px;

	&__jido {
		position: relative;
		padding-top: 450px;

		.root_daum_roughmap {
			width: 1920px !important;
			z-index: 0;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
		}

		&__text {
			position: relative;
			align-items: center;
			padding-top: 40px;
			padding-left: 70px;
			padding-bottom: 40px;
			box-sizing: border-box;
			max-width: 1200px;
			height: 250px;
			width: 100%;
			background-color: #1a1b1e;
			color: #fff;
			z-index: 9999;
			margin: 0 auto;
			display: flex;

			&__tit {
				font-size: 40px;
				margin-right: 70px;
				padding-right: 70px;
				border-right: 1px solid #5f6062;
				height: 100%;
				display: grid;
				align-items: center;

			}

			&__box {
				font-size: 18px;

				h4 {
					font-size: 24px;
					margin-bottom: 10px;

					&::before {
						@include sprite($map-icon);
						margin-right: 10px;
					}
				}
			}

		}
	}

	.col_red2 {
		color: #af0734;
	}

	.col_yellow2 {
		color: #eba900;
	}

	@media screen and (min-width: 1px) and (max-width: 768px) {
		&__jido__text {
			display: block;
			height: auto;
			padding: 20px;

			&__tit {
				font-size: 26px;
				border-right: 0;
			}

			h4 {
				font-size: 18px;
			}

			&__box {
				font-size: 16px;
			}
		}
	}
}


/*지원시설*/
.jiwon-sisal {
	.popupzone {
		width: 100%;
		max-width: 620px;
		float: left;
		position: relative;

		.popupzone-nav {
			text-align: left;

			>div>div {
				display: none;
			}

			.popup-total {
				display: none;
			}
		}

		.slide-next-item {
			border-right: 0;
		}
	}

	&__top {
		clear: both;
		display: inline-block;
		width: 100%;
	}

	&__box {
		width: calc(100% - 620px);
		float: left;
		background-color: #1a1b1e;
		color: #fff;
		min-height: 419px;
		padding: 46px 48px;
		box-sizing: border-box;

		.h3Type1 {
			color: #fff;
			padding-top: 20px;

			&:first-child {
				padding-top: 0;
			}
		}


	}

	@media screen and (min-width: 1px) and (max-width: 1600px) {
		.jiwon-sisal__box {
			float: none;
			width: 100%;
			min-height: auto;
		}

		.popupzone {
			float: none;
			margin: 0 auto;
		}

	}

	@media screen and (min-width: 1px) and (max-width:768px) {
		.popupzone {
			img {
				max-width: 100%;
				width: 100%;
			}
		}

		.jiwon-sisal__box {
			padding: 15px 20px;

			>p {
				font-size: 16px;
			}
		}

		&__box .h3Type1 {
			font-size: 18px;
			padding-top: 10px;
			margin-bottom: 5px;
		}

	}
}

/*개요-연혁*/
.v-slider {
	max-width: 1280px;
	width: 100%;
	margin: 0 auto;

	>div>div {
		padding: 0 !important;
	}

	&__year {
		font-size: 240px;
		color: #f3f3f3;
		width: 680px;
		font-weight: bold;
	}

	&__txt {
		font-size: 20px;
		// position:absolute;z-index:2; top:50%; transform:translateY(-50%);
		width: calc(100% - 680px);

		li {
			display: flex;

			.v-slider__txt__date {
				width: 115px;
				color: #999999;
			}

			.v-slider__txt__in {
				width: calc(100% - 115px);
			}
		}
	}

	.slide-wrapper {
		display: -webkit-box;
	}

	.slide-image-container {
		width: 30%;
	}

	.slide-image {}

	.slide-text-container {
		width: 70%;
	}

	/* carousel specific styles */
	.slick-prev.slick-arrow {
		cursor: pointer;
		position: absolute;
		top: 280px;
		left: 580px;
		z-index: 10;
	}

	.slick-next.slick-arrow {
		cursor: pointer;
		position: absolute;
		bottom: 280px;
		left: 580px;
		z-index: 10;
	}

	.slick-slide {
		outline: none;
	}

	.item {
		display: flex;
		align-items: center;
		position: relative;
		overflow: visible !important;
		margin: 4px;
		height: 220px;
		opacity: 1;
		transform: translate3d(0, 0, 0);
		transition: opacity 1.6s cubic-bezier(0, 1, 0.86, 1), transform 0.8s cubic-bezier(0, 1, 0.86, 1);
		outline: none;
		overflow: hidden;
		z-index: -1;

		.v-slider__txt {
			display: none;
		}
	}

	.item img {
		width: 100%;
		transform: rotate(0) translate3d(0, 0, 0);
		transition: filter 1.6s cubic-bezier(0, 1, 0.86, 1);
	}

	.item.slick-animate {
		opacity: 1;
		overflow: visible;
		z-index: 99;
		transition: filter 1.6s cubic-bezier(0, 1, 0.86, 1), opacity 0.4s cubic-bezier(0, 1, 0.86, 1), transform 0.4s cubic-bezier(0, 1, 0.86, 1);
		transition: filter 1.6s cubic-bezier(0, 1, 0.86, 1), opacity 0.4s cubic-bezier(0, 1, 0.86, 1);

	}

	.slick-center {
		opacity: 1;
	}

	.slick-center .v-slider__txt {
		display: block;
	}

	.slick-center .v-slider__year {
		color: #111111;
	}


	.btn {
		border: 2px solid black;
		height: 10px;
		width: 20px;
		position: absolute;
		top: 23px;
		right: 40px;
	}

	@media screen and (min-width: 1px) and (max-width:1200px) {
		.v-slider__year {
			font-size: 80px;
			width: 270px;
		}

		.v-slider__txt {
			font-size: 20px;
			width: calc(100% - 270px);
		}

		.slick-prev.slick-arrow {
			left: 210px;
			width: 40px;
			top: 140px;
		}

		.slick-next.slick-arrow {
			left: 210px;
			width: 40px;
			bottom: 140px;
		}

		.item {
			height: 120px;
		}
	}

	@media screen and (min-width: 1px) and (max-width:768px) {
		.item {
			height: 70px;
		}

		.v-slider__year {
			font-size: 50px;
			width: 190px;
		}

		.v-slider__txt {
			font-size: 16px;
			width: calc(100% - 190px);
		}

		.v-slider__txt li .v-slider__txt__date {
			width: 90px;
		}

		.v-slider__txt li .v-slider__txt__in {
			width: calc(100% - 90px)
		}

		.slick-prev.slick-arrow {
			left: 130px;
			top: 75px;
		}

		.slick-next.slick-arrow {
			left: 130px;
			bottom: 75px;
		}
	}

	@media screen and (min-width: 1px) and (max-width:480px) {
		.v-slider__year {
			font-size: 30px;
			width: 130px;
		}

		.v-slider__txt {
			font-size: 14px;
			width: calc(100% - 130px);
		}

		.v-slider__txt li .v-slider__txt__date {
			width: 70px;
		}

		.v-slider__txt li .v-slider__txt__in {
			width: calc(100% - 70px)
		}

		.slick-prev.slick-arrow {
			left: 80px;
		}

		.slick-next.slick-arrow {
			left: 80px;
		}
	}
}

/*판교tv소개 - 입주기업정보(인포그래픽스)*/

.Infographics {max-width:1580px; width:100%; margin: 0 auto;
	>h4 {
		font-size: 40px;
		font-weight: bold;
		margin-bottom: 20px;
	}

	.popupzone {
		width: 76%;
		float: left;
		font-size: 0;
		position: relative;

		.slick-prev {
			display: block !important;
			position: absolute;
			top: 50%;
			left: 20px;
			transform: translateY(-50%);

			&:before {
				content: '';
				@include sprite($slide-left2);
			}
		}

		.slick-next {
			display: block !important;
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);

			&:before {
				content: '';
				@include sprite($slide-right2);
			}
		}

		.popupzone-nav {
			text-align: center;
			bottom: 30px;

			>div {
				border-radius: 100px;

				>ul li .slide-pause-item {
					border-right: 0;
				}

				.slide-pause-item {
					background: url(../../resources/images/icons/main-visual-pause.png) center center no-repeat;
					width: 35px;
					height: 35px;
					margin-right: 10px;
				}

				.popup-total-num {
					padding-right: 0;
				}

				>div {
					padding-left: 25px;
					padding-right: 7px;
				}
			}
		}

	}

	&__ul {
		float: left;
		width: 24%;
		padding-left: 30px;
		box-sizing: border-box;

		>li {
			position: relative;
			margin-bottom: 40px;

			h5 {
				color: #000;
				border: 4px solid #0470e4;
				max-width: 277px;
				border-radius: 100px 60px 60px 100px;
				border-left: none;
				height: 67px;
				box-sizing: border-box;
				text-align: center;
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-end;

				>span.num {
					position: absolute;
					left: 0;
					color: #fff;
					height: 67px;
					width: 67px;
					line-height: 67px;
					text-align: center;
					display: inline-block;
					z-index: 1;

					&:before {
						position: absolute;
						content: '';
						left: 0;
						z-index: -1;
						top: 0;
						width: 80px;
						height: 67px;
						background: url('../../resources/images/cont/Infographics-img1.png') center center no-repeat;
					}

					&:after {
						z-index: -2;
						width: 30px;
						height: 4px;
						content: '';
						background-color: #fff;
						position: absolute;
						left: 30px;
						top: 0;
					}
				}

				>span.tit {
					display: inline-block;
					font-size: 20px;
					font-family: 'GongGothicM';
					font-weight: normal;
					width: calc(100% - 67px)
				}
			}
		}

		&__in {
			padding-top: 20px;
			padding-left: 12px;

			li {
				font-size: 18px;
				display: flex;
				color: #242424;

				span.Infographics__span1 {
					width: 120px;
				}

				span.Infographics__span2 {
					width: calc(100% - 120px)
				}
			}
		}
	}


	@media screen and (min-width: 1px) and (max-width:1200px) {
		&__ul>li h5>span.tit {
			font-size: 18px;
		}
	}

	@media screen and (min-width: 1px) and (max-width:1100px) {
		.popupzone {
			float: none;
			width: 100%;
			margin-bottom: 20px;
		}
		&__ul {
			float: none;
			width: 100%;
			padding-left: 0;
			display: flex;
			justify-content: space-between;
			gap: 10px;
		}
		&__ul__in li span.Infographics__span1 {
			width: auto;
		}
		&__ul>li h5>span.tit {
			font-size: 18px;
		}
	}

	@media screen and (min-width: 1px) and (max-width:768px) {
		&__ul {
			display: block;
		}

		&__ul>li h5 {
			max-width: 100%;
		}

		&__ul>li h5>span.tit {
			font-size: 20px;
		}

		>h4 {
			font-size: 24px;
		}

		&__ul__in li span.Infographics__span1 {
			width: 130px
		}

	}
}

/*시설예약 - 대관시설예약_상세페이지*/
.cont-in__wrap2.ver2 {
	padding-bottom: 100px;

	&:after {
		content: '';
		position: absolute;
		height: 380px;
		width: 100%;
		z-index: -1;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		left: 50%;
		bottom: 0;
		-webkit-box-shadow: 0px 7px 21px 1px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0px 7px 21px 1px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 7px 21px 1px rgba(0, 0, 0, 0.1);
	}

	.cont-type1 {
		&__right-box {
			padding-top: 120px;
			> h4 {
				margin-top: 0;
				font-size: 48px
			}

			.accordian__ul__wrap {
				margin-top: 100px;
			}
		}
	}

}

.reservation-detail{width:100%;max-width:1580px; display:flex; gap:60px; margin:0 auto; 
	padding-top:90px;border-bottom:1px solid #e0e0e0;margin-bottom:50px; padding-bottom:100px;
	.viewOption {position:sticky; top:0;}
	h3.h3Type1 { font-size:30px; margin-top:50px;
		&:first-child {margin-top:0;}
	}
	> li:first-child {width:calc(100% - 640px)}
	> li:last-child {width:580px;}
	.basicTable1 table {min-width:auto;}
	.reservation-calendar {
		&__top {position:relative; 
			.left{ text-indent: -9999px; font-size:0;position:absolute; right:42px; top:10px;
				&:before {@include sprite($calender-prev); display:block;}
			}
			.right{ text-indent: -9999px; font-size:0;position:absolute; right:0; top:10px;
				&:before {@include sprite($calender-next); display:block;}
			}
		}

		&__date{color:#1a1b1e; font-size:36px; font-weight:bold; font-family: 'GongGothicM';}
		table {width:100%; text-align: center; margin-top:30px; margin-bottom:30px;
			th,td {padding-top:16px; padding-bottom:16px;}
			th {color:#525659; font-size:18px; font-family: 'GongGothicM'; font-weight:300;}
			td {font-size:20px; color:#555555;}
			td.gray {color:#aaaaaa;}
			td.on {background-color:#0050a0; color:#fff;}
		}	
		&__btn {height:84px; line-height:84px; color:#fff; font-size:24px; background-color:#0050a0; text-align: center; display: inline-block; width:100%;}
		&__time {display:flex; flex-wrap: wrap; gap:10px; margin-bottom:50px;
			li {width:calc(50% - 5px);
				&.on a {background-color:#0050a0; color:#fff;}
				a.selected {background-color:#0050a0; color:#fff;}
			}
			li a{padding-top:10px; padding-bottom:10px; width:100%;display:inline-block; border-radius:50px; box-sizing: border-box; border:1px solid #cccccc; text-align: center;  font-size:16px;}
		}
	}
	@media screen and (min-width: 1px) and (max-width:1200px) {
		display:block;
		> li:first-child {width:100%;}
		> li:last-child {width:100%;}
	}
	.reservation__info {border:1px solid #e0e0e0; padding:25px 28px; font-size:16px; 
		h4 {font-size:24px; font-weight:800; margin-bottom:15px;}
		.phone {position:relative; padding-left:26px;
			&:before {@include sprite($phone-icon); position:absolute; left:3px; top:2px;}

		}
		.email {position:relative;padding-left:26px;
			&:before {@include sprite($mail-icon); position:absolute; left:0; top:8px;}
		}
	}
}


@media screen and (min-width:1px) and (max-width:1300px) {
	.cont-in__wrap2.ver2 .cont-type1__right-box {padding-top:50px;}
	.cont-in__wrap2.ver2 .cont-type1__right-box > h4 {font-size:26px;}
	.cont-in__wrap2.ver2 .cont-type1__right-box .accordian__ul__wrap {margin-top:30px;}
	.cont-in__wrap2.ver2 {padding-bottom:30px;}
	.reservation-detail {padding-top:30px;}
	.reservation-detail h3.h3Type1 {font-size:20px;}
	.reservation-detail .reservation__info {padding:20px}
	.reservation-detail .reservation__info h4 {font-size:18px;}
}

/*03_시설예약 _02_대관시설예약_예약현황*/
.reservation__top {margin-bottom:30px;
	&__select-box {display:flex;}
	&__select {margin-right:10px;
		select {
			height: 50px;
			border: 1px solid #111;
			float: left;
			padding-left: 15px;
			color: #111;
			font-size: 16px;
			background: url(../../resources/images/icons/select-bul.png) no-repeat 89% center;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			min-width:200px;
			padding-right: 50px;
		}
	}
	&__p{font-size:16px;color:#ee060b; text-align: right; margin-top:-30px;} 

	@media screen and (min-width:1px) and (max-width:1300px) {
		&__p{margin-top:0;} 

	}

	@media screen and (min-width:1px) and (max-width:768px) {
		&__select-box {
			display:inline-block;
		}
		&__select {display:inline-block; margin-bottom:10px;}
		&__p {display:block; text-align: left;}

	}
}


/*대관시설 예약 - 개요*/
.gayo {display:flex; margin-top:30px; padding-top:30px;margin-bottom:70px;
	background:url('../../resources/images/cont/gayo-step-img.png') top left no-repeat;
	li {padding-right:20px; flex:1;}
	&__step {color:#fff; background-color:#000;font-size:15px; width:72px; height:32px; line-height: 32px;text-align: center;}
	h4 {font-size:22px; font-weight:bold; margin-top:10px; margin-bottom:10px; }
	p {font-size:16px; color:#555555;}

	@media screen and (min-width:1px) and (max-width:768px) {
		flex-wrap: wrap;
		li {flex:auto; margin-bottom:20px;}
	}
}

/*대관시설예약 시설현황*/
.facility-status  {
	.sub-tab2 {display:flex;gap:10px; margin-bottom:30px;
		&__ul {width:calc(100% - 580px); display:flex; gap:10px; flex-wrap: wrap; border:none; align-content:flex-start; margin-bottom:0;}
		&__ul__li {
			a {padding-top:0;padding-bottom:0; border:0;
				img {max-height:136px;}

			}
			&.on {border:4px solid #0050a0; box-sizing: border-box;}
		}
		&__cont {max-width:570px;}
	}
	.convenient-facilities {display:flex; gap:30px;}

	@media screen and (min-width:1px) and (max-width:1580px) {
		.sub-tab2__ul__li {width:calc(33.33% - 7px);}
	}
	
	@media screen and (min-width:1px) and (max-width:1000px) {
		.sub-tab2 {display:block;
			&__cont {text-align:center; width:100%; margin-bottom:20px}
			&__ul__li {    width: calc(25% - 8px) !important;}
			&__ul {width:100%;}
		}
	}
}

/*위로가기 버튼*/
.top-move {position:fixed; font-size:0; right:90px; bottom:100px;z-index:1;
		&:before {@include sprite($top-move); }
		
	@media screen and (min-width:1px) and (max-width:768px) {
		right:20px;
	}

}


/*통합검색*/
.input_box {
    .header__Search__tap__list {display:none;}
    &.focus .header__Search__tap__list{display:block;
        position:absolute; width:100%;background-color:#fff;
       li {border-bottom:1px solid #ddd; box-sizing: border-box;}
        li a {position:relative; display:block; width:100%; padding:8px 20px 8px 40px; box-sizing: border-box; font-size:16px;
            &:hover, &:focus {background-color:rgb(250, 250, 250);}
            &:before {position:absolute;left:15px; top:50%; margin-top:-9px;
                @include sprite($search_icon);}
            &:after {position:absolute; right:15px;top:50%; margin-top:-9px;
                @include sprite($arrow_icon);}
        }

    }

}

/*230105수정사항*/
@media screen and (min-width: 1px) and (max-width: 480px) {
.bi-info__btn-box li > a {width:100%; padding-left:0;padding-right:0;}
.selectboxN1 {width:100%;box-sizing: border-box;}
.show-list__top__search__inputbox {width:100%;margin-left:0 !important;}
.show-list__top__search__inputbox input {width:100%; box-sizing: border-box;}
.basic-board-type2__table-top .search-box__table-search button {width:40px !important; height:40px !important; }
.show-list__top {padding:0;}
.selectboxN1 {margin-top:8px;}
.selectboxN1:first-child {margin-top:0;}
.show-list__top__search__inputbox a {border-top:1px solid #ddd; border-bottom:1px solid #ddd;}
.ver2 .show-list__top__info {font-size:15px; margin-bottom:0;}
.pagination {margin-top:5px;}
.pagination a {width:12px !important;vertical-align: middle;}
.cont-type2__left-box .btn6,.cont-type2__left-box .btn4 {width:100%; line-height:unset; text-align:left; box-sizing: border-box;padding:13px 20px 13px 20px;}
.cont-type2__left-box, .cont-type2__right-box {padding-right:0;}
.accordian__tit {padding:20px 76px 20px 15px;}
.accordian__ul li span {font-size:15px;}
.depth3-wrap.menu-num-4>ul>li {width:100%;}
.bbs-st1__top__type a:first-child {width:100%; }
.bbs-st1__top__ul li>span, .bbs-st1__top__ul li>label {flex-basis:70px;}
.bbs-st1__top__type,.bbs-st1__top__ul li>form {width:calc(100% - 70px)}

}