/*sub-page-container*/
.sub-page-container {
	padding-bottom: 60px;
	font-family: $font2;
	background-image: url('../../resources/images/cont/sub-container-bg.jpg');
	background-repeat: repeat-x;

	@media screen and (min-width:1px) and (max-width:$siteSize) {
		padding-top: 0;
		padding-bottom: 0;
		background-image: none;
	}
}

.sub-page-container__in {
	// width: $siteSize;
	margin: 0 auto;
	position: relative;
	// background-color: #fff;
	box-sizing: border-box;
	@include floatWrap;
	// background-image: url('../../resources/images/basic/sidebar-left-bg.png');
	background-position: 0 0;
	background-repeat: repeat-y;

	@media screen and (min-width:1px) and (max-width:$siteSize) {
		background-image: none;
	}
}

.sub-contents {
	border-top: 1px solid #ddd;
}

.cont-in {
	max-width:1580px; width:100%; margin:0 auto;
	padding-bottom: 140px;
	min-height: 500px;
	@include fontSize(18);
	line-height: 1.6;
	color: #000;
	&__wrap {border-top:1px solid #ddd; padding-top:60px;}
	@media screen and (min-width:1px) and (max-width:$siteSize) {
		width: auto;
	}

	&.ver2 {
		width: 100%;
		max-width:100% !important;
		padding-bottom: 0px;
	}
}
.width__1200 {margin:0 auto; width:100%; max-width:1200px;}

.cont-in img {
	max-width: 100%;
	height:auto !important;
}

@media screen and (min-width:1px) and (max-width:$siteSize) {

	.sub-page-container__in {
		width: auto;
	}

	.sub-contents {
		width: auto;
		float: none;
		padding: 10px;
		border-top: 1px solid #ddd;
	}

	.cont-in img {
		height: auto !important;
	}
}

@media screen and (min-width:1px) and (max-width:768px) {
	.sub-page-container {
		padding-top: 51px;
	}
}

.sub-contents__top {
	position: relative;
	//border-bottom: 1px solid #e0e0e0;
	font-family: 'Noto Sans KR';
	/*background-image:url("../../commons/images/global/sub-visual-bg1.jpg");*/
	background-position: right top;
	background-repeat: no-repeat;

	//margin-bottom: 40px;
	@media screen and (min-width:1px) and (max-width:$siteSize) {
		margin-bottom: 40px;
	}
}

.sub-contents__top h2.sub-tit {
	font-size: 40px;
	color: #222;
	font-family: 'GongGothicM';
	padding-top: 70px;
	padding-bottom: 50px;
	text-align: center;
	height: 40px;
	font-weight: normal;

	@media screen and (min-width:1px) and (max-width:$siteSize) {
		text-indent: 0;
		border-bottom: 0;
	}
}

/* location */
.location-box {
	position: relative;
	display: none;
}

.location-box__cont {
	position: absolute;
	right: 30px;
	text-align: right;
	top: 10px;
	width: 100%;

	span {
		display: inline-block;
		font-size: 14px;
		font-size: 1.4rem;
		height: 28px;
		line-height: 28px;
		color: #000;

		&:before {
			content: '';
			display: inline-block;
			font-size: 0;
			@include sprite($location-icon);
			margin: 0 17px;

			@media screen and (min-width:1px) and (max-width:480px) {
				& {
					margin: 0 10px;
				}
			}
		}

		&:first-child::before {
			display: none;
		}
	}
}

.location-box__cont .loc-home {
	display: block;
	padding-left: 30px;
	font-size: 14px;
	font-size: 1.4rem;
	height: 28px;
	line-height: 27px;
	background-image: url("../../resources/images/icons/home.png");
	background-position: 0 center;
	background-repeat: no-repeat;
	color: #666;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

/* 프린트, url 버튼 등*/


/*메인 퀵박스 공유, 프린트 버튼 등*/
.sub-page-container .quick-box .quick-box__share,
.sub-page-container .quick-box .quick-box__print,
.sub-page-container .quick-box .quick-box__share__wrap.on {
}

.sub-page-container .quick-box .quick-box__share__wrap.on .quick-box__share {
	border: none;
}

.quick-box {
	z-index: 9;
	position: absolute;
	top: 126px;
	right: 50%;
	transform: translateX(50%);
	font-size: 0;
	text-indent: -9999px;
}

.quick-box a {
	float: left;
}

.quick-box__share {
	vertical-align: top;
	@include sprite($special-img2);
}

.quick-box__share__wrap {
	position: relative;
	float: left;
	transition: all 0.3s ease 0s;
}

.quick-box__share__wrap .quick-box__in {display:none;}
.quick-box__share__wrap.on .quick-box__in {
	opacity: 1;
	border: 1px solid #cccccc;
	display:block;
	background-color: #fff;
}

.quick-box__share__wrap.on .quick-box__share {}

.quick-box__share__wrap.on .quick-box__share:before {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	content: '';
	@include sprite($special-img1);
}

.quick-box__print {
	@include sprite($special-img3);
	display: inline-block;
	margin-left: 10px;
	position: relative;
	vertical-align: top;
}


.quick-box__in {
	opacity: 0;
	position: absolute;
	padding: 10px 17px;
	left: 50%;
	transform: translateX(-50%);
	top: 50px;
}

.quick-box__in a {
	position: relative;
	margin-bottom: 5px;
}

.quick-box__in a:last-child {
	position: relative;
	margin-bottom: 0
}

.quick-box__sns1 {
	@include sprite($special-img4);
}

.quick-box__sns2 {
	@include sprite($special-img5);
}


.quick-box__sns3 {
	width: 22px;
	position: relative;
	vertical-align: top;
}

.quick-box__sns3:before {
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
	content: '';
	vertical-align: middle;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: -221px -539px;
	background-image: url("../images/sprite.png");
	width: 22px;
	min-width: 22px;
	height: 20px;
}

.quick-box__url {
	@include sprite($special-img6);
}


.quick-box a:hover .quick-box__sns-go {
	display: block !important;
	padding: 0 10px;
	font-size: 12px;
	position: absolute;
	top: -31px;
	height: 22px;
	line-height: 22px;
	text-indent: 0;

	left: 58%;
	margin-left: 0;
	transform: translateX(-50%);
	border-radius: 20px;
	color: #333333;
}

.quick-box a:hover .quick-box__sns-go::before {
	content: '';
	content: '';
	vertical-align: middle;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: -187px -683px;
	background-image: url("../images/sprite.png");
	width: 10px;
	min-width: 10px;
	height: 8px;
	position: absolute;
	top: 22px;
	left: 50%;
	transform: translateX(-50%);
}

.quick-box a:hover .quick-box__print-go {
	display: block !important;
	padding: 0 10px;
	font-size: 12px;
	position: absolute;
	top: -31px;
	height: 22px;
	line-height: 22px;
	text-indent: 0;
	background-color: rgba(244, 148, 28, 0.8);
	left: 50%;
	margin-left: 0;
	transform: translateX(-50%);
	border-radius: 20px;
	color: #fff;
}

.quick-box a:hover .quick-box__print-go::before {
	content: '';
	@include sprite($special-img3);
	position: absolute;
	top: 22px;
	left: 50%;
	transform: translateX(-50%);
}

.sub-page-container .quick-box a:hover .quick-box__sns-go {
	background-color: #fff !important;
	border: 1px solid #cccccc;
}

.sub-page-container .quick-box a:hover .quick-box__sns-go::before {
	content: '';
	vertical-align: middle;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: -702px -68px;
	background-image: url("../images/sprite.png");
	width: 8px;
	min-width: 8px;
	height: 9px;
}

@media screen and (min-width: 1px) and (max-width: 1200px) {
	.quick-box {
		top: 112px;
		right: 270px;
	}

	.sub-page-container .quick-box {
		top: 40px;
		right: 0;
	}
}

@media screen and (min-width: 1px) and (max-width: 768px) {
	.quick-box {
		top: 161px;
		right: 140px;
	}

	.quick-box__print {
		display: none;
	}

	.sub-page-container .quick-box {
		top: 30px;
		right: 0;
	}
}

@media screen and (min-width: 1px) and (max-width: 620px) {
	.quick-box {
		display: none;
	}
}

.special-box {
	position: absolute;
	top: 50px;
	right: 5px;
	z-index: 9;

	a {
		border: 1px solid #fff;

		&:focus {
			border: 2px solid gold;
		}
	}

	&__print {
		@include sprite($icon-print);
		margin-right: 4px;
		text-indent: -9999px;
	}

	&__url {
		@include sprite($icon-url);
		text-indent: -9999px;
		display: none !important;
	}

	&__sns {
		@include sprite($icon-url);
		text-indent: -9999px;
		display: none !important;
	}

	.special-openbox {

		&__cont {
			display: none;
			border: 1px solid #ddd;
			white-space: nowrap;
			padding: 10px;
			background-color: #fff;

			a {
				vertical-align: middle;
				display: inline-block;

				span {
					@include hiddenWord;
				}

				&.type1 {
					@include sprite($newsns-f);
				}

				&.type2 {
					@include sprite($newsns-tw);
				}

				&.type3 {
					@include sprite($newsns-ks);
				}

				&.type4 {
					@include sprite($newsns-kt);
				}

				&.type5 {
					@include sprite($newsns-b);
				}

				&:hover,
				&:focus {

					&.type1 {
						@include sprite($newsns-f-on);
					}

					&.type2 {
						@include sprite($newsns-tw-on);
					}

					&.type3 {
						@include sprite($newsns-ks-on);
					}

					&.type4 {
						@include sprite($newsns-kt-on);
					}

					&.type5 {
						@include sprite($newsns-b-on);
					}
				}


			}
		}

		&.on {
			.special-openbox__btn {
				border: 1px solid #000;

				&:before {
					@include sprite($sns-on);
				}
			}

			.special-openbox__cont {
				display: block;
				position: absolute;
				top: 65px;
				right: 0;
			}

		}
	}
}

@media screen and (min-width:769px) and (max-width:$siteSize) {
	.location-box__cont {
		position: relative;
		top: 3px;
		width: auto;
		text-align: right;
	}

	.sub-contents__top h2.sub-tit {
		padding-top: 40px;
	}

	.special-box {
		top: 38px;
	}

}

@media screen and (min-width:1px) and (max-width:768px) {
	.sub-contents__top {
		background-image: none !important;
		margin-bottom: 10px;
		display: block;
	}

	.sub-contents__top h2.sub-tit {
		font-size: 21px;
		margin-bottom: 8px;
		padding-top: 40px;
		height: auto;
	}

	.location-box {
		position: relative;
		top: 0;
		right: 0;
		padding-bottom: 0px;
	}

	.location-box__cont {
		position: relative;
		white-space: normal;
		width: auto;
		text-align: left;
		overflow: visible;
		top: 0;
		left: 0;
		padding-top: 0px;
	}

	.special-box {
		display: none;
	}
}



/* checkbox */

input[class="check-type"] + label {
	display: inline-block;
	color:#999999;
	height: 44px;
	line-height: 44px;
	cursor: pointer;
	position: relative;
	padding-right:20px;
	font-size:16px;
	padding-left:45px;
	span {z-index:10}
	&:after {
		display: inline-block;
		content: ' ';
		width: 100%;
		height: 44px;
		box-sizing: border-box;
		position: absolute;
		top:0px;left:0px;
		border: 1px solid #cccccc;
		//background-color:#dddddd;
		border-radius: 30px;
	}

	&:before {
		content:' ';
		@include sprite($check-type-off);
		position: absolute;
		top:50%; margin-top:-8px;left:20px;
		z-index: 9;
	}
}

input[class="check-type"]:checked + label {
	color:#0050a0;
	&:after {
		z-index:0;
		border: 1px solid #0050a0;
	}
	&:before {
		content:' ';
		@include sprite($check-type);
	}
}

input[class="check-type"]:focus + label {
	&:after {
	}
}
input[class="check-type"] {
	position: absolute;
	left: -9999px;
}
/*

input[class="check-type"] + label {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 2px solid #cccccc;
	background-color:#dddddd;
	cursor: pointer;
	border-radius: 30px;
	position: relative;
	font-size: 0;
	text-indent: -9999px;
	&:before {
		content:' ';
		@include sprite($check-type);
		position: absolute;
		top:10px;left:10px;
	}
}

input[class="check-type"]:checked + label {
	background-color: #666666;
}

input[class="check-type"]:focus + label {
	border: 2px solid gold;
}
input[class="check-type"] {
	position: absolute;
	left: -9999px;
}
*/

/* 2 */
input[class="check-type2"] + label {
	display: inline-block;
	height: 18px;
	line-height: 18px;
	position: relative;
	padding-left:27px;

	&:after {
		display: inline-block;
		content: ' ';
		width: 18px;
		height: 18px;
		cursor: pointer;
		border:1px solid #ddd;
		position: absolute;
		top:0px;left:0px;
	}
}

input[class="check-type2"]:checked + label {
	&:before {
		content:' ';
		@include sprite($check-type2-on);
		position: absolute;
		top:4px;left:4px;
	}
}

input[class="check-type2"]:focus + label {
	&:after{
		border:1px solid #bbbbbb;
	}

}
input[class="check-type2"] {
	position: absolute;
	left: -9999px;
}



/* 3 */
input[class="check-type3"] + label {
	display: inline-block;
	height: 18px;
	line-height: 18px;
	cursor: pointer;
	position: relative;
	padding-left:27px;

	&:after {
		display: inline-block;
		content: ' ';
		width: 18px;
		height: 18px;
		cursor: pointer;
		border:1px solid #ddd;
		position: absolute;
		top:0px;left:0px;
	}
}

input[class="check-type3"]:checked + label {
	&:before {
		content:' ';
		@include sprite($check-type3-on);
		position: absolute;
		top:4px;left:4px;
	}
}

input[class="check-type3"]:focus + label {
	&:after{
		border:1px solid #ec6159;
	}
}
input[class="check-type3"] {
	position: absolute;
	left: -9999px;
}



/*라디오버튼*/
input[class="radio-type"] + label {
	display: inline-block;
	height: 17px;
	line-height: 17px;
	border: 0;
	cursor: pointer;
	position: relative;
	padding-left:27px;

	&:before {
		content:' ';
		@include sprite($radio-type-off);
		position: absolute;
		top:6px;left:5px;
	}

}

input[class="radio-type"]:checked + label {
	&:before {
		@include sprite($radio-type-on);
	}
}

input[class="radio-type"]:focus + label {}
input[class="radio-type"] {
	position: absolute;
	left: -9999px;
}



.choose-label-text {
	margin-left:0;
	color:#7d7d7d;
	font-weight:bold;
	font-size:16px;
	padding:5px;
}
.choose-textarea {
	display: block;
	box-sizing: border-box;
	border:1px solid #d5d9dd;
	background-color: #e9ecf0;
	padding:20px;
	height: 100px;
	font-size:16px;
	margin-top:10px;
	width:100%;
	color:#768393;
	&.long {height:150px;}
}

// 동영상 게시물 상세에서 아이프레임 있을경우
.editor_view.on {
	& .editor_view__cont {
		position: relative;
		width: 100%;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		/*16:9*/
		background-color: #00235d;
		color: #fff;

		>p {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			iframe {
				width: 100%;
				height: 100%
			}
		}
	}
}

//test

.sub-slide {
	position: relative;
	margin-bottom: 10px;

	&__box {
		max-width: 100%;
		margin: 0 auto;

		img {
			width: 100%
		}
	}

	&__nav {
		position: absolute;

		width: 50px;
		display: block;
		top: 50%;
		margin-top: -35px;
		height: 70px;
		background-color: #000;
		opacity: 0.8;
		text-indent: -9999px;

		&:after {
			width: 20px;
			height: 27px;
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 20px;
			color: #fff;
			text-indent: 0;
			font-size: 22px;
			font-family: $font1;
		}

		&:hover,
		&:focus {
			&:after {
				color: gold
			}
		}

	}

	&__prev {
		left: 0;

		&:after {
			content: '<';
		}
	}

	&__next {
		right: 0;

		&:after {
			content: '>';
		}
	}
}

.sub-slide-cro {
	margin-bottom: 40px;
	margin-left: -10px;
	overflow: hidden;

	img {
		height: 100px;
		width: 100%;
		padding-left: 10px;
		box-sizing: border-box;
		opacity: 0.4;
		transition: all 0.3s ease 0s;

		@media screen and (min-width:1px) and (max-width:$siteSize) {
			height: 100px !important;
		}

		@media screen and (min-width:1px) and (max-width:768px) {
			height: 55px !important;
		}
	}

	.slick-current {
		img {
			opacity: 1;
		}
	}

	a:focus {
		img {
			opacity: 1;
		}
	}
}


//서브페이지 팝업레이어

.layer-box {
	height: 0;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
}

.layer-box.on {
	height: 100%;
	visibility: visible;
	opacity: 1;
}

.layer-box {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	height: 100%;
	background-image: url('../../resources/images/basic/opa2.png');
	background-repeat: repeat;
	color: #fff;
}

.photo-slide {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	width: 100%;

	.slick-prev {
		color: #fff;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 10%;
		z-index: 999;
		font-size: 0;

		&.slick-disabled {
			display: none !important;
		}

		&:before {
			font-size: 40px;
			content: '<';
			display: block;
			padding: 20px;
		}

		&:hover,
		&:focus {
			&:before {
				color: gold
			}
		}

		@media screen and (min-width:1px) and (max-width:768px) {
			left: 2%;
		}
	}

	.slick-next {
		color: #fff;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 10%;
		z-index: 999;
		font-size: 0;

		&.slick-disabled {
			display: none !important;
		}

		&:before {
			font-size: 40px;
			content: '>';
			display: block;
			padding: 20px;
		}

		&:hover,
		&:focus {
			&:before {
				color: gold
			}
		}

		@media screen and (min-width:1px) and (max-width:768px) {
			right: 2%;
		}
	}
}

.photo-slide__item {
	width: 100%;
	text-align: center;

	img {
		margin: 0 auto;
	}
}

.layer-box__close {
	font-size: 30px;
	font-weight: bold;
	display: block;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	background-color: #ddd;
	border-radius: 40px;
	color: #000;
	position: absolute;
	top: 30px;
	right: 30px;
}

.root_daum_roughmap {
	width: 100% !important;
}

/* 다음맵 접근성 추가 .warp_map으로 감쌈 */
.wrap_map a:focus,
.wrap_map button:focus,
.roughmap_maker_label a:focus .roughmap_lebel_text,
.root_daum_roughmap .wrap_btn_zoom button:focus {
	border: 2px solid gold;
}

.wrap_controllers.hide {
	display: none;
}

// 슬라이더
.slider-for {
	width: 1200px !important;
	margin: 0 auto;

	a {
		display: inline-block;
	}

	a:focus {
		border: 1px solid black
	}

	&__item {
		background-color: cadetblue;
		min-height: 200px;
	}
}

.slider-nav-outwrap {
	width: 1278px;
	overflow: hidden;
	margin: 0 auto;
}

.slider-nav {
	width: 1200px !important;
	margin: 0 auto;

	a {
		display: inline-block;
		text-align: center;
	}

	a:focus {
		background-color: #444
	}

	.slick-current {
		background-color: black;
		color: #fff;
	}
}


/*3뎁스*/
.depth3 {
	&__ul {
		display: flex;
		margin: 0 auto;
		&.ver2 {margin-bottom:160px;}
		> li {position:relative;
			flex: 1;
			text-align: center;
			box-sizing: border-box;
			&:last-child {
				border-right: none;margin-bottom:90px;
			}
			&.on > a{
				background-color: #1a1b1e;
				color: #fff;
			}
			> a {display:flex; width:100%; height:100%; align-items: center;
				height: 58px;
				justify-content: space-evenly;
				border: 1px solid #dddddd;
				background-color: #fff;
			}
		}
	}
	
	&-wrap {
		&.menu-num-1{
			> ul {max-width:400px; width:100%; }
		}
		&.menu-num-2{
			> ul {max-width:500px; width:100%;}
			
		}
		&.menu-num-3{
			> ul {max-width:600px; width:100%;}
			
		}
		&.menu-num-4{
			> ul {max-width:700px; width:100%;}
			
		}
		&.menu-num-5{
			> ul {max-width:800px; width:100%;}
		}
		
		@media screen and (min-width:1px) and (max-width:768px) {

			&.menu-num-4{
				> ul {display:flex;flex-wrap: wrap;}
				
				> ul > li {flex:auto;  border-right:0; border-left:0; width:50%;position:relative;}
				> ul > li:first-child, &.menu-num-4 > ul > li:nth-child(2) {border-top:0; }
				> ul > li:nth-child(2n) {}
			}

		}

		
		@media screen and (min-width:1px) and (max-width:500px) {
			> ul {flex-direction: column;
				> li {width:100%; 
					&:last-child {margin-bottom:30px;}
					> a {font-size:16px;height:48px;}
				}
			}

		}
	}	
	
	
	@media screen and (min-width:1px) and (max-width:1200px) {

		&__ul.ver2 {margin-bottom:20px;}
	}
}

/*4뎁스*/
.depth4__wrap {margin:0 auto; max-width:700px; margin:0 auto;position:relative;

	@media screen and (min-width:1px) and (max-width:1200px) {
		text-align: center;
		//position:relative; transform:none; margin-left:0;display:inline-block;text-align: center;
		//&:before {    transform: none;
		//	margin-left: -12px;}
		//> li {width:100%;padding:5px;}
	}


}
.depth4__ul {
	position: absolute;
	-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
	margin-left: 50%;
	margin-top: 35px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	&:before {@include sprite($depth4-img); position:absolute;transform:translateX(-50%); left:50%; top:-24px; }
	> li {  font-size: 18px;
		float: left;
		padding: 10px;
		-webkit-box-sizing: border-box;
				box-sizing: border-box;
		a {color:#777777; display:inline;}
		&.on a{color:#0050a0; border-bottom:1px solid #0050a0;}
		
	}

	@media screen and (min-width:1px) and (max-width:1200px) {
		display: inline-block;
    text-align: center;
    position: relative;
    margin-left: 50%;

		&:before { -webkit-transform: none;
            transform: none;
			margin-left: -12px;}
		> li {
			width: 100%;
			padding: 0;
			a {font-size:15px; }
		}
	}

}


/*게시판 스타일1*/
.bbs-st1{
	&__top {position:relative;
		border-top:3px solid #111;padding-top:30px; padding-bottom:10px;
		&__ul {
			li {padding-left:50px;position:relative; margin-bottom:30px;clear:both; display:flex; align-items: center;
				> span , > label {flex-basis:100px; float:left; font-size:18px; font-weight:bold;}
			}
		}
		&__type {display: flex; gap: 5px; flex-wrap: wrap; ;width: calc(100% - 100px);
			> a {width:150px; min-height:50px; float:left; text-align: center; color:#555; border:1px solid #e0e0e0;display:flex;
				align-items: center;box-sizing:border-box;
				justify-content: space-around;
				&.on {background-color:#111; color:#fff; border:1px solid #111;}
			}
		}
		&__map {position:absolute; bottom:0; right:0;padding-right:25px; padding-left:25px; box-sizing:border-box; min-width:130px; height:50px; line-height:50px; border-radius: 100px; border:1px solid #111; text-align:center;
			a {width: 100%;
				height: 100%;
				display: inline-block; 
				&:before {
					padding-right:10px;
					margin-top:-2px;
					@include sprite($bbs-map-img);
				}
			}

		}
		&__search__wrap {display:flex; width:100%;}
		&__search {border: 1px solid #e0e0e0;height:50px; padding-left:20px; color:#999;box-sizing:border-box; max-width:450px; width:100%;
			&::placeholder {color:#999;}
			&__btn {background-color:#f8f8f8; font-size: 0;width:50px; height:50px; line-height:50px; border:1px solid #e0e0e0; float:right;
				box-sizing:border-box; border-left:none;text-align:center;
				&:after { 
					@include sprite($bbs-search-img);
				}
			}
		}

	}
	&__bottom {padding-top:50px;border-top:1px solid #e0e0e0;
		> p {font-size:16px; color:#555555;}
		&__ul {
			overflow: hidden;
			padding-top: 20px;
			display:flex;
			flex-wrap: wrap;
			> li {
				float: left;
				width: 23.6%;
				margin-right: 1.647%;
				margin-bottom: 35px;box-sizing: border-box;
				border: 1px solid #e0e0e0;
				padding-bottom: 20px;
				.tit {
					h5 {font-size:16px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;
						&.blue {color:#1179c7;}
						&.red {color:#db4453;}
					}
					h4 {font-size:24px; font-weight:bold;
						overflow:hidden; text-overflow:ellipsis; white-space:nowrap;
					}
				}
				> a {
					display: block; 
				}

				.img-box {position:relative; z-index:1; overflow:hidden; margin-bottom:20px; padding-bottom:69.9%; background-color:#f7f7f7; text-align:center;}
				.img-box .inner_box {position:absolute; left:0; top:0; z-index:1; width:100%; height:100%;}
				.img-box .mark {display:inline-block; position:absolute; left:0; top:0; z-index:1; height:25px; line-height:25px; padding:0 10px; background-color:#f4511e; color:#fff; font-size:14px;}
				.img-box img {width:100%; height:100% !important; -webkit-transition:all 0.3s; -moz-transition:all 0.3s; transition:all 0.3s;}
				 > a:hover img, > a:focus img {-webkit-transform:scale(1.3); -moz-transform:scale(1.3); transform:scale(1.3);}
				.video-bg {display:block; position:absolute; left:0; top:0; z-index:1; width:100%; height:100%; background: rgba(0,0,0,0.2) url('/template/cms/user/base/image/basic/video_bg.png') center center no-repeat;}
				.txt-box {padding:0 25px;}
				.txt-box .sort {margin-bottom:3px; color:#2196f3; font-weight:500;}
				.new_mark, .vi_board_list .new_mark {position:static; width:18px; height:18px; line-height:18px; margin-top:0; margin-right:3px; vertical-align:top;}
				&:nth-child(4n) {
					margin-right:0;
				}
				.basicList3 {font-size:15px; color:#555; margin-top:10px;
					> li:before {top:11px;}
				}
			}
			
		}
		
	}
	@media screen and (min-width:1px) and (max-width:1300px) {
		&__top__ul li {padding-left:20px;flex-wrap: wrap; }
	}
	@media screen and (min-width:1px) and (max-width:1200px) {
		&__bottom__ul > li {width:32%; margin-right:2%; }
		&__bottom__ul > li:nth-child(4n) {margin-right:2%;}
		&__bottom__ul > li:nth-child(3n) {margin-right:0;}
		&__bottom__ul > li .tit h4 {font-size:20px;}
		&__bottom__ul > li .tit h5 {font-size:15px;}
	}
	@media screen and (min-width:1px) and (max-width:768px) {
		&__top__type {gap:0;}
		&__top__type a{width:49%;font-size:16px; margin-left:2%; margin-bottom:2%;}
		&__top__type a:nth-child(2n+1) {margin-left:0;}
		&__top__search {    width: calc(100% - 50px);
			&::placeholder {font-size:16px;}
		}
		&__bottom__ul > li .txt-box {padding:0 15px;}
		&__bottom__ul > li {width:49%; margin-right:1%; }
		&__bottom__ul > li:nth-child(4n) {margin-right:1%;}
		&__bottom__ul > li:nth-child(3n) {margin-right:1%;}
		&__bottom__ul > li:nth-child(2n) {margin-right:0;}
		&__bottom__ul > li .tit h4 {font-size:20px;}
		&__bottom__ul > li .tit h5 {font-size:15px;}
	}
	@media screen and (min-width:1px) and (max-width:700px) {
		&__top__search__wrap {width: calc(100% - 100px); }
		&__top__ul li {padding-left:10px;
			> form {width: calc(100% - 100px);
				.bbs-st1__top__search__wrap {width:100%}
			}
		}
		&__top__map {position:relative;display:block; flex:auto; margin-left:0; width:100%; margin-top:10px;}
	}
}





/*게시판 스타일2*/
.bbs-st2{
	&__top {position:relative;
		border-top:3px solid #111;padding-top:50px; padding-bottom:10px;
		&__ul {
			&__li {position:relative; margin-bottom:40px;clear:both;  display:inline-block;padding-left:50px;padding-right:50px;
				 select {height:50px;border:1px solid #e0e0e0; float:left;padding-left:15px; color:#999999; font-size:16px;
					background:url('../../resources/images/icons/select-bul.png') no-repeat 89% center;
					-webkit-appearance:none; /* for chrom */
					-moz-appearance:none; /* for firefox */
					appearance:none;
				 }
				 select::-ms-expand{
					display:none;/*for IE10,11*/
				 }
				 select.category {width:110px;}
				 select.consortium {width:150px;}
				 select.corporation{width:110px; margin-right:5px;}
				 &__in {float:left; display:flex; align-items: center;

					label {padding-right:15px;float:left; font-size:18px; font-weight:bold;padding-left:50px;}
					&:first-of-type label {padding-left:0;}
				 }
			}
		}
		&__search__wrap {float:left;}
		&__search {border: 1px solid #e0e0e0;height:50px; padding-left:20px; color:#999;box-sizing:border-box; width:396px;font-size:16px;
			&::placeholder {color:#999;}
			&__btn {background-color:#f8f8f8; font-size: 0;width:50px; height:50px; line-height:50px; border:1px solid #e0e0e0; float:right;
				box-sizing:border-box; border-left:none;text-align:center;
				&:after { 
					@include sprite($bbs-search-img);
				}
			}
		}

	}


	&__bottom {padding-top:50px;border-top:1px solid #e0e0e0;}
	@media screen and (min-width:1px) and (max-width:1240px) {
		&__top__ul__li {display:flex; flex-wrap:wrap;
			&__in {width:50%;}
			&__in:last-child {width:100%; margin-top:10px;
				label {padding-left:0;}
				.bbs-st2__top__search__wrap {width:100%;}
				.bbs-st2__top__search {width:calc(100% - 50px); }
			}
			select.category, select.consortium {width:100%;}
		}


	}

	@media screen and (min-width:1px) and (max-width:768px) {
		&__top__ul__li {padding-left:10px; padding-right:10px;}
		&__top__ul__li__in label {padding-left:10px; font-size:16px;padding-right:10px;}
		&__top__ul__li select {padding-left:10px;background:url('../../resources/images/icons/select-bul.png') no-repeat 91% center;}
		&__top__ul__li__in:last-child .bbs-st2__top__search {padding-left:10px;}
	}

}

.excel-btn {width:140px; height:44px; line-height:44px; border:1px solid #000000; font-size:16px; display:inline-block;padding-left:15px;text-align:left;
	&::before {margin-right:6px; box-sizing: border-box;
		@include sprite($bbs-excel-img);}
}

.bbs-excel {font-size: 0; 
	&::before {
		@include sprite($down-icon3);}
}



.pagination { font-size:16px; margin:0 auto; text-align: center;
	a {height:38px; width:38px; line-height:38px; display:inline-block;
		&.on {color:#fff; background-color:#1a1b1e;}
	}
	.first, .prev, .next, .last {border:1px solid #e0e0e0;box-sizing: border-box;
		&:before {margin-top:-3px;}

	}
	.first {margin-right:-3px;margin-right: -5px;
		&:before { 
			@include sprite($bbs-first);
		}
	}
	.prev {margin-right:10px;
		&:before { 
			@include sprite($bbs-prev);
		}
	}
	.next {margin-left:10px;
		&:before { 
			@include sprite($bbs-next);
		}
	}
	.last {margin-left: -5px;
		&:before { 
			@include sprite($bbs-last);
		}
	}
	
	@media screen and (min-width:1px) and (max-width:768px) {
		a {width: 22px;height:26px; line-height:26px; font-size:15px;}
		.prev {margin-right:0; }
		.next {margin-left:0;}
	}

}