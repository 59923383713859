$loginSize: 500px;
$loginSizeMobile: 100%;

.login-wrap {
	padding-top:100px;
	text-align: center;
	&__desc {
		font-size: 15px;
		color:#666;
		margin-bottom: 30px;
	}
	&__form {
		margin-bottom:20px;
		&__input{
			display:block;
			margin:0 auto;
			margin-bottom:5px;
			width:$loginSize;
			border: 1px solid #000;
			height: 58px;
			line-height: 58px;
			font-size: 16px;
			padding: 0;
			padding-left: 10px;
			box-sizing: border-box;
		}
		&__submit{
			& {
				color:#fff;
				background-color:#000;
				height: 60px;
				line-height: 60px;
				display: inline-block;
				width:$loginSize;
				vertical-align: middle;
				font-size:15px;
				font-weight: 600;
			}
			&:focus {
				background-color: #333;
				color: gold;
			}

		}

	}
	&__form--bottom {
		width:$loginSize;
		margin:0 auto;
		display: flex;
		a {
			flex: 1;
			position:relative;
			&:before {
				position: absolute;
				top:5px;left:0;
				@include sprite($login-bul)
			}
			&:first-child:before {
				display:none !important;
			}
		}
	}
}

@media screen and (min-width:1px) and (max-width:600px) {
	.login-wrap {padding-top:50px;}
	.login-wrap__desc {font-size:14px;}
	.login-wrap__form__input {width:$loginSizeMobile;}
	.login-wrap__form__submit {width:$loginSizeMobile;}
	.login-wrap__form--bottom {width:$loginSizeMobile;}

}




/*회원가입 - 로그인*/
.login{
	&__wrap {
    max-width: 1200px; width:100%;
    border-top: 3px solid #333333;
    margin:0 auto;
    padding: 0 140px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-align: center;
    background: url(../../resources/images/cont/login-bg.jpg) right top no-repeat, url(../../resources/images/cont/login-bg2.jpg) left bottom no-repeat}

  &__btn1 {color:#fff;background-color:#0050a0;min-width:140px;height:54px;display:inline-flex; font-size:16px;justify-content:space-around;    align-items: center;
  }
  &__btn2 {color:#fff;background-color:#26272b;;min-width:140px; height:54px;display:inline-flex; font-size:16px;justify-content:space-around;    align-items: center;
  }
  &__tit span {
    font-size: 50px;
    color: #143553;
    padding-top: 75px;
    display: inline-block;
    font-weight: 500;
  }
  
  &__tit p {
    font-size: 18px;
    color: #333333;
  }
  
  &__in {
    margin: 0 auto;
    display: inline-block;
    padding: 35px 0;
  }
  
  &__in__left {
    float: left;
  }
  
  &__in__left p {
    color: #111111;
    margin-bottom:10px;
  }
  
  &__in__left label.login_id, &__in__left label.login_pw {
    width: 80px;
    display: inline-block;
    text-align: left;
    font-size: 18px;
  }
  
  &__in__left input {
    box-sizing: border-box;
    border: 1px solid #eeeeee;
    height: 58px;
    padding-left: 20px;
  }
  &__in__left input::placeholder {
    color:#999999; font-size:16px;}
  .login__in__right {
    float: left;
    margin-left: 5px;
  }
  &__in__right {float:left;}
  &__in__right a {
    height: 126px;
    line-height: 126px;margin-left:10px;
    color: #fff;
    background: #1a1b1e;
    padding: 0 36px;
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
  }
  
  &__middle{
    padding: 50px 0 70px 0;
    border-top: 1px solid #e0e0e0;
  }
  &__middle p {display:inline-block; margin-right:15px;}

  
 	&__bottom {background-color:#f8f8f8;border-top:1px solid #e0e0e0; border-bottom:1px solid #e0e0e0;padding-top:44px; padding-bottom:44px;
    &__in {max-width:650px; width:100%; margin:0 auto; background:url('../../resources/images/cont/login-img1.png') left center no-repeat; padding-left:100px;
        display:flex;  min-height: 80px; align-items: center;box-sizing:border-box;
        &__text {font-size:18px; color:#111111; font-weight:bold; padding-right:30px;
            span {font-size:16px; color:#555555;}
        }
    }
    .btn4 {background-color:transparent}
  }
  &__step {background-color:#e6e9ee;padding-top:22px; padding-bottom:20px;


	&__ul {max-width:412px; width:100%; display:flex; margin:0 auto;
      li {color:#fff; background-color:#1a1b1e; border-radius:100px; width:44px; height:44px; text-align: center;line-height:44px;position:relative; margin-left:50px;
        &.on {background-color:#Fff; color:#111111; border:1px solid #111111; border-radius: 100px;width:130px; height:44px;
          span {font-weight:bold;position:relative;    width:18px;text-align: left; display: inline-block; margin-right:8px; 
            &:before {content:''; position:absolute; background-color:#cccccc;  width:1px; height:14px; right:0; top:50%; margin-top:-8px;}
          }
        }
        &:before {content:''; width:30px; height:1px; border-top:1px dashed #26272b; position:absolute; left:-40px; top:50%; margin-top:-0.5px;}
        &:first-child {
          margin-left:0;
          &:before {display:none;}

        }
      }
    }
    &1 {

      &__ol li { font-size:15px; line-height:25px;  margin-left:30px;list-style-type:decimal-leading-zero;   }
      &__ol li ol{margin-bottom:10px;margin-left:20px}
      &__ol li ol li { margin:0; list-style-type:lower-alpha; }
      &__ol li ol li ol{margin-bottom:10px;margin-left:20px}
      &__ol li ol li ol li { margin:0; list-style-type:lower-roman;}
            

    }
    &1__bottom__btn {float:right;display:flex; gap:5px;}
    &1__box {border-top:3px solid #333333;background-color:#f8f8f8; height:450px;padding:40px 50px; font-size:16px;overflow:auto; box-sizing: border-box;
      h4 {font-size:24px; font-weight:bold;}
      h5 { margin-top:15px; margin-bottom:15px;}

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #2f3542;
        border-radius: 10px;
        background-clip: padding-box;
      }
      &::-webkit-scrollbar-track {
        background-color: #eeeeee;
      }
      
    }
    &2 {margin-top:50px; border-top:3px solid #333333; text-align: center; 
      background:url('../../resources/images/cont/login-img2.png') center 55px no-repeat;padding-top:320px;

      h4{font-size:24px; font-weight:bold; }
      p {font-size:16px;margin-top:15px; margin-bottom:30px;}
      &__in {margin-top:60px;
        background-color:#f8f8f8; padding-top:30px; padding-bottom:30px;
        font-size:16px;border-top:1px solid #e0e0e0; border-bottom:1px solid #e0e0e0; 
        span {color:#0050a0;}
      }
    }
    
  }


}
.label_section {display:none;}
.privacy_wrap {
        position: relative;
        /* max-width: 1200px; */
        width: 100%;
        margin: 0 auto;
      }

      .privacy_wrap .personal_info {
        margin: 20px 0;
        padding: 15px;
        border: solid 2px #e3e4e5;
        word-break: keep-all;
      }
      .privacy_wrap .label_section {
        margin-bottom: 20px;
      }
      .privacy_wrap .info {
        margin: 10px 0 0 10px;
        color: #ff7817;
        font-weight: 400;
      }
      .privacy_wrap .tit {
        border-top: 2px solid black;
        text-align: center;
        padding: 0.6rem 0;
        background: #f3f3f3;
        color: black;
        font-weight:500;
        font-size: 18px !important;	
      }
      .privacy_wrap .tit:after{width:0;height:0}
      .privacy_wrap .tit::first-letter {  color:#000; font-weight:normal; }
      .privacy_wrap .label_wrap {
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        /* display: flex;
        flex-flow: row wrap;
        justify-content: space-around; */
        position: relative;
        gap: 10px;
      }
      .privacy_wrap .label_box {
        /* width: 16%;
        min-width: 130px; */
        box-sizing: border-box;
        padding: 20px 10px;
        border-radius: 10px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #fff;
        position: relative;
        border: 1px solid #1647aa;
      }

      .privacy_wrap .label_box div[id^='label_layer_'] {
        display: none;
      }
      .privacy_wrap .label_box:hover div[id^='label_layer_'],
      .label_box:focus div[id^='label_layer_'] {
        display: block;
      }
      .privacy_wrap .label_cnt {
        font-size: 14px;
        margin-top: 20px;
        font-weight: 400;
        color: #111;
      }
      .privacy_wrap .label_layer {
        position: absolute;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid #1647aa;
        width: 400px;
        z-index: 1;
        // box-shadow: 0 3px 7px 1px rgb(0 0 0 / 30%);
      }
      .privacy_wrap .label_layer {
        top: -1px;
        left: 110%;
      }

      .privacy_wrap .label_box:nth-child(6n) .label_layer,
      .privacy_wrap .label_box:nth-child(6n-1) .label_layer {
        top: 0;
        right: 110%;
        left: auto;
      }

      .privacy_wrap .label_layer .label_desc_top {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        background-color: #1647aa;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }
      .privacy_wrap .label_layer .label_desc_top p {
        color: #fff;
      }
      .privacy_wrap .label_layer .label_desc_btm {
        width: 100%;
        box-sizing: border-box;
        padding: 15px 20px;
      }
      /* 목차 */
      .privacy_wrap .privacy_list ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      .privacy_wrap .privacy_list ul li {
        border-bottom: 1px solid #ededed;
        padding: 8px 5px;
      }

      .privacy_wrap .privacy_list ul li a img {
        width: 25px;
        display: inline-block;
        margin: 0 5px;
        image-rendering: -webkit-optimize-contrast;
      }
      .privacy_wrap .privacy_list ul li a {
        display: flex;
        align-items: flex-start;
      }
      .privacy_wrap .privacy_detail {
        margin: 30px 0;
      }
      /*상세내용*/
      .privacy_detail {
        word-break: keep-all;
      }

      .privacy_detail > div {
        margin-bottom: 25px;
      }
      .privacy_detail .pvc_tit {
        display: flex;
        align-items: flex-start;
        margin: 0 0 10px 0;
        font-weight: 500;
        font-size: 18px;
      }

      .privacy_detail .pvc_tit img {
        display: inline-block;
        margin: 0 10px 0 0;
        width: 30px;
        image-rendering: -webkit-optimize-contrast;
      }

      .privacy_detail > div ol,
      .privacy_detail > div ul {
        margin: 10px 0 10px 20px;
        text-indent: -20px;
        line-height: 120%;
      }
      .privacy_detail ol strong,
      .privacy_detail ul strong {
        display: block;
        font-weight: 500;
      }
      .privacy_detail div > ol li,
      .privacy_detail div > ul li {
        margin-bottom: 8px;
        line-height: 140%;
      }
      .privacy_detail div > ol > li > ol {
        margin-left: 15px;
      }

      .privacy_detail .download {
        display: inline-block;
        margin: 5px 15px 5px 0;
        border: 1px solid #0a55a5;
        color: #1647aa;
        padding: 5px 15px;
      }
      .privacy_detail figcaption {
        font-size: 13px;
        color: #646464;
      }
      /* .privacy_detail div > ol > li ol,
      .privacy_detail div > ol > li ul {
        margin: 10px 0;
      } */
      @media all and (max-width: 1024px) {
        .privacy_wrap .label_wrap {
          grid-template-columns: repeat(4, 1fr);
        }

        .privacy_wrap .label_box:nth-child(6n) .label_layer,
        .privacy_wrap .label_box:nth-child(6n-1) .label_layer {
          top: 0;
          left: 110%;
        }
        .privacy_wrap .label_box:nth-child(4n) .label_layer,
        .privacy_wrap .label_box:nth-child(4n-1) .label_layer {
          top: 0;
          right: 110%;
          left: auto;
        }
        .privacy_wrap .label_layer {
          width: 300px;
        }
      }
      @media all and (max-width: 600px) {
        .privacy_wrap .label_wrap {
          grid-template-columns: repeat(3, 1fr);
        }
        .privacy_wrap .label_box {
          padding: 20px 15px;
        }
        .privacy_wrap .label_box:nth-child(6n) .label_layer,
        .privacy_wrap .label_box:nth-child(6n-1) .label_layer,
        .privacy_wrap .label_box:nth-child(4n) .label_layer,
        .privacy_wrap .label_box:nth-child(4n-1) .label_layer {
          top: 160px;
          left: 0%;
        }
        .privacy_wrap .label_wrap .label_box .label_layer {
          top: 160px;
          left: 0%;
        }
        .privacy_wrap .label_box:nth-child(3n) .label_layer,
        .privacy_wrap .label_box:nth-child(3n-1) .label_layer {
          top: 160px;
          left: auto;
          right: 0;
        }

        .privacy_wrap .privacy_list ul {
          grid-template-columns: repeat(1, 1fr);
        }
        .privacy_wrap .privacy_list ul li:last-child {
          display: none;
        }
      }
      @media all and (max-width: 360px) {
        .privacy_wrap .label_wrap {
          grid-template-columns: repeat(2, 1fr);
        }
        .privacy_wrap .label_box:nth-child(6n) .label_layer,
        .privacy_wrap .label_box:nth-child(6n-1) .label_layer,
        .privacy_wrap .label_box:nth-child(4n) .label_layer,
        .privacy_wrap .label_box:nth-child(4n-1) .label_layer,
        .privacy_wrap .label_box:nth-child(3n) .label_layer,
        .privacy_wrap .label_box:nth-child(3n-1) .label_layer {
          top: 160px;
          left: 0%;
        }
        .privacy_wrap .label_wrap .label_box .label_layer {
          top: 160px;
          left: 0%;
        }
        .privacy_wrap .label_box:nth-child(2n) .label_layer {
          top: 160px;
          left: auto;
          right: 0;
        }
      }

  @media screen and (min-width: 1px) and (max-width: 768px) {
    .login__wrap {
      padding: 0 20px;
    }
    .login__bottom {padding-left:20px;}
    .login__bottom__in {display:block;}
    
    .login__bottom__in__text {line-height:1.3}
    .login__bottom__in__text span {margin-top:5px; margin-bottom:10px; display:block;}
    .login__step1__box {padding:20px 20px;}
    .login__step1__box h4 {font-size:20px;}
    .login__step1__bottom__btn {width:100%; float:none; margin-top:5px; flex-direction: column;}
    .login__step__ul {justify-content: center;}
    .login__step__ul li {margin-left:10px;}
    .login__step__ul li:before {display:none;}
    .login__step2 h4{font-size:22px;}
  }
  @media screen and (min-width: 1px) and (max-width: 768px) and (min-width: 1px) and (max-width: 550px) {
    .login__wrap .login__tit span {
      padding-top: 30px;
      font-size:44px;
    }
    .login__wrap .login__tit p {
      font-size: 16px;
    }
    .login__wrap .login__in {
      width: 100%;
      padding: 20px 0;
    }
    .login__wrap .login__in__left {
      float: none;
      text-align: left;
      text-align: center;
    }
    .login__wrap .login__in__left input {
      width: 70%;
    }
    .login__wrap .login__in__left p:last-child {
      margin-left: 0;
    }
    .login__wrap .login__in__right {
      float: none;
      margin-left: 0;
    }
    .login__wrap .login__in__right a {
      width: 100%;margin-left:0;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      height: 60px;
      line-height: 60px;
      margin-top: 5px;
    }
    .login__wrap .login__middle {
      padding: 20px 0 20px 0;
    }
    .login__wrap .login__middle > a {
      margin-bottom: 5px;
      display: block;
      width: 100%;
      margin-left: 0 !important;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
    }
  }
  

  /*회원가입 첫페이지 기업회원인지 개인회원인지*/
  .login__first-page {max-width:880px; width:100%; margin:0 auto; 
     &__ul {display:flex;
      li {width:50%; text-align: center; padding-top:320px;
        h4 {font-size:40px; font-weight:bold; color:#111111;}
        p{font-size:18px; color:#555555;margin-top:20px; margin-bottom:30px;}
      }
      li:first-child {background:url(../../resources/images/cont/login0-img1.png) center 60px no-repeat}
      li:last-child {background:url(../../resources/images/cont/login0-img2.png) center 60px no-repeat}
     }

     @media screen and (min-width: 1px) and (max-width: 768px) {
      &__ul li h4 {font-size:20px;}
      &__ul li p {font-size:16px;}
      &__ul {
        li {padding-top:180px;}
        li:first-child {background-size:100px;}
        li:last-child {background-size:100px;}
      }
     }

  }