$headerHeight:120px;//header 높이
$headerLinks:0px;//상단 링크들 높이
$headerLinksBgColor:transparent;//상단링크 배경색
$gnb-menu__a:120px;//메뉴링크 높이 (ver2에서는 제외)
$gnb-menu-img:120px;//메뉴링크 bg 이미지 원본 높이
$topBannerHeight: 140px;//topbanner 높이

    @function gnbTop(){//1차메뉴 top위치
        @return $headerHeight - $gnb-menu__a
    }
    $gnbTop:gnbTop();

    //ver2
    @function ver2GnbMenu(){ // ver2에서 1차메뉴 height값
        @return $headerHeight - $headerLinks
    }
    $ver2GnbMenu:ver2GnbMenu();

//down-all bg
    @function gnbDonwAllBg(){//down-all bg 포지션
        @return -$gnb-menu-img + $gnb-menu__a;
    }
    $gnbDonwAllBg:gnbDonwAllBg();


//ver2 + down-all bg
    @function ver2DownAllBg(){// ver2에서 down-all bg 포지션
       @return $headerHeight - $gnb-menu-img - $headerLinks;
    }
    $ver2DownAllBg:ver2DownAllBg();


/*ul 사이즈 설정필수*/

//ver2 일때
$ver2ul:900px;//ul사이즈 설정
    @function ver2ulLP(){
        @return $siteSize - $ver2ul
    }
    $ver2ulLP:ver2ulLP();//padding-left설정

//ver2 + addAllmenu 일때
$ver2ulAll:800px;//ul사이즈 설정
$ver2ulAllRP:100px;//전체메뉴버튼 영역 사이즈설정
    @function ver2ulAllLP(){
        @return $siteSize - ($ver2ulAll + $ver2ulAllRP)
    }
    $ver2ulAllLP:ver2ulAllLP();//padding-left설정

//addAllmenu 일때
@function normalAllMenuSize(){
    @return $siteSize - $ver2ulAllRP
}
$normalAllMenuSize:normalAllMenuSize();//ul사이즈 설정

    /*컬러설정*/
$gnbFontColor1:#fff; //1뎁스
$gnbFontColor1over:#fff; //1뎁스오버

$gnbFontColor2:#fff; //2뎁스
$gnbFontColor2over:#fff; //2뎁스오버

$gnbSubNavBg:#ffefe7; //2뎁스오버 배경색
$subNavBoxColor:#fff; //2차보더색

$ie7Ul:25%; // ie7에서 메뉴사이즈

//left-tit 일때
@function leftTit(){
    @return $siteSize / 2;
}
$leftTit : leftTit();

/*header*/
.header {
    height:$headerHeight;
    //position: fixed;
    z-index: 99;
    width:100%;
    font-family: $font2;
    .subpage &{
        position: relative;
        @media screen and (min-width:1px) and (max-width:768px) {
            position: fixed;
        }
    }
    /*헤더 위 링크들*/
    &__links {
        position: relative; width: 1500px; margin: 0 auto;
        z-index: 66999;
        &__1 {
            position: absolute; top: 31px; right: 40px; z-index: 10;
            transition: all 0.3s ease 0s; white-space:nowrap;
            & > a {position: relative;color:#000;margin-left:17px;height:30px}
            &__icon-alert {
                a {display: block;}
                img {width:92px;}
                top:-11px;
                @media screen and (min-width:1px) and (max-width:768px) {
                    display: none;;
                }
            }


        }


        &__1 > a:hover {opacity:0.8;}
        &__1 > a:focus {text-decoration: underline;}

        &__2 {//로고
            position: absolute;
            top:25px;right:10px;z-index: 10;
        }

        @media screen and (min-width:769px) and (max-width:$siteSize) {
            &__1 {top:3px; right:40px;}
        }
        @media screen and (min-width:769px) and (max-width:1200px) {
            &__1 {top:30px}
        }
        @media screen and (min-width:1px) and (max-width:768px) {
            &__1 > a {font-size:13px;width:auto !important;margin-left:10px !important;color:#000;
            padding-left:0;
            }
            &__1 > a:before {display:none !important;}
            &__1 > a.header__links__1__icon3 {color:#000;
                span {display: inline;}
            }
        }

    }

    &__wrap {
        position: relative;

        &__h1 {
            width: 100%;
            max-width: 1900px;
            margin: 0 auto;
            position: relative;
            z-index: 10000;

            h1 {
                position: absolute;
                left: 25px;
                top: 35px;

                a {
                    //width:240px;
                    //height:55px;
                    //line-height:55px;
                    display: block;
                    text-align: center;
                    //font-size:20px;
                    //@include sprite($title);
                    font-size: 0;
                }

            }

        }
    }
    @media screen and (min-width:1px) and (max-width:1500px) {
        &__links { width: auto;}
        &__wrap__h1 { width: auto;}
        h1 a img {max-height:40px;}
    }


	/*header search*/
	/*
	&__search {position:absolute;top:0px;left:50%;margin-left:-70px;}
	&__search__in {border:1px solid #333;background-color:#fff;}
	&__search-in_box {border:0px;padding:0;height:32px;width:150px;line-height: 32px; vertical-align: top;}
    */

    &__search {

        position: relative;
        z-index: 999999;
        width: 100%;
        max-width: 1900px;
        margin: 0 auto;
        background-color: transparent;
        &__open {
            position: absolute;
            top: 44px;
            right: 175px;
            //border: 1px solid #0f9d4c;
            border-radius: 100px 100px;
            color:#0f9d4c;
            display: block;
            height:32px;
            line-height:32px;
            font-size: 16px;
            font-weight: 500;
            //padding-right: 10px;
            text-indent: -9999px;
            //padding-left: 35px;
            overflow: hidden;
            width:40px;
            ///background-color: #fff;
            background-position: 10px center;
            background-repeat: no-repeat;

            &:focus {border:1px solid #0e813f}
            @media screen and (min-width:1201px) and (max-width:1400px) {
                right:112px;
            }
            @media screen and (min-width:1px) and (max-width:768px) {
                display: none;
            }
        }

        &__in {
            display: none;
            position: absolute;
            top: 30px;
            right: 102px;
            border: 1px solid #0f9d4c;
            background-color: #fff;
            border-radius: 34px;
            padding-left: 12px;
            padding-right: 6px;

            //아래 박스형으로 등장하게 수정
            box-shadow: 0px 7px 11px -13px rgba(0, 0, 0, 0.75);
            top: $headerHeight;
            right: 0;
            border: 0;
            background-color: #fff;
            border-radius: 0;
            padding-left: 12px;
            padding-right: 6px;
            text-align: center;
            width: 100%;
            padding-top: 30px;
            padding-bottom: 30px;
            &__inner {
                display: inline-block;
                border-bottom:2px solid #000;
                width: 42%;
                position: relative;
                text-align: left;
                padding-bottom:5px;
            }

            &.on {display: block;}
            @media screen and (min-width: 769px) and (max-width: 1200px){
                top:141px;
            }
            @media screen and (min-width:1px) and (max-width:768px) {
                display: block;
                right:0;
                &__inner {width:80%}
            }
        }

        &-in_box {
            border: 0px;
            padding: 0;
            padding-left: 10px;
            height: 40px;
            width: 85%;
            line-height: 30px;
            vertical-align: top;
            font-size:17px;
            background-color: transparent;
            padding-top: 2px;
            @media screen and (min-width:1px) and (max-width:768px) {
                width:77%
            }
        }
        .top-s-btn {padding:6px;
            position: absolute;
            top:0;right:0;
        }
        .top-search-close-btn {
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            right:10%;
            @media screen and (min-width:1px) and (max-width:768px) {
                right:10px;
                top:20px;
                display: none;
            }
            &:focus {
                border:1px solid #0e813f
            }
        }
    }

	.add-search-btn &__search__in {margin-right: 0px;top:$headerHeight;
        @media screen and (min-width:1px) and (max-width:768px) {
            top:50px;
        }
    }
	.add-search-btn &__search {display: none;}
	.add-search-btn &__search.on {display: block;}
		*+ html .add-search-btn &-search-btn {display: inline;}

	@media screen and (min-width:769px) and (max-width:1500px) {
		&__search {width: auto;}
	}
	@media screen and (min-width:1px) and (max-width:768px) {
        &__search {display: none;
            &__in {top: 52px;}
            .add-search-btn &__in {right: 0; margin-right: 0;}
        }
		&__search.on {display: block; width: auto;}

		&-search-btn {display: none !important}
	}

}

@media screen and (min-width:1px) and (max-width:1500px) {
    .header { background-color: #26272b;}
}
@media screen and (min-width:769px) and (max-width:1200px) {
    .header {border-bottom:1px solid #3c3d41;}
}

/*gnb*/
.gnb {
    width: 100%;
    position: absolute;
	left: 0;
	top: 80px;
	background: {
        repeat: repeat-x;
        position: center top;
    }
    transition: all 0.3s ease 0s;
    box-shadow: 0px 7px 11px -13px rgba(0,0,0,0.75);
    .selected &{box-shadow: 0px 7px 11px -13px rgba(0,0,0,0.75);}


    /*한개씩 떨어짐*/
    .gnb-ul {display: block; width: $siteSize; padding-left:0px; margin:0 auto;}
    .gnb-menu {float: left;}
    .gnb-menu > a {
        display: block;
        text-align: center;
        color:#fff;
        height: 60px;
        line-height: 60px;
        text-decoration: none;
        font: {
            size: 24px;
            weight:500;
        }
        span {display: inline-block;vertical-align: top;
            font-family: $font2;}
    }
    .gnb-menu.over > a,
    .gnb-menu.focus > a,
    .gnb-menu > a:hover,
    .gnb-menu > a:focus {color:$gnbFontColor1over;
        span {
            background-image: url('../../resources/images/basic/gnb-bul.png');
            background-position: center bottom;
            background-repeat: repeat-x;
            position: relative;
            z-index: 9999;
        }
    }
    .gnb-menu.over > a,
    .gnb-menu.focus > a,
    .gnb-menu > a:hover,
    .gnb-menu > a:focus {color:$gnbFontColor1over}

    .gnb-menu {position: relative;}
    .sub-nav {visibility:hidden;height:0;position:absolute;top:60px;left:0;width:100%;z-index: 98;background-color:#26272b;padding-top:0;opacity:0;
    transition: all 0.3s ease 0s;}
    .gnb-menu.over .sub-nav,
    .selected & .gnb-menu.focus .sub-nav {visibility:visible;height:auto;opacity:1;}
    .sub-nav__box {border:2px solid #00235d;border-top:0px;padding-left:20px;padding-top:10px;padding-bottom: 10px;transition: all 0.3s ease 0s;
        &__list > a {display: block;padding:8px 0 8px 10px;}
        &__list > a:hover,
        &__list > a:focus {text-decoration: underline;color:#000;}
    }
    .gnb-menu.over .sub-nav__box,
    .selected & .gnb-menu.focus .sub-nav__box {padding-bottom: 10px;padding-top:15px;}

}

*+ html .gnb {position:absolute;top:0;padding-top:0;}


/* all 2nd menu down
===============================*/
.down-all .gnb {
    background-image:url("../../resources/images/basic/header-bg-ver1.png");

    .sub-nav {
        display: block;
        text-align: center;
        height: 0;
        overflow: hidden;
        visibility: hidden;
        position: relative;
        top: 0;
        left: 0;
        opacity: 1;
        background: transparent;

        &__box {
            border: 0px;
            padding: {
                left:0;
                top:10px;
                bottom:10px;
            }

            &__list a {
                display: block;
                width: 100%;
                padding: 8px 0;
                color: #fff;
                font: {
                    family: $font1;
                    weight:400;
                    size:16px;
                }
				/*
                &:hover,
				&:focus {}
				*/
            }
        }

    }


    .gnb-menu.over {
        .sub-nav {
            background-color:transparent;
            &__box__list a {color:#fff;}
            &__box__list a:hover,
            &__box__list a:focus {color:$gnbFontColor2over;}
        }

    }


    .gnb-menu.over > a,
    .gnb-menu.focus > a {
        background: {
            // image:url("../../resources/images/basic/header-gnb-bg-on.png") !important;
            //color:#0e5390;
        position:left bottom;
        repeat: repeat-x;
       }
    }

}

.mainpage .gnb .gnb-menu > a {color:#fff;}

.down-all {
    .header__wrap__h1 h1 a {
        img {display: none;}
        &:focus {border:1px solid gold}
    }
}
.mainpage .down-all .header__wrap__h1 h1 a {
    @include sprite($title2);
    @media screen and (min-width:1201px) and (max-width:1300px) {
        @include sprite($title,1.3);
    }
}
.subpage .down-all .header__wrap__h1 h1 a {
    @include sprite($title2);
    @media screen and (min-width:1201px) and (max-width:1300px) {
        @include sprite($title2,1.3);
    }
}
.down-all.selected {
    background-color: #26272b;
    position: relative;
    .header__wrap__h1 h1 a {
        img {display: none;}
        @include sprite($title2);
        @media screen and (min-width:1201px) and (max-width:1300px) {
            @include sprite($title2,1.3);
        }
    }

    .gnb {background-color: #26272b;}
    .gnb .gnb-ul .sub-nav {
        height: auto;
        visibility: visible;
        padding: {
            top: 10px;
            bottom: 10px;
        }
        transition: all 0s ease 0s;
    }
    .gnb .gnb-menu > a {
        background: {
            image: url("../../resources/images/basic/header-bg-ver-main2.png");
            repeat: repeat-x;
            position: left bottom;
        }
        color:#fff;

        @media screen and (min-width:1px) and (max-width:768px) {
            background-image: none;
        }
    }

}

    /* 2차메뉴 균일 박스 디자인 적용시 */
    .down-all .gnb {
        .gnb-ul {display:table;}
        .gnb-menu {display:table-cell;float:none;}
        .gnb-menu.over {background-color:transparent}
    }

	/* 2차메뉴 보더적용시 주석 해제 */

	.down-all .gnb .gnb-menu:first-child {border-left:0px solid #eee}
	.down-all .gnb .gnb-menu:last-child {border-right:0px solid #eee !important}
	.down-all .gnb .gnb-menu {padding-right:1px;}
	.down-all.selected .gnb .gnb-menu {
        //border-right:1px solid #eee;
        padding-right:0;}
	.down-all .gnb .gnb-menu:first-child {}
	.down-all .gnb .gnb-menu > a {
		display: block;
		margin-left: -1px;
		position: relative;
		text-align: center;
		width: 102%;
		}



/*모드별 디자인
	ver2
	down-all
	add-all-menu
	add-search-btn
--------------*/

/*ver2*/
.ver2 {
    .header__wrap__h1 h1 {top:27px;z-index: 9999;

        @media screen and (min-width:769px) and (max-width:1500px) {
            top:27px;
        }
    }

    .gnb {
        top:0;
        z-index: 9;
        .gnb-ul {width:950px;}
        .gnb-menu > a {height:$headerHeight;line-height: $headerHeight;}
        .sub-nav {top:$headerHeight;

        }

    }
    &.down-all .gnb {
        background-image:url("../../resources/images/basic/header-bg-ver-main2hover.png");

        .sub-nav {top:0;}
    }
    &.down-all.selected .gnb {background-image:url("../../resources/images/basic/header-bg-ver-main2hover.png");}
}


/*down-all*/


/* add-all-menu */
.add-all-menu {
    .all_menu_wrap__outwrap {display:block;}
    .gnb .gnb-ul {width:1250px;}
}

/*ver2 + add-all-menu*/
.ver2.add-all-menu .gnb .gnb-ul {width:940px;padding-right:153px;padding-left: 140px;}
// 검색바 다시 약간수정
    .ver2.add-all-menu .gnb .gnb-ul {width:1030px;padding-right:0;padding-left: 30px;margin:0 auto;}

@media screen and (min-width:1401px) and (max-width:1700px) {

    .ver2.add-all-menu .gnb .gnb-ul {
        width:870px;padding-left:0px;padding-right:0px;
    }
}
@media screen and (min-width:1201px) and (max-width:1400px) {
    .ver2.add-all-menu .gnb .gnb-ul {
        width:780px;
        padding-left:120px;
    }
}

@media screen and (min-width:769px) and (max-width:1200px) {
    .header {height:140px;}
	.header__wrap__h1 h1 {top:20px !important}
	.gnb {
        top: 80px !important; background-image: none !important;
        .gnb-ul { width:99% !important; padding-left:0 !important; padding-right:0 !important;}
	    .gnb-menu > a { height: 60px !important; line-height: 60px !important;}
    }

}



/* 펼쳤을때 좌측 타이틀*/
.down-all .gnb .sub-nav{
    // background-image: url('../../resources/images/basic/gnb-tit-bg.jpg'),url('../../resources/images/basic/gnb-bg.jpg');
    // background-repeat:no-repeat, repeat-y;
    // background-position: left top, center top;
    // background-size: auto 100%, auto 100% ;
    @media screen and (min-width:1px) and (max-width:1200px) {
        background-image:none;
    }
}
.down-all .gnb .sub-nav__box {position: relative;
}
.gnb-tit {display:none;}
.gnb .gnb-tit {display:none;

    position: absolute;
    top:50px;left:0;
    z-index: 999;
    width:240px;text-align: left;
    color:#000;letter-spacing: -1px;font-size:27px;font-weight:bold;
    &:before {
        content:' ';
        display: block;
        width:40px;
        height:1px;
        background-color:#000;
        position: absolute;
        top:55px;
        left:0;
    }
    &:after {position:absolute;
        top:75px;
        left:0px;
        font-size:16px;
        line-height: 1.6;
        color:#666;
        font-weight: normal;
        white-space:pre;
    }
}
@media screen and (min-width:1px) and (max-width:1200px) {
    .gnb .gnb-tit {display:none;}
}


@media screen and (min-width:769px) and (max-width:1500px) {
    .header__links__2 {top:15px;}
}
@media screen and (min-width:1px) and (max-width:768px) {

    .header__links__2 {
        top:10px;
        right:10px;
        z-index: 0;
    }
    .mobile_menu_open .header__links__2  {display:none !important;}
}

.gnb .sub-nav__3rd,
.gnb .gnb-thrd-menu {display: none;}


// .gnb .gnb-menu:last-child > a span {
//     color:#0e813f;
//     position: relative;
//     &:before {
//         position: absolute;
//         top:11px;
//         left:50%;
//         transform: translateX(-50%);
//         @include sprite($leaf,2);
//         @media screen and (min-width:769px) and (max-width:$siteSize) {
//             display: none;
//         }
//     }
// }

// 마이페이지, 영문사이트 이동 버튼
.header__etc-wrap {
    max-width: 1900px;
    margin: 0 auto;
    position: relative;
}
.header__etc {
    position: absolute;
    top:42px;
    right:95px;
    z-index: 9999;
    display:inline-block;
    @media screen and (min-width:1px) and (max-width:1400px) { 
        top:35px; right:10px;
        > a {margin-left:8px !important;} 
    }
    
    @media screen and (min-width:1px) and (max-width:768px) {
        display:none;
    }
    > a {float:left; margin-left:15px;box-sizing:border-box;
        &:hover, &:focus {opacity:0.6; }
        &:first-child {margin-left:0;}
    }
    
}
.header__logout {
    z-index:9999;
    height:38px; 
    line-height:38px;
    color:#fff;
    font-size:12px;
    &:before {@include sprite($header-logout); margin-right:9px; }
}

.header__MyPage {
    text-indent: -9999px;
    z-index:9999;
    @include sprite($header-mypage);
}


.header__Search {
    text-indent: -9999px;
    z-index:9999;
    @include sprite($header-search);
}
.header__Search__tap {display:none;left:0; top:120px; position:fixed; width:100%; height:100%;
     background:url('../../resources/images/cont/header-search-in-bg.jpg') center top no-repeat rgba(000,000,000, 0.6);
    &.open {display:block;}
    .search-input {width:calc(100% - 60px); position: relative;}
    &__in {border-bottom:2px solid #fff; max-width:1000px; margin:0 auto; width:100%; margin-top:70px; display:flex;
        label {font-size:0;width:60px; float:left;}
        label:before {@include sprite($header-search-in); margin-top:15px; margin-left:10px; }
        input {height:70px; background-color:transparent; border:none;padding-left:10px; color:#7c7c7d; font-size:30px; width:100%; box-sizing: border-box}
    }
    &__close {@include sprite($header-search-in-close);position:absolute; right:40px; top:190px; font-size:0;}
    
    .header__Search__tap__list {display:none;}
    &.focus {
        .header__Search__tap__list {display:block;
            position:absolute; width:100%;background-color:#fff;
            li a {position:relative; display:block; width:100%; padding:8px 20px 8px 40px; box-sizing: border-box; font-size:16px;
                &:hover, &:focus {background-color:rgb(250, 250, 250);}
                &:before {position:absolute;left:15px; top:50%; margin-top:-9px;
                    @include sprite($search_icon);}
                &:after {position:absolute; right:15px;top:50%; margin-top:-9px;
                    @include sprite($arrow_icon);}
            }
        }

    }
}


.ver-left .header .header__Search__tap.open{top:0 !important;}

.header__eng {
    text-indent: -9999px;
    z-index:9999;
    @include sprite($header-eng);
    &:hover,
    &:focus {text-decoration: underline;}
    @media screen and (min-width:1201px) and (max-width:1400px) {
        right:48px;
    }
    @media screen and (min-width:1px) and (max-width:768px) {
        padding:0 6px;
        span {display: none;}
        color:#000;
        background-image: none !important;
        // right:unset;
        // left:-250px;
        // transition: all 0.3s ease 0s;
        // .mobile_menu_open & {
        //     left: 15px;
        //     top: 10px;
        //     z-index: 999999;
        //     border: 0;
        //     height: 30px;
        //     line-height: 30px;
        // }
        // 검색 없앤후 그 자리를 영문사이트 이동 버튼으로 교체
        right:8px;
        top:8px;
        //border:0px;

        border-radius: 10px 10px;
        height:35px;
        line-height:35px;
        z-index: 999999;
        .mobile_menu_open & {
            display:none;
        }
    }
}
.header__kor {
    text-indent: -9999px;
    z-index:9999;
    @include sprite($header-kor);
    &:hover,
    &:focus {text-decoration: underline;}
    @media screen and (min-width:1201px) and (max-width:1400px) {
        right:48px;
    }
    @media screen and (min-width:1px) and (max-width:768px) {
        padding:0 6px;
        span {display: none;}
        color:#000;
        background-image: none !important;
        // right:unset;
        // left:-250px;
        // transition: all 0.3s ease 0s;
        // .mobile_menu_open & {
        //     left: 15px;
        //     top: 10px;
        //     z-index: 999999;
        //     border: 0;
        //     height: 30px;
        //     line-height: 30px;
        // }
        // 검색 없앤후 그 자리를 영문사이트 이동 버튼으로 교체
        right:8px;
        top:8px;
        //border:0px;

        border-radius: 10px 10px;
        height:35px;
        line-height:35px;
        z-index: 999999;
        .mobile_menu_open & {
            display:none;
        }
    }
}


//모바일 검색 버튼 없애기
//.mobile_top_nav .m_s_btn {display: none !important;}

// .mainpage .header__search__open {
//     background-image: url('../../resources/images/icons/search-btn2.png');
// }

.header__search__open {
    background-image: url('../../resources/images/icons/search-btn2.png');
}
// .header__eng {
//     background-image:url("../../resources/images/icons/eng.png");
//     color:#fff;
// }

// .mainpage .down-all.selected {
//     .header__search__open {
//         background-image:url("../../resources/images/icons/search-btn2n.png");
//     }
//     .header__eng {
//         background-image:url("../../resources/images/icons/eng2.png");
//         color:#000;
//     }
// }

@media screen and (min-width: 1px) and (max-width: 768px) {
    .subpage .down-all .header__wrap__h1 h1 a {height:auto !important}
    }

