/*	footer
==========*/

$footBgColor: #1a1b1e; // 배경색
$footFontColor: #fff; // 기본폰트컬러
$footFontCpBx1-Color: #fff; // 링크컬러
$footFontCpBx1-Color-p: #fbf36f; // 개인정보처리방침 링크 컬러


.footer {
	background-color:$footBgColor;
	color:$footFontColor;
	position: relative;
	font-family: $font2;
	font-size: 16px;
	padding-top:30px;
	padding-bottom:70px; text-align: center;;
	/*background-image:url("../../commons/images/global/footer-bg.png");background-position:0 45px;background-repeat:repeat-x;*/
	a:hover,
	a:focus {text-decoration: underline;}
	&__in {
		width:$siteSize;
		margin:0 auto;
		position:relative;
		min-height:180px;
		@include floatWrap;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			min-height:150px;
		}
	}
}
.foot_img {
	@include sprite($foot_img);
	text-indent: -9999px;
	font-size:0;
}
.copy_box {margin-top:20px;
    z-index: 9;
	ul {@include floatWrap;display:inline-block; margin:0 auto;}
	li {float:left;
		position: relative;
		padding-left:30px;
		a {

			color:$footFontCpBx1-Color;
			vertical-align: top;
		}
		&:before {
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			left:15px;
			@include sprite($copy_li_bg);
		}
		&:first-child:before {display:none;}
	}
	li:first-child {background-image:none;padding-left:0;

	}
	li:first-child a {background-image:none;}
	li.persnal-rule a {color:$footFontCpBx1-Color-p;font-weight: bold;}
	li.sns-li {background-image:none;}
}

.copy_box2{
	width:auto;
	padding-top:20px;
	font-size:16px;
	.item1{
		margin-bottom:15px;
		font-style:normal;
		line-height:1.5;
		color:$footFontColor;
		ul {
			@include floatWrap;
			li {
				padding-left:20px;

				&:first-child {
					padding-left:0;
					background-image:none;
				}
			}
		}
	}
	.item2 {
		font-style:normal;
		margin-top:5px;
		margin-bottom:4px;
		font-size:12px;
		font-size:1.2rem;
		line-height:1.6;
		color:$footFontColor;
	}
}

.copy_box3 {
	position:absolute;
	right:0;
	top:10px;
}

.copy_box4 {
	position:absolute;
	right:0;
	top:80px;
}

/*통계*/
.total-count {
	background-color:#399e90;
	padding:10px 30px 10px 10px;
	border-radius: 30px;
	@include floatWrap;
	&__dt {display:inline-block;color:#fff;margin-right:5px;
		padding-left:20px;
		&:before {
			content: '';padding-right:10px;padding-left:14px;
			display: inline-block;
			@include sprite($total-count-bul);

		}
		&:first-child:before {display:none;}
	}
	&__dd {display:inline-block;color:#fff}

}

/*푸더 셀렉트*/
.footer-select {
	background-color: #434a54;
	border:1px solid #a1a5aa;
	border-radius: 10px;
	overflow: hidden;
}
.footer-select__item {
	@include selectBox3(180px);
}
.sel_go_btn {
	display:inline-block;
	height:38px;
	width:43px;
	line-height:38px;
	text-align:center;
	color:#fff;
	background-color:#272727;
	border-left:1px solid #717171;
}
*+ html .sel_go_btn {display:inline;}

@media screen and (min-width:1px) and (max-width:$siteSize) {

	/*footer*/
	.footer {
		height:auto;
		min-height:100px;
		padding:10px;
		background-image:none;
		position:relative;
		z-index:12;

		&__in {width:100%;}
	}
	.foot_img {display:none;}
	.copy_box {
		position:relative;
		left:0;
		top:0;
		margin-bottom:5px;
		background-color:transparent;
		padding:5px;
	}
	.copy_box2 {
		position:relative;
		left:0;
		top:0;
		padding-top:10px;
		.item1 {font-size:15px;}
	}
	.copy_box li {
		background-image:none;
		padding:4px 10px;
		&::before {display:none;}
	}
	.select_bottom {
		position:relative;
		right:0;
		top:0;
	}
	.select_bottom p {
		float:left;
	}
	.copy_box2 .item1 li {
		padding-left:0;
		background-image:none;
		float:none;
	}
	.copy_box3 {position: relative;top:0;margin-bottom:5px;}
	.copy_box4 {position: relative;top:0;}
	.total-count {padding:0;border-radius:0;padding:5px;}
	.total-count__dt:first-child {padding-left:0;}

	.footer-select {display:inline-block;}
	.sel_go_btn {border-left:0px;}
}
@media screen and (min-width:851px) and (max-width:1000px) {


	/*footer*/
	.copy_box4 {position:absolute;top:39px;right:10px;}
	.copy_box4 p {float:none;}

}

@media screen and (min-width:1px) and (max-width:768px) {
	.copy_box2 .item1 li strong {display:block;margin-top:5px;}
}
@media screen and (min-width:1px) and (max-width:470px) {
	.copy_box li {padding:0;float:left;width:50%;text-align:center;padding-top:5px;padding-bottom:5px;height: 24px;}

	.copy_box2 .item1 li {padding-left:0 !important;background-image: none;width:100%;}
}
