

/*	메인페이지
===========================*/


@font-face{
	font-family:'GongGothicL';
	src:url('GongGothic_Light.eot');
	src:url("../../resources/fonts/GongGothic_Light.eot?#iefix") format('embedded-opentype'),
        url("../../resources/fonts/GongGothic_Light.woff") format("woff"),
        url("../../resources/fonts/GongGothic_Light.woff2") format("woff2"),
        url("../../resources/fonts/GongGothic_Light.ttf") format('truetype');
	font-weight:normal;
	font-style: normal;
}

@font-face{
	font-family:'GongGothicM';
	src:url('GongGothic_Medium.eot');
	src:url("../../resources/fonts/GongGothic_Medium.eot?#iefix") format('embedded-opentype'),
        url("../../resources/fonts/GongGothic_Medium.woff2") format("woff"),
        url("../../resources/fonts/GongGothic_Medium.woff2") format("woff2"),
        url("../../resources/fonts/GongGothic_Medium.ttf") format('truetype');
	font-weight:normal;
	font-style: normal;
}

@font-face{
	font-family:'GongGothicB';
	src:url('GongGothic_Bold.eot');
	src:url("../../resources/fonts/GongGothic_Bold.eot?#iefix") format('embedded-opentype'),
        url("../../resources/fonts/GongGothic_Bold.woff") format("woff"),
        url("../../resources/fonts/GongGothic_Bold.woff2") format("woff2"),
        url("../../resources/fonts/GongGothic_Bold.ttf") format('truetype');
	font-weight:normal;
	font-style: normal;
}


@font-face {
    font-family: 'Pretendard-Regular';
    src: url("../../resources/fonts/Pretendard-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}
/*메인첫화면 로딩아이콘*/
div.loading-box{height:80px;width:80px;
	display:none;position:fixed;top:50%;left:50%;z-index:9999;
	transform:translateX(-50%);
}
div.loading-box p{color:#fff;text-align:center}

body.loading .loading-box {display:block;}


.resol {display: none;}


/*메인 레이아웃*/
.main-section {
	position:relative;
	background-color:#ffffff;
	font-family: 'Pretendard-Regular';
	margin-top:-$headerHeight;
	z-index: 0;

	@media screen and (min-width:769px) and (max-width:$siteSize) {
		margin-top:-141px;
	}
	@media screen and (min-width:1px) and (max-width:768px) {
		margin-top:0;
		padding-top:51px;
	}
}



.popupzone2 {


    position: relative;
    margin-top: 0px;
    width: 100%;
	height: auto;
	.slick-arrow {border:0px;top:220px;}
	.slick-prev {position: absolute;left:10px;z-index: 1}
	.slick-next {position: absolute;right:10px;z-index: 1;}
	.popupzone-slide-first a {display: block;}
	.popupzone-slide-first a:focus {opacity: 1;}
	padding-left:700px;
	box-sizing: border-box;
	.popupzone2-nav {bottom:-100px; left:0; text-align: center; top:unset}
}
//
.popupzone-slide-big {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9999;
	width:760px;
	.slick-next {display:none !important}
	
	.popupzone2-list__item {
		> a {position:relative;box-sizing: border-box;
			width:760px;
			background-color: gold;
			height:520px;
			display: block;padding:50px; 
			font-size:30px;
			box-sizing: border-box;
		}
		&__btn1 {position:absolute; bottom:50px; color:#000; height:54px; line-height:54px; font-size:16px;display:inline-block; padding-left:20px;padding-right:60px;background:url(../../resources/images/icons/main-way-go-img1.png) 86% center no-repeat #fff;}
		
		&__btn2 {position:absolute; bottom:50px; color:#000; height:54px; line-height:54px; font-size:16px;display:inline-block; padding-left:20px;padding-right:60px; background:url(../../resources/images/icons/main-way-go-img3.png) 86% center no-repeat #fff }
		/* Shine */
		> a {
			position: relative;
		}
		> a::before {
			position: absolute;
			top: 0;
			left: -75%;
			z-index: 2;
			display: block;
			content: '';
			background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			-webkit-transform: skewX(-25deg);
			transform: skewX(-25deg);
			opacity: 0;
		}
		> a:hover::before {
			-webkit-animation: shine .75s;
			animation: shine .75s;
			width: 50%;
			height: 100%;
			opacity:1;
		}
		@-webkit-keyframes shine {
			100% {
			left: 125%;
			}
		}
		@keyframes shine {
			100% {
			left: 125%;
			}
		}
	}
	h4 {font-size:40px; color:#fff;font-family: 'GongGothicM';

	}
	p {font-size:20px; color:#fff;margin-top:15px;}
}

@media screen and (min-width:1px) and (max-width:560px) {
	.popupzone-slide-big p {font-size:16px;}
	.popupzone-slide-big h4 {font-size:24px;}
	.popupzone-slide-big .popupzone2-list__item__btn1,.popupzone-slide-big .popupzone2-list__item__btn2 {font-size:14px; line-height:40px; height:40px;}
	.main-visual .slick-next,.main-visual .slick-prev {background-size:16px}
	.popupzone2-nav > div > ul {display:inline-block !important;}
	
}
.popupzone-slide-first{
	margin-left:-320px;
	.popupzone2-list__item {
		transition: all 0.3s ease 0s; margin-left:30px;

		a {padding-top:50px; padding-left:40px;
			width: 380px; pointer-events: none;
			cursor: default;
			display: block;
			height: 520px;background-position: right;
			background-color: gold;
			transition: all 0.3s ease 0s;
			box-sizing: border-box;
			font-size:30px;
			h4 {font-size:30px; color:#fff;font-family: 'GongGothicM';}
		}
		p,&__btn1,&__btn2 {display:none;}
		
		img {object-fit:cover;border-radius: 0px;overflow:hidden;margin:0 auto;}
		&.slick-center a{
		}
		&.slick-current {
		}
		&.slick-current a{
			opacity: 1;
		}
	}
}
.popupzone2-nav {
	@include slickDots;
	> div {background:none;}
	> div > ul li .slide-pause-item {background:url(../../resources/images/icons/main-visual-pause.png) center center no-repeat; border-right:0;transition: all 0.3s ease 0s; }
	.slick-dots-list li button {width:10px; height:10px; background-color:#fff;}
	.slick-dots-list li.slick-active button {position:relative; width:24px; height:24px; border:3px solid #fff;transition: all 0.3s ease 0s; 
		&:before {background-color:#fff; border-radius:100px; width:6px; height:6px;position:absolute; top:50%; margin-top:-3px;left:50%; content:'';margin-left:-3px;display:inline-block; }
	}
	 > div > ul li a {width:20px;}
}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	.popupzone2 .popupzone-list__item img {height:auto;object-fit:unset;}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.popupzone2 {max-width:768px;margin:0 auto;margin-bottom:10px;}
	.popupzone2 .popupzone-list__item img {height:auto !important;}
	.popupzone2-nav {top:9px;right:0px;padding-right:0;}
}
@media screen and (min-height:1px) and (max-height:930px) {
	.popupzone2-nav > div > ul {display:none;}
	.popupzone2-nav {top:15px;right:10px;}
	.popupzone2__tit {display: none;}
}
// ie9
.lte-ie9 .popupzone2 .popupzone-list__item {height:340px;overflow: hidden;}
.lte-ie9 .popupzone2 .popupzone-list__item img {height:auto;}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	.lte-ie9 .popupzone2 .popupzone-list__item {height:300px;overflow: hidden;}
	.lte-ie9 .popupzone2 .popupzone-list__item img {height:300px;object-fit:cover}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.lte-ie9 .popupzone2 .popupzone-list__item {height:auto;overflow: hidden;}
	.lte-ie9 .popupzone2 .popupzone-list__item img {height:auto;}
}



/*판교*/

.main-section {position:relative;
	font-family: $font2;
}
.main-section__in {
	position:relative;
	min-height: 500px;
	background-color:#fff;
	@include floatWrap;
}

.main-lay-1 {
	max-width:1200px; width:100%; padding-top:160px; padding-bottom:150px;
	margin:0 auto;
	&__in {display:flex; justify-content: space-between;}
}
.main-lay-2 {background:url(../../resources/images/temp/main-project-bg.jpg); display:inline-block; width:100%;
	padding-top:150px; padding-bottom:110px;min-height:290px;

	}
.main-lay-3 {
	max-width:1200px;width:100%;
	margin:0 auto;padding-top:150px; padding-bottom:180px;}
.main-lay-4 {}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	.main-section__in {width:auto;}
	
}

@media screen and (min-width:1px) and (max-width:768px) {
	.main-section {padding-top:51px;}
	.main-lay-1,.main-lay-2,.main-lay-3 {padding-top:70px; padding-bottom:70px;}
}



/*팝업존*/
.popupzone {

    position: relative;
    margin-top: 0px;
    width: calc(39.2% - 20px); 
	height: auto;box-sizing:border-box; 
	.slick-arrow {border:0px;top:220px;}
	.slick-prev {position: absolute;left:10px;z-index: 1;display:none !important;}
	.slick-next {position: absolute;right:10px;z-index: 1;display:none !important;}
	.slick-slide a:hover,
	.slick-slide a:focus {opacity: 0.8;}

	&__tit {font-size:40px; font-family: 'GongGothicM'; margin-bottom:30px;}
	&__item > img {width:100%}
	
	.popupzone-nav {bottom:4px; }
}

.popupzone-nav {
	@include slickDots;
}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	.popupzone .popupzone-list__item img {object-fit:cover}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.popupzone {margin:0 auto;margin-bottom:10px;padding:0;margin-top:30px;}
	.popupzone .popupzone-list__item img {height:auto; width:100%; }
}
// ie9
.lte-ie9 .popupzone .popupzone-list__item {height:340px;overflow: hidden;}
.lte-ie9 .popupzone .popupzone-list__item img {height:auto;}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	.lte-ie9 .popupzone .popupzone-list__item {height:300px;overflow: hidden;}
	.lte-ie9 .popupzone .popupzone-list__item img {height:300px;object-fit:cover}
}
@media screen and (min-width:1px) and (max-width:768px) {
	.lte-ie9 .popupzone .popupzone-list__item {height:auto;overflow: hidden;}
	.lte-ie9 .popupzone .popupzone-list__item img {height:auto;}
}

/*팝업존2*/
.popupzone-nav2 {
	@include slickDots2;
}

.popupzone-nav3 {
	@include slickDots2;
	& > div > ul li a {border:1px solid #000;}
	& > div > ul li .slide-prev-item {background: url('../../resources/images/icons/popup-prev2.png') center center no-repeat;}
	& > div > ul li .slide-next-item {background: url('../../resources/images/icons/popup-next2.png') center center no-repeat;}
	& > div > ul li .slide-more-item {background: url('../../resources/images/icons/popup-more2.png') center center no-repeat;}
}

/*NEWS*/
.news {width:calc(60.8% - 20px); box-sizing:border-box; position:relative; margin-right:20px; box-sizing: border-box;
	&__tit {font-size:40px; font-family: 'GongGothicM';margin-bottom:30px; }
	&__ul {border-top: 2px solid #000;
		&__li {border-bottom:1px solid #cccccc; padding-left:30px; padding-right:30px; padding-top:19px; padding-bottom:19px;
			.cont {width:100%; font-size:20px;color:#111; font-weight:500;display: inline-block;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;}
			.date {font-size:16px; color:#777777;margin-top:10px; }
		}
		
	}
	.popupzone-slide4 {
		.slick-arrow {display:none !important; }
	}
	.popupzone-nav {bottom:4px; }
}
@media screen and (min-width:1px) and (max-width:1200px) {
	.main-lay-1__in {padding-left:1%; padding-right:1%;}
	.news__ul__li {padding-left:10px; padding-right:10px; padding-top:15px; padding-bottom:15px;}
	.news__ul__li .cont {font-size:16px;}
	.news__tit {font-size:34px;}
	.popupzone__tit {font-size:34px; margin-top:0;}
}


@media screen and (min-width:1px) and (max-width:768px) {
	.main-lay-1__in {display:block;}
	.news {width:100%;}
	.popupzone {width:100%;}
	.popupzone__tit { margin-top:50px;}
}
/*popupzone*/
.popupzone {}
/*시설안내 아카이브*/
.shortcut {margin-top:140px;
	&__ul {display:flex; gap:40px; text-align: center; color:#fff; align-items: center;
		&__li {flex:1;min-height:580px;display:grid; align-items: center;transition: all 0.3s ease 0s; transform: scale(0.97);
			&:first-child {background:url(../../resources/images/temp/main-baro-go-img1.jpg) center center no-repeat; background-size: cover;}
			&:last-child {background:url(../../resources/images/temp/main-baro-go-img2.jpg) center center no-repeat; background-size: cover;}
			h4 {font-size:44px; font-weight:300;font-family: 'GongGothicM'; margin-top:-20px;
				span {font-size:16px; font-weight:100;}

			}
			p {font-size:18px; font-weight:300; line-height: 1.5;margin-top:15px; margin-bottom:35px;}
			&__in {}
			&:hover {  -webkit-transform: scale(1);
				transform: scale(1);
		-webkit-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;box-shadow: 6px 10px 17px -7px rgba(0,0,0,0.6);}
		}
	}
	&__btn {margin: 0 auto; text-align: center;
		a {text-align:left; padding-left:18px; font-size:16px; background:url(../../resources/images/icons/main-way-go-img2.png) 90% center no-repeat;    transition: all 0.3s ease 0s;
			padding-right:18px;display:block; border:1px solid #fff; height:54px; max-width:200px; width:100%; display:grid; align-items: center;  margin:0 auto; margin-top:10px;
			&:hover {color:#000;background:url(../../resources/images/icons/main-way-go-img1.png) 90% center no-repeat #fff;}

		}
	}
}


@media screen and (min-width:1px) and (max-width:1200px) {
	.shortcut__ul{gap:10px;}
	.shortcut__ul__li p {font-size:16px; padding-left:10px; padding-right:10px;}
	.shortcut__ul__li h4{font-size:36px;}
	.shortcut__btn a {font-size:15px;}
}


@media screen and (min-width:1px) and (max-width:680px) {
	.shortcut__ul{display:block;}
	.shortcut__ul__li {min-height:500px;}
	.shortcut {margin-top:50px;}
}
/*support project*/
.main-project {max-width:1580px;width:100%; color:#fff;position:relative;  margin:0 auto;
	&__tit__wrap {float:left;width:380px; }
	&__tit {font-size:44px;font-family: 'GongGothicM';  }
	p {font-size:18px; margin-top:15px; font-weight:300}
	.popupzone-slide2 {width:calc(100% - 380px); float:left;
		.slick-track {margin-left:unset; margin-right:unset;}
		button {display:none !important;}
		&__text {font-size:20px; font-weight:300; line-height: 1.3; margin-top:12px;margin-bottom:32px;word-wrap: break-word;
			overflow: hidden;padding-right:1%;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2; width:96%; 
			-webkit-box-orient: vertical;height:55px
		}
		.popupzone-list__item__in {padding-left:2%; padding-right:2%;}
	}
	.popupzone-list__item a{}
}


@media screen and (min-width:1px) and (max-width:1200px) {
	.main-project {padding-left:1%;padding-right:1%; box-sizing: border-box; }
	.main-project__tit__wrap {width:100%; float:none;}
	.main-project .popupzone-slide2 {width:100%; float:none;}
	.popupzone-nav2 {top:5px; right:1%; text-align:right; left:auto;}
	.popupzone-nav2 > div > ul li a {width:30px; height:30px;}
	.main-project__tit {font-size:36px;}
	.main-project__tit br{display:none;}
	.main-project p {font-size:16px; margin-bottom:20px; margin-top:15px;}
	.main-project p br {display:none;}
	.main-project .popupzone-slide2__text {font-size:16px; height:40px;margin-bottom:20px;}
}

@media screen and (min-width:1px) and (max-width:768px) {
	.main-project__tit {font-size:28px;}
	
}


/* SNS */

.SNS {position:relative; 
	&__tit {font-size:44px; font-family: 'GongGothicM'; margin-bottom:25px;}
	&__ul {display: flex;justify-content: space-between;
		flex-wrap: wrap; gap:16px 20px; 
		&__li {flex-basis: 285px;
			a {position:relative; overflow:hidden; display:inline-block; }
			&__in {
				padding:40px 30px;font-size:22px; font-weight:500;box-sizing: border-box;color:#fff; position:absolute; width:100%; height:100%; background-color: rgba(0,0,0,0.7);bottom:-500px; transition: 0.5s ease;
				&__text {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2; 
					-webkit-box-orient: vertical;}
			}
		}	
		&__li:hover .SNS__ul__li__in {bottom:0 ; }
	}
	
	&__btn {position:absolute; right:0; top:0; 
		a {text-indent: -9999px; font-size:0; margin-left:2px;}
		a:hover {}
		&__facebook {
			@include sprite($main-sns-btn-img1);
		}

		&__instagram {@include sprite($main-sns-btn-img2);}
		&__youtube {@include sprite($main-sns-btn-img3);}
		&__more {@include sprite($main-sns-btn-img4);}
	}
}

@media screen and (min-width:1px) and (max-width:1220px) {
	.SNS__tit {padding-left:1%; padding-right:1%; font-size:34px;}
	.SNS__ul {display:inline-block;}
	.SNS__btn {right:1%;}
	.SNS__ul__li {width:33.333%; float:left; padding:1%; box-sizing: border-box; text-align: center;}
}

@media screen and (min-width:1px) and (max-width:600px) {
	.SNS__ul {display:inline-block;}
	.SNS__ul__li {width:50%; float:left; padding:1%; box-sizing: border-box; text-align: center;}
}



/*SNS2 221205 추가*/
.SNS2 {display:flex; gap:20px; position:relative;
	h3 {font-size:28px; font-family: 'GongGothicM'; margin-bottom:30px; 
		&:before {margin-right:10px;}
	}
	// .youtube {width:49.9%;
	// 	h3:before {content:''; @include sprite($main-sns-youtube)}
	// }
	.youtube {width:100%; position:relative;
		&__in {display:flex; gap:30px; 
			a {flex:1;}
			&__tit {font-size:22px; font-weight:bold; margin-top:20px; margin-bottom:20px; min-height:54px;
				word-wrap: break-word;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
			&__date {font-size:16px; color:#777777;}
		}
		h3:before {content:''; @include sprite($main-sns-youtube)}
	}
	.Instagram {width:26.7%;
		h3:before {@include sprite($main-sns-insta)}
	}
	.facebook {width:26.7%;
		h3:before {@include sprite($main-sns-f)}
	}

	
	.fix-size {
		display: block; border:1px solid #e0e0e0;
		height: 330px;
		box-sizing: border-box;
		overflow: hidden;
		iframe {
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: auto;
			height: auto;
			min-width: 100%; object-fit: cover;
			width:100%;
			min-height: 100%;
		}
		img{
			position: relative;object-fit: cover;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: auto;
			height: auto;
			min-width: 100%;
			min-height: 100%;
	}
	.fb_iframe_widget {display:inline;}
	.fb_iframe_widget span {display:inline;}
	}
	// .fix-size__in {
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// }
	
	// .fix-size img {
	// 	width: 100%;
	// 	height: 100% !important;
	// 	object-fit: cover;
	// 	position: absolute;
	// 	top: 50%;
	// 	left: 0;
	// 	transform: translateY(-50%);
	//   }
	
	
	@media screen and (min-width:1px) and (max-width:1200px) {
		.youtube {padding-left:1%; padding-right:1%;}
		.main-tab__cont__more {right:1%}
	}
	@media screen and (min-width:1px) and (max-width:860px) {
		h3:before {display:none !important}
	}
	@media screen and (min-width:1px) and (max-width:600px) {
		.fix-size {height:220px}
		 h3:before {display:inline-block !important}
		display:inline-block; 
		box-sizing:border-box;padding-left:10px; padding-right:10px;
		.youtube {width:100%; }
		.Instagram {width:49%;float:left; margin-top:20px; margin-right:1%; }
		.facebook {width:49%;float:left; margin-top:20px; margin-left:1%;}
		.youtube__in {gap:10px}
		.youtube__in__tit {font-size:19px; min-height:48px;}
	}
	@media screen and (min-width:1px) and (max-width:450px) {
		.Instagram, .facebook {width:100%;margin-left:0; margin-right:0;}
		.fix-size {height: auto;
			img,iframe {top:auto;left:auto; transform:none;}
		}
	}
}



/*partner*/
.partner {margin-top:150px;position:relative;min-height:320px;
	&__tit__wrap {float:left;width:400px; }
	&__tit {font-size:44px;font-family: 'GongGothicM'; }
	p {font-size:18px; margin-top:15px; font-weight:300}
	.popupzone-slide3 {width:calc(100% - 400px); float:left;
		button {display:none !important;}
		&__text {font-size:20px; font-weight:300; line-height: 1.3; margin-top:12px;margin-bottom:32px;word-wrap: break-word;
			overflow: hidden;padding-right:1%;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2; width:96%; font-family:'GongGothicM';
			-webkit-box-orient: vertical;height:55px
		}
		.popupzone-list__item__in {margin:5px;height:100px; background-color:#f2f6f7; border-radius:15px; display:flex; align-items:center; justify-content: space-around;}
		
		.popupzone-list__item a{}
	}
	.popupzone-nav3 {top:155px ;}

}




@media screen and (min-width:1px) and (max-width:1200px) {
	.partner {padding-left:1%; padding-right:1%; box-sizing: border-box; margin-top:50px;}
	.partner__tit {font-size:34px;}
	.partner__tit__wrap {float:none;width:100%;}
	.partner .popupzone-slide3 {float:none;width:100%;}
	.partner .popupzone-nav3 {top:5px; right:1%; text-align:right; left:auto;}
	.popupzone-nav3 > div > ul li a {width:30px; height:30px;}
	.partner p {font-size:16px; margin-bottom:15px; margin-top:10px;}
	.partner .popupzone-slide3 .popupzone-list__item__in {height:80px; box-sizing: border-box;padding:10px;}
}

@media screen and (min-width:1px) and (max-width:768px) {
	.news__tit, .popupzone__tit, .SNS__tit, .partner__tit {font-size:28px;}
}


/*Archive 탭*/

.archive {margin-top:150px; position:relative;
	.slick-prev {position:absolute; top:-68px; right:70px; width:40px; height:40px;text-indent: -9999px;
		background: url('../../resources/images/icons/popup-prev2.png') center center no-repeat;
		&:focus {background-color:gold;}
	}
	.slick-next {position:absolute; top:-68px; right:30px; width:40px; height:40px;text-indent: -9999px;
		background: url('../../resources/images/icons/popup-next2.png') center center no-repeat;	
		&:focus {background-color:gold;}
	}
	.popupzone-nav {display:none;}
	.slick-list {margin:0 -15px;}
	.slick-slide {
		margin:0 15px;
	}
	.macin-tab__cont__tit {padding-top:20px; margin-bottom:30px; min-height: 60px;font-size:22px; font-weight:bold; line-height: 1.4;
		word-wrap: break-word;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.macin-tab__cont__data {font-size:16px; color:#777777;}
}

@media screen and (min-width:1px) and (max-width:$siteSize) {
	.archive .slick-prev, .archive .slick-next {top:-112px}
	.archive {padding-left:1%; padding-right:1%; box-sizing: border-box;}
	.archive .macin-tab__cont__tit {font-size:18px;min-height:50px;}
}

@media screen and (min-width:1px) and (max-width:768px) {
	.archive {margin-top:70px;}
	.archive .slick-prev, .archive .slick-next {top:-98px;}
}
@media screen and (min-width:1px) and (max-width:600px) {
	.archive .slick-prev, .archive .slick-next {top:-100px;}
	.archive .slick-list {margin:0 -5px;}
	.archive .slick-slide {
		margin:0 5px;
	}
	.archive .macin-tab__cont__tit {font-size:18px;}
	.archive .main-tab__cont__more {top:5px}

}

@media screen and (min-width:1px) and (max-width:420px) {
	.archive .slick-prev, .archive .slick-next {top:-102px;}
}
/*메인 이미지 사이즈 고정*/
/*이미지 고정 사이즈*/

.main-project {
	.fix-size {
		position: relative;
		width: 100%;
		height: 0;
		overflow: hidden;
		padding-bottom: 58%;
		/*16:9*/
		background-color: #eee;
		color: #fff;
	}
	
	.fix-size__in {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	
	.fix-size iframe {
		width: 100%;
		height: 100%;
	}
	
	.fix-size img {
		width: 100%;
		height: 100% !important;
			object-fit: cover;
			position: absolute;
	}

}



.SNS {
	.fix-size {
		position: relative;
		width: 100%;
		height: 0;
		overflow: hidden;
		padding-bottom: 100%;
		/*16:9*/
		background-color: #eee;
		color: #fff;
	}
	
	.fix-size__in {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	

}

.main-lay-1 .news {
	.popupzone-slide4 {}
	.fix-size {border:1px solid #e0e0e0;
		position: relative;
		width: 100%;
		height: 0;text-align: center;
		overflow: hidden;
		padding-bottom: 45.7%;
		/*16:9*/
		background-color: #eee;
		color: #fff;
		img {display:inline-block;}
	}
	
	.fix-size__in {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.main-lay-1 .popupzone {
	.popupzone-slide {}
	.fix-size {border:1px solid #e0e0e0;
		position: relative;
		width: 100%;
		height: 0;text-align: center;
		overflow: hidden;
		padding-bottom: 72.1%;
		
		/*16:9*/
		background-color: #eee;
		color: #fff;
		img {display:inline-block;}
	}
	
	.fix-size__in {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.main-tab {

	.fix-size {
		position: relative;
		width: 100%;
		height: 0;text-align: center;
		overflow: hidden;
		padding-bottom: 75.6%;
		/*16:9*/
		background-color: #eee;
		color: #fff;
		img {display:inline-block;border:1px solid #e0e0e0;box-sizing: border-box;
			position:absolute; left:50%; top:50%; transform:translate(-50%, -50%);
		}
	}
	
	.fix-size__in {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}


}