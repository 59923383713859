
// 유관기관 링크

.foot-go-link {
	background-color:#fafbff;
	position: relative;
	z-index: 9999;
	border-top:1px solid #dddddd;
	border-bottom:1px solid #dddddd;
	&__in {
		width:$siteSize;
		margin:0 auto;
		@include floatWrap;
		border-right:1px solid #dddddd;
		position: relative;
		@media screen and (min-width:1px) and (max-width:$siteSize) {
			width:auto;
			border-right:0px;
		}
		&__item {
			width: 25%;
			float:left;

			@media screen and (min-width:1px) and (max-width:$siteSize) {
				width:50%;
				position: relative;
			}
			@media screen and (min-width:1px) and (max-width:768px) {
				//width:100%;
				position: unset;
			}
			&__a {
				border-left:1px solid #dddddd;
				display: block;
				height: 60px;
				line-height: 60px;
				padding-left:20px;
				color:#555;
				font-weight: 600;
				font-size: 16px;
				@media screen and (min-width:1px) and (max-width:$siteSize) {
					border-bottom:1px solid #5f6570;
				}
				@media screen and (min-width:1px) and (max-width:768px) {
					height: 40px;
					line-height: 40px;
					border-left:0px;
				}
				&:hover,
				&:focus {

					background-color: #fff;
				}
				&:focus {
					text-decoration: underline;
				}
				position: relative;
				&:after {
					@include sprite($footlink-on);
					position: absolute;
					right:20px;
					top:50%;
					transform: translateY(-50%);

					.on & {@include sprite($footlink);}

					@media screen and (min-width:1px) and (max-width:768px) {
						right:10px;
					}
				}
				.on & {background-color: #fff;
				}
			}
			&__box {
				display: none;
				position: absolute;
				bottom:60px;
				@media screen and (min-width:1px) and (max-width:768px) {
					bottom:82px;
					width:100%;
				}

				background-color: #fff;
				box-shadow: 2px -4px 18px -10px rgba(0, 0, 0, 0.75);
				border-bottom:1px solid #e2e5f3;
				min-width: 300px;
				.on & {display: block;}

				.depth {
					@include floatWrap;
					.sec_box {
						@include floatWrap;
						float:left;
						ul {
							float:left;
							padding:20px;
							min-width: 100px;
							@media screen and (min-width:1px) and (max-width:768px) {
								padding:10px;
							}
							li {
								a {
									display: block;
									padding:7px 0;
									color:#333;
									font-size:15px;
									&:hover,
									&:focus {text-decoration: underline;}
									@media screen and (min-width:1px) and (max-width:768px) {
										padding:3px 0;
									}
								}
							}
						}
					}
				}
			}
		}
		&__item:nth-child(1) {
			.foot-go-link__in__item__box {
				left:0px
			}
		}
		&__item:nth-child(2) {
			.foot-go-link__in__item__box  {
				left:25%;
				@media screen and (min-width:1px) and (max-width:768px) {
					left:0;
				}
			}
		}
		&__item:nth-child(3) {
			.foot-go-link__in__item__box  {
				right:0px
			}
		}
		&__item:nth-child(4) {
			.foot-go-link__in__item__box  {
				right:0px
			}
		}
	}
}